import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchFilter from '../SearchFilter';
import { Button } from 'reactstrap';
import { closeFilterGroups } from 'utils/windowEvents';
import { usePrevious } from 'hooks';

const BusinessSearchFilter = ({
	addFilter,
	className,
	clearFilters,
	removeFilter,
	filters,
	isLV,
	isSV,
	translate,
	toggleShowFilter
}) => {
	const prevIsSV = usePrevious(isSV);
	const prevIsLV = usePrevious(isLV);

	useEffect(() => {
		if (isLV && !prevIsLV) {
			toggleShowFilter(true);
		} else if (isSV && !prevIsSV) {
			toggleShowFilter(false);
		}
	}, [isLV, isSV, prevIsLV, prevIsSV, toggleShowFilter]);

	const onDoneClick = useCallback(() => {
		closeFilterGroups();
		toggleShowFilter(false);
	}, [toggleShowFilter]);

	const classProps = classnames('business-search-filter-wrapper', className, !isSV && 'mt-4');

	return (
		<div className={classProps}>
			<SearchFilter
				className="business-search-filter"
				addFilter={addFilter}
				removeFilter={removeFilter}
				filters={filters}
			/>
			{isSV && (
				<div className="business-search-filter-footer">
					<Button onClick={clearFilters}>{translate('FilterModal.clear')}</Button>
					<Button active onClick={onDoneClick}>
						{translate('common.done')}
					</Button>
				</div>
			)}
		</div>
	);
};

BusinessSearchFilter.defaultProps = {};

BusinessSearchFilter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	className: PropTypes.string,
	clearFilters: PropTypes.func,
	filters: PropTypes.shape({
		amenities: PropTypes.object,
		cuisine: PropTypes.object,
		dietaryPreferences: PropTypes.object
	}),
	isLV: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired,
	toggleShowFilter: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default BusinessSearchFilter;
