import React, { useCallback, useMemo } from 'react';
import {
	EditProfileActions,
	FormFieldRequirements,
	MFAModalPage,
	ModalPage,
	PageError,
	PasswordInput,
	ProfileSectionItem,
	RequiredFieldNote
} from 'components/molecules';
import { useTranslate } from 'hooks';
import { Checkboxes, Col, Row } from 'components/atoms';
import { Form } from 'react-final-form';
import formFieldRequirements from './formFieldRequirements';
import { MFA_FIELD_ID, useEditMFAEnabled, useEditSecurityFormCancelModal, useEditSecurityMFAModal } from './hooks';
import validateEditSecurityForm from './utils/validate';
import { useUserPasswordMutation } from 'reactQuery/mutations';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
import { history } from 'utils/router';
import { useUserContext } from 'context';

const EditSecurityForm = () => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const labelTranslateKey = 'Profile.security.labels';
	const dispatch = useDispatch();

	const { updateUserPassword } = useUserPasswordMutation({
		setReduxIsLoading: (isLoading, message) => dispatch(setIsLoading(isLoading, message))
	});

	const { hasError: hasMFAError, onToggleMFA, showMFAModal, onUpdateMFA } = useEditSecurityMFAModal();
	const { onCancel, onDiscardChanges, showCancelModal } = useEditSecurityFormCancelModal();
	const { onMfaEnabledChange } = useEditMFAEnabled({ onEnableMFA: onUpdateMFA });

	const { initialValues, mfaRequired, usernameItem } = useMemo(
		() => ({
			initialValues: {
				currentPassword: '',
				newPassword: '',
				confirmPassword: '',
				mfaEnabled: user?.security?.mfaEnabled ? [MFA_FIELD_ID] : []
			},
			mfaRequired: user?.company?.security?.mfaRequired,
			usernameItem: { key: 'username', value: user?.userName }
		}),
		[user?.security, user?.company, user?.userName]
	);

	const onSubmit = useCallback(
		(values, form, complete) => {
			// clearMFAError();
			const data = {
				old: values.currentPassword,
				new: values.newPassword
			};

			return updateUserPassword(data, {
				onSuccess: () => {
					complete();
					history.goBack();
				},
				onError: e => {
					complete({ form: e?.response?.data?.response?.msg || 'Could not update password.' });
				}
			});
		},
		[updateUserPassword]
	);

	return (
		<Col className="edit-security-form">
			<RequiredFieldNote />
			<ProfileSectionItem item={usernameItem} section="security" className="mb-4" />
			<Form
				validate={validateEditSecurityForm.bind(this, true)}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ dirty, submitErrors, values, form: { change }, handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							{submitErrors?.form && <PageError message={[submitErrors?.form].join('\n')} />}

							<Col gap="15px" mt="36px">
								<PasswordInput
									id="curr-password-field"
									name="currentPassword"
									label={translate(`${labelTranslateKey}.currPassword`)}
									hideRequirements
								/>
								<PasswordInput
									id="new-password-field"
									name="newPassword"
									label={translate(`${labelTranslateKey}.newPassword`)}
									hideRequirements
								/>
								<PasswordInput
									id="confirm-password-field"
									name="confirmPassword"
									label={'Confirm Password'}
									hideRequirements
								/>
								<FormFieldRequirements
									formFieldText={formFieldRequirements(translate, validateEditSecurityForm(values))}
								/>
								{!mfaRequired && (
									<Row px="4px">
										<Checkboxes
											name={MFA_FIELD_ID}
											id={MFA_FIELD_ID}
											data={[
												{ label: 'Enable Multi-Factor Authentication', value: MFA_FIELD_ID }
											]}
											onChange={onMfaEnabledChange.bind(this, change)}
										/>
									</Row>
								)}
							</Col>
							<EditProfileActions primaryIsSubmit onCancel={onCancel.bind(this, dirty)} />
						</form>
					);
				}}
			/>
			{showCancelModal && <ModalPage secondaryClick={onDiscardChanges} />}
			{showMFAModal && (
				<MFAModalPage hasError={hasMFAError} section="phoneNumber" onPhoneNumberPageCancel={onToggleMFA} />
			)}
		</Col>
	);
};

EditSecurityForm.propTypes = {};

export default EditSecurityForm;
