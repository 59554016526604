import { connect } from 'react-redux';
import AddCreditCardStep from './ui-component';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { goToStep } from 'actions/profile_setup';
import { setCardToEdit } from 'actions/edit_payment';
import { closeModal } from 'actions/profile_setup';

const mapDispatchToProps = dispatch => ({
	closeProfileSetupModal: () => dispatch(closeModal()),
	goToGreeting: () => dispatch(goToStep(ProfileSetupSteps.GREETING)),
	setCardToEdit: card => dispatch(setCardToEdit(card))
});

export default connect(null, mapDispatchToProps)(AddCreditCardStep);
