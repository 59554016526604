// subscribeFormData

import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Body, Col, Row, Title } from 'components/atoms';
import { useTranslate } from 'hooks';

/*
interface ExistingUserInfoProps {

}
*/

const ExistingUserInfo = ({ titleKey, user } /*: ExistingUserInfoProps*/) => {
	const translate = useTranslate();

	const firstName = user?.firstName || user.name?.first || '';
	const lastName = user?.lastName || user.name?.last || '';

	return (
		<>
			{titleKey && (
				<Title mb="16px" transform="none" align="left">
					{translate(titleKey)}
				</Title>
			)}
			{user && (
				<Row gap="12px" extraClassName={styles.userInfo}>
					<div className={styles.avatar}>{firstName.slice(0, 1).toUpperCase()}</div>
					<Col>
						<Body variant="body2">
							{firstName} {lastName}
						</Body>
						<Body variant="body2">{user.email.primary}</Body>
					</Col>
				</Row>
			)}
		</>
	);
};

ExistingUserInfo.propTypes = {
	titleKey: PropTypes.string,
	user: PropTypes.object.isRequired
};

export default ExistingUserInfo;
