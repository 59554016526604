import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CTAItem } from 'components/atoms';
import { history } from 'utils/router';
import classnames from 'classnames';
import { useTranslate } from 'hooks';
import useGeneralContext from 'context/GeneralContext';

const POSITIVE = 'positive',
	NEGATIVE = 'negative';

const RecommendationEndorsementButtons = ({ restaurantId, isSV }) => {
	const translate = useTranslate();
	const {
		authState: { isAuthed },
		recommendationModal: { toggleShow }
	} = useGeneralContext();
	// (show, type, restaurantId) => dispatch(toggleShowModal(show, type, restaurantId))

	const toggleModal = useCallback(
		type => {
			if (isAuthed) {
				toggleShow({ show: true, type, restaurantId, prefill: false });
			} else {
				history.replace(`/login?redirect=${window.location.pathname + window.location.hash}`);
				toggleShow({ show: true, type, restaurantId, prefill: false });
			}
		},
		[isAuthed, restaurantId, toggleShow]
	);

	const onClick = useCallback(
		type => {
			toggleModal(type);
		},
		[toggleModal]
	);

	const className = classnames('flex justify-center', !isSV && 'rec-btns align-center');
	return (
		<div className="endorse">
			<div className={className}>
				<CTAItem
					dataCy="recommend-pos"
					className="secondary"
					config={{ text: 'Yes', action: onClick.bind(this, POSITIVE) }}
				>
					{translate('common.yes')}
				</CTAItem>
				<CTAItem
					dataCy="recommend-neg"
					className="secondary"
					config={{ text: 'No', action: onClick.bind(this, NEGATIVE) }}
				>
					{translate('common.no')}
				</CTAItem>
			</div>
		</div>
	);
};

RecommendationEndorsementButtons.propTypes = {
	restaurantId: PropTypes.string,
	isSV: PropTypes.bool.isRequired
};

export default RecommendationEndorsementButtons;
