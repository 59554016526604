import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyAutocomplete } from 'components/organisms';
import { history } from 'utils/router';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { hasProp } from 'utils/object';
import * as Modals from './components';
import { companyLookupSyncQuery } from 'reactQuery/queries/useCompanyLookupQuery';
import { useTranslate } from 'hooks';
import { Form } from 'react-final-form';
import { Body, Button, Col, Row } from 'components/atoms';
import { useUserContext } from 'context';

const CompanySelectModal = ({
	closeCompanySelectModal,
	location,
	setCompany: setCompanyProp,
	setDidRegisterWithIneligible,
	show = false,
	showCancelModal,
	showDeleteConnectionsWarningModal,
	showConfirmCompanyChangeToIneligibleModal,
	showMerckModal,
	showPointsEligibilityModal,
	showSelectModal,
	showSkipModal,
	startChangeStep,
	type,
	updateUser: updateUserProp,
	userCompany
}) => {
	const { user } = useUserContext();
	const hasConnections = (user?.connections || []).length > 0;
	const translate = useTranslate();

	const [showHelp, setShowHelp] = useState(false);
	const [initialCompany, setInitialCompany] = useState(userCompany?.name ? userCompany : null);
	const [initialCompanySet, setInitialCompanySet] = useState(userCompany?.name ? userCompany : false);
	const [submittingValues, setSubmittingValues] = useState();

	const { isRegistrationFlow } = useMemo(
		() => ({
			isRegistrationFlow: location.pathname.indexOf('/users/register') > -1
		}),
		[location]
	);

	useEffect(() => {
		if (!initialCompany && userCompany && show && !initialCompanySet) {
			setInitialCompany(userCompany);
			setInitialCompanySet(true);
		}
		if (!show && initialCompany) {
			setInitialCompanySet(false);
			setInitialCompany(undefined);
		}
	}, [initialCompany, userCompany, show, initialCompanySet]);

	const toggleShowHelp = useCallback(
		newShowHelp => {
			if (newShowHelp === undefined || newShowHelp.target) {
				newShowHelp = !showHelp;
			}

			setShowHelp(newShowHelp);
		},
		[showHelp]
	);

	const doShowSkipModal = useCallback(() => {
		toggleShowHelp(false);
		showSkipModal();
	}, [showSkipModal, toggleShowHelp]);

	const handleLegacyUser = useCallback(
		async (values, deleteConfirmed = false) => {
			const company = values.company_autocomplete;
			const hasSelectedCompanyItem = hasProp(company, 'name');
			let isEligible;

			if (hasSelectedCompanyItem) {
				isEligible = !(!company.myDinova.eligible || company.myDinova.participant !== 2);
			} else {
				const companyFound = await companyLookupSyncQuery(company?.name);
				if (companyFound) {
					if (company.code) {
						isEligible = companyFound.myDinova.eligible && companyFound.myDinova.participant === 2;
					} else {
						isEligible = false;
					}
				} else {
					isEligible = false;
				}
			}

			if (deleteConfirmed || isEligible) {
				await updateUser(values, () => setCompany(company), company.name);
			} else {
				showConfirmCompanyChangeToIneligibleModal();
			}
		},
		[setCompany, showConfirmCompanyChangeToIneligibleModal, updateUser]
	);

	const handleNotLegacyUser = useCallback(
		async (values, deleteConfirmed = false) => {
			const company = values.company_autocomplete;
			let _deleteConfirmed = typeof deleteConfirmed === 'boolean' ? deleteConfirmed : false;

			if (_deleteConfirmed || !hasConnections) {
				if (hasProp(company, 'name')) {
					if (!company.myDinova.eligible || company.myDinova.participant !== 2) {
						showConfirmCompanyChangeToIneligibleModal();
					} else {
						await updateUser(values, () => setCompany(company), company.name);
						if (company.name === 'Merck') {
							showMerckModal();
							return;
						}
					}
				} else {
					const companyFound = await companyLookupSyncQuery(company?.name);
					if (companyFound?.name === 'Merck') {
						showMerckModal();
						await updateUser(values, () => setCompany(companyFound), companyFound.name);
						return;
					}

					let isMyDinovaEligible = false;
					if (companyFound) {
						isMyDinovaEligible = companyFound.myDinova.eligible && companyFound.myDinova.participant === 2;
					}

					if (isMyDinovaEligible) {
						await updateUser(values, () => setCompany(companyFound), companyFound.name);
					} else {
						showConfirmCompanyChangeToIneligibleModal();
					}
				}
			} else if (!_deleteConfirmed || hasConnections) {
				showDeleteConnectionsWarningModal(() => confirm(values, null, true));
				return;
			}
		},
		[
			confirm,
			hasConnections,
			setCompany,
			showConfirmCompanyChangeToIneligibleModal,
			showDeleteConnectionsWarningModal,
			showMerckModal,
			updateUser
		]
	);

	const confirm = useCallback(
		async (values, form, deleteConfirmed = false) => {
			setSubmittingValues(values);
			if (isRegistrationFlow) {
				await handleNotLegacyUser(values, deleteConfirmed);
			} else {
				if (hasProp(initialCompany, 'code')) {
					await handleNotLegacyUser(values, deleteConfirmed);
				} else {
					// for updating a user's company when they don't have a company set but already have connections - we don't want to delete the connections
					await handleLegacyUser(values, deleteConfirmed);
				}
			}
		},
		[handleLegacyUser, handleNotLegacyUser, initialCompany, isRegistrationFlow]
	);

	const updateUser = useCallback(
		async (values, cb, companyName) => {
			const company = values.company_autocomplete;
			const name = companyName || company.name || company;
			let noChange = name === userCompany?.name;

			if (noChange) {
				cb && cb();
			} else {
				const doUpdate = () =>
					new Promise(resolve => {
						const callBack = () => {
							cb();
							resolve();
						};
						updateUserProp(
							{
								company: {
									name
								}
							},
							callBack
						);
					});

				await doUpdate();
			}
		},
		[updateUserProp, userCompany]
	);

	const confirmCompanyChangeToIneligible = useCallback(() => {
		const doAfterUpdate = () => {
			closeCompanySelectModal();
			if (!isRegistrationFlow) {
				showPointsEligibilityModal();
				history.push('/profile');
			} else {
				setDidRegisterWithIneligible();
				startChangeStep(4);
			}
		};
		updateUser(submittingValues, doAfterUpdate);
	}, [
		closeCompanySelectModal,
		isRegistrationFlow,
		setDidRegisterWithIneligible,
		showPointsEligibilityModal,
		submittingValues,
		startChangeStep,
		updateUser
	]);

	const cancel = useCallback(() => {
		toggleShowHelp(false);
		showCancelModal();
	}, [showCancelModal, toggleShowHelp]);

	const confirmCancel = useCallback(() => {
		if (window.location.pathname.includes('/profile/personal')) {
			goToProfile();
		} else {
			closeCompanySelectModal();
		}
	}, [closeCompanySelectModal, goToProfile]);

	const denyCancel = useCallback(() => {
		if (userCompany.name) {
			doClose();
		} else {
			showSelectModal();
		}
	}, [doClose, showSelectModal, userCompany]);

	const onClose = useCallback(() => {
		if (userCompany.name) {
			doClose();
		} else {
			cancel();
		}
	}, [cancel, doClose, userCompany]);

	const setCompany = useCallback(
		(_company, shouldDoClose = true) => {
			setCompanyProp && setCompanyProp(_company);
			if (shouldDoClose) {
				doClose();
			}
		},
		[doClose, setCompanyProp]
	);

	const doClose = useCallback(() => {
		toggleShowHelp(false);
		closeCompanySelectModal();
	}, [closeCompanySelectModal, toggleShowHelp]);

	const goToProfile = useCallback(() => {
		closeCompanySelectModal();
		history.push('/profile');
	}, [closeCompanySelectModal]);

	const selectModal = useMemo(() => {
		return (
			<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader toggle={onClose}>{translate(`select-company.header`)}</ModalHeader>
				<ModalBody>
					<Row pb={'20px'}>
						<Body>{translate(`select-company.body`)}</Body>
					</Row>
					<Form
						onSubmit={confirm}
						render={({ handleSubmit }) => (
							<>
								<Col gap={'15px'} align="stretch">
									<CompanyAutocomplete
										initialSelectedValue={user?.company?.name ? user?.company : { name: '' }}
									/>
									{/* Next Button */}
									<Row px={'15px'} pt={'28px'}>
										<Button
											fullWidth
											size="large"
											type="submit"
											variant="contained"
											onClick={handleSubmit}
											dataCy="company-select-submit"
										>
											{translate(`select-company.primaryButtonText`)}
										</Button>
									</Row>
									<Row px={'15px'} pb={isRegistrationFlow ? 0 : '22px'}>
										<Button
											fullWidth
											size="large"
											variant="outlined"
											onClick={() => toggleShowHelp(!showHelp)}
											dataCy="company-select-help"
											id="company-select-help"
										>
											{translate(`select-company.secondaryButtonText`)}
										</Button>
									</Row>

									{isRegistrationFlow && (
										<Row px={'15px'} pb="22px" justify="center">
											<Button
												fullWidth
												block
												className={'z-depth-0'}
												variant="text"
												onClick={doShowSkipModal}
												dataCy="company-select-skip"
												id="company-select-skip"
											>
												{translate(`select-company.tertiaryButtonText`)}
											</Button>
										</Row>
									)}
								</Col>
							</>
						)}
					/>
				</ModalBody>
			</Modal>
		);
	}, [
		confirm,
		doShowSkipModal,
		isRegistrationFlow,
		onClose,
		show,
		showHelp,
		toggleShowHelp,
		translate,
		user?.company
	]);

	const doSkip = useCallback(() => {
		closeCompanySelectModal();
		startChangeStep(4);
	}, [closeCompanySelectModal, startChangeStep]);

	if (type === 'merck') {
		return <Modals.MerckModal show={show} goToProfile={goToProfile} onClose={onClose} />;
	}

	if (type === 'unconnected') {
		return <Modals.UnconnectedModal show={show} doClose={doClose} onClose={onClose} />;
	}

	if (type === 'select') {
		return selectModal;
	}

	if (type === 'skip') {
		return (
			<Modals.SkipModal
				company={user?.company}
				doClose={doClose}
				onClose={onClose}
				doSkip={doSkip}
				show={show}
				showSelectModal={showSelectModal}
			/>
		);
	}

	if (type === 'cancel') {
		return <Modals.CancelModal confirmCancel={confirmCancel} denyCancel={denyCancel} show={show} />;
	}

	if (type === 'confirm_ineligible_company_change' || type === 'initial_ineligible_company_select') {
		return isRegistrationFlow ? (
			<Modals.InformInitialSelectOfIneligibleCompanyModal
				confirmCompanyChangeToIneligible={confirmCompanyChangeToIneligible}
				show={show}
				showSelectModal={showSelectModal}
			/>
		) : (
			<Modals.ConfirmCompanyChangeToIneligibleModal
				confirmCompanyChangeToIneligible={confirmCompanyChangeToIneligible}
				show={show}
				showSelectModal={showSelectModal}
			/>
		);
	}

	return null;
};

CompanySelectModal.propTypes = {
	closeCompanySelectModal: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	setCompany: PropTypes.func.isRequired,
	setDidRegisterWithIneligible: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showCancelModal: PropTypes.func.isRequired,
	showDeleteConnectionsWarningModal: PropTypes.func.isRequired,
	showConfirmCompanyChangeToIneligibleModal: PropTypes.func.isRequired,
	showMerckModal: PropTypes.func.isRequired,
	showPointsEligibilityModal: PropTypes.func.isRequired,
	showSelectModal: PropTypes.func.isRequired,
	showSkipModal: PropTypes.func.isRequired,
	startChangeStep: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	updateUser: PropTypes.func.isRequired,
	userCompany: PropTypes.object
};

export default CompanySelectModal;
