import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown, ReadOnlyInput, WithHelp } from 'components/molecules';
import { preferredCCTypeDisplayText } from 'utils/card';
import { useTranslate } from 'hooks';

/* eslint-disable no-mixed-spaces-and-tabs */
const ReduxFormCCTypeDropdown = ({ cards, className, disabled, doBeforeChange, field, readOnly, required }) => {
	const translate = useTranslate();
	const { input, meta } = field;

	return readOnly ? (
		<ReadOnlyInput
			dataCy="cc-type"
			labelKey="ConnectToPoints.inputs.creditcardType.singleCardLabel"
			value={`${this.props.company.supportedConnections[0].issuer.name} (${preferredCCTypeDisplayText(
				this.props.company.supportedConnections[0].cardType
			)})`}
		/>
	) : (
		<WithHelp
			help={translate('ConnectToPoints.inputs.creditcardTypeTitle.help')}
			id="cc-type-select"
			className={classnames(
				className && className,
				disabled && 'disabled',
				required && 'required',
				cards.length === 1 && 'hidden'
			)}
		>
			<Dropdown
				disabled={disabled}
				dataCy="cc-type-select"
				isRequired={true}
				title={translate('ConnectToPoints.inputs.creditcardType.label')}
				hasError={!meta.valid}
				error={meta.valid ? '' : translate(field.meta.error)}
				onChange={(card /*, cardIndex*/) => {
					if (doBeforeChange) {
						doBeforeChange(card);
					}
					input.onChange(card);
				}}
				required={required}
				value={
					input.value.id
						? {
								displayValue: `${input.value.issuer.name} (${preferredCCTypeDisplayText(
									input.value.cardType
								)})`,
								value: input.value.id
						  }
						: undefined
				}
				options={
					cards.length
						? cards.map(type => {
								return {
									...type,
									value: type.cardType,
									displayValue: `${type.issuer.name} (${preferredCCTypeDisplayText(type.cardType)})`
								};
						  })
						: []
					/* eslint-enable no-mixed-spaces-and-tabs */
				}
			/>
		</WithHelp>
	);
};

ReduxFormCCTypeDropdown.defaultProps = {};

ReduxFormCCTypeDropdown.propTypes = {
	cards: PropTypes.array.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	doBeforeChange: PropTypes.func,
	field: PropTypes.object.isRequired,
	readOnly: PropTypes.bool,
	required: PropTypes.bool
};

export default ReduxFormCCTypeDropdown;
