import React, { useMemo } from 'react';
import useRecommendationOptionsContext from 'reactQuery/context/RecommendationOptionsContext';
import { Checkboxes, Col, Row } from 'components/atoms';
import { TextField } from 'mui-rff';

import styles from './style.module.scss';

/*
interface NegativeRecommendationFormFieldsProps {

}
*/

const NegativeRecommendationFormFields = (/*: NegativeRecommendationFormFieldsProps*/) => {
	const { negative } = useRecommendationOptionsContext();

	const negativeGroups = useMemo(() => {
		return negative.map(pos => {
			return {
				...pos,
				checkboxData: pos.categories.map(cat => {
					return {
						label: cat.name,
						value: cat.id,
						checked: cat.selected
					};
				})
			};
		});
	}, [negative]);

	return (
		<Col gap="28px" fullWidth dataCy="negative-recommendation-form">
			{negativeGroups.map(group => {
				return (
					<Col fullWidth key={group.name} data-cy={`rec-group-${group.name}`}>
						<Row className={styles.checkboxWrapper}>
							<Checkboxes dataCy="recommendation-checkboxes" name="for" data={group.checkboxData} />;
						</Row>
						<Row fullWidth p="7px 26px 0">
							<TextField
								name="feedback"
								multiline
								label="Please describe your experience"
								inputProps={{ minRows: 6 }}
							/>
						</Row>
					</Col>
				);
			})}
		</Col>
	);
};

// NegativeRecommendationFormFields.propTypes = {
// };

export default NegativeRecommendationFormFields;
