// import { User } from "@/types/user";

export const validate = (values /*: User*/) => {
	const errors = {
		name: {},
		email: {}
	}; /* as User;*/
	if (!values.name.first) {
		errors['name']['first'] = 'First Name Required';
	}
	if (!values.name.last) {
		errors['name']['last'] = 'Last Name Required';
	}
	if (!values.email.primary) {
		errors['email']['primary'] = 'Email Required';
	}

	return errors;
};

export const getInitialValuesFromUser = (user /*: User*/) => ({
	name: {
		first: user?.name?.first,
		last: user?.name?.last
	},
	email: {
		alternate: user?.email?.alternate,
		primary: user?.email?.primary
	},
	phone: {
		mobile: user?.phone?.mobile
	},
	address: {
		mailing: {
			postalCode: user?.address?.mailing?.postalCode
		}
	},
	company: user?.company,
	diningTypes: (user?.diningTypes || []).map(dt => dt.id)
});
