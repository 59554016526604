import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY, USER_RECOMMENDATIONS_QUERY_KEY, RESTAURANT_DETAILS_QUERY } from 'reactQuery/queries';
/*
interface UseUserRecommendationMutationProps {}
*/

const useUserRecommendationMutation = (
	{
		onError,
		onSuccess,
		setErrorInRedux,
		setDataInRedux,
		setReduxIsLoading
	} = {} /*:UseUserRecommendationMutationProps*/
) => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: ({ restaurantId, form }) => {
			return axios.post(
				`${basePath()}/users/recommendations/${restaurantId}`,
				JSON.stringify(form),
				AUTH_REQUEST_HEADER()
			);
		},
		onMutate: () => {
			setReduxIsLoading && setReduxIsLoading(true, 'Submitting Recommendation...');
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			if (setErrorInRedux) {
				setErrorInRedux(error);
			}

			onError && onError(error);
		},
		onSuccess: (data /*, variables, context*/) => {
			queryClient.invalidateQueries({ queryKey: [USER_RECOMMENDATIONS_QUERY_KEY] });
			queryClient.invalidateQueries({ queryKey: [RESTAURANT_DETAILS_QUERY] });
			queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });

			queryClient.refetchQueries({
				queryKey: [USER_RECOMMENDATIONS_QUERY_KEY]
			});
			queryClient.refetchQueries({
				queryKey: [RESTAURANT_DETAILS_QUERY]
			});
			queryClient.refetchQueries({
				queryKey: [USER_QUERY_KEY]
			});

			if (data.data.errors) {
				onError && onError(data);
			} else {
				onSuccess && onSuccess(data);

				if (setDataInRedux) {
					setDataInRedux(data);
				}
			}
		},
		onSettled: () => {
			setReduxIsLoading && setReduxIsLoading(false);
		}
	});

	return {
		isMutating: mutation.isPending || false,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		submitRecommendation: mutation.mutate
	};
};

export default useUserRecommendationMutation;
