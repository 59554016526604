import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/organisms';
import { FormFieldRequirements } from 'components/molecules';

const ReduxFormInput = ({ field, inputProps }) => {
	return (
		<InputField
			onBlur={field.input.onBlur}
			onFocus={field.input.onFocus}
			onChange={field.input.onChange}
			value={field.input.value}
			checked={field.input.checked}
			hasRequirements={field.meta.warning ? true : false}
			isReduxForm
			{...inputProps}
		>
			{field.meta.warning && (
				<FormFieldRequirements
					formFieldText={{
						requirements: field.meta.warning.requirements,
						titleKey: field.meta.warning.titleKey
					}}
				/>
			)}
			{/*{ requirements: requirements.requirements, title: requirements.title }*/}
		</InputField>
	);
};

ReduxFormInput.defaultProps = {};

ReduxFormInput.propTypes = {
	field: PropTypes.object.isRequired,
	inputProps: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		error: PropTypes.string,
		hasError: PropTypes.bool,
		value: PropTypes.any,
		label: PropTypes.any.isRequired,
		help: PropTypes.string,
		example: PropTypes.string,
		recentSearches: PropTypes.node
	}).isRequired
};

export default ReduxFormInput;
