import React from 'react';
import PropTypes from 'prop-types';
import { Body, Row } from 'components/atoms';
import { IconError } from 'components/atoms';
import styles from './style.module.scss';

/*
interface FormServerErrorProps {

}
*/

const FormServerError = ({ error } /*: FormServerErrorProps*/) => {
	return error ? (
		<Row fullWidth gap="8px" align="center" extraClassName={styles.errorWrapper}>
			<IconError width="30" height="30" fill="#e10600" />
			<Body variant="body1" weight="bold" color="error" dataCy="onboarding-email-pref-server-error">
				{error}
			</Body>
		</Row>
	) : null;
};

FormServerError.propTypes = {
	error: PropTypes.string
};

export default FormServerError;
