import StepTemplate from './ui-component';
import { connect } from 'react-redux';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { goToStep } from 'actions/profile_setup';

const mapDispatchToProps = dispatch => ({
	goToGreeting: () => dispatch(goToStep(ProfileSetupSteps.GREETING))
});

export default connect(null, mapDispatchToProps)(StepTemplate);
