import React, { useMemo } from 'react';
import COVID19Message from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import useGeneralContext from 'context/GeneralContext';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	applyPickupDeliveryFilter: filters => {
		filters.forEach(f => ownProps.addFilter(f));
	}
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

const DataWrapper = props => {
	const { covid19State } = useGeneralContext();
	const moreProps = useMemo(
		() => ({
			config: covid19State?.config,
			COVID19State: (covid19State?.state || '').toUpperCase(),
			show: covid19State?.show,
			toggleCovidShow: covid19State?.toggleCovidShow
		}),
		[covid19State]
	);
	return <COVID19Message {...props} {...moreProps} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(DataWrapper));
