import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { HelpIcon, Row } from 'components/atoms';
import { TextField } from 'mui-rff';
import { useTranslate } from 'hooks';
import { getCCInputLabelBasic, getCCHelp } from 'utils/card';
import { IconButton, InputAdornment } from '@mui/material';
import useGeneralContext from 'context/GeneralContext';
/*
interface CardNumberFieldProps {

}
*/

const CardNumberField = ({ disabled, selectedCard } /*: CardNumberFieldProps*/) => {
	const translate = useTranslate();
	const { isDoDMode } = useGeneralContext();

	const [showCardNumber, setShowCardNumber] = useState(false);
	const toggleShowCardNumber = useCallback(() => {
		setShowCardNumber(prev => !prev);
	}, []);

	const icon = showCardNumber
		? 'https://dinova-images-dev.imgix.net/icons/icon-show.svg'
		: 'https://dinova-images-dev.imgix.net/icons/icon-hide.svg';

	const label = isDoDMode
		? translate('ConnectToPoints.inputs.creditcard.dod-card-label')
		: getCCInputLabelBasic(
				translate,
				selectedCard ? selectedCard.cardType : undefined,
				selectedCard ? selectedCard.fullNumberRequired : true
		  );

	return (
		<Row fullWidth align="flex-start">
			<TextField
				data-cy="card-number"
				disabled={disabled}
				label={label}
				name="cardNumber"
				required={true}
				type={showCardNumber ? 'text' : 'password'}
				helperText={
					selectedCard && selectedCard.instructions
						? selectedCard.instructions.selectedCard === 'null'
							? ''
							: selectedCard.instructions.card
						: null
				}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={toggleShowCardNumber}>
								<img
									style={{ height: 30, width: 30 }}
									src={icon}
									alt={showCardNumber ? 'Hide' : 'Show'}
								/>
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
			<HelpIcon color="secondary" text={selectedCard ? getCCHelp(translate, selectedCard) : ''} />
		</Row>
	);
};

CardNumberField.propTypes = {
	disabled: PropTypes.bool,
	selectedCard: PropTypes.object
};

export default CardNumberField;
