import React, { useCallback } from 'react';
import { useTranslate } from 'hooks';
import { useDispatch } from 'react-redux';
import { toggleShowCompanySelectModal } from 'actions/company_select_modal';

/*
interface NotYourCompanyButtonProps {}
*/

const NotYourCompanyButton = (/*{} : NotYourCompanyButtonProps*/) => {
	const dispatch = useDispatch();
	const translate = useTranslate();

	const onNotYourCompanyClick = useCallback(() => {
		dispatch(toggleShowCompanySelectModal(true, 'select'));
	}, [dispatch]);

	return (
		<button data-cy="not-your-company" className="link-button" onClick={onNotYourCompanyClick}>
			{translate('ConnectToPoints.not-your-company')}
		</button>
	);
};

export default NotYourCompanyButton;
