import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { toggleBodyClassName } from 'utils/DOM';
import { usePrevious } from 'hooks';

const BasicModal = ({
	center,
	children,
	className,
	dataCy,
	iconBaseURL,
	maxWidth,
	onClose,
	show,
	showClose = true
}) => {
	const previousShow = usePrevious(show);

	// componentDidMount
	useEffect(() => {
		if (show) {
			toggleBodyClassName('basic-modal-open', true);
		}
	});

	// componentDidUpdate
	useEffect(() => {
		if (show !== previousShow) {
			toggleBodyClassName('basic-modal-open', show);
		}

		// componentWillUnmount
		return () => {
			toggleBodyClassName('basic-modal-open', false);
		};
	}, [previousShow, show]);

	const classProps = classnames('basic-modal', className, center && 'center');

	const style = maxWidth ? { maxWidth } : {};

	return show ? (
		<div className={classProps} data-cy={dataCy}>
			<div className="basic-modal-content-wrapper" style={style}>
				{showClose && (
					<div className="close-wrapper">
						<img
							src={`${iconBaseURL}/icon-close-black.svg`}
							alt="green close button"
							className="close-btn"
							onClick={onClose}
						/>
					</div>
				)}
				<div className="basic-modal-content">{children}</div>
			</div>
		</div>
	) : null;
};

BasicModal.propTypes = {
	center: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	iconBaseURL: PropTypes.string.isRequired,
	maxWidth: PropTypes.string,
	onClose: PropTypes.func,
	showClose: PropTypes.bool,
	show: PropTypes.bool.isRequired
};

export default BasicModal;
