import React from 'react';
import RecommendationsModal from './ui-component';
import { RecommendationOptionsContextProvider } from 'reactQuery/context/RecommendationOptionsContext';

const WrappedRecommendationsModal = props => (
	<RecommendationOptionsContextProvider>
		<RecommendationsModal {...props} />
	</RecommendationOptionsContextProvider>
);

export default WrappedRecommendationsModal;
