import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { currencyFormat } from 'utils/formatters';
import { useTranslate } from 'hooks';

const ViewRewardOptions = ({ className, cards }) => {
	const translate = useTranslate();
	const classProps = classNames('view-reward-options', className);

	return (
		<div className={classProps}>
			<Title className="list-title" align="left" size="h3">
				{translate('Rewards.optionsTitle')}
			</Title>
			<ul className="list" data-cy="view-reward-options">
				{cards.map((card, index) => {
					const amount = Number.parseInt(card.amount),
						points = Number.parseInt(card.points);

					return (
						<li key={index.toString()} data-cy={`view-reward-option-${points}`}>
							<span className="points" data-cy="points">
								{currencyFormat(points)} {translate('RewardView.pointsSuffix')}
							</span>
							<span className="money" data-cy="money">
								($
								{currencyFormat(amount)} {translate('RewardView.moneySuffix')})
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

ViewRewardOptions.defaultProps = {
	className: '',
	cards: []
};

ViewRewardOptions.propTypes = {
	className: PropTypes.string,
	cards: PropTypes.array.isRequired
};

export default ViewRewardOptions;
