import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { handleNavLinkClick } from 'utils/router';
import { Button } from 'components/atoms';

const NavItem = ({ children, itemClassName, disabled, hidden, item, isActive, parentKey }) => {
	if (hidden) {
		return null;
	}

	const wrapperClass = classNames('nav-item', disabled && 'disabled');

	const classProps = classNames(
			'nav-link',
			itemClassName && itemClassName,
			isActive && 'active',
			item.upperCase === false && 'normal-text'
		),
		title = item.mobileTitle || item.title;

	return (
		<li className={wrapperClass} data-cy={`nav-item-${title}`}>
			{!item.url && (
				/* Is just a menu item header */
				<div
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
					key={`NavItem-${parentKey || item.url}`}
					className={classProps}
				>
					<Button variant="nav-item">{title}</Button>
				</div>
			)}
			{item.url && (
				<>
					{item.fullUrl === true ? (
						<a
							href={item.url}
							target="_blank"
							rel="noopener noreferrer"
							className={classProps}
							key={`NavItem-${parentKey || item.url}`}
							onClick={handleNavLinkClick.bind(this, item.url)}
						>
							{item.variant === 'primary' ? <Button variant="nav-item">{title}</Button> : title}
						</a>
					) : (
						<Link
							to={item.url}
							className={classProps}
							key={`NavItem-${parentKey || item.url}`}
							onClick={handleNavLinkClick.bind(this, item.url)}
						>
							{item.variant === 'primary' ? <Button variant="nav-item">{title}</Button> : title}
						</Link>
					)}
				</>
			)}
			{children}
		</li>
	);
};

NavItem.defaultProps = {
	hidden: false,
	item: {
		fullUrl: false,
		mobileTitle: null,
		title: 'Title',
		url: '/',
		upperCase: false
	},
	isActive: false
};

NavItem.propTypes = {
	// Required
	parentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	// Optional
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	itemClassName: PropTypes.string,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	item: PropTypes.object,
	isActive: PropTypes.bool
};

export default NavItem;
