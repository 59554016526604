import React from 'react';
import PropTypes from 'prop-types';
import { LogoImage } from 'components/molecules';
import { Text } from 'components/atoms';

/**
 * A splash image container.  Sets image by using a div with backgroundImage: props.bannerImgUrl
 * Allows for an optional logoImg to be displayed ontop of the splash image.  logoImg is renered in an img.
 * @param {JumbotronProps} props
 */
const Jumbotron = ({ bannerImg, dataCy, logoAlt, logoImg, promoAlt, promotionalText, promotional, withoutLogo }) => {
	if (!bannerImg || bannerImg === null || bannerImg === 'null') {
		return null;
	}

	const bannerImgUrl = `url("${bannerImg}")`;

	return (
		<div className="jumobotron" data-cy={`jumbotron-${dataCy}`}>
			{promotional && <img src={bannerImg} data-cy={`jumbotron-promo-img-${dataCy}`} alt={promoAlt} />}
			{!promotional && (
				<div
					className="banner-img"
					data-cy={`jumbotron-banner-img-${dataCy}`}
					style={{ backgroundImage: bannerImgUrl }}
				/>
			)}
			{withoutLogo !== true && logoImg && (
				<LogoImage dataCy={dataCy} className="logo-img" src={logoImg} alt={logoAlt} />
			)}
			{promotionalText && (
				<div className="promotional-text" data-cy={`jumbotron-promo-text`}>
					<Text>{promotionalText}</Text>
				</div>
			)}
		</div>
	);
};

Jumbotron.defaultProps = {
	logoImg: '',
	withoutLogo: false,
	promotional: false
};

/**
 * {@link Jumbotron} Props
 * @interface Props_Jumbotron
 * @property {string} bannerImg            The src for the splash image.
 * @property {string} [dataCy]             A unique id for cypress tests.
 * @property {string} [logoImg]            The src for the logo image.
 * @property {string} [logoAlt]            The alt for the logo image.
 * @property {string} [promotionalText]    The text to show.
 * @property {boolean} [promotional]       If will include a promo image/text.
 * @property {string} [promoAlt]           The alt for the promo image.
 * @property {boolean} [withoutLogo=false] If to show a logo image.
 */
Jumbotron.propTypes = {
	// Required
	bannerImg: PropTypes.string.isRequired,
	// Optional
	dataCy: PropTypes.string,
	logoAlt: PropTypes.string,
	logoImg: PropTypes.string,
	withoutLogo: PropTypes.bool,
	promoAlt: PropTypes.string,
	promotionalText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	promotional: PropTypes.bool
};

export default Jumbotron;
