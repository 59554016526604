const MuiMenuTheme = {
	styleOverrides: {
		paper: {
			border: 'solid #1c871c 3px', // $dinova-green
			borderRadius: '0 0 5px 5px',

			'&.select-menu': {
				marginTop: '-3px'
			}
		},
		popper: {
			// top: '-3px !important'
		},
		list: {
			padding: 0
			// backgroundColor: 'red'
		}
	}
};

export default MuiMenuTheme;
