import { useMemo } from 'react';

// interface useAppDownloadQRCodeProps {}

const useAppDownloadQRCode = (isDownloadPath = false) => {
	const qrCodeImgSrc = useMemo(() => {
		if (isDownloadPath) {
			return '/download_landing_page_prod_qrCode.png';
		}
		const host = window.location.host;

		// local
		if (host.includes('local')) {
			return '/local_qrCode.png';
		}

		// feature
		if (host.includes('feature')) {
			return '/feature_qrCode.png';
		}

		// p4
		if (host.includes('p4')) {
			return '/p4_qrCode.png';
		}

		// uat
		if (host.includes('apps.uat')) {
			return '/uat_qrCode.png';
		}
		// prod
		if (host.includes('apps.dinova')) {
			return '/prod_qrCode.png';
		}

		return 'prod_qrCode.png';
	}, [isDownloadPath]);

	return qrCodeImgSrc;
};

export default useAppDownloadQRCode;
