const updateFiltersFromURLParams = (urlParams, userFilters) => {
	let newFilters = Object.assign({}, userFilters);
	let selectedFilters = [];

	Object.entries(urlParams).forEach(([urlKey, urlVal]) => {
		Object.entries(newFilters).forEach(([filterGroupName, filterGroup]) => {
			if (filterGroupName.toLowerCase() === urlKey) {
				Object.values(filterGroup).forEach(filterItem => {
					urlVal.split(',').forEach(val => {
						if (
							filterItem.filterKey &&
							filterItem.filterKey.toLowerCase() === `${urlKey}.${val.toLowerCase()}`
						) {
							filterItem.selected = true;
							filterGroup.activeCount += 1;
							selectedFilters.push(filterItem);
						}
					});
				});
			}
		});
	});

	return { newFilters, selectedFilters };
};

export default updateFiltersFromURLParams;
