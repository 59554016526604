import React, { useCallback, useMemo } from 'react';
import StepListItem from './StepListItem';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { SETUP_FLOW_URL_PARAM, SETUP_COMPLETE_URL_PARAM } from 'components/organisms/ProfileSetupModal/constants';
import { selectCompletedStepsFromUser } from 'actions/profile_setup/selectors';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

const ProfileSetupStepList = ({ closeProfileSetupModal, history }) => {
	const translate = useTranslate();
	const { user } = useUserContext();

	const { creditCardComplete, emailComplete, profileComplete, favoritesComplete } = useMemo(
		() => selectCompletedStepsFromUser(user),
		[user]
	);

	const translateBaseKey = 'ProfileSetup.modals';
	const navigateToSearch = useCallback(() => {
		closeProfileSetupModal();
		const url = new URL(window.location.href);
		const path = url.pathname;
		const queryString = url.search;
		const resetSearch = path === '/search' && queryString.includes('setupComplete=true');
		const newUrl = resetSearch
			? `/search?${SETUP_FLOW_URL_PARAM}&${SETUP_COMPLETE_URL_PARAM}`
			: `/search?${SETUP_FLOW_URL_PARAM}`;
		history.push(newUrl);

		// if (resetSearch) history.go(0);
	}, [closeProfileSetupModal, history]);

	return (
		<li className={styles.list} data-cy="step-list">
			<StepListItem
				complete={profileComplete}
				title={translate(`${translateBaseKey}.step1.title`)}
				step={ProfileSetupSteps.PROFILE}
				dataCy="profile"
			/>
			<StepListItem
				complete={creditCardComplete}
				title={translate(`${translateBaseKey}.step2.title`)}
				step={ProfileSetupSteps.ADD_CREDIT_CARD}
				dataCy="add-credit-card"
			/>
			<StepListItem
				complete={emailComplete}
				title={translate(`${translateBaseKey}.step3.title`)}
				step={ProfileSetupSteps.EMAILS}
				dataCy="emails"
			/>
			<StepListItem
				complete={favoritesComplete}
				title={translate(`${translateBaseKey}.step4.title`)}
				step={ProfileSetupSteps.FAVORITE}
				onClick={navigateToSearch}
				dataCy="favorites"
			/>
		</li>
	);
};

ProfileSetupStepList.propTypes = {
	closeProfileSetupModal: PropTypes.func,
	history: PropTypes.object
};

export default ProfileSetupStepList;
