// import { Company } from '@/types';
import { useCompanyAutocompleteQueryNew } from 'reactQuery/queries';

/*
interface useCompanyAutocompleteProps {
  searchValue?: string;
}
*/

const useCompanyAutocomplete = ({ searchValue } /*: useCompanyAutocompleteProps*/) => {
	const { data, fetchStatus, ...others } = useCompanyAutocompleteQueryNew({
		searchValue
	});

	return {
		...others,
		data: data /*as Company.Company[]*/,
		loading: ['fetching'].includes(fetchStatus)
	};
};

export default useCompanyAutocomplete;
