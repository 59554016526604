import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Step } from 'components/atoms';
import { StepIndicators } from 'components/molecules';

const RegistrationSteps = props => {
	const { className, registration, location } = props;
	const classProps = classNames('registration-steps ', className);

	const stepLeft = (currentStep, steps) => {
		const stepBaseWidth = 67;
		const baseOffset = 12;
		const dividedStepsPercent = stepBaseWidth / steps.length;
		if (currentStep === 1) {
			return baseOffset * currentStep;
		} else {
			return dividedStepsPercent * currentStep;
		}
	};

	return (
		<div className={classProps}>
			<style
				dangerouslySetInnerHTML={{
					__html: [
						'.step-indicators:after {',
						'  width: ' + stepLeft(registration.currentStep, registration.steps) + '%;',
						'}'
					].join('\n')
				}}
			/>
			{registration.currentStep < 4 && <Step registration={registration} />}
			{location.pathname.indexOf('users/register') > -1 && <StepIndicators registration={registration} />}
		</div>
	);
};

RegistrationSteps.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
	registration: PropTypes.object
};

export default RegistrationSteps;
