import { asyncComponent } from 'hoc';

const LazyRoster = asyncComponent(() => {
	return import(
		/* webpackChunkName: "DashboardPage" */
		/* webpackMode: "lazy" */
		'.'
	).then(module => module.default);
});

export default LazyRoster;
