import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Body content to render inside of a {@link TabPanel}.
 * @param {TabPanelBodyProps} props
 */
const TabPanelBody = ({ className, match, tabs }) => {
	const classProps = classnames('tab-panel-body-wrap', className);

	return (
		<div className={classProps}>
			{tabs.map((tab, i) => {
				const isActive = tab.applyURLParams(tab, match)[1];
				const activeClass = classnames('tab-panel', isActive && 'active');

				return (
					<div key={`tab-body-${i}`} className={activeClass}>
						{tab.component}
					</div>
				);
			})}
		</div>
	);
};

TabPanelBody.defaultProps = {
	activeTab: 0,
	tabs: []
};

/**
 * {@link TabPanelBody}
 * @interface Props_TabPanelBody
 * @property {match} match React router match object
 * @property {ITab[]} tabs
 */
TabPanelBody.propTypes = {
	className: PropTypes.string,
	tabs: PropTypes.array,
	match: PropTypes.object
};

export default TabPanelBody;
