import React, { useCallback } from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { InvisibleButton, Text } from 'components/atoms';
import { history } from 'utils/router';

const ComingSoonBanner = ({ translate }) => {
	let message = <Text className={styles.link}>{translate('Banner.comingSoon')}</Text>;

	const onClick = useCallback(() => {
		if (!window.location.pathname.includes('download')) {
			history.push('/we-moved');
		}
	}, []);

	return (
		<div className={styles.wrapper}>
			<InvisibleButton onClick={onClick}>
				<div className={styles.btnContent}>{message}</div>
			</InvisibleButton>
		</div>
	);
};

ComingSoonBanner.propTypes = {
	translate: PropTypes.func.isRequired
};

export default ComingSoonBanner;
