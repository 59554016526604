import React from 'react';
import PropTypes from 'prop-types';
import { HelpIcon, Row } from 'components/atoms';
import { TextField } from 'mui-rff';
import { useTranslate } from 'hooks';

/*
interface CardholderNameInputProps {

}
*/

const CardholderNameInput = ({ disabled }) => {
	const translate = useTranslate();

	return (
		<Row align="flex-start" fullWidth>
			<TextField
				label={translate('ConnectToPoints.inputs.cardholderName.label')}
				name="nameOnCard"
				required={true}
				helperText={translate('ConnectToPoints.inputs.cardholderName.example')}
				data-cy="cardholder-name"
				disabled={disabled}
			/>
			<div style={{ marginTop: 6 }}>
				<HelpIcon color="secondary" text={translate('ConnectToPoints.inputs.cardholderName.help')} />
			</div>
		</Row>
	);
};

CardholderNameInput.propTypes = {
	disabled: PropTypes.bool
};

export default CardholderNameInput;
