import React from 'react';
import PropTypes from 'prop-types';
import { PageHeaderTitle, Text } from 'components/atoms';
import { PageHeader } from 'components/molecules';
import { useTranslate } from 'hooks';

const AutoEnrollUserInvalidToken = ({ isLoading }) => {
	const translate = useTranslate();

	return (
		<div className="flex row justify-center" data-cy="auto-enroll-invalid-token">
			<PageHeader hideImage={true}>
				<PageHeaderTitle align="center" dataCy="update-personal-title">
					{translate('AutoEnrollUserForm.invalidToken.header')}
				</PageHeaderTitle>
			</PageHeader>
			{!isLoading && (
				<React.Fragment>
					<Text>{translate('AutoEnrollUserForm.invalidToken.description')}</Text>
				</React.Fragment>
			)}
		</div>
	);
};

AutoEnrollUserInvalidToken.defaultProps = {};

AutoEnrollUserInvalidToken.propTypes = {
	isLoading: PropTypes.bool.isRequired
};

export default AutoEnrollUserInvalidToken;
