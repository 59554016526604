import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { PageError } from 'components/molecules';
import { useTranslate } from 'hooks';

const ShareBox = ({ className = '', link, isLoadingLink = false, showLearnMore = false }) => {
	const translate = useTranslate();
	// const [copied, setCopied] = useState(false);
	const [message, setMessage] = useState(translate('ReferACoWorker.copybutton_copy'));

	const classProps = classNames('share-box', className);
	const showError = !isLoadingLink && !link;

	const copyLinkToClipboard = useCallback(() => {
		navigator.clipboard.writeText(link);
		setMessage(translate('ReferACoWorker.copybutton_success'));
		setTimeout(() => {
			setMessage(translate('ReferACoWorker.copybutton_copy'));
		}, 3000);
	}, [link, translate]);

	return (
		<div className={classProps}>
			<Row className={'mt-3 mb-3'}>
				<Col className={'text-center darker'}>{translate('ReferACoWorker.sharelink')}</Col>
			</Row>
			{showLearnMore && (
				<Link to="/refer/details" className="learn-more">
					{translate('common.learnMoreBtn')}
				</Link>
			)}
			{link && (
				<Col>
					<Col
						md="12"
						xs="12"
						className="d-flex justify-content-center align-items-center link"
						data-cy="referral-link"
					>
						{link}
					</Col>
					<Col md="12" xs="12" className="d-flex justify-content-center align-items-center copy">
						{/* <CopyToClipboard text={link} onCopy={toggleAlert} data-cy="copy-referral-button"> */}
						<Button className="z-depth-0" onClick={copyLinkToClipboard} data-cy="copy-referral-button">
							{message}
						</Button>
						{/* </CopyToClipboard> */}
					</Col>
				</Col>
			)}
			{showError && <PageError type="warning" message={translate('errors.missing_link')} />}
		</div>
	);
};

ShareBox.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string,
	isLoadingLink: PropTypes.bool,
	showLearnMore: PropTypes.bool
};

export default ShareBox;
