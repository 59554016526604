import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { chain, cloneDeep, map } from 'lodash';
import { useTranslate } from 'hooks';

const SearchFilterBar = ({ amenityItems, className = '', cuisineItems, dietaryItems, isSV, onFilter, ...others }) => {
	const translate = useTranslate();
	const selectedFilters = useCallback(() => {
		const filterItems = [
			...map(cuisineItems, (item, index) => ({ ...item, filterName: 'cuisine', filterIndex: index })),
			...map(dietaryItems, (item, index) => ({ ...item, filterName: 'dietary', filterIndex: index })),
			...map(amenityItems, (item, index) => ({ ...item, filterName: 'amenity', filterIndex: index }))
		];
		return chain(filterItems)
			.filter(item => item.selected === true)
			.sortBy('value')
			.value();
	}, [amenityItems, cuisineItems, dietaryItems]);

	const removeFilter = useCallback(
		(filterName, filterIndex) => {
			const filterPath = filterName + 'Items';
			let newItems = cloneDeep(others[filterPath]);
			newItems[filterIndex].selected = false;
			onFilter(filterName, filterPath, newItems, filterIndex);
		},
		[onFilter, others]
	);

	const classProps = classNames('search-filters-bar', className);

	return (
		<div className={classProps}>
			{isSV === false &&
				selectedFilters.length > 0 && [
					<span className="title" key={0}>
						{translate('FilterModal.title')}:
					</span>,
					<div className="selected-filters-list" key={1}>
						{map(selectedFilters, item => (
							<div className="filter-item">
								<span className="filter-value">{item.value}</span>
								<i
									className="remove-filter-btn"
									onClick={() => removeFilter(item.filterName, item.filterIndex)}
								>
									x
								</i>
							</div>
						))}
					</div>
				]}
		</div>
	);
};

SearchFilterBar.propTypes = {
	className: PropTypes.string,
	isSV: PropTypes.bool,
	cuisineItems: PropTypes.arrayOf(PropTypes.object),
	dietaryItems: PropTypes.arrayOf(PropTypes.object),
	amenityItems: PropTypes.arrayOf(PropTypes.object),
	onFilter: PropTypes.func.isRequired
};

export default SearchFilterBar;
