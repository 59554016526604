import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { each } from 'lodash';

const PageHeader = ({
	bannerImg = null,
	bannerUrl,
	children,
	className = '',
	cobranded = false,
	config,
	height = null,
	hide,
	hideImage = false,
	isLV = true,
	useCrop = true,
	useTop = false,
	width = null
}) => {
	const showHeaderBar = false;
	const [titleComponent, _setTitleComponent] = useState(null);

	const setTitleComponent = useCallback(component => {
		switch (component.type.name) {
			case 'PageHeaderTitle':
				_setTitleComponent(component);
				break;
			default:
				break;
		}
	}, []);

	useEffect(() => {
		if (titleComponent) {
			return;
		}
		if (children && children.type) {
			setTitleComponent(children);
		} else if (children) {
			// TODO: Take a second look at this, it doesn't make sense to use an each to set,
			// it'll just end up being the last child in the array.
			each(children, component => {
				setTitleComponent(component);
			});
		}
	}, [children, setTitleComponent, titleComponent]);

	const { heightParam, widthParam } = useMemo(() => {
		let _heightParam = height || 200;
		let _widthParam = width || 1400;

		if (bannerImg && cobranded) {
			_widthParam = 2800;
			_heightParam = 400;
		}

		return {
			heightParam: _heightParam,
			widthParam: _widthParam
		};
	}, [bannerImg, cobranded, height, width]);

	const imgSrc = useMemo(() => {
		let _imgSrc = useCrop
			? `${bannerImg}?w=${widthParam}&crop=edges&h=${heightParam}&fit=crop`
			: `${bannerImg}?w=${widthParam}&h=${heightParam}`;

		_imgSrc = useTop ? `${_imgSrc}&crop=top` : _imgSrc;

		if (bannerImg && cobranded) {
			_imgSrc = _imgSrc.replace('&fit=crop', '');
			if (!isLV) {
				_imgSrc = `${_imgSrc.replace('LV', 'SV')}?w=${isLV ? widthParam : window.innerWidth}&h=${
					isLV ? heightParam : 138
				}`;
			}
		}

		return _imgSrc;
	}, [bannerImg, cobranded, heightParam, isLV, useCrop, useTop, widthParam]);

	const componentClasses = classNames('container', 'page-header', className, cobranded ? 'cobranded' : '');

	const img = useMemo(() => {
		if (bannerImg) {
			return (
				<img
					className={cobranded ? 'page-banner cobranded' : 'page-banner'}
					src={bannerImg.indexOf('static') > -1 ? bannerImg : `${config.cdn_basePath}/graphics/${imgSrc}`}
					aria-hidden={true}
					alt={cobranded ? 'Page Banner' : 'Promotional Banner'}
				/>
			);
		}

		if (bannerUrl !== undefined) {
			return (
				<a
					href={bannerUrl}
					target="top"
					alt="2x for personal, 10x for business document"
					className="page-banner"
				>
					{img}
				</a>
			);
		}
	}, [bannerImg, bannerUrl, cobranded, config.cdn_basePath, imgSrc]);

	return (
		!hide && (
			<div className={componentClasses}>
				{bannerImg && !hideImage && (
					<div className="row">
						<div className="col">{img}</div>
					</div>
				)}
				{showHeaderBar && titleComponent && (
					<div className="row">
						<div className="col page-header-col">
							<header className="header-bar">{titleComponent}</header>
						</div>
					</div>
				)}
				{children}
			</div>
		)
	);
};

PageHeader.propTypes = {
	className: PropTypes.string,
	bannerImg: PropTypes.string,
	bannerUrl: PropTypes.string,
	children: PropTypes.node,
	config: PropTypes.object,
	useCrop: PropTypes.bool,
	useTop: PropTypes.bool,
	width: PropTypes.number,
	height: PropTypes.number,
	cobranded: PropTypes.bool,
	isLV: PropTypes.bool,
	hideImage: PropTypes.bool,
	hide: PropTypes.bool
};

export default PageHeader;
