import OnboardingAdminList from './ui-components';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';
import { setIsLoading } from 'actions/loader';

const FORM_NAME = 'onboardingList';

const mapStateToProps = state => ({
	formValues: hasProp(state.form, FORM_NAME) ? state.form[FORM_NAME].values : {},
	// idToken: state.users.idToken,
	initialValues: {
		file: null
	},
	translate: getTranslate(state.locale)
	// username: hasProp(state.users, 'user.userName') ? state.users.user.userName : ''
});

const mapDispatchToProps = dispatch => ({
	setIsLoading: show => dispatch(setIsLoading(show, 'Uploading CSV')),
	setFormValue: (fieldName, value) => dispatch(change(FORM_NAME, fieldName, value))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: FORM_NAME
	})(OnboardingAdminList)
);
