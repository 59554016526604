import PaymentTypes from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	const cards = hasProp(state, 'details.restaurant.paymentMethods.creditCards')
		? state.details.restaurant.paymentMethods.creditCards
		: [];
	return { cards };
};

export default connect(mapStateToProps)(PaymentTypes);
