import React from 'react';
import PropTypes from 'prop-types';
import { hasProp } from 'utils/object';
import { Text } from 'components/atoms';
import { useTranslate } from 'hooks';

const RecommendationsIncentive = ({ incentiveKey }) => {
	const translate = useTranslate();
	let Incentive = translate(incentiveKey);
	let pointVal = translate('Recommendations.pointVal');
	if (hasProp(Incentive, 'props.dangerouslySetInnerHTML.__html')) {
		Incentive.props.dangerouslySetInnerHTML.__html = Incentive.props.dangerouslySetInnerHTML.__html.replace(
			'{points}',
			pointVal
		);
	} else {
		Incentive = Incentive.replace('{points}', pointVal);
	}
	return (
		<div className="incentive">
			<Text dataCy="incentive" size="xs">
				{Incentive}
			</Text>
		</div>
	);
};

RecommendationsIncentive.defaultProps = {};

RecommendationsIncentive.propTypes = {
	incentiveKey: PropTypes.string.isRequired
};

export default RecommendationsIncentive;
