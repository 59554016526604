import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { hasProp } from 'utils/object';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

const SubHeader = ({ dataCy, links, size, title, titleKey }) => {
	const translate = useTranslate();

	const { user } = useUserContext();
	const Header = size;
	return (
		<div className="container dashboard sub-header" data-cy={dataCy}>
			<Header className="sub-title center">{titleKey ? translate(titleKey) : title}</Header>
			<div className="sub-nav">
				{links.map((link, i) => {
					if (
						hasProp(user, 'company') &&
						link.titleKey === 'common.redemption' &&
						translate('cobranding.companies.' + user.company.code + '.allows_redemption') == 'false'
					) {
						return null;
					}
					return (
						<NavLink key={`sub-nav-${i}`} to={link.to} data-cy={link.dataCy || link.title}>
							{link.titleKey ? translate(link.titleKey) : link.title ? link.title : ''}
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

SubHeader.defaultProps = {
	links: [],
	size: 'h3'
};

SubHeader.propTypes = {
	dataCy: PropTypes.string,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			dataCy: PropTypes.string,
			to: PropTypes.string.isRequired,
			titleKey: PropTypes.string,
			title: PropTypes.string
		})
	),
	size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	title: PropTypes.string,
	titleKey: PropTypes.string
};

export default SubHeader;
