import Cards from './ui-component';
import { connect } from 'react-redux';
import { setActiveCard } from 'actions/landing_page_cards';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
	return {
		activeCard: state.landingCards.activeCard,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	setActiveCard: (card, cardURL) => dispatch(setActiveCard(card, cardURL))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cards));
