import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

/**
 * Info icon that, when clicked, triggers to show/hide a text box.
 * @param {ToggleButtonProps} props
 * @param {string} props.id The id for the toggle button.
 * @param {object} props.config Dinova Redux state.config.
 * @param {function} [props.onToggle] On toggle icon click handler.
 *
 */
const ToggleButton = ({ id, config, dataCy, onToggle: onToggleProp, isOpen: isOpenProp }) => {
	const translate = useTranslate();
	const dropdown_trigger = useRef();
	const [isOpen, setIsOpen] = useState(isOpenProp || false);

	const toggleVisibility = useCallback(() => setIsOpen(prev => !prev), []);

	const onToggle = useCallback(
		e => {
			if (onToggleProp) {
				onToggleProp(e);
			} else {
				toggleVisibility();
			}
		},
		[onToggleProp, toggleVisibility]
	);

	const _isOpen = isOpenProp === true || isOpenProp === false ? isOpenProp : isOpen;
	const icon = _isOpen ? 'icon-show' : 'icon-hide';

	return (
		<React.Fragment>
			<div
				data-cy={`toggle-btn-${dataCy}`}
				data-toggle="collapse"
				type="button"
				className="toggle-btn btn-flat"
				id={id}
				onClick={onToggle}
				ref={dropdown_trigger}
			>
				<img
					className="Di_SideShowIcon"
					src={`${config.cdn_basePath}/icons/${icon}.svg`}
					alt={translate('common.toggleBtn')}
				/>{' '}
			</div>
		</React.Fragment>
	);
};

/**
 * ToggleButton Props
 * @interface Props_ToggleButton
 * @property {string} id The id for the toggle button
 * @property {object} config Dinova Redux state.config.
 * @property {function} translate Dinova Redux getTranslate(state.locale).
 * @property {function} [onToggle] On toggle icon click handler.
 */
ToggleButton.propTypes = {
	// Required
	id: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	// Optional
	dataCy: PropTypes.string,
	onToggle: PropTypes.func,
	isOpen: PropTypes.bool
};

const mapStateToProps = state => ({
	config: state.config,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(ToggleButton);
