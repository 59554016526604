import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { basePath } from 'actions/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useForgotPasswordMutationProps {}
*/

const useForgotPasswordMutation = ({ onError, onSuccess } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();

	const mutation = useMutation({
		mutationFn: async email => {
			return await axios.post(`${basePath()}/users/forgotpassword`, JSON.stringify({ email }));
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'requesting password'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			dispatch(setIsLoading(false));

			onSuccess && onSuccess(data);
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		sendForgotPassword: mutation.mutate
	};
};

export default useForgotPasswordMutation;
