import { useCallback, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { stringToBoolean } from 'utils/validations';
import { history } from 'utils/router';
import { hasProp } from 'utils/object';
import Cookies from 'universal-cookie';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

export const RING_TYPES = {
	NO_POINTS_NO_CONNECTIONS: 'NO_POINTS_NO_CONNECTIONS',
	NO_POINTS: 'NO_POINTS',
	INELIGIBLE: 'NO_POINTS',
	HAS_POINTS: 'HAS_POINTS',
	NO_CONNECTIONS: 'NO_CONNECTIONS'
};

const cookies = new Cookies();

// interface useDashboardPageProps {}

const useDashboardPage = ({
	availableRewards,
	modal,
	setModal,
	modalsShown,
	startChangeStep,
	toggleModal,
	updateHeader,
	windowWidth
}) => {
	const translate = useTranslate();
	const { user, isFetched, isLoading } = useUserContext();
	const [redirect, setRedirect] = useState(false);
	const [didInitialShow, setDidInitialShow] = useState(
		modalsShown.includes(translate('Dashboard.modals.mfa_optin.id'))
	);

	useEffect(() => {
		if (isFetched && !isLoading && !user) {
			setRedirect(true);
		}
	}, [isFetched, isLoading, user]);

	useEffect(() => {
		if (!didInitialShow && Object.keys(user || {}).length > 0) {
			if (cookies.get('dontAsk') === undefined) {
				showMFA();
				setDidInitialShow(true);
			}
		}
	}, [didInitialShow, showMFA, user]);

	// componentDidMount
	useEffect(() => {
		updateHeader(true);
		window.scrollTo(0, 0);
		// initShowBanner();
	}, [initShowBanner, updateHeader]);

	const ringType = useMemo(() => {
		if (isIneligible) {
			return RING_TYPES.INELIGIBLE;
		} else if (user?.points > 0 && (user?.connections || [])?.length === 0) {
			return RING_TYPES.NO_POINTS_NO_CONNECTIONS; // You have earned XX points [> Use my points] [> View my points history] |FOOTER| Want to earn even more myDinova reward points?
		} else if ((user?.connections || [])?.length === 0) {
			return RING_TYPES.NO_CONNECTIONS; // You have one more step to complete to start earning myDinova Reward points [GET POINTS]
		} else if (user?.points === 0) {
			return RING_TYPES.NO_POINTS; // Find a Dinova Restaurant to earn your first myDinova points [FIND]
		} else {
			return RING_TYPES.HAS_POINTS; // You have earned XX points [REDEEM] [HISTORY]
		}
	}, [user, isIneligible]);

	const onClick = useCallback(() => {
		startChangeStep(2);
		history.push('/users/connect?redirect=/mydinova');
	}, [startChangeStep]);

	const onBannerClick = useCallback(() => {
		history.push('/refer/details');
	}, []);

	const onBannerClose = useCallback(
		e => {
			e.stopPropagation();
			cookies.set(`show-referral-${user.userName}`, false, {
				path: '/',
				expires: moment()
					.add(30, 'days')
					.toDate()
			});
			// setShowBanner(false);
		},
		[user]
	);

	const showMobileBanner = useMemo(() => {
		const cookie = cookies.get(`show-referral-${user?.userName}`) || 'true';
		const showBanner = stringToBoolean(cookie) && (user?.connections || []).length > 0;

		return windowWidth < 768 && showBanner;
	}, [user, windowWidth]);

	const initShowBanner = useCallback(() => {
		// let response = true;
		// if (hasProp(user, 'company.code')) {
		// 	response = translate(`cobranding.companies.${user?.company?.code}.show_dashboard_banner`).includes(
		// 		'Missing'
		// 	)
		// 		? true
		// 		: JSON.parse(translate(`cobranding.companies.${user?.company?.code}.show_dashboard_banner`));
		// }
		// setShowBanner(response);
	}, []);

	const showPromo = useCallback(() => {
		// let response = true;
		// if (hasProp(user, 'company.code')) {
		// 	response = translate(`cobranding.companies.${user?.company?.code}.show_dashboard_promos`).includes(
		// 		'Missing'
		// 	)
		// 		? true
		// 		: JSON.parse(translate(`cobranding.companies.${user?.company?.code}.show_dashboard_promos`));
		// }
		// setShowPromo(response);
	}, []);

	const showMFA = useCallback(() => {
		if (user?.security && !user?.security?.mfaEnabled) {
			showModal();
		}
	}, [user, showModal]);

	const showModal = useCallback(() => {
		const mfaRequired = user?.company?.security?.mfaRequired;
		const translateKey = mfaRequired ? 'mfa_required' : 'mfa_optin';

		const modalProps = {
			id: translate(`Dashboard.modals.${translateKey}.id`),
			header: translate(`Dashboard.modals.${translateKey}.header`),
			body: translate(`Dashboard.modals.${translateKey}.body`),
			primaryButtonText: translate(`Dashboard.modals.${translateKey}.primaryButtonText`),
			secondaryButtonText: mfaRequired ? null : translate('Dashboard.modals.mfa_optin.secondaryButtonText')
		};
		setModal(modalProps);

		onToggle();
	}, [user, onToggle, setModal, translate]);

	const onToggle = useCallback(() => {
		const newIsOpen = !modal.isOpen;
		toggleModal(newIsOpen);
	}, [modal, toggleModal]);

	const showConnectFooter = useMemo(() => !isLoading && ringType === RING_TYPES.NO_POINTS_NO_CONNECTIONS, [
		isLoading,
		ringType
	]);

	const primaryButtonText = useMemo(
		() => (availableRewards === 0 ? translate('Dashboard.rewards_button') : translate('Dashboard.redeem_button')),
		[availableRewards, translate]
	);

	const singleButtonText = useMemo(
		() =>
			ringType === RING_TYPES.NO_CONNECTIONS
				? translate('Dashboard.get_points_button')
				: translate('Dashboard.find_button'),
		[ringType, translate]
	);

	const isIneligible = useMemo(() => {
		return (
			(hasProp(user, 'company.myDinova') && !user?.company?.myDinova?.eligible) ||
			(hasProp(user, 'company') && !user?.company?.code)
		);
	}, [user]);

	return {
		onBannerClick,
		onBannerClose,
		onClick,
		primaryButtonText,
		redirect,
		ringType,
		showConnectFooter,
		showMobileBanner,
		showPromo,
		singleButtonText,
		user,
		isLoading
	};
};

export default useDashboardPage;
