import jwt from 'jsonwebtoken';

export const PERMISSIONS = {
	ADMIN: '50',
	MY_DINOVA: '3',
	DASHBOARD_REBATE_VIEWER: '15',
	REBATE_REPORT_VIEWER: '11',
	PRIVATE_ROSTER: '4'
};

export const getPermissions = userIdToken => {
	const decodedUserIdToken = jwt.decode(userIdToken),
		permissions = decodedUserIdToken['custom:roles'].split(',');

	return permissions;
};

export const hasPermissions = (userPermissions = [], requiredPermissions = []) => {
	if (requiredPermissions.length) {
		if (!userPermissions.length) {
			return false;
		} else {
			let doPrint = [],
				i = 0;

			while (i < requiredPermissions.length) {
				doPrint.push(userPermissions.indexOf(requiredPermissions[i]) > -1);
				i++;
			}
			return doPrint.indexOf(false) === -1;
		}
	} else {
		return true;
	}
};
