export const FETCH_FAVORITES_START = 'FETCH_FAVORITES_START';
export const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS';
export const FETCH_FAVORITES_ERROR = 'FETCH_FAVORITES_ERROR';
export const TOGGLE_SHOW_FAVORITES_FILTER = 'TOGGLE_SHOW_FAVORITES_FILTER';
export const ADD_FAVORITES_START = 'ADD_FAVORITES_START';
export const ADD_FAVORITES_SUCCESS = 'ADD_FAVORITES_SUCCESS';
export const ADD_FAVORITES_ERROR = 'ADD_FAVORITES_ERROR';
export const REMOVE_FAVORITES_START = 'REMOVE_FAVORITES_START';
export const REMOVE_FAVORITES_SUCCESS = 'REMOVE_FAVORITES_SUCCESS';
export const REMOVE_FAVORITES_ERROR = 'REMOVE_FAVORITES_ERROR';
