import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, SubmissionError } from 'redux-form';
import { LocationAutoComplete, ReduxFormInput } from 'components/organisms';
import { Button } from 'reactstrap';
import qs from 'query-string';
import { RecentSearches } from 'components/molecules';
import { updateRecentSearchs } from 'utils/search';
import { programaticBlurWindowEvent } from 'utils/windowEvents';
import { isMobile } from 'utils/browser';

class CateringSearchForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: null,
			hasFindRecentSearchData: false,
			hasNearRecentSearchData: false,
			isRecentClick: false,
			near: '',
			find: ''
		};
	}

	componentDidMount = () => {
		document.addEventListener('keyup', this.handleKeyUp);
		this.initFields();
	};

	componentWillUnmount = () => {
		document.removeEventListener('keyup', this.handleKeyUp);
	};

	inputs = ['find', 'near'];

	onFocusOut = e => {
		console.log(this.state.isRecentClick);
		console.log('e', e.target);
		if (this.state.isRecentClick) {
			if (e.target.name === 'near') {
				this.props.setFieldValue('near', this.state.near);
			} else if (e.target.name === 'catering-find') {
				this.props.setFieldValue('find', this.state.find);
			}
		} else {
			if (e.target.name === 'near') {
				programaticBlurWindowEvent(`catering-near-autocomplete`);
				this.setState({ near: e.target.value });
			} else if (e.target.name === 'catering-find') {
				this.setState({ find: e.target.value });
				programaticBlurWindowEvent(`catering-find`);
			} else {
				return;
			}
		}
	};

	handleKeyUp = e => {
		if (e.key === 'Enter') {
			if (isMobile()) {
				this.form.dispatchEvent(new Event('submit', { cancelable: true }));
			}
			if (e.target.name === 'near') {
				programaticBlurWindowEvent(`catering-near-autocomplete`);
			} else if (e.target.name === 'catering-find') {
				programaticBlurWindowEvent(`catering-find`);
			} else {
				return;
			}
		} else {
			return;
		}
	};

	onRecentSearchLoad = (type, hasRecentSearchData) => {
		if (type === 'find') {
			this.setState({ hasFindRecentSearchData: hasRecentSearchData });
		} else {
			this.setState({ hasNearRecentSearchData: hasRecentSearchData });
		}
	};

	updateRecentSearchCache = () => {
		const { find, near } = this.props.formValues;

		let message = '';
		if (find && near) {
			message = `${find} near ${near}`;
		} else if (!find && near) {
			message = near;
		} else if (find && !near) {
			message = `${find} near me`;
		} else {
			return;
		}

		const newSearch = {
			keywords: find,
			near,
			message
		};

		updateRecentSearchs('catering', newSearch);
	};

	handleRecentSearchMouseEnter = e => {
		this.setState({ isRecentClick: true, find: e.target.dataset.find, near: e.target.dataset.near });
	};

	handleRecentSearchMouseLeave = () => {
		this.setState({ isRecentClick: false });
	};

	onRecentClick = data => {
		console.log('data:', data);
		const { near, find } = data;
		if (find && !near) {
			this.props.setFieldValue('find', find);
			programaticBlurWindowEvent(`catering-find`);
		} else if (!find && near) {
			this.props.setFieldValue('near', near);
			programaticBlurWindowEvent(`catering-near-autocomplete`);
		} else {
			return;
		}
	};

	onBlur = (originalBlurFunc, e) => {
		if (this.state.isRecentClick) {
			if (e.target.id === 'catering-near-autocomplete') {
				e.target.value = this.state.near;
			} else if (e.target.id === 'catering-find') {
				e.target.value = this.state.find;
			}
		}
		originalBlurFunc(e);
		this.form.dispatchEvent(new Event('submit', { cancelable: true }));
	};

	initFields = () => {
		const fields = this.inputs.map((input, index) => {
			return (
				<Field
					name={input}
					key={`catering-form-field-${index}`}
					component={field => {
						if (isMobile() && this.isSearchPage()) {
							this[input] = field.input;
							field.input.onBlur = this.onBlur.bind(this, field.input.onBlur);
						}
						if (input === 'near') {
							return (
								<LocationAutoComplete
									whiteBackground={this.props.whiteBackground}
									reduxId="catering"
									reduxField={field}
									label={
										this.isSearchPage()
											? false
											: this.props.translate('Home.cateringSearch.nearLabel')
									}
									onBlur={field.input.onBlur}
									placeholder={this.props.translate('Home.cateringSearch.nearPlaceholder')}
									initialValue={field.input.value}
									id={`catering-${input}`}
									dataCy={`catering-${input}-field`}
									hasRecentSearchData={() => this.state.hasNearRecentSearchData}
									pulseLoaderColor={'#ffffff'}
									onErrorUsePlacehodler={window.location.href.indexOf('search') > -1}
									recentSearches={
										<RecentSearches
											type="catering"
											recent="near"
											recentCallback={this.onRecentClick}
											onListLoad={this.onRecentSearchLoad.bind(this, 'near')}
											onMouseEnter={this.handleRecentSearchMouseEnter}
											onMouseLeave={this.handleRecentSearchMouseLeave}
										/>
									}
								/>
							);
						} else {
							return (
								<ReduxFormInput
									field={field}
									inputProps={{
										dataCy: `catering-${input}-field`,
										name: `catering-${input}`,
										id: `catering-${input}`,
										error: 'Lorem.. Error',
										hasError: !field.meta.valid,
										label: this.isSearchPage()
											? false
											: this.props.translate('Home.cateringSearch.findLabel'),
										placeholder: this.props.translate('Home.cateringSearch.findPlaceholder'),
										hasRecentSearchData: () => this.state.hasFindRecentSearchData,
										recentSearches: (
											<RecentSearches
												type="catering"
												recent="keywords"
												recentCallback={this.onRecentClick}
												onListLoad={this.onRecentSearchLoad.bind(this, 'find')}
												onMouseEnter={this.handleRecentSearchMouseEnter}
												onMouseLeave={this.handleRecentSearchMouseLeave}
											/>
										)
									}}
								/>
							);
						}
					}}
				/>
			);
		});

		this.setState({ fields });
	};

	submit = async vals => {
		if (!vals.near || vals.near === this.props.translate('Home.cateringSearch.locationInput.placeholder')) {
			await this.doNearMeSearch(vals);
		} else {
			this.doSearchFromForm(vals);
		}
	};

	doSearchFromForm = async vals => {
		const params = {
			amenities: 'catering'
		};

		if (vals.near) {
			params.near = vals.near;
		}

		if (vals.find) {
			params.keywords = vals.find;
		}

		this.updateRecentSearchCache();

		this.props.history.push(`/search?${qs.stringify(params)}/#/`);

		if (window.location.href.indexOf('/search') > -1 && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	doNearMeSearch = async vals => {
		const { geoLocation, getLocation } = this.props,
			{ hasGeoLocation, wasDenied } = geoLocation;
		if (!hasGeoLocation) {
			if (wasDenied) {
				this.showNoLocationError();
			} else {
				try {
					const loc = await getLocation();
					this.doGeolocationSearch(vals, { lat: loc.coords.latitude, lng: loc.coords.longitude });
				} catch (e) {
					this.showNoLocationError();
				}
			}
		} else {
			this.doGeolocationSearch(vals, { lat: geoLocation.lat, lng: geoLocation.lng });
		}
	};

	doGeolocationSearch = (vals, loc) => {
		const params = {
			amenities: 'catering'
		};
		if (vals.find) {
			params.keywords = vals.find;
		}

		params.location = `${loc.lat},${loc.lng}`;
		this.props.history.push(`/search?${qs.stringify(params)}/#/`);

		if (window.location.href.indexOf('/search') > -1 && this.props.onSubmit) {
			this.props.onSubmit(true);
		}
	};

	showNoLocationError = () => {
		throw new SubmissionError({
			near: 'No Geolocation or near search provided'
		});
	};

	isSearchPage = () => window.location.href.indexOf('/search') > -1;

	render() {
		const { appendButtons, isSV, submitButtonText } = this.props,
			classProps = classnames('catering-search', appendButtons && 'append-buttons');

		return (
			<form
				ref={c => (this.form = c)}
				className={classProps}
				name="catering-search"
				onSubmit={this.props.handleSubmit(this.submit)}
			>
				{this.state.fields}
				<input type="submit" hidden />
				<div className="btns-wrapper">
					<Button
						color="primary"
						type="submit"
						data-cy="catering-search-submit"
						className={isSV && this.isSearchPage() ? 'hidden' : ''}
					>
						{submitButtonText}
					</Button>
					{appendButtons && appendButtons}
				</div>
			</form>
		);
	}
}

CateringSearchForm.defaultProps = {};

CateringSearchForm.propTypes = {
	appendButtons: PropTypes.node,
	formValues: PropTypes.shape({
		find: PropTypes.string,
		near: PropTypes.string
	}),
	geoLocation: PropTypes.object,
	getLocation: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired, // redux form
	history: PropTypes.object.isRequired,
	isSV: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
	submitButtonText: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired,
	whiteBackground: PropTypes.bool
};

export default CateringSearchForm;
