import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslate } from 'hooks';

const OrderActions = ({ className, onRedeem, onCancel, enoughPoints }) => {
	const translate = useTranslate();
	const classProps = classNames('order-actions', className),
		buttonText = enoughPoints ? 'common.cancelBtn' : 'common.backBtn';

	return (
		<div className={classProps}>
			{enoughPoints && (
				<Button onClick={onRedeem} color="primary" data-cy="submit">
					{translate('Order.redeemBtn')}
				</Button>
			)}
			<Link to={'/rewards'} onClick={onCancel} className="btn-wrapper" data-cy="cancel">
				<Button>{translate(buttonText)}</Button>
			</Link>
		</div>
	);
};

OrderActions.defaultProps = {
	className: ''
};

OrderActions.propTypes = {
	className: PropTypes.string,
	onRedeem: PropTypes.func,
	onCancel: PropTypes.func,
	enoughPoints: PropTypes.bool.isRequired
};

export default OrderActions;
