import { useCallback, useMemo, useState } from 'react';
import { login as loginAction } from 'actions/users';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { history } from 'utils/router';
import { setModal, toggleModal } from 'actions/modal';
import { getPermissions, PERMISSIONS } from 'utils/permissions';

// interface useLoginProps {}

const useLogin = ({ skipRedirect } = {} /*:useLoginProps*/) => {
	const dispatch = useDispatch();
	const [mfaToken, setMFAToken] = useState();
	const [sentTo, setSentTo] = useState();
	const location = useLocation();

	const redirect = useMemo(() => {
		if (skipRedirect) {
			return false;
		}
		const search = location.search.replace('?', '');
		let _redirect = queryString.parse(search).redirect;

		if (location.hash) {
			_redirect += location.hash;
		}

		return _redirect;
	}, [location, skipRedirect]);

	const login = useCallback(
		(user, isMFA = false) => {
			const returnFullResponse = true;
			return dispatch(
				loginAction(
					user,
					response => {
						const authedUser = response.user;
						const permissions = getPermissions(authedUser?.tokens?.id);
						const isAdmin = permissions.includes(PERMISSIONS.ADMIN);

						switch (response.response.code) {
							case 200:
								if (window.Android) {
									isAdmin && !skipRedirect && history.push('/');
								} else if (
									// in leave a rec from details page flow
									!skipRedirect &&
									redirect &&
									redirect !== 'undefined' &&
									redirect.indexOf('restaurants') > -1
								) {
									if (window.location.href.indexOf('/admin') > -1) {
										history.replace(redirect);
									}
								} else {
									isAdmin && !skipRedirect && history.replace('/');
								}
								if (isMFA) {
									dispatch(toggleModal(false));
									return true;
								}
								return;
							// case 410:
							// case 401:
							case 411:
								setMFAToken(response.mfaToken);
								setSentTo(response.sentTo);
								dispatch(
									setModal({
										id: 'mfa', //this.props.translate('reset.modals.ok.id'),
										header:
											'Security is important to us, which is why we strongly recommend enabling multi-factor authentication on your account.', //this.props.translate('reset.modals.ok.title'),
										body: `Click below to enable SMS Authentication or skip this step if not ready to enable on your account. In the future, you can activate this feature from your Security settings in your Account Profile page.`, //this.props.translate('reset.modals.ok.body'),
										primaryButtonText: 'ok' //this.props.translate('reset.modals.ok.primaryButtonText'),
									})
								);

								dispatch(toggleModal(true));
								return;
							case 412:
								!skipRedirect && history.replace('/change');
								return;
							case 400:
								if (isMFA) {
									return false;
								}
								return;
							default:
								return;
						}
					},
					returnFullResponse
				)
			);
		},
		[dispatch, redirect, skipRedirect]
	);

	return {
		login,
		mfaToken,
		sentTo
	};
};

export default useLogin;
