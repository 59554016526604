/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withAccordion } from 'components/molecules';
import { preferredCCTypeDisplayText } from 'utils/card';
import { Text } from 'components/atoms';
import { CONNECTION_CREATED, DELETE_ALL_CONNECTIONS } from 'utils/windowEvents';
import { WithHelp } from 'components/molecules';
import { sortUserConnections } from 'utils/user';
import { useTranslate } from 'hooks';
import { useCompanyByCodeQuery } from 'reactQuery/queries';
import { useUserContext } from 'context';

const RegisteredConnections = ({ imageBaseUrl }) => {
	const translate = useTranslate();
	const { user, refetch } = useUserContext();

	const { companyCode, userConnections } = useMemo(
		() => ({
			companyCode: user?.company?.code,
			userConnections: user ? sortUserConnections(user) : []
		}),
		[user]
	);

	const { data: company } = useCompanyByCodeQuery({ companyCode });
	const supportedConnectionCardTypes = (company?.supportedConnections || []).map(c => c.cardType);

	// componentDidMount
	useEffect(() => {
		window.addEventListener(DELETE_ALL_CONNECTIONS, deleteAllConnections);
		window.addEventListener(CONNECTION_CREATED, connectionCreated);

		// componentWillUnmount
		return () => {
			window.removeEventListener(DELETE_ALL_CONNECTIONS, deleteAllConnections);
			window.removeEventListener(CONNECTION_CREATED, connectionCreated);
		};
	});

	const deleteAllConnections = useCallback(() => {
		// console.log('%c delete all connections', 'color: limegreen');
		// setUserConnections([]);
	}, []);

	const connectionCreated = useCallback(
		event => {
			const connection = event.detail;
			let newUserConnections = userConnections.slice();
			newUserConnections.push(connection);
			refetch();
			// setUserConnections(newUserConnections);
		},
		[refetch, userConnections]
	);

	const getCCIconImage = useCallback(
		cardName => {
			const _cardName = cardName.toLowerCase();

			let iconType;
			switch (_cardName) {
				case 'mastercard': {
					iconType = 'mc';
					break;
				}
				case 'american express': {
					iconType = 'amex';
					break;
				}
				default: {
					iconType = _cardName;
					break;
				}
			}
			return `${imageBaseUrl}/icons/icon-${iconType}.svg`;
		},
		[imageBaseUrl]
	);

	const connectionCount = userConnections.length;

	return withAccordion(
		connectionCount === 0 ? (
			<div className="connected-cards-content empty">{translate('RegisteredConnections.noConnections')}</div>
		) : (
			<div className="connected-cards-content">
				{userConnections.map((connection, index) => {
					let cardName = `${preferredCCTypeDisplayText(connection.cardType)}`;
					const ineligible =
							supportedConnectionCardTypes.length > 0 &&
							supportedConnectionCardTypes.indexOf(connection.cardType) === -1,
						classProps = classnames('connected-card', ineligible && 'ineligible has-help');

					let cardNumber;
					if (cardName === 'American Express') {
						cardNumber = `XXXX-XXXXXX-X${connection.last4}`;
					} else {
						cardNumber = `XXXX-XXXX-XXXX-${connection.last4}`;
					}

					return (
						<div
							key={`userConnection-${index}`}
							className={classProps}
							data-cy={`registered-connection-${index}`}
						>
							<div>
								<Text size="sm" weight="thin" dataCy={`name-on-card-${index}`}>
									<span className="normal">
										{translate('ConnectToPoints.inputs.cardholderName.label')}:
									</span>{' '}
									{connection.nameOnCard}
								</Text>
								<Text size="sm" weight="thin" dataCy={`card-number-${index}`}>
									<span className="normal">{cardName}:</span> {cardNumber}
								</Text>
								{connection.employeeId && (
									<Text size="sm" weight="thin" dataCy={`empId-${index}`}>
										<span className="normal">
											{translate('ConnectToPoints.inputs.employeeId.label')}:
										</span>{' '}
										{connection.employeeId}
									</Text>
								)}
							</div>
							<div className="flex-col align-end">
								<img src={getCCIconImage(cardName)} className="cc-icon" alt="" />
								{ineligible && (
									<WithHelp
										id={`connection-help-${index}`}
										help={translate('RegisteredConnections.ineligibleConnectionHelp')}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		),
		{
			title:
				connectionCount === 0 ? (
					translate('RegisteredConnections.title')
				) : (
					<span>
						{translate('RegisteredConnections.title')}{' '}
						<span className="font-green">({connectionCount}/10)</span>
					</span>
				),
			canCollapse: false,
			expanded: true,
			className: 'bordered',
			titleAlign: 'center',
			dataCy: 'registered-connections'
		}
	);
};

RegisteredConnections.propTypes = {
	imageBaseUrl: PropTypes.string.isRequired
};

export default RegisteredConnections;
