/* eslint-disable no-case-declarations */
import * as t from '../actions/landing_page/types';

/**
 * [landingPageDetaultState description]
 * @type {Object}
 */
const landingPageDetaultState = {
	promo: {
		isLoading: true,
		isActive: false,
		start_date: false,
		end_date: false,
		graphic: false
	}
};

export default (state = landingPageDetaultState, action) => {
	switch (action.type) {
		case t.INIT_LANDING_PAGE_PROMO: {
			return { ...state, promo: { ...action.payload, isLoading: false } };
		}

		default:
			return state;
	}
};
