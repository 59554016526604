import { useQuery } from './';
import { basePath } from 'actions/utils';
import moment from 'moment';

const categorizeByYear = recs => {
	return recs.reduce((accum, rec) => {
		let year = moment(rec.date).year(),
			month = moment(rec.date).month() + 1;

		if (!accum[year]) {
			accum[year] = {};
		}

		if (!accum[year][month]) {
			accum[year][month] = [];
		}

		accum[year][month].push(rec);

		return accum;
	}, {});
};

const sortCategorizedByMonth = categorized => {
	Object.keys(categorized).forEach(key => {
		let year = categorized[key];
		Object.keys(year).forEach(month => {
			year[month] = year[month].sort((a, b) => {
				return new Date(b.date) - new Date(a.date);
			});
		});
	});
	return categorized;
};

const sortByDate = recs => {
	// NOTE: moment "month" is index 0 based.  0 = January.  Our api is index 1 based. 1 = January.
	let byYear = sortCategorizedByMonth(categorizeByYear(recs));

	return byYear;
};

export const USER_RECOMMENDATIONS_QUERY_KEY = 'user_recommendations';

const useUserRecommendationsQuery = ({ setReduxIsLoading, setDataInRedux } = {}) => {
	const url = `${basePath()}/users/recommendations`;
	return useQuery({
		// Req
		queryHandler: res => sortByDate(res.data.recommendations),
		queryKey: USER_RECOMMENDATIONS_QUERY_KEY,
		url,

		// Opt
		// quietLoading: boolean,
		reduxLoadingMsg: 'Loading User Recommendations...',
		reduxQueryDataAccr: query => sortByDate(query.data.recommendations),
		requiresAuth: true,
		setDataInRedux,
		setReduxIsLoading
		// urlBuilder: (queryKey) => string
	});
};

export default useUserRecommendationsQuery;
