// not dod form
/*
{
  company: "UBER"
  id: 369 // selectedCardType.id
  nameOnCard: "Yellow Bananas"
  number: "1234"
}
*/

/*
Dod form
company: "UBER"
firstName: "Yellow"
id: 369 // selectedCardType.id
lastName: "Bananas"
middleName: "B"
number: "123"
*/

const getInitialValuesFromUser = ({ company, user }) => {
	const hasConnections = !user || (user?.connections || []).length > 0;

	return {
		company: user?.company,
		selectedCard: company?.supportedConnections[0],
		email: user?.email?.primary,
		firstName: hasConnections ? '' : user?.name?.first,
		middleName: hasConnections ? '' : user?.name?.middle,
		lastName: hasConnections ? '' : user?.name?.last,
		nameOnCard: hasConnections ? '' : `${user?.name?.first} ${user?.name?.last}`
		// id:
		//     cardNumber
		// :
		// undefined
		// email
		// :
		// "2ag9b7zik0sx@example1.com"
		// employeeId
		// :
		// undefined
		// firstName
		// :
		// "Yellow"
		// lastName
		// :
		// "Bananas"
		// middleName
		// :
		// "B"
		// nameOnCard
		// :
		// "Yellow Bananas"
		// selectedCardType
		// :
		// undefined
	};
};

export default getInitialValuesFromUser;
