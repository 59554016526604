import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PageHeaderTitle, Text } from 'components/atoms';
import { ModalPage, PageHeader } from 'components/molecules';
import { AutoEnrollUserForm } from 'components/organisms';
import { ReferSharing } from 'components/organisms';
import { Link } from 'react-router-dom';

class FormContent extends Component {
	componentDidMount() {
		this._initCancelModal();
	}

	render() {
		const { complete, isLoading, translate } = this.props,
			title = !complete
				? translate('AutoEnrollUserForm.validToken.header')
				: translate('AutoEnrollUserForm.validToken.completeHeader'),
			description = !complete
				? translate('AutoEnrollUserForm.validToken.description')
				: translate('AutoEnrollUserForm.validToken.completeDescription');

		return (
			<div>
				{complete && (
					<PageHeader useCrop={true} useTop={true} width={540}>
						<PageHeaderTitle align="center" dataCy="update-personal-title">
							{title}
						</PageHeaderTitle>
					</PageHeader>
				)}
				{!complete && (
					<PageHeader hideImage={true}>
						<PageHeaderTitle align="left" dataCy="update-personal-title">
							{title}
						</PageHeaderTitle>
					</PageHeader>
				)}
				{!isLoading && (
					<Text paragraph className="">
						{description}
					</Text>
				)}
				{/* Form */}
				{!complete && this._inCompleteContent()}
				{/* Congrats & Referral Link */}
				{complete && this._completeContent()}
			</div>
		);
	}

	_inCompleteContent = () => (
		<React.Fragment>
			<AutoEnrollUserForm onCancel={this.onCancel} token={this.props.token} />
			<ModalPage isOpen={this.props.modal.isOpen} secondaryClick={this.onCancelInModal} />
		</React.Fragment>
	);

	_completeContent = () => (
		<div className="flex row justify-center">
			<ReferSharing
				link={this.props.user ? this.props.user.urls.referral : ''}
				parent={'auto_enroll_user_form_page'}
				isLoadingLink={false}
			/>
			<Link className="btn btn-primary mt-10 mb-0" to="/mydinova">
				{this.props.translate('AutoEnrollUserForm.validToken.completeButton')}
			</Link>
		</div>
	);

	_initCancelModal() {
		this.props.setModal({
			id: 'AutoEnrollUserForm.validToken.cancel-modal',
			header: this.props.translate('AutoEnrollUserForm.validToken.cancelModal.header'),
			body: this.props.translate('AutoEnrollUserForm.validToken.cancelModal.body'),
			primaryButtonText: this.props.translate('common.continueBtn'),
			secondaryButtonText: this.props.translate('common.cancelBtn')
		});
	}

	onCancel = e => {
		e.preventDefault();
		this.onToggleCancelDialog();
	};

	onToggleCancelDialog = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
	};

	onCancelInModal = () => {
		this.props.history.push('/');
	};
}

FormContent.defaultProps = {
	className: ''
};

FormContent.propTypes = {
	complete: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	token: PropTypes.string,
	user: PropTypes.object
};

export default FormContent;
