const MuiInputBaseTheme = {
	styleOverrides: {
		input: {
			padding: '13px 17px',
			fontWeight: 400
		},

		root: {
			'&.Mui-error': {
				'.MuiOutlinedInput-notchedOutline': {
					border: 'solid #e10600 3px !important' // $danger-red, $loading-red
				}
			}
		}
	}
};

export default MuiInputBaseTheme;
