import React, { useCallback } from 'react';
import { Button } from 'components/atoms';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

/*
interface AppStoreButtonLinkProps {

}
*/

const AppDownloadButtonLink = ({ bgImg, link } /*: AppStoreButtonLinkProps*/) => {
	const gotoAppStore = useCallback(() => {
		window.open(link);
	}, [link]);

	return (
		<Button
			style={{
				background: bgImg
			}}
			className={styles.appStoreButtonLink}
			onClick={gotoAppStore}
		/>
	);
};

AppDownloadButtonLink.propTypes = {
	bgImg: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};

export default AppDownloadButtonLink;
