import { asyncComponent } from 'hoc';

const LazyTermsPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "TermsPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyTermsPage;
