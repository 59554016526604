import { combineReducers } from 'redux';
// import reducers
import { reducer as form } from 'redux-form';
import { localeReducer } from 'react-localize-redux';
import configReducer from './config';
// import detailsReducer from './details';
import editPaymentReducer from './edit_payment';
import favoritesPageReducer from './favorites_page';
import modalReducer from './modal';
import registrationReducer from './registration';
import rewardReducer from './reward';
import usersReducer from './users';
import locationAutocompleteReducer from './location_autocomplete';
import geoLocationReducer from './geo_location';
import businessSearchFilter from './new_business_search_filter';
import searchPage from './search_page';
import businessSearchMap from './business_search_map';
import userRecommendations from './user_recommendations';
import companySelect from './company_select';
import pointsElegibilityModal from './points_elegibility_modal';
import deleteConnectionsWarningModal from './delete_connections_warning_modal';
import companySelectModal from './company_select_modal';
import promotions from './promotions';
import landingPageCards from './landing_page_cards';
import landingPage from './landing_page';
import autoEnrollUserFormPage from './auto_enroll_user_form_page';
import profileSetup from './profile_setup';

import loader from './loader';
import viewport from './viewport';

// combine to single object
export const reducers = {
	companySelectModal,
	config: configReducer,
	deleteConnectionsWarningModal,
	// details: detailsReducer,
	editPayment: editPaymentReducer,
	form,
	locale: localeReducer,
	modal: modalReducer,
	registration: registrationReducer,
	reward: rewardReducer,
	users: usersReducer,
	landingCards: landingPageCards,
	locationAutocomplete: locationAutocompleteReducer,
	favoritesPage: favoritesPageReducer,
	geoLocation: geoLocationReducer,
	businessSearchFilter,
	searchPage,
	businessSearchMap,
	userRecommendations,
	companySelect,
	pointsElegibilityModal,
	promotions,
	loader,
	viewport,
	landingPage,
	autoEnrollUserFormPage,
	profileSetup
};

// add factory reset for store
const makeRootReducer = reducers => {
	return (state, action) => {
		let storeState = state;
		storeState = action.type === 'FACTORY_RESET' ? undefined : state;

		return combineReducers(reducers)(storeState, action);
	};
};

// define root reducer
const rootReducer = makeRootReducer(reducers);
export default rootReducer;
