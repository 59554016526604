/* eslint-disable no-case-declarations */
import * as t from '../actions/edit_payment/types';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const editPaymentFormDefaultState = {
	card: undefined
};

export default (state = editPaymentFormDefaultState, action) => {
	switch (action.type) {
		case t.SET_CARD_TO_EDIT: {
			return {
				...state,
				card: action.payload
			};
		}

		default:
			return state;
	}
};
