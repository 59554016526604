import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { WithHelp } from 'components/molecules';
import { phoneFormatter } from 'utils/formatters';
import { useTranslate } from 'hooks';

const ProfileSectionItem = ({ className = '', item, section }) => {
	const translate = useTranslate();
	const [show, setShow] = useState(false);

	const toggleShow = useCallback(() => {
		setShow(prev => !prev);
	}, []);

	const classProps = classNames('profile-section-item', className, {
		disabled: !item.value && !item.isDesc,
		[item.className]: item.className,
		['has-help']: item.help
	});

	const value = useMemo(() => {
		if (item.key === 'phone') {
			return phoneFormatter(item.value);
		} else if (item.private) {
			return show ? item.value : 'X'.repeat(item.value.length);
		} else {
			return item.value;
		}
	}, [item?.key, item?.private, item?.value, show]);

	if (item.isDesc) {
		return (
			<div className={classProps}>
				<Text size="xs">{translate(`Profile.${section}.description`)}</Text>
			</div>
		);
	}

	return (
		<div className={classProps} data-cy={`${section}-item`}>
			{item.help && <WithHelp id={`profile-${section}-help`} help={item.help} />}
			<Title inline size="h3" align="left" dataCy={item.key}>
				{translate(`Profile.${section}.labels.${item.key}`)}
				{item.required && <span className="required">*</span>}:
			</Title>
			<span id={`${item.key}-field`} className="item-value" data-cy="value">
				{value}
			</span>
			{item.private && (
				<span onClick={toggleShow} className="ml-1">
					<span className={show ? '' : 'hidden'}>
						<i className={'fa fa-eye-slash'} aria-hidden="true" />
					</span>
					<span className={show ? 'hidden' : ''}>
						<i className={'fa fa-eye'} aria-hidden="true" />
					</span>
				</span>
			)}
		</div>
	);
};

ProfileSectionItem.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security', 'recommendations']).isRequired
};

export default ProfileSectionItem;
