import React from 'react';
import { RestaurantListItemNew } from 'components/molecules';
import { LazyDetailsHeader } from 'components/ecosystems';
import PropTypes from 'prop-types';

const OnboardingAdminRow = props => {
	const {
		restaurantId,
		cuisineURL,
		restaurantName,
		index,
		logoURL,
		translate,
		IMAGE_BASE_URL,
		onCheckboxChange,
		selectedRestaurants
	} = props;

	const isChecked = selectedRestaurants.some(item => item.id === restaurantId);
	const defaultImagePath = '/missing_image.png';
	const defaultImageURL = `${IMAGE_BASE_URL}${defaultImagePath}`;
	const logoImg = logoURL || defaultImageURL;
	const cuisineImg = cuisineURL || defaultImageURL;

	var item = [
		{
			images: {
				logoImage: {
					url: logoImg
				},
				cuisineImage: {
					url: cuisineImg
				},
				logo: logoImg,
				main: cuisineImg
			},
			address: {
				country: {
					iso: 'US'
				},
				city: 'Atlanta',
				street: ['25 Andrew Young International Blvd. NW'],
				postalCode: '30303',
				state: {
					abbreviation: 'GA'
				}
			},
			cuisine: {
				name: 'This is test data',
				id: 20
			},
			name: restaurantName,
			id: restaurantId,
			brand: {
				name: restaurantName,
				id: restaurantId
			},
			menuSpecialties: [
				{
					name: 'Placeholder',
					id: 379
				},
				{
					name: 'Placeholder 2',
					id: 384
				}
			],
			distance: {
				span: '35.41613502328915',
				units: 'mi'
			}
		}
	];

	const handleCheckboxChange = event => {
		onCheckboxChange(restaurantId, restaurantName, event.target.checked);
	};

	return (
		<div>
			<div style={{ width: '391px' }}>
				<div className="list business-search-results vertical">
					<input
						type="checkbox"
						value={restaurantId}
						onChange={handleCheckboxChange}
						checked={isChecked}
						style={{ marginBottom: '10px' }} // Add some margin for spacing
					/>
					<RestaurantListItemNew
						cuisineName={null}
						menuSpecialties={null}
						city={null}
						distance={0}
						id={restaurantId}
						image={cuisineImg}
						key={restaurantId}
						name={restaurantName}
						numberOfLocations={1}
						stateAbbr=""
						restaurant={{}}
						otherRestaurants={{}}
						activePin={null}
						onSelectPin={null}
						onTransition={null}
						index={index}
						onSectionListToggle={null}
						toggled={false}
						item={item}
					/>
				</div>
			</div>
			<div>
				{console.log('sending:', cuisineURL, IMAGE_BASE_URL, logoURL, restaurantName, restaurantId)}
				<LazyDetailsHeader
					bannerImg={cuisineImg}
					className="test"
					imageBaseUrl={IMAGE_BASE_URL}
					logoImg={logoImg}
					restaurantName={restaurantName}
					translate={translate}
					promotion={null}
				/>
			</div>
		</div>
	);
};

OnboardingAdminRow.defaultProps = {};

OnboardingAdminRow.propTypes = {
	restaurantId: PropTypes.string,
	restaurantName: PropTypes.string,
	cuisineURL: PropTypes.string,
	index: PropTypes.number,
	logoURL: PropTypes.string,
	translate: PropTypes.object,
	IMAGE_BASE_URL: PropTypes.string,
	onCheckboxChange: PropTypes.func,
	selectedRestaurants: PropTypes.array
};

export default OnboardingAdminRow;
