import * as t from '../actions/profile_setup/types';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ProfileSetupSteps = {
	ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
	GREETING: 'GREETING',
	PROFILE: 'PROFILE',
	EMAILS: 'EMAILS',
	EMAILS_NOT_READY: 'EMAILS_NOT_READY',
	FAVORITE: 'FAVORITE',
	FINISH: 'FINISH'
};

const profileSetupDefaultState = {
	didInit: false,
	dismissedForever: true, //false,
	open: false,
	currentStep: ProfileSetupSteps.GREETING,
	completeSteps: new Set(),
	form: {
		optInToEmails: false,
		doNotPlanCateringOrEvents: false,
		doesOrderCatering: false,
		doesSchedulePrivateEvents: false
	},
	totalSteps: 0, //4,
	completedSteps: 0,
	onboardingFinished: true //false
};

const profileSetupReducer = (state = profileSetupDefaultState, action) => {
	const dismissedForever = cookies.get('profile_setup_dismissed_forever', false);

	switch (action.type) {
		case t.PROCEED: {
			return state;
		}

		case t.TOGGLE_MODAL_OPEN: {
			const newOpen = action.payload === undefined ? !state.open : action.payload;
			if (dismissedForever === 'true' || state.dismissedForever === true) {
				return {
					...state,
					open: false
				};
			}

			return { ...state, open: newOpen, currentStep: ProfileSetupSteps.GREETING };
		}

		case t.CLOSE_MODAL: {
			return { ...state, open: false, currentStep: ProfileSetupSteps.GREETING };
		}

		case t.GO_TO_STEP: {
			return {
				...state,
				currentStep: action.payload || ProfileSetupSteps.GREETING
			};
		}

		case t.UPDATE_FORM: {
			const { field, value } = action.payload;
			const newForm = { ...state.form };

			if (field === 'doNotPlanCateringOrEvents') {
				newForm['doNotPlanCateringOrEvents'] = value;
				newForm['doesOrderCatering'] = value ? false : state.form.doesOrderCatering;
				newForm['doesSchedulePrivateEvents'] = value ? false : state.form.doesSchedulePrivateEvents;
			} else if (field === 'doesOrderCatering' || field === 'doesSchedulePrivateEvents') {
				newForm['doNotPlanCateringOrEvents'] = value ? false : state.form.doNotPlanCateringOrEvents;
				newForm[field] = value;
			} else {
				newForm[field] = value;
			}

			return {
				...state,
				form: newForm
			};
		}

		case t.COMPLETE_STEP: {
			const newCompleteSteps = new Set(state.completeSteps);
			newCompleteSteps.add(action.payload);
			var completedSteps = state.completedSteps;
			if (action.payload !== 'EMAILS_NOT_READY') {
				completedSteps += 1;
			} else {
				completedSteps > 0 ? (completedSteps -= 1) : completedSteps;
			}
			return {
				...state,
				currentStep: ProfileSetupSteps.GREETING,
				completeSteps: newCompleteSteps,
				completedSteps: completedSteps
			};
		}

		case t.DISMISS_FOREVER: {
			cookies.set('profile_setup_dismissed_forever', true);

			return {
				...state,
				open: false,
				dismissedForever: true
			};
		}

		case t.DEFAULT_ONBOARDING: {
			return profileSetupDefaultState;
		}

		case t.FINISH_ONBOARDING: {
			return {
				...state,
				onboardingFinished: true // You can include this in your state
			};
		}

		case t.SET_COMPLETED_STEPS: {
			return {
				...state,
				completedSteps: action.payload
			};
		}

		case t.RESET: {
			return profileSetupDefaultState;
		}

		case t.INIT_ONBOARDING_STATE_FROM_USER: {
			const user = action.payload;
			const completedStepNames = Object.entries(user.onboardingStatus).reduce((acc, [key, value]) => {
				if (value) {
					acc.add(key);
				}
				return acc;
			}, new Set());

			return {
				...state,
				completeSteps: completedStepNames,
				completedSteps: completedStepNames.size,
				didInit: true,
				open: completedStepNames.size < state.totalSteps
			};
		}

		default: {
			if (dismissedForever === true) {
				return {
					...state,
					dismissedForever: true,
					open: false
				};
			}
			return state;
		}
	}
};

export default profileSetupReducer;
