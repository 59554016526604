import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CollapsiblePanel = ({ isOpen, children, className, toggle, onToggleClick }) => {
	const classProps = classnames('collapsible-panel', !isOpen && 'closed', className && className);

	const onClick = useCallback(() => {
		onToggleClick && onToggleClick(!isOpen);
	}, [onToggleClick, isOpen]);

	return (
		<div className={classProps}>
			<div>
				<div>{children}</div>
			</div>
			{toggle && (
				<div className="toggle" onClick={onClick}>
					<i className="fa fa-chevron-left" />
				</div>
			)}
		</div>
	);
};

/**
 * [propTypes description]
 * @interface CollapsiblePanelProps
 *
 * @example {}
 */
CollapsiblePanel.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	className: PropTypes.string,
	onToggleClick: PropTypes.func,
	toggle: PropTypes.bool
};

export default CollapsiblePanel;
