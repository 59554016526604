import React from 'react';
import { Text, Title } from 'components/atoms';
import { useTranslate } from 'hooks';

/*
	Header for FAQ page.
 */
const HelpHeader = () => {
	const translate = useTranslate();

	return (
		<div className="help-header">
			<Title>{translate('HelpFAQ.header')}</Title>
			<Text align="center">{translate('HelpFAQ.main')}</Text>
		</div>
	);
};

export default HelpHeader;
