export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_USER = 'CLEAR_USER';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const MIGRATE_PASSWORD = 'MIGRATE_PASSWORD';
export const REFRESH_TIME = 'REFRESH_TIME';
export const REFRESH_USER = 'REFRESH_USER';
export const REFRESH_USER_RUNNING = 'REFRESH_USER_RUNNING';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_USER = 'SET_USER';
export const SET_USER_TRANSACTIONS = 'SET_USER_TRANSACTIONS';
export const DELETE_PAYMENT_INFO = 'DELETE_PAYMENT_INFO';
export const USER_CREATED_CONNECTION = 'USER_CREATED_CONNECTION';
export const USER_COMPLETED_PROFILE = 'USER_COMPLETED_PROFILE';
export const USER_COMPLETED_EMAIL_OPT_IN_OUT = 'USER_COMPLETED_EMAIL_OPT_IN_OUT';
export const USER_FAVORITED_RESTAURANT = 'USER_FAVORITED_RESTAURANT';
export const USER_LAST_LOGIN = 'USER_LAST_LOGIN';
export const USER_CLOSED_FINISH = 'USER_CLOSED_FINISH';
export const ENABLE_MFA = 'ENABLE_MFA';
export const DISABLE_MFA = 'DISABLE_MFA';
export const DOORDASH_MODE = 'DOORDASH_MODE';
export const UPDATE_USER = 'UPDATE_USER';
