import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase } from 'components/atoms';

const SimpleList = ({ align, className, items, listId }) => {
	const classProps = classnames('simple-list', className, align);

	return (
		<div className={classProps} id={listId}>
			{items.length === 0 ? (
				<LoadingBase />
			) : (
				items.map(item => {
					return item;
				})
			)}
		</div>
	);
};

SimpleList.defaultProps = {
	align: 'vertical',
	items: []
};

SimpleList.propTypes = {
	className: PropTypes.string,
	align: PropTypes.oneOf(['vertical', 'horizontal']),
	items: PropTypes.array.isRequired,
	listId: PropTypes.string.isRequired
};

export default SimpleList;
