import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslate } from 'hooks';

const Distance = ({ className, distance }) => {
	const translate = useTranslate();

	if (!distance) return null;
	const number = parseFloat(distance.span).toFixed(1);
	const classProps = classnames('distance', className);

	return (
		<span className={classProps}>
			{translate('Details.distance')} {number} {translate(`Units.${distance.units}`)}
		</span>
	);
};

Distance.defaultProps = {
	className: ''
};

Distance.propTypes = {
	className: PropTypes.string,
	distance: PropTypes.object
};

export default Distance;
