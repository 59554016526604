import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import moment from 'moment';
import classnames from 'classnames';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

const LastLoginMessage = ({ className }) => {
	const { user } = useUserContext();
	const lastLogin = user?.lastLogin || {};
	const translate = useTranslate();

	let date = '';
	let ip = '';
	let userAgent = '';
	//eslint-disable-next-line
	let regex = /(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d\.apre]+)/g;
	if (lastLogin.userAgent) {
		let userAgentArray = lastLogin.userAgent.match(regex) || [];

		userAgent = userAgentArray.length > -1 ? userAgentArray[0] : '';
		if (userAgent == 'Trident/7.0') {
			userAgent = 'Internet Explorer 11';
		}
	}
	if (lastLogin.date) {
		date = `${moment(lastLogin.date).format('dddd, MMMM Do YYYY, h:mm a')} • `;
	}
	if (lastLogin.ip) {
		ip = `${lastLogin.ip} • `;
	}

	let loginString = `${date}${ip}${userAgent}`;

	return (
		lastLogin && (
			<Text size="sm" align="center" className={classnames('last-login', className)}>
				{translate('common.lastLoginMessage')} {loginString}
			</Text>
		)
	);
};

LastLoginMessage.defaultProps = {};
LastLoginMessage.propTypes = {
	className: PropTypes.string
};

export default LastLoginMessage;
