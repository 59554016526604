console.originalError = console.error;
console.error = function(...params) {
	const message = params.shift();
	const consoleError = message.startsWith && message.startsWith('Warning: ') ? console.warn : console.originalError;
	consoleError(message, ...params);
};

import { isDev } from 'utils/isNodeEnv';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'muiTheme';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import { Layout } from 'components/ecosystems';
import { STORE } from 'components/environment/App/redux_store.js';
import { AirbrakeErrorBoundary, Loader } from 'components/atoms';
import { loadConfig } from 'actions/config';
import { initialize } from 'react-localize-redux';
import { startFetchStrings } from 'actions/strings';
import ReactQueryProvider from './react_query_provider';

const removeIframes = () => {
	var iframes = document.querySelectorAll('iframe');
	for (var i = 0; i < iframes.length; i++) {
		try {
			document.body.removeChild(iframes[i]);
		} catch (e) {
			// console.log('removeIframes e', e);
		}
	}
};

class App extends Component {
	cookies = new Cookies();
	constructor(props) {
		super(props);
		this.languages = ['en'];
		this.cookies = new Cookies();
		this.state = {
			isInitialized: false
		};
	}

	componentDidMount = () => {
		const me = this;

		// init redux store with config vars and langauges
		STORE.dispatch([loadConfig(), initialize(this.languages)]);
		// fetch language translations
		STORE.dispatch(startFetchStrings()).then(() => {
			me.setState({ isInitialized: true });
		});

		if (isDev()) {
			removeIframes();
			this.interval = setInterval(() => {
				removeIframes();
			}, 1000);
		}
	};

	componentWillUnmount = () => {
		if (isDev()) {
			clearInterval(this.interval);
		}
	};

	render() {
		// console.log('Initialized?', this.state.isInitialized);
		return (
			<AirbrakeErrorBoundary>
				{/* eslint-disable-next-line no-extra-boolean-cast */}
				<Provider store={STORE}>
					<ThemeProvider theme={theme}>
						<ReactQueryProvider>{this.state.isInitialized ? <Layout /> : <Loader />}</ReactQueryProvider>
					</ThemeProvider>
				</Provider>
			</AirbrakeErrorBoundary>
		);
	}
}

App.defaultProps = {};

App.propTypes = {};

export default App;
