import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal as setModalAction, toggleModal as toggleModalAction } from 'actions/modal';
import { getLowerCasedParams } from 'utils/url';
import { useTranslate } from 'hooks';

// interface useRegistrationFormCancelModalProps {}

const useRegistrationFormCancelModal = (/*:useRegistrationFormCancelModalProps*/) => {
	const dispatch = useDispatch();
	const translate = useTranslate();

	const { modal } = useSelector(state => ({
		modal: state.modal
	}));

	const setModal = useCallback(
		modal => {
			dispatch(setModalAction(modal));
		},
		[dispatch]
	);

	const toggleModal = useCallback(
		toggle => {
			dispatch(toggleModalAction(toggle));
		},
		[dispatch]
	);

	const initCancelModal = useCallback(() => {
		setModal({
			id: translate('Registration.modals.cancel.id'),
			header: translate('Registration.modals.cancel.header'),
			body: translate('Registration.modals.cancel.body'),
			primaryButtonText: translate('Registration.modals.cancel.primaryButtonText'),
			secondaryButtonText: translate('Registration.modals.cancel.secondaryButtonText')
		});
	}, [translate, setModal]);

	useEffect(() => {
		initCancelModal();
	}, [initCancelModal]);

	const showCancelModal = useCallback(() => {
		toggleModal(!modal.isOpen);
	}, [modal.isOpen, toggleModal]);

	const onConfirmCancelRegistration = useCallback(() => {
		let redirect = getLowerCasedParams().redirect;
		if (redirect) {
			if (redirect.indexOf('#') === -1) {
				redirect += '/#/';
			}
			history.replace(redirect);
		} else {
			history.replace('/');
		}
	}, []);

	return {
		isOpen: modal.isOpen && modal.id === translate('Registration.modals.cancel.id'),
		onConfirmCancelRegistration,
		showCancelModal
	};
};

export default useRegistrationFormCancelModal;
