import { asyncComponent } from 'hoc';

const LazyComingSoonPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "ComingSoonPage" */
		/* webpackMode: "lazy" */
		'.'
	).then(module => module.default);
});

export default LazyComingSoonPage;
