const _ = require('lodash');
const commonConfig = require('./config.common');

// eslint-disable-next-line no-unused-vars
module.exports = function(options) {
	return _.merge({}, commonConfig(options), {
		// prod config here
		api_basePath_myDinova: 'https://prod.dinova.com/api-mydinova/v1',
		api_basePath_restaurant: 'https://prod.dinova.com/api-search/v2/',
		api_basePath_dashboard: 'https://prod.dinova.com/api-dashboard/v1/',
		api_basePath_zuppler: '//web5.zuppler.com',
		zuppler_dataChannelPath: 'http://api.zuppler.com/v3/channels/dinova.json',
		cdn_basePath: 'https://dinova-prod.imgix.net',
		imageBaseUrl: 'https://dinova-prod.imgix.net',
		content_paths: [
			'https://public-assets.prod.dinova.com/mydinova/v2/apps.json'
			// 'https://s3.amazonaws.com/dinova-public-assets-prod/mydinova/mydinova_copy.json',
			// 'https://s3.amazonaws.com/dinova-public-assets-prod/mydinova/search_dinova_copy.json'
		],
		web_basePath: 'https://search.dinova.com/',
		currentEnv: 'prod',
		color: 'green',
		ga_key: 'UA-47151707-7',
		api_key: 'AIzaSyDqABjy832-iA5bPCaMxHjn7TA3aBqLTbk',
		gtm_key: 'GTM-576P6RK',
		header_logo_link: 'https://www.dinova.com/',
		federated_identity_pool: 'us-east-1:d1d79a5b-bb32-4194-b131-2ffaf80ae75f',
		aws_connect_bucket: 'dinova-client-portal-storage-prod'
	});
};
