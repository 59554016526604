import React from 'react';
import PropTypes from 'prop-types';
import { BackButton, Title } from 'components/atoms';
import { useTranslate } from 'hooks';

/**
 * SearchPageHeader
 * @param {SearchPageHeaderProps} props
 */
const SearchPageHeader = ({ isSV, useMinimalLayout }) => {
	const translate = useTranslate();
	const title = isSV ? translate('Home.businessSearch.formLabel') : false;
	return (
		<>
			<div className="header">
				{!useMinimalLayout && <BackButton baseRoute="/" />}

				{title && <Title>{title}</Title>}
			</div>
		</>
	);
};

SearchPageHeader.defaultProps = {};

/**
 * SearchPageHeaderProps
 * @typedef {Object} SearchPageHeaderProps
 */
SearchPageHeader.propTypes = {
	isSV: PropTypes.bool.isRequired,
	useMinimalLayout: PropTypes.bool
};

export default SearchPageHeader;
