import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const tabPanelStyles = isVisible =>
	makeStyles(() => ({
		root: {
			overflow: isVisible ? 'visible' : 'hidden',
			height: isVisible ? 'auto' : '0px'
		}
	}));

const TabPanel = ({ children, index, value, ...other }) => {
	const styles = tabPanelStyles(index === value)();
	return (
		<div
			role="tabpanel"
			id={`company-dashboard-tabpanel-${index}`}
			aria-labelledby={`company-dashboard-tab-${index}`}
			className={styles.root}
			{...other}
		>
			{children}
		</div>
	);
};
TabPanel.defaultProps = {};
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

export default TabPanel;
