import React from 'react';
import PropTypes from 'prop-types';
import { BlurbPromo, ImagePromo, ListPromo, SubContentPromo } from './components';
import { useTranslate } from 'hooks';

const CardPromoBanner = ({ ariaHidden, className, hideImages, isFoldPromo, imageBaseUrl, translateKey }) => {
	const translate = useTranslate();

	let type;

	if (isFoldPromo) {
		type = 'image';
	} else {
		type = translate(`${translateKey}.promo.type`);
	}

	return type === 'blurb' ? (
		<BlurbPromo ariaHidden={ariaHidden} translateKey={translateKey} className={className} />
	) : type === 'subcontent' ? (
		<SubContentPromo ariaHidden={ariaHidden} translateKey={translateKey} className={className} />
	) : type === 'image' ? (
		<ImagePromo
			ariaHidden={ariaHidden}
			translateKey={translateKey}
			className={className}
			isFoldPromo={isFoldPromo}
			imageBaseUrl={imageBaseUrl}
		/>
	) : (
		<ListPromo
			ariaHidden={ariaHidden}
			translateKey={translateKey}
			hideImages={hideImages}
			className={className}
			imageBaseUrl={imageBaseUrl}
		/>
	);
};

CardPromoBanner.defaultProps = {};

CardPromoBanner.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	hideImages: PropTypes.bool,
	imageBaseUrl: PropTypes.string.isRequired,
	isFoldPromo: PropTypes.bool,
	promo: PropTypes.shape({
		type: PropTypes.oneOf(['list', 'subcontent', 'blurb', 'image']),
		title: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
		blurb: PropTypes.string,
		src: PropTypes.string
	}),
	translateKey: PropTypes.string.isRequired
};

export default CardPromoBanner;
