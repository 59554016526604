import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

/*
interface MerckModalProps {

}
*/

const MerckModal = ({ show, onClose, goToProfile } /*: MerckModalProps*/) => {
	const translate = useSelector(state => getTranslate(state.locale));

	return (
		<Modal isOpen={show} toggle={onClose} className="company_select_modal" maxWidth="550px" centered>
			<ModalHeader toggle={() => {} /*toggle*/}>{translate(`blocked-company.header`)}</ModalHeader>
			<ModalBody>{translate(`blocked-company.message`)}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							{/* Cloase Modal and Go To Profile Button */}
							<Button block color="primary" onClick={goToProfile}>
								{translate(`blocked-company.primaryButtonText`)}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

MerckModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	goToProfile: PropTypes.func.isRequired
};

export default MerckModal;
