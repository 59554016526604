import OnboardingAdminRow from './ui-components';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	IMAGE_BASE_URL: state.config.cdn_basePath
	// username: hasProp(state.users, 'user.userName') ? state.users.user.userName : ''
});

export default connect(mapStateToProps)(OnboardingAdminRow);
