export const ASSIGN_CARD = 'ASSIGN_CARD';
export const ASSIGN_CARD_DETAILS = 'ASSIGN_CARD_DETAILS';
export const ASSIGN_USER = 'ASSIGN_USER';
export const CHANGE_STEP = 'CHANGE_STEP';
export const RESET = 'RESET';
export const RESET_CARD = 'RESET_CARD';
export const RESET_CARD_DETAILS = 'RESET_CARD_DETAILS';
export const SET_ERROR = 'SET_ERROR';
export const SET_REFERRAL = 'SET_REFERRAL';
export const SET_DID_REGISTER_WITH_INELIGIBLE = 'SET_DID_REGISTER_WITH_INELIGIBLE';
