import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { useTranslate } from 'hooks';

const Ads = ({ className, dataKey, IMAGE_BASE_URL }) => {
	const translate = useTranslate();

	const classProps = classNames('ads', className),
		adsCount =
			parseInt(
				translate(`${dataKey}.ads.count`, null, {
					showMissingTranslationMsg: false
				})
			) || 0;

	return (
		adsCount > 0 && (
			<div className={classProps}>
				{range(adsCount).map((adNumber, index) => {
					const link = translate(`${dataKey}.ads.items.${adNumber + 1}.url`, null, {
						showMissingTranslationMsg: false
					});
					const img =
						IMAGE_BASE_URL +
						translate(`${dataKey}.ads.items.${adNumber + 1}.img`, null, {
							showMissingTranslationMsg: false
						});

					return (
						<a className="ad-item" href={link} key={index} target="_blank" rel="noopener noreferrer">
							<img src={img} />
						</a>
					);
				})}
			</div>
		)
	);
};

Ads.defaultProps = {
	className: '',
	dataKey: 'Home'
};

Ads.propTypes = {
	className: PropTypes.string,
	dataKey: PropTypes.string, // The base data accr for getting ad data out of lang file.
	IMAGE_BASE_URL: PropTypes.string
};

export default Ads;
