import AutoEnrollUserInvalidToken from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	isLoading: state.autoEnrollUserFormPage.isLoading,
	linkSent: state.autoEnrollUserFormPage.linkSent,
	error: state.autoEnrollUserFormPage.error
});

export default connect(mapStateToProps)(AutoEnrollUserInvalidToken);
