import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { loadRecentSearches } from 'utils/search';
import classnames from 'classnames';
import { useTraceUpdate, useTranslate } from 'hooks';

import { chain, isEqual, isFunction } from 'lodash';

const RecentSearches = props => {
	const translate = useTranslate();
	const {
		className = '',
		hasGeolocation,
		onListLoad,
		onMouseEnter: onMouseEnterProp,
		onMouseLeave: onMouseLeaveProp,
		recent = 'all',
		recentCallback,
		type
	} = props;
	useTraceUpdate(props);
	const [activeItem, setActiveItem] = useState();
	const [recentSearches, setRecentSearches] = useState([]);

	// componentDidMount
	useEffect(() => {
		const _recentSearches = loadRecentSearches(hasGeolocation, recent, type);
		if (!isEqual(_recentSearches, recentSearches)) {
			onListLoad && onListLoad(recentSearches.length > 0);
			setRecentSearches(_recentSearches);
		}
	}, [hasGeolocation, onListLoad, recent, recentSearches, type]);

	const onMouseEnter = useCallback(
		index => e => {
			if (activeItem !== index) {
				setActiveItem(index);
				onMouseEnterProp && onMouseEnterProp(e);
			}
		},
		[activeItem, onMouseEnterProp]
	);

	const onMouseLeave = useCallback(
		index => e => {
			if (activeItem !== index) {
				setActiveItem(index);
				onMouseLeaveProp && onMouseLeaveProp(e);
			}
		},
		[activeItem, onMouseLeaveProp]
	);

	const handleRecentSearches = useCallback(
		event => {
			event.stopPropagation();
			let data = {};
			const { near, find } = event.target.dataset;

			if (near) {
				data.near = near;
			}

			if (find) {
				data.find = find;
			}

			// this.props.setSearchSuggestion(data.suggestions);
			if (isFunction(recentCallback)) {
				recentCallback(data);
			}
		},
		[recentCallback]
	);

	const list = useMemo(() => {
		return chain(recentSearches || [])
			.map((item, key) => {
				let keywords = '',
					near = '',
					msg = '';
				switch (recent) {
					case 'keywords':
						msg = keywords = item['keywords'];
						break;
					case 'near':
						msg = near = item['near'];
						break;
					case 'all':
					default:
						keywords = item['keywords'];
						near = item['near'];
						msg = item['message'];
						break;
				}

				const classProps = classnames('item', key === activeItem && 'suggestion-active');

				return (
					<Button
						key={key}
						className={classProps}
						color="link"
						data-find={keywords}
						data-near={unescape(near)}
						data-cy={`recent-search-${key}`}
						onClick={handleRecentSearches}
						onMouseEnter={onMouseEnter(key)}
						onMouseLeave={onMouseLeave(undefined)}
					>
						{unescape(msg)}
					</Button>
				);
			})
			.value();
	}, [activeItem, onMouseEnter, onMouseLeave, handleRecentSearches, recent, recentSearches]);

	const classProps = classNames('recent-searches', className);

	return (
		list.length > 0 && (
			<div className={classProps}>
				<span className="label">{translate('Home.searchDropdown.recentLabel')}</span>
				<div>{list}</div>
			</div>
		)
	);
};

RecentSearches.propTypes = {
	className: PropTypes.string,
	// setSearchSuggestion: PropTypes.func,
	hasGeolocation: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onListLoad: PropTypes.func,
	recent: PropTypes.oneOf(['keywords', 'near', 'all']),
	recentCallback: PropTypes.func,
	type: PropTypes.oneOf(['catering', 'business'])
};

export default RecentSearches;
