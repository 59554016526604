import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { InputField } from 'components/organisms';
import { useTranslate } from 'hooks';

const RecipientForm = ({
	className,
	nameField,
	isNameValid,
	emailField,
	isEmailValid,
	onNameChange,
	onEmailChange
}) => {
	const translate = useTranslate();

	const classProps = classNames('recipient-form', className);
	return (
		<div className={classProps}>
			<Title align="left" size="h4">
				{translate('Order.recipientTitle')}
			</Title>
			<span className="note">{translate('Order.recipientNote')}</span>
			<InputField
				dataCy="name"
				label={translate('Order.recipientName.label')}
				name="name_field"
				id="name-field"
				hasError={!isNameValid}
				error={translate('Order.recipientName.errorText')}
				value={nameField}
				onChange={onNameChange}
			/>
			<InputField
				dataCy="email"
				label={translate('Order.recipientEmail.label')}
				name="email_field"
				id="email-field"
				hasError={!isEmailValid}
				error={translate('Order.recipientEmail.errorText')}
				value={emailField}
				onChange={onEmailChange}
			/>
		</div>
	);
};

RecipientForm.defaultProps = {
	className: '',
	nameField: '',
	emailField: '',
	isNameValid: true,
	isEmailValid: true
};

RecipientForm.propTypes = {
	className: PropTypes.string,
	nameField: PropTypes.string,
	isNameValid: PropTypes.bool,
	emailField: PropTypes.string,
	isEmailValid: PropTypes.bool,
	onNameChange: PropTypes.func,
	onEmailChange: PropTypes.func
};

export default RecipientForm;
