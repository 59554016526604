import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { useTranslate } from 'hooks';
import ToggleRecTypeButtons from '../ToggleRecTypeButtons';
import { Text, Title } from 'components/atoms';
import useGeneralContext from 'context/GeneralContext';

/*
interface RecommendationsModalHeaderProps {

}
*/

const RecommendationsModalHeader = ({ onTypeSwitch } /*: RecommendationsModalHeaderProps*/) => {
	const translate = useTranslate();
	const {
		recommendationModal: { prefill, type }
	} = useGeneralContext();

	const isPositiveRec = type === 'positive';
	const formTitle = prefill
		? translate(`Recommendations.editRecTypeTitle`)
		: translate(`Recommendations.titles.positive`);

	// const errorCmp = useMemo(() => {
	// 	if (error && errorCode !== 'NONE_SELECTED') {
	// 		let title =
	// 			errorCode === 500 ? translate('NotFound.header3') : translate(`errors.codes.${errorCode}.message`);
	// 		let content = errorCode === 500 ? translate(`errors.codes.${errorCode}.message`) : error;
	// 		return (
	// 			<React.Fragment data-cy="bananas">
	// 				<Title size="h2" align="center" dataCy="recommendations-modal-title">
	// 					{title}
	// 				</Title>
	// 				<Text size="sm" align="center">
	// 					{content}
	// 				</Text>
	// 				<div className="scroll-modal-buttons">
	// 					<Button fullWidth color="primary" onClick={closeModal}>
	// 						{translate('common.continueBtn')}
	// 					</Button>
	// 				</div>
	// 			</React.Fragment>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// }, [closeModal, error, errorCode, translate]);

	return (
		<>
			{/* {errorCmp} */}
			<Title size="h2" align="center" dataCy="recommendations-modal-title" className={styles.title}>
				{formTitle}
			</Title>
			<ToggleRecTypeButtons isPositiveRec={isPositiveRec} onTypeSwitch={onTypeSwitch} />
			{/* {formErrorCmp} */}
			<Text size="sm" align="center" className={styles.checkAll}>
				{translate('common.checkAll')}
			</Text>
		</>
	);
};

RecommendationsModalHeader.propTypes = {
	// formErrorCmp: PropTypes.node,
	onTypeSwitch: PropTypes.func
};

export default RecommendationsModalHeader;
