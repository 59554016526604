import React from 'react';
import PropTypes from 'prop-types';

/**
 * Image element with ref set to this.img.  Ref can be used in an HOC to manipulate image element.
 * @param {Props_Image} props
 */
const Image = ({ alt, className, dataCy, onLoad, refSetter, src }) => (
	<img alt={alt} src={src} className={className} onLoad={onLoad} ref={refSetter} data-cy={`image-${dataCy}`} />
);

Image.defaultProps = {
	className: '',
	onLoad: () => {},
	src: ''
};

/**
 * Image component props shape.
 * @interface Props_Image
 * @property {string} [alt=null] Image alt.
 * @property {string} [className=null] Additional className to add to the image element.
 * @property {string} [dataCy=null]    A unique id for cypress tests.
 * @property {func}   [onLoad=() => {}]    On image load handler.
 * @property {string} [src=""]       The image src.
 */
Image.propTypes = {
	alt: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	onLoad: PropTypes.func,
	refSetter: PropTypes.object, // createRef()
	src: PropTypes.string
};

export default Image;
