import React, { Component } from 'react';

// Can be used when CompanySelect lives in a parent component who has a prop provided submit funciton. Ex: EditPersonalForm
// Will not work if CompanySelect lives in parent component who has a parent component submit method. Ex: RegistrationForm
export default function FormWithCompanySelect(WrappedComponent, funcMap = {}) {
	class FormWithCompanySelect extends Component {
		constructor(props) {
			super(props);

			this.handleKeyUp = this.handleKeyUp.bind(this);

			this.state = {
				companySelectHasFocus: false
			};
		}

		componentDidMount = () => {
			window.addEventListener('keyup', this.handleKeyUp);
		};

		componentWillUnmount = () => {
			window.removeEventListener('keyup', this.handleKeyUp);
		};

		onCompanySelectFocus = () => {
			this.setState({ companySelectHasFocus: true });
		};

		onCompanySelectBlur = () => {
			this.setState({ companySelectHasFocus: false });
		};

		handleKeyUp = e => {
			if (e.key === 'Enter') {
				if (this.state.companySelectHasFocus) {
					e.preventDefault();
				} else {
					if (funcMap.onEnterPress) {
						if (typeof funcMap.onEnterPress === 'string') {
							const handler = this.props[funcMap.onEnterPress];

							if (handler) {
								handler(e);
							}
						} else {
							funcMap.onEnterPress(e);
						}
					}
				}
			}
		};

		render() {
			return (
				<WrappedComponent
					{...this.props}
					onCompanySelectFocus={this.onCompanySelectFocus}
					onCompanySelectBlur={this.onCompanySelectBlur}
					isCompanySelectFocused={this.state.companySelectHasFocus}
				/>
			);
		}
	}
	return FormWithCompanySelect;
}
