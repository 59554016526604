import { validateCard } from 'utils/card';
import { lengthValidator } from 'utils/form/validators';
import { AMEX } from 'utils/card';

const creditCardNumberValidator = (card, cardNumber) => {
	if (!card) {
		return 'ConnectToPoints.inputs.creditcard.invalidCardType';
	}
	if (!cardNumber) {
		return 'ConnectToPoints.inputs.creditcard.invalidCardNumber';
	} else if (!card.fullNumberRequired) {
		if (!lengthValidator(cardNumber, 4)) {
			return 'ConnectToPoints.inputs.creditcard.invalidCardNumber';
		}
	} else if (card.fullNumberRequired) {
		const length = card.cardType === AMEX ? 15 : 16;
		if (!lengthValidator(cardNumber, length)) {
			return 'ConnectToPoints.inputs.creditcard.invalidCardNumber';
		}
		// let card = vals.selectedCardType;
		let { isValidCardType, isValidCardNumber } = validateCard(card, cardNumber);
		if (!isValidCardType) {
			return 'ConnectToPoints.inputs.creditcard.invalidCardType';
		} else if (!isValidCardNumber) {
			return 'ConnectToPoints.inputs.creditcard.invalidCardNumber';
		}
	}

	return undefined;
};

export default creditCardNumberValidator;
