import React from 'react';
import { useSelector } from 'react-redux';
import { AppDownloadButtonLink } from 'components/atoms';
// import PropTypes from 'prop-types'

/*
interface AppStoreButtonLinkProps {

}
*/

const AppStoreButtonLink = (/*: AppStoreButtonLinkProps*/) => {
	const link = 'https://itunes.apple.com/us/app/dinova-restaurant-marketplace/id829424959?mt=8&at=10l6Xd';

	const imageBaseUrl = useSelector(state => state.config.imageBaseUrl);

	return (
		<AppDownloadButtonLink
			bgImg={`url(${imageBaseUrl}/mydinova/logos/apple-app-store-badge.svg) no-repeat`}
			link={link}
		/>
	);
};

// AppStoreButtonLink.propTypes = {

// }

export default AppStoreButtonLink;
