import React from 'react';
import PropTypes from 'prop-types';
import { CTAMenu } from 'components/molecules';
import { generateActions } from './utils';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const DetailsAboutCTAMenu = ({ restaurant }) => {
	const { imageBaseUrl, translate } = useSelector(state => ({
		imageBaseUrl: state.config.imageBaseUrl,
		translate: getTranslate(state.locale)
	}));
	const actions = generateActions(restaurant, translate, imageBaseUrl);

	return actions.length > 0 ? <CTAMenu actions={actions} /> : null;
};

DetailsAboutCTAMenu.defaultProps = {};

DetailsAboutCTAMenu.propTypes = {
	restaurant: PropTypes.object
};

export default DetailsAboutCTAMenu;
