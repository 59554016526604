import { getVal } from 'utils/object';

export const deselectFilter = (filter, currentFilters) => {
	if (filter.forEach) {
		filter.forEach(f => {
			currentFilters = deselectFilter(f, currentFilters);
		});
	} else {
		let currentFilter = getVal(currentFilters, filter.filterKey);
		currentFilter.selected = false;
		currentFilters[filter.filterKey.split('.')[0]].activeCount -= 1;
	}
	return currentFilters;
};

export default deselectFilter;
