import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

/**
 * [FilterGroupItem description]
 */
const FilterGroupItem = ({ className, filter, filterName, onClick, showResultsCount, useCheck }) => {
	return (
		<li
			data-cy={`filter-item-${filterName}`}
			data-filter={filterName.toLowerCase().replaceAll(' ', '_')}
			onClick={async () => {
				await onClick(filter);
			}}
			className={classnames(className && className, filter.selected && 'selected')}
		>
			{useCheck && <div className="box" />}
			<Text className="mb-0">
				{filterName}
				{showResultsCount && ` (${filter.resultsCount})`}
			</Text>
		</li>
	);
};

FilterGroupItem.defaultProps = {};

/**
 * [propTypes description]
 * @interface FilterGroupItemProps
 *
 * @example {}
 */
FilterGroupItem.propTypes = {
	className: PropTypes.string,
	filter: PropTypes.object.isRequired,
	filterName: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	showResultsCount: PropTypes.bool,
	useCheck: PropTypes.bool
};

export default FilterGroupItem;
