import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslate } from 'hooks';

const HeaderSection = ({ bannerImg, className = '', history, logoImg, title, withoutLogo }) => {
	const translate = useTranslate();

	const back = useCallback(
		e => {
			e.preventDefault();
			if (history.length > 1) {
				history.goBack();
			} else {
				history.push('/search');
			}
		},
		[history]
	);

	const classProps = classNames('header-section', className);
	const bannerImgUrl = `url("${bannerImg}")`;

	return (
		<div className={classProps}>
			<div className="banner-img" style={{ backgroundImage: bannerImgUrl }} />
			<a className="back-link" onClick={back}>
				<i className="fa fa-chevron-left" />
				{translate('Details.back')}
			</a>
			<span className="title">{title}</span>
			{withoutLogo !== true && <img className="logo-img" src={logoImg} />}
		</div>
	);
};

HeaderSection.propTypes = {
	bannerImg: PropTypes.string,
	className: PropTypes.string,
	history: PropTypes.object.isRequired,
	logoImg: PropTypes.string,
	title: PropTypes.string,
	withoutLogo: PropTypes.bool
};

export default HeaderSection;
