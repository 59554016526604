/* eslint-disable no-case-declarations */
import * as t from '../actions/users/types';
import Cookies from 'universal-cookie';
import { getPermissions } from 'utils/permissions';
import { isEmpty } from 'lodash';
// const _ = require('lodash');

const cookies = new Cookies();

/**
 * Redux_UsersState
 * @interface Redux_UsersState
 * @property {boolean}  authenticated
 * @property {date}     [authenticated_time=undefined]
 * @property {boolean}  [resetPassword=false]
 * @property {boolean}  [migrated=false]
 * @property {boolean}  [expired=false]
 * @property {string}   [errorCode='']
 * @property {object}   [user=undefined]
 * @property {boolean}  [refreshingUser=false]
 */
const usersDefaultState = {
	authenticated: cookies.get('token') ? cookies.get('token') : '',
	authenticated_time: undefined,
	errorCode: '',
	expired: false,
	idToken: undefined,
	migrated: false,
	permissions: [],
	refreshingUser: false,
	resetPassword: false,
	user: undefined,
	lastLogin: {}
};

export default (state = usersDefaultState, action) => {
	switch (action.type) {
		case t.AUTH_USER: {
			if (action.payload === '') {
				return {
					...usersDefaultState,
					authenticated: '',
					idToken: undefined
				};
			} else {
				if (action.payload.id) {
					const permissions = getPermissions(action.payload.id);
					return { ...state, authenticated: action.payload.access, idToken: action.payload.id, permissions };
				}
				return { ...state, authenticated: action.payload, idToken: undefined };
			}
		}
		case t.CLEAR_USER: {
			return { ...usersDefaultState, authenticated: '' };
		}
		case t.REFRESH_USER:
			const permissions = getPermissions(action.payload.id);
			return { ...state, authenticated: action.payload.access, idToken: action.payload.id, permissions };
		case t.SET_USER:
			if (isEmpty(state.lastLogin)) {
				return {
					...state,
					user: {
						...action.payload,
						hasConnections: action.payload.connections.length > 0,
						securityPIN: '999999'
					},
					lastLogin: action.payload.lastLogin
				};
			} else {
				return {
					...state,
					user: {
						...action.payload,
						hasConnections: action.payload.connections.length > 0,
						securityPIN: '999999'
					}
				};
			}
		case t.UPDATE_USER: {
			// console.log('%c payload', 'color: limegreen', action.payload);
			// const newThing = _.merge(state, action.payload);
			// const anotherNewThing = Object.assign(state.user, action.payload);
			// console.log('%c newThing', 'color: limegreen', newThing);
			// console.log('%c anotherNewThing', 'color: orange', anotherNewThing);
			// console.log('%c state', 'color:red', state);
			return state;
		}
		case t.USER_CREATED_CONNECTION: {
			return state;
			// return {
			// 	...state,
			// 	user: {
			// 		...state.user,
			// 		hasConnections: true,
			// 		onboardingStatus: { ...state.user.onboardingStatus, connectedCard: true }
			// 	}
			// };
		}
		case t.USER_COMPLETED_EMAIL_OPT_IN_OUT: {
			console.log('USER_COMPLETED_EMAIL_OPT_IN_OUT', action);
			return state;
			// return {
			// 	...state,
			// 	user: {
			// 		...state.user,
			// 		onboardingStatus: {
			// 			...state.user.onboardingStatus,
			// 			optInNotification: action.status.onboardingStatus.optInNotification
			// 		}
			// 	}
			// };
		}
		case t.USER_COMPLETED_PROFILE: {
			return state;
			// return {
			// 	...state,
			// 	user: { ...state.user, onboardingStatus: { ...state.user.onboardingStatus, completeProfile: true } }
			// };
		}
		case t.USER_FAVORITED_RESTAURANT: {
			return state;
			// return {
			// 	...state,
			// 	user: { ...state.user, onboardingStatus: { ...state.user.onboardingStatus, favoriteRestaurant: true } }
			// };
		}
		case t.USER_CLOSED_FINISH: {
			return state;
			// return {
			// 	...state,
			// 	user: { ...state.user, onboardingStatus: { ...state.onboardingStatus, viewCompletion: true } }
			// };
		}
		case t.SET_USER_TRANSACTIONS: {
			let user = state.user;
			if (window.Cypress) {
				// Return Test Data if in Cypress Mode
				const cypressStub = require('./test_data/users/transactions/stub').default;
				return cypressStub(state, user);
			}

			return {
				...state,
				user: {
					...user,
					transactions: {
						...user.transactions,
						[Object.keys(action.payload)[0]]: action.payload[Object.keys(action.payload)[0]]
					}
				}
			};
		}
		case t.DELETE_PAYMENT_INFO: {
			return state;
			// const connections = state.user.connections.filter(c => c.id !== action.payload);

			// return {
			// 	...state,
			// 	user: {
			// 		...state.user,
			// 		connections
			// 	}
			// };
		}
		case t.FETCH_USER_ERROR:
		case t.AUTH_ERROR:
			return { ...state, errorCode: action.payload };
		case t.MIGRATE_PASSWORD:
			return { ...state, resetPassword: true, migrated: true, user: action.payload };
		case t.RESET_PASSWORD:
			return { ...state, resetPassword: true, migrated: false, user: action.payload };
		case t.CLEAR_ERROR:
			return { ...state, errorCode: '' };
		case t.REFRESH_USER_RUNNING:
			return { ...state, refreshingUser: action.payload };
		case t.REFRESH_TIME:
			let moment = undefined;
			if (action.payload) {
				moment = action.payload;
			}
			return { ...state, authenticated_time: moment };
		case t.USER_LAST_LOGIN:
			return { ...state, lastLogin: action.payload };
		default:
			return state;
	}
};
