import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const UserLocationMapMarker = ({ className, IMAGE_BASE_URL }) => {
	const classProps = classNames('user-location-map-marker', className);
	const imgSrc = `${IMAGE_BASE_URL}/icons/icon-current-location-small.png`;

	return <img className={classProps} src={imgSrc} alt="Current Location Marker" />;
};

UserLocationMapMarker.defaultProps = {
	className: ''
};

UserLocationMapMarker.propTypes = {
	// Required
	IMAGE_BASE_URL: PropTypes.string.isRequired,

	// Optional
	className: PropTypes.string
};

export default UserLocationMapMarker;
