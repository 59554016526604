import React, { useCallback } from 'react';
import { Text } from 'components/atoms';
import { getLangFileCardLabelKey } from 'utils/card';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

const ProfilePaymentIneligibilityMessage = ({
	hasEligible,
	ineligibleConnectionCardTypes,
	supportedConnectionCardTypes
}) => {
	const translate = useTranslate();
	const buildIneligiblePrompt = useCallback(() => {
		const cardNames = supportedConnectionCardTypes.map(t =>
			translate(`Profile.payment.ineligible.cardNames.${getLangFileCardLabelKey(t)}`)
		);

		if (cardNames.length === 1) {
			return buildSingleCardEligibilityMessage('singlePrompt', cardNames);
		} else {
			if (cardNames.length === 2) {
				return buildTwoCardEligibilityMessage('multiPrompt', cardNames);
			} else {
				return buildThreePlusCardEligibilityMessage('multiPrompt', cardNames);
			}
		}
	}, [
		buildSingleCardEligibilityMessage,
		buildTwoCardEligibilityMessage,
		buildThreePlusCardEligibilityMessage,
		supportedConnectionCardTypes,
		translate
	]);

	const buildSingleCardEligibilityMessage = useCallback(
		(translateKey, cardNames) => {
			return (
				// get prompt string from lang file
				translate(`Profile.payment.ineligible.${translateKey}`)
					// replace cardType place holder with actual card type
					.replace('${cardTypes}', cardNames.join(''))
			);
		},
		[translate]
	);

	const buildTwoCardEligibilityMessage = useCallback(
		(translateKey, cardNames) => {
			const joiner = translate('Profile.payment.ineligible.joiners.or');
			return (
				// get prompt string from lang file
				translate(`Profile.payment.ineligible.${translateKey}`)
					// replace cardTypes place holder with actual card types and joiner
					.replace('${cardTypes}', cardNames.join(` ${joiner} `))
					// use an or a as needed
					.replace('a(n)', cardNames[0][0].toLowerCase() === 'a' ? 'an' : 'a')
			);
		},
		[translate]
	);

	const buildThreePlusCardEligibilityMessage = useCallback(
		(_, cardNames) => {
			const joiner = translate('Profile.payment.ineligible.joiners.or');

			// put joiner in correct place
			cardNames.splice(-1, 0, joiner);
			return (
				// get prompt string from lang file
				translate('Profile.payment.ineligible.multiPrompt')
					// join card names with an ','
					.replace('${cardTypes}', cardNames.join(`, `))
					// remove trailing ',' from joiner caused by join(', ')
					.replace(`${joiner},`, joiner)
					// user an or a as needed
					.replace('a(n)', cardNames[0][0].toLowerCase() === 'a' ? 'an' : 'a')
			);
		},
		[translate]
	);

	const buildIneligibleNotice = useCallback(() => {
		if (ineligibleConnectionCardTypes[0] != null) {
			let cardNames = Array.from(ineligibleConnectionCardTypes).map(t =>
				translate(`Profile.payment.ineligible.cardNames.${getLangFileCardLabelKey(t)}`)
			);

			if (cardNames.length === 1) {
				return buildSingleCardEligibilityMessage('notice', cardNames);
			} else {
				if (cardNames.length === 2) {
					return buildTwoCardEligibilityMessage('notice', cardNames);
				} else {
					return buildThreePlusCardEligibilityMessage('notice', cardNames);
				}
			}
		}
	}, [
		buildSingleCardEligibilityMessage,
		buildTwoCardEligibilityMessage,
		buildThreePlusCardEligibilityMessage,
		ineligibleConnectionCardTypes,
		translate
	]);

	return ineligibleConnectionCardTypes.size > 0 ? (
		<div className="center ineligible-message" data-cy="profile-payment-ineligibility-message-wrapper">
			<Text size="sm" weight="thin" align="center" className="mb-0" dataCy="profile-payment-ineligibility-notice">
				{buildIneligibleNotice()}
			</Text>
			{!hasEligible && (
				<Text
					size="sm"
					weight="thin"
					align="center"
					className="mb-0"
					dataCy="profile-payment-ineligibility-prompt"
				>
					{buildIneligiblePrompt()}
				</Text>
			)}
		</div>
	) : null;
};

ProfilePaymentIneligibilityMessage.propTypes = {
	hasEligible: PropTypes.bool.isRequired,
	ineligibleConnectionCardTypes: PropTypes.oneOfType([PropTypes.instanceOf(Set), PropTypes.array]),
	supportedConnectionCardTypes: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number)
	])
};

export default ProfilePaymentIneligibilityMessage;
