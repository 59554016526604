import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

const FormFieldRequirements = ({ formFieldText }) => {
	const translate = useTranslate();
	const title = useMemo(() => {
		if (formFieldText) {
			return formFieldText.titleKey ? translate(formFieldText.titleKey) : formFieldText.title;
		}
	}, [formFieldText, translate]);

	const requirementList = useMemo(() => {
		if (formFieldText) {
			const { requirements } = formFieldText;

			return requirements.map((requirement, index) => (
				<li className={requirement.status} key={index}>
					<i className="fas fa-check" />
					{requirement.textKey ? translate(requirement.textKey) : requirement.text}
				</li>
			));
		}
	}, [formFieldText, translate]);

	return (
		<div className="form-field-requirements di_TintGrey mt-2">
			<p>{title}</p>
			<ul className="requirement-list">{requirementList}</ul>
		</div>
	);
};

FormFieldRequirements.propTypes = {
	formFieldText: PropTypes.object.isRequired
};

export default FormFieldRequirements;
