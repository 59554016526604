import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'mui-rff';
import { Col } from 'components/atoms';
import { FormFieldRequirements } from 'components/molecules';

/*
interface TextFieldNewProps {

}
*/

const TextFieldNew = (props /*: TextFieldNewProps*/) => {
	const validState = useMemo(
		() =>
			(props.requirements || []).map(requirement => {
				return {
					status: requirement.validator(props.value) ? 'valid' : 'invalid',
					textKey: requirement.textKey
				};
			}),
		[props.requirements, props.value]
	);

	const validate = useCallback(() => {
		if (validState.find(requirement => requirement.status === 'invalid')) {
			return 'Required';
		}

		return;
	}, [validState]);

	if (props.requirements?.length) {
		return (
			<Col fullWidth>
				<TextField {...props} validate={validate} />
				<FormFieldRequirements
					formFieldText={{
						requirements: validState,
						titleKey: props.titleKey
					}}
				/>
			</Col>
		);
	}

	return <TextField {...props} />;
};

TextFieldNew.propTypes = {
	dataCy: PropTypes.string,
	// All required if showing requirements. Otherwise, none are required.
	requirements: PropTypes.arrayOf(
		PropTypes.shape({
			validator: PropTypes.func,
			title: PropTypes.string
		})
	),
	titleKey: PropTypes.string,
	value: PropTypes.any
};

export default TextFieldNew;
