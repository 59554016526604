import Card from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';
import { setActiveCard } from 'actions/landing_page_cards';

const mapStateToProps = (state, ownProps) => ({
	imageBaseUrl: state.config.cdn_basePath,
	showAbout: state.landingCards.showAbout,
	isActive: state.landingCards.activeCard === ownProps.index,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	close: () => dispatch(setActiveCard())
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(Card));
