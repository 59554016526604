import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';
import { getSpecialFilterLabel } from 'utils/search_new';

const ActiveFiltersBar = ({ activeFilters, removeFilter }) => {
	const classProps = classnames('active-filter-bar', activeFilters && activeFilters.length > 0 && 'active');

	const activeFilterComponents = useMemo(() => {
		return activeFilters.map((f, i) => {
			const isLast = i === activeFilters.length - 1;
			let name = f.selectedFilterDisplayValue || startCase(f.value);
			let specialLabel = getSpecialFilterLabel(f.dataAccr);

			return removeFilter ? (
				<span
					onClick={() => {
						removeFilter(f);
					}}
					style={{ whiteSpace: 'pre' }}
					data-cy={`active-filter-${name}`}
				>
					{specialLabel || name}
					{!isLast && ', '}
				</span>
			) : (
				`${specialLabel || name}`
			);
		});
	}, [activeFilters, removeFilter]);

	return activeFilters && activeFilters.length > 0 ? (
		<div className={classProps} data-cy="active-filter-bar">
			<div className="active-filter-list-wrapper">
				<Text className="flex mb-0">
					{removeFilter ? activeFilterComponents : activeFilterComponents.join(', ')}
				</Text>
			</div>
		</div>
	) : null;
};

ActiveFiltersBar.defaultProps = {};

ActiveFiltersBar.propTypes = {
	activeFilters: PropTypes.arrayOf(
		PropTypes.shape({
			dataAccr: PropTypes.string.isRequired
		})
	),
	removeFilter: PropTypes.func.isRequired
};

export default ActiveFiltersBar;
