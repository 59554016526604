import * as t from '../actions/delete_connections_warning_modal/types';

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */
const deleteConnectionsWarningModalInitialState = {
	show: false,
	confirmHandler: () => {}
};

export default (state = deleteConnectionsWarningModalInitialState, action) => {
	switch (action.type) {
		case t.TOGGLE_SHOW_DELETE_CONNECTIONS_WARNING_MODAL: {
			const { show, confirmHandler } = action.payload;
			return {
				confirmHandler,
				show
			};
		}

		default:
			return state;
	}
};
