import { useMutation, useQueryClient } from '@tanstack/react-query';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
import * as LEGACY_REDUX_REWARDS_TYPES from 'actions/reward/types';
import { USER_QUERY_KEY } from 'reactQuery/queries';

/*
interface useRedeemRewardMutationProps {}
*/

const useRedeemRewardMutation = ({ onError, onSuccess } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async order => {
			dispatch({ type: LEGACY_REDUX_REWARDS_TYPES.REDEEMED_REWARD, payload: order });
			return await axios.post(`${basePath()}/users/rewards`, JSON.stringify(order), AUTH_REQUEST_HEADER());
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'Redeeming your reward'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			dispatch(setIsLoading(false));

			// Update cache with new user data
			queryClient.invalidateQueries({
				queryKey: [USER_QUERY_KEY]
			});
			queryClient.refetchQueries({
				queryKey: [USER_QUERY_KEY]
			});

			onSuccess && onSuccess(data);
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		redeemReward: mutation.mutate
	};
};

export default useRedeemRewardMutation;
