import useQuery from './useQuery';
import axios from 'axios';
import { basePath_dashboard, AUTH_REQUEST_HEADER_ID } from 'actions/utils';

/*
interface useCompanyTransactionDetailsQueryProps {}
*/

const COMPANY_CARDHOLDER_DETAILS_QUERY_KEY = 'company_cardholder_details_query';

const useCompanyCardholderDetailsQuery = ({ companyCode } /*:useCompanyTransactionDetailsQueryProps*/) => {
	return useQuery({
		queryKey: [COMPANY_CARDHOLDER_DETAILS_QUERY_KEY, companyCode],
		queryFn: async ({ queryKey }) => {
			const code = queryKey[1];
			const url = `mydinova/clients/users?code=${code}`;

			return axios.get(`${basePath_dashboard()}${url}`, AUTH_REQUEST_HEADER_ID()).then(res => res.data);
		},
		enabled: Boolean(companyCode),
		reduxLoadingMsg: 'Loading Cardholder Details...',
		requiresAuth: true
	});
};

export default useCompanyCardholderDetailsQuery;
