import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

// interface useCOVID19StateProps {}

const useCOVID19State = (/*:useCOVID19StateProps*/) => {
	const { translate } = useSelector(state => ({
		translate: getTranslate(state.locale)
	}));
	const [stateAbbr, setStateAbbr] = useState('');
	const [show, setShow] = useState(true);
	const [hideSafetyMeasures, setHideSafetyMeasures] = useState(false);
	const [config, setConfig] = useState({
		active: false,
		message: '',
		color: '',
		activeFilter: false
	});

	const setCovidState = useCallback(
		({ stateAbbr: newStateAbbr, show: newShow, hideSafetyMeasures: newHideSafetyMeasures, config: newConfig }) => {
			if (newStateAbbr !== stateAbbr) {
				setStateAbbr(newStateAbbr);
			}

			if (newShow !== show) {
				setShow(newShow);
			}

			if (newHideSafetyMeasures !== hideSafetyMeasures) {
				setHideSafetyMeasures(newHideSafetyMeasures);
			}

			if (!isEqual(newConfig, config)) {
				setConfig(newConfig);
			}
		},
		[stateAbbr, show, hideSafetyMeasures, config]
	);

	const toggleCovidShow = useCallback(() => {
		setShow(prev => !prev);
	}, []);

	const loadRestaurantCovidState = useCallback(
		restaurant => {
			let newHideSafetyMeasures = false;
			if (Object.keys(restaurant.safetyMeasures).length === 0) {
				newHideSafetyMeasures = true;
			} else {
				let hasPractices = [];
				if (hasProp(restaurant, 'safetyMeasures.socialDistancing')) {
					hasPractices.push(restaurant.safetyMeasures.socialDistancing.length > 0);
				}

				if (hasProp(restaurant, 'safetyMeasures.dinerScreening')) {
					hasPractices.push(restaurant.safetyMeasures.dinerScreening.length > 0);
				}

				if (hasProp(restaurant, 'safetyMeasures.sanitization')) {
					hasPractices.push(restaurant.safetyMeasures.sanitization.length > 0);
				}

				if (hasPractices.filter(v => v === false).length === 0) {
					newHideSafetyMeasures = false;
				} else {
					newHideSafetyMeasures = true;
				}
			}

			const stateAbbr = restaurant?.address?.state?.abbreviation;

			const newConfig = {
				active: translate(`notification.states.${stateAbbr}.active`) === 'true',
				message: translate(`notification.states.${stateAbbr}.message`),
				color: translate(`notification.states.${stateAbbr}.color`),
				activeFilter: translate(`notification.states.${stateAbbr}.activeFilter`) === 'true'
			};

			if (!isEqual(newConfig, config)) {
				setConfig(newConfig);
			}

			if (newHideSafetyMeasures !== hideSafetyMeasures) {
				setHideSafetyMeasures(newHideSafetyMeasures);
			}
		},
		[config, hideSafetyMeasures, translate]
	);

	return {
		state: stateAbbr,
		show,
		hideSafetyMeasures,
		config,
		loadRestaurantCovidState,
		toggleCovidShow,
		setCovidState
	};
};

export default useCOVID19State;
