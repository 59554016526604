import React from 'react';
import PropTypes from 'prop-types';
import { Field, SubmissionError } from 'redux-form';
import { LoadingBase } from 'components/atoms';
import { TermsAndConditions } from 'components/molecules';
import { ReduxFormInput } from 'components/organisms';
import { Button } from 'reactstrap';
import validate from './validate';

class AutoEnrollUserForm extends React.Component {
	state = {
		passwordField: null,
		passwordConfirmField: null,
		termsAndConditionsField: null,
		tandcOpen: false,
		emailOptField: null,
		offersOptField: null
	};

	componentDidMount = () => {
		const isConfirm = true;
		this.initPasswordField();
		this.initPasswordField(isConfirm);
		this.initTermsAndConditionsField();
		this.initEmailOptField();
		this.initOffersOptField();
	};

	initPasswordField = isConfirm => {
		let passwordField = (
			<Field
				name={isConfirm ? 'passwordConfirm' : 'password'}
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: isConfirm ? 'password-confirm-field' : 'password-field',
								name: isConfirm ? 'passwordConfirm' : 'input_password',
								id: isConfirm ? 'passwordConfirm' : 'input-password',
								type: 'password',
								error: isConfirm ? this.props.translate(field.meta.error || '') : '',
								hasError: !field.meta.valid,
								label: this.props.translate(
									isConfirm
										? 'AutoEnrollUserForm.validToken.inputs.confirmPassword.label'
										: 'Registration.inputs.password.label'
								)
							}}
						/>
					);
				}}
			/>
		);

		isConfirm ? this.setState({ passwordConfirmField: passwordField }) : this.setState({ passwordField });
	};

	initTermsAndConditionsField = () => {
		let termsAndConditionsField = (
			<Field
				name="terms"
				type="checkbox"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'checkboxSingle',
								required: true,
								option: {
									label: this.props.translate('common.tandcLabel'),
									checked: field.input.checked,
									id: 'tandc',
									name: 'tandc'
								},
								isReduxForm: true,
								dataCy: 'termsAndConditions-field',
								name: 'input_termsAndConditions',
								id: 'input-termsAndConditions',
								error: this.props.translate('Registration.terms-label-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('common.tandcLabel'),
								triggers: [<TermsAndConditions key="trigger-two" />]
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ termsAndConditionsField });
	};

	initOffersOptField = () => {
		// const me = this;
		let offersOptField = (
			<Field
				name="offersOpt"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'radio',
								dataCy: 'offersOpt-field',
								name: 'input_offersOpt',
								id: 'input-offersOpt',
								error: this.props.translate('Registration.optin-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.offers-label'),
								alignOptions: 'horizontal',
								value: field.input.value.value,
								required: false,
								options: [
									{
										value: 'yes',
										displayValue: 'Yes',
										id: 'offers-yes',
										name: 'optIn.offers'
									},
									{
										value: 'no',
										displayValue: 'No',
										id: 'offers-no',
										name: 'optIn.offers'
									}
								]
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ offersOptField });
	};

	initEmailOptField = () => {
		let emailOptField = (
			<Field
				name="emailOpt"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'radio',
								dataCy: 'emailOpt-field',
								name: 'input_emailOpt',
								id: 'input-emailOpt',
								error: this.props.translate('Registration.optin-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.email-summary-label'),
								alignOptions: 'horizontal',
								value: field.input.value.value,
								required: false,
								options: [
									{
										value: 'yes',
										displayValue: 'Yes',
										id: 'email-yes',
										name: 'optIn.email'
									},
									{
										value: 'no',
										displayValue: 'No',
										id: 'email-no',
										name: 'optIn.email'
									}
								]
								// help: 'Lorem.. help',
								// example: 'Lorem.. example'
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ emailOptField });
	};

	/**
	 * Our submit funciton for when the user submits the form.
	 * @param  {object} vals This will be the redux-form vals object.
	 */
	submit = async vals => {
		const errors = validate(vals);
		if (Object.keys(errors).length) {
			throw new SubmissionError(errors);
		} else {
			const me = this;
			setTimeout(() => {
				vals.token = this.props.token;
				me.props.createAutoEnrollUser(vals);
			}, 500);
		}
	};

	toggleTandC = () => {
		this.setState({ tandcOpen: !this.state.tandcOpen });
	};

	/**
	 * Render a form with the redux-form provided handleSubmit prop.  The handleSubmit prop expects to be passed a submit function.
	 * If no submit funciton is provided, the form will subumit by the default html form submit funcitonality.
	 * @return {[type]} [description]
	 */
	render = () => {
		const { translate } = this.props;

		return (
			<div data-cy="auto-enroll-valid-token">
				{this.props.isLoading && <LoadingBase message={translate('AutoEnrollUserForm.validToken.loading')} />}
				{!this.props.isLoading && !this.props.complete && (
					<form onSubmit={this.props.handleSubmit(this.submit)} className="auto-enroll-user-form">
						{this.state.passwordField}
						{this.state.passwordConfirmField}
						{this.state.termsAndConditionsField}
						{/* <Text size="sm">
							{translate('AutoEnrollUserForm.validToken.disclaimer')}
							<span className="link" onClick={this.toggleTandC}>
								{translate('common.tandcLabel')}
							</span>
							<TermsAndConditions
								toggleDropdown={this.toggleTandC}
								isOpen={this.state.tandcOpen}
								key="trigger-two"
								showTriggerText={false}
								showCaret={false}
							/>
						</Text> */}
						<div className="mb-4 mt-10">
							<h3>What would you like to hear about?</h3>

							{this.state.emailOptField}
							{this.state.offersOptField}
						</div>
						<div className="edit-profile-actions">
							<Button color="primary">{this.props.translate('common.finish')}</Button>
						</div>
					</form>
				)}
			</div>
		);
	};
}

AutoEnrollUserForm.defaultProps = {};

AutoEnrollUserForm.propTypes = {
	complete: PropTypes.bool.isRequired,
	/* eslint-disable */
	createAutoEnrollUser: PropTypes.func.isRequired, // referenced as me.createAutoEnrollUser so this prop type fails
	/* eslint-enable */
	initialValues: PropTypes.shape({
		password: PropTypes.string,
		passwordConfirm: PropTypes.string,
		autoEnrollToken: PropTypes.string
	}).isRequired,
	isLoading: PropTypes.bool.isRequired, // redux-form provided prop
	handleSubmit: PropTypes.func.isRequired, // redux-form provided prop
	translate: PropTypes.func.isRequireds,
	token: PropTypes.string
};

export default AutoEnrollUserForm;
