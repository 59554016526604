import RightIconNav from './ui-component';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';

const mapStateToProps = state => ({
	config: state.config
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default withRouter(connect(mapStateToProps)(withSizes(mapSizesToProps)(RightIconNav)));
