import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserConnectionDeleteOneMutationProps {}
*/

const useUserConnectionDeleteOneMutation = (
	{ onError, onSuccess, setErrorInRedux } = {} /*:useUserConnectionDeleteOneMutationProps*/
) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async connectionId => {
			return axios.delete(`${basePath()}/users/connections/${connectionId}`, AUTH_REQUEST_HEADER());
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'removing payment information'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			setErrorInRedux && setErrorInRedux(error);

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			dispatch(setIsLoading(false));
			if (data.status !== 200) {
				// rollBackMutation();

				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({
					queryKey: [USER_QUERY_KEY]
				});
				queryClient.refetchQueries({
					queryKey: [USER_QUERY_KEY]
				});

				onSuccess && onSuccess(data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		deleteConnection: mutation.mutate
	};
};

export default useUserConnectionDeleteOneMutation;
