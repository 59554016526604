const MuiButtonTheme = {
	styleOverrides: {
		root: {
			// fontSize: '16px',
			// padding: '15px 28px',
			borderRadius: '0px',
			backgroundColor: 'transparent',

			'&:hover': {
				backgroundColor: 'transparent !important'
				// color: 'white'
			}
		}
	}
};

export default MuiButtonTheme;
