import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { OptionsList } from 'components/organisms';
import classnames from 'classnames';
/**
 * COVIDRestaurantPrecautions
 * @param {COVIDRestaurantPrecautionsProps} props
 */
const COVIDRestaurantPrecautions = ({ isLV, safetyMeasures }) => (
	<div className="precautions flex justify-center">
		{safetyMeasures.map(measure => {
			return (
				<div className={classnames(isLV ? 'third-width center mb-6' : `precaution`)} key={measure.title}>
					<div className={classnames('header', isLV && 'flex-col align-center')}>
						<img src={measure.icon} alt={measure.title} />
						<Text weight="bold" align="center" className={classnames(!isLV && 'ml-2 mb-0')}>
							{measure.title}
						</Text>
					</div>
					<div className={classnames('content', isLV && 'flex justify-center')}>
						<OptionsList className="covid" dataCy={`covid-${measure.title}`} options={measure.practices} />
					</div>
				</div>
			);
		})}
	</div>
);

COVIDRestaurantPrecautions.defaultProps = {};

/**
 * COVIDRestaurantPrecautionsProps
 * @typedef {Object} COVIDRestaurantPrecautionsProps
 */
COVIDRestaurantPrecautions.propTypes = {
	isLV: PropTypes.bool.isRequired,
	safetyMeasures: PropTypes.array.isRequired
};

export default COVIDRestaurantPrecautions;
