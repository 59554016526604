import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PageError } from 'components/molecules';
import { Row, CardBody } from 'reactstrap';

const PageErrorWithCopy = props => {
	const { className, message, alert } = props;
	const classProps = classNames('dashboard-error', className);
	return (
		<CardBody className={classProps}>
			<PageError message={alert} type="warning" />
			<Row className="text-center">{message}</Row>
		</CardBody>
	);
};

PageErrorWithCopy.propTypes = {
	alert: PropTypes.string.isRequired,
	className: PropTypes.string,
	message: PropTypes.string.isRequired
};

export default PageErrorWithCopy;
