import { passwordWarning } from 'utils/form/warnings';

const registrationFormWarnings = vals => {
	const warnings = {
		password: passwordWarning(vals.password)
	};

	return warnings;
};

export default registrationFormWarnings;
