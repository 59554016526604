import { emailValidator } from 'utils/form/validators';

const cardIdValidator = (val, idType) => {
	if (idType === 'email') {
		if (!val || val.length === 0) {
			return 'Required';
		} else {
			let isValidEmail = emailValidator(val);
			if (!isValidEmail) {
				return 'Please enter a valid email address.';
			}
		}
	} else if (idType === 'empID') {
		if (!val || val.length === 0) {
			return 'Required';
		}
	}
	return undefined;
};

export default cardIdValidator;
