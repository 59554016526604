import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { currencyFormat } from 'utils/formatters';
import { useTranslate } from 'hooks';

const RewardCard = ({ className, reward, userPoints }) => {
	const translate = useTranslate();
	const classProps = classNames('reward-card', className);

	const canBuy = userPoints >= Number.parseInt(reward.cards[0].points) || 0;

	let opts = {
		key: reward.id,
		className: 'card-btn',
		'data-cy': 'select-reward-btn'
	};

	if (canBuy) {
		opts['color'] = 'primary';
	}

	return (
		<div className={classProps} data-cy={`reward-card`}>
			<img className="card-cover" src={reward.image} alt="" />
			<div className="card-container">
				<span className="card-title" data-cy="reward-title">
					{reward.title}
				</span>
				<span className="card-point" data-cy="reward-points">
					{currencyFormat(Number.parseInt(reward.cards[0].points))} {translate('Rewards.cardPoint')}
				</span>
				<Link
					to={`/rewards/${canBuy ? 'order' : 'view'}/${reward.brand_id}`}
					className="card-btn-wrapper"
					data-cy="reward-link"
				>
					<Button block {...opts}>
						{translate(canBuy ? 'Rewards.redeemBtn' : 'Rewards.viewBtn')}
					</Button>
				</Link>
			</div>
		</div>
	);
};

RewardCard.defaultProps = {
	className: '',
	userPoints: 0
};

RewardCard.propTypes = {
	className: PropTypes.string,
	reward: PropTypes.object.isRequired,
	userPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default RewardCard;
