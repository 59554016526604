import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { ProfileSectionList } from 'components/organisms';
import validateEditPaymentForm from './validate';
import { getInternalConnectionType } from 'utils/card';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { Col, Row } from 'components/atoms';
import { CardNumberField, CardholderNameInput } from 'components/molecules';
import useEditPaymentForm from './hooks/useEditPaymentForm';
import useGeneralContext from 'context/GeneralContext';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks';
import { history } from 'utils/router';

const EditPaymentFormNew = props => {
	const { onDelete } = props;
	const translate = useTranslate();
	const { card } = useSelector(state => ({ card: state.editPayment.card }));
	const { type } = card;
	const idType = getInternalConnectionType(type);
	const { clearErrors, companyName, initialValues, onCancel, onPristineSubmit, onSubmit } = useEditPaymentForm(props);
	const { isDoDMode } = useGeneralContext();

	useEffect(() => {
		if (!card) {
			history.replace('/profile/#/');
		}

		return () => {
			clearErrors();
		};
	}, [card, clearErrors]);

	return (
		<Form
			initialValues={initialValues}
			validate={validateEditPaymentForm(translate, idType, card, isDoDMode)}
			onSubmit={onSubmit}
			render={({ handleSubmit, submitting, pristine }) => (
				<form onSubmit={pristine ? onPristineSubmit : handleSubmit}>
					<Col fullWidth gap="15px">
						<Row fullWidth justify="space-between">
							<ProfileSectionList
								section="payment"
								list={[
									{
										key: 'company',
										value: companyName
									}
								]}
								// className="mb-4"
							/>
							<RequiredFieldNote />
						</Row>
						<Row fullWidth>
							<CardNumberField selectedCard={card} />
						</Row>
						{!isDoDMode && (
							<Row fullWidth>
								<CardholderNameInput />
							</Row>
						)}
						{isDoDMode && (
							<Row fullWidth>
								<TextField
									name="firstName"
									data-cy="firstName"
									label={translate('ConnectToPoints.inputs.dod-firstName.label')}
								/>
							</Row>
						)}
						{isDoDMode && (
							<Row fullWidth>
								<TextField
									name="middleName"
									data-cy="middleName"
									label={translate('ConnectToPoints.inputs.dod-middleName.label')}
								/>
							</Row>
						)}
						{isDoDMode && (
							<Row fullWidth>
								<TextField
									name="lastName"
									data-cy="lastName"
									label={translate('ConnectToPoints.inputs.dod-lastName.label')}
								/>
							</Row>
						)}
						{idType === 'empID' && (
							<Row fullWidth>
								<TextField
									name="employeeId"
									data-cy="employeeId"
									label={translate('ConnectToPoints.inputs.employeeId.label')}
								/>
							</Row>
						)}
						{idType === 'email' && (
							<Row fullWidth>
								<TextField
									name="email"
									data-cy="email"
									label={translate('ConnectToPoints.inputs.employeeEmail.label')}
								/>
							</Row>
						)}
					</Col>
					<EditProfileActions onCancel={onCancel.bind(this, pristine)} isLoading={submitting} />
					<div className="delete-payment-wrapper center">
						<a className="delete-payment-btn" onClick={onDelete} data-cy="delete-connection-button">
							{translate('Profile.payment.delete')}
						</a>
					</div>
				</form>
			)}
		/>
	);
};

EditPaymentFormNew.defaultProps = {};

EditPaymentFormNew.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	onCancel: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};

export default EditPaymentFormNew;
