import React from 'react';
import { useSelector } from 'react-redux';
import { AppDownloadButtonLink } from 'components/atoms';
// import PropTypes from 'prop-types'

/*
interface AppStoreButtonLinkProps {

}
*/

const GooglePlayStoreButtonLink = (/*: AppStoreButtonLinkProps*/) => {
	const link =
		'https://play.google.com/store/apps/details?id=com.dinova.locator&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

	const imageBaseUrl = useSelector(state => state.config.imageBaseUrl);

	return (
		<AppDownloadButtonLink
			bgImg={`url(${imageBaseUrl}/mydinova/logos/google-play-badge.svg) no-repeat`}
			link={link}
		/>
	);
};

// GooglePlayStoreButtonLink.propTypes = {

// }

export default GooglePlayStoreButtonLink;
