import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
/*
interface UnconnectedModalProps {

}
*/

const UnconnectedModal = ({ show, onClose, doClose } /*: UnconnectedModalProps*/) => {
	const translate = useSelector(state => getTranslate(state.locale));
	return (
		<Modal isOpen={show} toggle={onClose} className="company_select_modal" maxWidth="550px" centered>
			<ModalHeader toggle={() => {} /*toggle*/}>{translate(`unconnected-company.header`)}</ModalHeader>
			<ModalBody>{translate(`unconnected-company.message`)}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							{/* Close Modal and Go To Profile Button */}
							<Button block color="primary" onClick={doClose}>
								{translate(`unconnected-company.primaryButtonText`)}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

UnconnectedModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	doClose: PropTypes.func.isRequired
};

export default UnconnectedModal;
