import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';

// Create a client
const queryClient = new QueryClient();

const ReactQueryProvider = ({ children }) => {
	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

ReactQueryProvider.propTypes = {
	children: PropTypes.node
};

export default ReactQueryProvider;
