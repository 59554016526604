import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { Button } from 'reactstrap';
import { history } from 'utils/router';
import { useTranslate } from 'hooks';
import useGeneralContext from 'context/GeneralContext';

/**
 * [COVIDRestaurantFeedback description]
 */
const COVIDRestaurantFeedback = ({ fetchOnButtonClick, fetchRecommendationsOptions, restaurantId }) => {
	const translate = useTranslate();

	const {
		authState: { isAuthed },
		recommendationModal: { showModal }
	} = useGeneralContext();

	const toggleModal = useCallback(
		type => {
			if (isAuthed) {
				(show, type, restaurantId) => showModal({ show, type, restaurantId });

				showModal({ show: true, type, restaurantId });
			} else {
				history.push(`/login?redirect=${window.location.pathname + window.location.hash}`);
				showModal({ show: true, type, restaurantId });
			}
		},
		[isAuthed, showModal, restaurantId]
	);

	const leaveFeedback = useCallback(() => {
		if (fetchOnButtonClick) {
			fetchRecommendationsOptions(restaurantId);
		}
		toggleModal('positive');
	}, [fetchOnButtonClick, fetchRecommendationsOptions, restaurantId, toggleModal]);

	return (
		<div className="COVIDRestaurantFeedback flex mt-3">
			<Text className="mb-0">
				<Button className="more-button bold mr-2" onClick={leaveFeedback}>
					{translate('Details.covid.leave-feedback')}
				</Button>
				{translate('Details.covid.leave-feedback-desc')}
			</Text>
		</div>
	);
};

/**
 * [propTypes description]
 * @interface COVIDRestaurantFeedbackProps
 *
 * @example {}
 */
COVIDRestaurantFeedback.propTypes = {
	fetchOnButtonClick: PropTypes.bool.isRequired,
	fetchRecommendationsOptions: PropTypes.func.isRequired,
	restaurantId: PropTypes.string
};

export default COVIDRestaurantFeedback;
