import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const apiKey = process.env.REACT_APP_QR_CODE_API_KEY;

// interface useQRCodeProps {}

const useQRCode = (props /*:useQRCodeProps*/) => {
	const { frameName = 'no-frame', qrCodePath, qrCodeLogo = 'no-logo' } = props;
	const didLoad = useRef(false);
	const [qrCode, setQRCode] = useState(null);
	const qrCodeText = `${window.location.origin}${qrCodePath}`;

	useEffect(() => {
		didLoad.current = false;
	}, [props]);

	useEffect(() => {
		if (didLoad.current) {
			return;
		}
		const params = `frame_name=${frameName}&qr_code_text=${qrCodeText}&image_format=SVG&qr_code_logo=${qrCodeLogo}`;
		const url = `https://api.qr-code-generator.com/v1/create?access-token=${apiKey}&${params}`;
		console.log('%c url', 'color: red', url);

		axios.get(url).then(res => {
			console.log('%c res', 'color: red', res);
			setQRCode(res.data);
			didLoad.current = true;
		});
	}, [frameName, qrCodeText, qrCodeLogo]);

	return {
		qrCode
	};
};

export default useQRCode;
