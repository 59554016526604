import COVIDRestaurantPrecautions from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { hasProp } from 'utils/object';

const getSocialDistancing = details => {
	return hasProp(details, 'restaurant.safetyMeasures.socialDistancing')
		? details.restaurant.safetyMeasures.socialDistancing.map(o => o.name)
		: [];
};

const getSanitization = details => {
	return hasProp(details, 'restaurant.safetyMeasures.sanitization')
		? details.restaurant.safetyMeasures.sanitization.map(o => o.name)
		: [];
};

const getDinerScreening = details => {
	return hasProp(details, 'restaurant.safetyMeasures.dinerScreening')
		? details.restaurant.safetyMeasures.dinerScreening.map(o => o.name)
		: [];
};

const mapStateToProps = state => {
	const translate = getTranslate(state.locale);
	return {
		safetyMeasures: [
			{
				title: translate('Details.covid.social-distancing'),
				icon: `${state.config.cdn_basePath}/${translate('Details.covid.social-distancing-icon')}`,
				practices: getSocialDistancing(state.details)
			},
			{
				title: translate('Details.covid.sanitization'),
				icon: `${state.config.cdn_basePath}/${translate('Details.covid.sanitization-icon')}`,
				practices: getSanitization(state.details)
			},
			{
				title: translate('Details.covid.diner-screening'),
				icon: `${state.config.cdn_basePath}/${translate('Details.covid.diner-screening-icon')}`,
				practices: getDinerScreening(state.details)
			}
		].filter(m => m.practices.length > 0),
		translate
	};
};

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(COVIDRestaurantPrecautions));
