// RegistrationForm MetaTags config
// @type MetaConfig[]
export default [
	{
		type: 'title',
		translateKey: 'Registration.meta-title'
	},
	{
		type: 'meta',
		translateKey: 'Registration.meta-description',
		name: 'description'
	},
	{
		type: 'meta',
		translateKey: 'Registration.meta-keywords',
		name: 'keywords'
	}
];
