import { hasProp } from 'utils/object';

export const getOptions = restaurant => {
	let options = {};
	if (hasProp(restaurant, 'amenities.general')) {
		restaurant.amenities.general.forEach(o => {
			let ids = [6, 7, 8];
			if (ids.indexOf(o.id) > -1) {
				options[o.name] = true;
			}
		});
	}

	if (hasProp(restaurant, 'safetyMeasures.sanitization')) {
		let hasSealedItems = restaurant.safetyMeasures.sanitization.filter(s => s.id === 603)[0];
		options['Sealed Packaging'] = hasSealedItems;
	}

	return options;
};
