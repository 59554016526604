import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { ContactInfo } from 'components/molecules';
import CONTACTS from './dinova_contact_info';
import { useTranslate } from 'hooks';

/**
 * Displays Dinova contact information to allow the user easy access to custom support.
 * @constructor
 * @param {MoreHelpProps} props
 */
const MoreHelp = ({ dataKey, imageBaseUrl, title, help }) => {
	const translate = useTranslate();

	return (
		<div className="more-help">
			{title && (
				<Title align="left" transform="none" size="h3">
					{title}
				</Title>
			)}
			{help && (
				<Text align="left" paragraph size="sm">
					{help}
				</Text>
			)}
			<ContactInfo contacts={CONTACTS(imageBaseUrl, translate)[dataKey]} />
		</div>
	);
};

MoreHelp.defaultProps = {
	dataKey: 'business'
};

/**
 * MoreHelp Props
 * @interface MoreHelpProps
 * @property {string} dataKey The data accesor used to fetch contact data.
 * @property {string} imageBaseUrl
 * @property {function} translate
 */
MoreHelp.propTypes = {
	dataKey: PropTypes.string,
	imageBaseUrl: PropTypes.string.isRequired,
	title: PropTypes.string,
	help: PropTypes.string
};

export default MoreHelp;
