import React from 'react';
import { Checkboxes /*CheckboxData,*/ } from 'mui-rff';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classnames from 'classnames';
/*
interface CheckboxesNewProps {

}
*/
const CheckedIcon = () => {
	return <div className={classnames(styles.checkbox, styles.checkedIcon)} />;
};

const UnCheckedIcon = () => {
	return <div className={classnames(styles.checkbox, styles.unCheckedIcon)} />;
};

const CheckboxesNew = ({ label, name, required, data, ...others } /*: CheckboxesNewProps*/) => {
	return (
		<Checkboxes
			label={label}
			name={name}
			required={required}
			data={data}
			icon={<UnCheckedIcon />}
			checkedIcon={<CheckedIcon />}
			{...others}
			sx={styles.checkbox}
			formGroupProps={{
				'data-cy': `${others.dataCy}-form-group`
			}}
		/>
	);
};

CheckboxesNew.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	)
};

export default CheckboxesNew;
