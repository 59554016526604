import { useCallback, useState } from 'react';
import validateMFAForm from '../validate';
import { getPhoneOrFax } from 'utils/regex';
import { PHONE_INPUT_ID } from '../PhoneNumberPage';
import useMFAMutations from './useMFAMutations';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/modal';
import useGeneralContext from 'context/GeneralContext';

// interface useMFAFormSubmitProps {}

const useMFAFormSubmit = (
	{
		hasError: hasErrorProp,
		isLoginFlow,
		onPhoneNumberPageCancel: onPhoneNumberPageCancelProp,
		setScreen,
		screen
	} /*:useMFAFormSubmitProps*/
) => {
	const dispatch = useDispatch();
	const [hasError, setHasError] = useState(false);
	const {
		login: { login, mfaToken }
	} = useGeneralContext();

	const closeModal = useCallback(() => {
		dispatch(toggleModal(false));
	}, [dispatch]);

	const onError = useCallback(
		e => {
			if (screen === 'code' && e?.response?.data?.response?.code !== 411) {
				setHasError(e?.response?.data?.response?.msg || 'Invalid code');
			}
		},
		[screen]
	);

	const { connectMFA } = useMFAMutations({ onError, screen, setScreen });

	const showPhoneNumberScreen = useCallback(() => {
		setScreen('phoneNumber');
	}, [setScreen]);

	const onPhoneNumberPageCancel = useCallback(() => {
		// if is editSecurity
		onPhoneNumberPageCancelProp && onPhoneNumberPageCancelProp();
		setScreen('start');
	}, [onPhoneNumberPageCancelProp, setScreen]);

	const onSubmitPhoneNumber = useCallback(
		values => {
			const phoneNumber = values.phone;

			let errors = validateMFAForm(getPhoneOrFax(phoneNumber));

			if (errors.phone) {
				// setHasError(true);
				document.getElementById(PHONE_INPUT_ID).focus();
			} else {
				let phone = getPhoneOrFax(phoneNumber);
				// setPhoneNumber(phone);

				let userUpdate = {
					phone: {
						mobile: phone
					}
				};

				connectMFA(userUpdate);
			}
		},
		[connectMFA]
	);

	const onSubmitCode = useCallback(
		values => {
			if (isLoginFlow) {
				const code = values.code;
				const user = {
					mfa: {
						token: mfaToken,
						code
					}
				};

				const didLogin = login(user, true);

				if (didLogin) {
					setHasError(false);
					closeModal();
				} else {
					setHasError('Invalid code');
				}
			} else {
				connectMFA({ code: values.code }, { onSuccess: closeModal });
			}
		},
		[connectMFA, closeModal, isLoginFlow, login, mfaToken]
	);

	const onSubmit = useCallback(
		values => {
			if (screen === 'start') {
				showPhoneNumberScreen(values);
			} else if (screen === 'phoneNumber') {
				onSubmitPhoneNumber(values);
			} else if (screen === 'code') {
				onSubmitCode(values);
			}
		},
		[showPhoneNumberScreen, onSubmitPhoneNumber, onSubmitCode, screen]
	);

	const onCancel = useCallback(() => {
		if (screen === 'start') {
			closeModal();
		} else if (screen === 'phoneNumber') {
			onPhoneNumberPageCancel();
		} else if (screen === 'code') {
			showPhoneNumberScreen();
		}
	}, [closeModal, onPhoneNumberPageCancel, screen, showPhoneNumberScreen]);

	return {
		onCancel,
		hasError: hasError || hasErrorProp,
		onPhoneNumberPageCancel,
		onSubmit,
		screen
	};
};

export default useMFAFormSubmit;
