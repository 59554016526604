import SearchFilter from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV /*, isMV*/ } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
	// isMV: isMV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(SearchFilter));
