import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';
import {
	RecommendationEndorsement,
	RecommendationEndorsementButtons,
	RecommendationsIncentive
} from 'components/molecules';
import { Col } from 'reactstrap';
import { RECOMMENDATION_SUBMIT } from 'utils/windowEvents';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks';

const Recommendations = ({ restaurant, className, incentiveKey, fetchOnButtonClick, location, onCountClick, isSV }) => {
	const translate = useTranslate();
	const { restaurantIdsWithRecs } = useSelector(state => ({
		restaurantIdsWithRecs: state.userRecommendations.restaurantIdsWithRecs
	}));
	const { didRecommend } = useMemo(() => {
		let _didRecommend = false;
		if (location.pathname.indexOf('history') > -1) {
			_didRecommend = restaurantIdsWithRecs.indexOf(restaurant?.id) > -1;
		} else {
			_didRecommend = restaurant?.user?.recommendation?.length > 0 || false;
		}
		return {
			didRecommend: _didRecommend
		};
	}, [location, restaurant, restaurantIdsWithRecs]);
	const [didSubmit, setDidSubmit] = useState(false);

	// use withRouter instead
	const isDetailsPage = window.location.pathname.indexOf(`restaurants`) > -1;

	// componentDidMount
	useEffect(() => {
		// Event is fired from submitRecommendation action handler success.
		window.addEventListener(RECOMMENDATION_SUBMIT, handleAfterSubmit);

		// componentWillUnmount
		return () => {
			// Event is fired from submitRecommendation action handler success.
			window.removeEventListener(RECOMMENDATION_SUBMIT, handleAfterSubmit);
		};
	}, [handleAfterSubmit]);

	const handleAfterSubmit = useCallback(
		event => {
			if (event.detail.restaurantId === restaurant?.id || isDetailsPage) {
				setDidSubmit(true);
			}
		},
		[isDetailsPage, restaurant?.id]
	);

	const classProps = classnames('recommendations', onCountClick && 'has-count', className && className);

	const sv = useMemo(
		() => (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				{!didRecommend && (
					<div className="flex align-center">
						<div className="mr-4">
							<RecommendationEndorsement />
							<RecommendationsIncentive incentiveKey={incentiveKey} />
						</div>
						<RecommendationEndorsementButtons
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurant?.id}
						/>
					</div>
				)}
			</div>
		),
		[classProps, didRecommend, fetchOnButtonClick, incentiveKey, restaurant?.id]
	);

	const mv = useMemo(
		() => (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				{!didRecommend && (
					<div className="flex justify-center align-center">
						<RecommendationEndorsement />
						<RecommendationEndorsementButtons
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurant?.id}
						/>
						<RecommendationsIncentive incentiveKey={incentiveKey} />
					</div>
				)}
			</div>
		),
		[classProps, didRecommend, fetchOnButtonClick, incentiveKey, restaurant?.id]
	);

	if (didRecommend && !isDetailsPage) {
		// history transaction page after submit recommendation
		return (
			<div
				data-cy="recommendations"
				id="recommendations-section"
				className="recommendations transaction-didSubmit"
			>
				<Col xs="8">
					<div className="flex justify-center">
						<Text size="xs" align="center" dataCy="transactions-list-ty">
							{translate('Recommendations.transactions-will-receive-points')}
						</Text>
					</div>
				</Col>
			</div>
		);
	}

	if (didSubmit && isDetailsPage) {
		return null;
	}

	if (className === 'transactions') {
		return sv;
	} else {
		return didRecommend ? false : isSV ? sv : mv;
	}
};

Recommendations.propTypes = {
	restaurant: PropTypes.object, // required if on details page
	className: PropTypes.string,
	incentiveKey: PropTypes.string,
	fetchOnButtonClick: PropTypes.bool,
	location: PropTypes.object.isRequired,
	onCountClick: PropTypes.func,
	isSV: PropTypes.bool.isRequired
};

export default Recommendations;
