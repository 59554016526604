import React from 'react';
import PropTypes from 'prop-types';
import { HelpIcon, Row, Select } from 'components/atoms';
import { useTranslate } from 'hooks';
import { preferredCCTypeDisplayText } from 'utils/card';
/*
interface CreditCardTypeSelectProps {

}
*/

const CreditCardTypeSelect = ({ cardTypes = [], disabled, selectedCard } /*: CreditCardTypeSelectProps*/) => {
	const translate = useTranslate();
	const cardTypeOptions = cardTypes.map(cardType => ({
		value: cardType,
		label: `${cardType.issuer.name} (${preferredCCTypeDisplayText(cardType.cardType)})`
	}));

	return (
		<Row fullWidth>
			<Select
				label="Select Your Card"
				name="selectedCard"
				options={cardTypeOptions}
				required
				defaultValue={selectedCard}
				dataCy="credit-card-type"
				disabled={disabled}
			/>
			<HelpIcon color="secondary" text={translate('ConnectToPoints.inputs.creditcardTypeTitle.help')} />
		</Row>
	);
};

CreditCardTypeSelect.propTypes = {
	cardTypes: PropTypes.array,
	disabled: PropTypes.bool,
	selectedCard: PropTypes.object
};

export default CreditCardTypeSelect;
