import React from 'react';
import { useTranslate } from 'hooks';

const ConnectionsRingSVG = () => {
	const translate = useTranslate();
	const connectionsText = [];
	const connectionsTextCount = parseInt(translate('Rewards.pointsRing.connections.count'));
	for (let i = 0; i < connectionsTextCount; i++) {
		connectionsText.push(translate(`Rewards.pointsRing.connections.${i + ''}`));
	}

	return (
		<text x="50%" y="30%" textAnchor="middle" dy="0.3em" data-cy="connections-ring">
			<tspan x="50%" dy=".6em" className="font-weight-bold">
				{connectionsText[0]}
			</tspan>
			<tspan x="50%" dy="1.2em" className="font-weight-bold">
				{connectionsText[1]}
			</tspan>
			<tspan x="50%" dy="1.3em" className="font-weight-bold">
				{connectionsText[2]}
			</tspan>
			<tspan x="50%" dy="1.2em" className="font-small">
				{connectionsText[3]}
			</tspan>
			<tspan x="50%" dy="1.2em" className="font-small">
				{connectionsText[4]}
			</tspan>
		</text>
	);
};

ConnectionsRingSVG.propTypes = {};

export default ConnectionsRingSVG;
