import { Body, Col, Row } from 'components/atoms';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { ExistingUserInfo } from 'components/molecules';
import PropTypes from 'prop-types';

/*
interface AlreadySubscribedProps {

}
*/

const NotYouLogOut = ({ user } /*: AlreadySubscribedProps*/) => {
	const translate = useTranslate();
	const baseKey = 'SubscribePage.NotYouLogOut';

	return (
		<Col gap="16px">
			{user && <ExistingUserInfo user={user} titleKey={`${baseKey}.subTitle`} />}

			<Row gap="4px">
				<Body variant="body2">{translate(`${baseKey}.notYou`)}</Body>
				<Link to="/logout">{translate(`${baseKey}.logout`)}</Link>
			</Row>
		</Col>
	);
};

NotYouLogOut.propTypes = {
	user: PropTypes.object
};

export default NotYouLogOut;
