export const SEARCH_RESTAURANTS_START = 'SEARCH_RESTAURANTS_START';
export const SEARCH_RESTAURANTS_SUCCESS = 'SEARCH_RESTAURANTS_SUCCESS';
export const SEARCH_RESTAURANTS_ERROR = 'SEARCH_RESTAURANTS_ERROR';
export const TOGGLE_SHOW_FILTER = 'TOGGLE_SHOW_FILTER';
export const TOGGLE_SHOW_LIST = 'TOGGLE_SHOW_LIST';
export const SET_ACTIVE_PIN = 'SET_ACTIVE_PIN';
export const SET_MAP_CARD_DISPLAYED = 'SET_MAP_CARD_DISPLAYED';
export const SET_SEARCH_PAGE_ERROR_TYPE = 'SET_SEARCH_PAGE_ERROR_TYPE';
export const SAVE_SEARCH_PAGE_STATE = 'SAVE_SEARCH_PAGE_STATE';
export const HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE = 'HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE';
export const SET_LAST_BUSINESS_SEARCH = 'SET_LAST_BUSINESS_SEARCH';
