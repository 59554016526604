import { useCallback, useState } from 'react';
import Cookies from 'universal-cookie';
import useMFAFormSubmit from './useMFAFormSubmit';
import useGeneralContext from 'context/GeneralContext';
import { useUserContext } from 'context';
const cookies = new Cookies();

export const SCREEN_MAP = {
	start: 1,
	phoneNumber: 2,
	code: 3
};
// interface useMFAModalPageProps {}

const useMFAModalPage = (
	{ hasError: hasErrorProp, isLoginFlow, onPhoneNumberPageCancel, section = 'start' } /*:useMFAModalPageProps*/
) => {
	const { user } = useUserContext();
	const {
		login: { sentTo: initialPhoneNumber }
	} = useGeneralContext();

	const [screen, setScreen] = useState(section);

	const form = useMFAFormSubmit({
		hasError: hasErrorProp,
		isLoginFlow,
		onPhoneNumberPageCancel,
		setScreen,
		screen
	});
	const isRequired = user?.company?.security?.mfaRequired;

	const onDontAskChange = useCallback(value => {
		if (value.includes('dontAsk')) {
			cookies.set('dontAsk', true, { maxAge: 2147483647 });
		} else {
			cookies.remove('dontAsk');
		}
	}, []);

	return {
		...form, // closeModal, hasError, onPhoneNumberPageCancel, onSubmit, screen, showPhoneNumberScreen
		onDontAskChange,
		initialPhoneNumber,
		isRequired
	};
};

export default useMFAModalPage;
