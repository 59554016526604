import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

/*
interface SkipModalProps {

}
*/

const SkipModal = ({ company, doClose, onClose, doSkip, show, showSelectModal } /*: SkipModalProps*/) => {
	const translate = useSelector(state => getTranslate(state.locale));

	const doCancelSkip = useCallback(() => {
		if (company.code) {
			doClose();
		} else {
			showSelectModal();
		}
	}, [company, doClose, showSelectModal]);

	return (
		<Modal isOpen={show} toggle={onClose} className="company_select_modal" maxWidth="550px" centered>
			<ModalHeader toggle={() => {} /*toggle*/}>{translate(`ConnectToPoints.modals.skip.header`)}</ModalHeader>
			<ModalBody>{translate(`ConnectToPoints.modals.skip.body`)}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							{/* "CONTINUE" button - Go Back To Select Company Button */}
							<Button block color="primary" onClick={doCancelSkip} data-cy="cancel-skip">
								{translate(`ConnectToPoints.modals.skip.primaryButtonText`)}
							</Button>
						</Col>
						<Col sm="12">
							{/* "YES, SKIP" button - Cancel Select Company Button */}
							<Button block color="secondary" onClick={doSkip} data-cy="confirm-skip">
								{translate(`ConnectToPoints.modals.skip.secondaryButtonText`)}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

SkipModal.propTypes = {
	company: PropTypes.object.isRequired,
	doSkip: PropTypes.func.isRequired,
	doClose: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showSelectModal: PropTypes.func.isRequired
};

export default SkipModal;
