import React from 'react';
import { useTranslate } from 'hooks';

const PointsRingSVG = () => {
	const translate = useTranslate();
	const pointsText = [];
	const pointsTextCount = parseInt(translate('Rewards.pointsRing.getPoints.count'));
	for (let i = 0; i < pointsTextCount; i++) {
		pointsText.push(translate(`Rewards.pointsRing.getPoints.${i + ''}`));
	}

	return (
		<text x="50%" y="30%" textAnchor="middle" dy="0.3em" data-cy="no-points-ring">
			<tspan x="50%" dy="1.5em" className="font-weight-bold">
				{pointsText[0]}
			</tspan>
			<tspan x="50%" dy="1.2em" className="font-weight-bold" data-cy="points">
				{pointsText[1]}
			</tspan>
			<tspan x="50%" dy="1.3em" className="font-small">
				{pointsText[2]}
			</tspan>
			<tspan x="50%" dy="1.2em" className="font-small">
				{pointsText[3]}
			</tspan>
		</text>
	);
};

PointsRingSVG.propTypes = {};

export default PointsRingSVG;
