import MuiAutocompleteTheme from './autocomplete';
import MuiFormLabelTheme from './form_label';
import MuiFormHelperTextTheme from './helper_text';
import MuiInputBaseTheme from './input_base';
import MuiOutlinedInputTheme from './input_outlined';

const inputTheme = {
	MuiAutocomplete: MuiAutocompleteTheme,
	MuiFormLabel: MuiFormLabelTheme,
	MuiFormHelperText: MuiFormHelperTextTheme,
	MuiInputBase: MuiInputBaseTheme,
	MuiOutlinedInput: MuiOutlinedInputTheme
};

export default inputTheme;
