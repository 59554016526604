import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Truncate from 'react-truncate';
import { useTranslate } from 'hooks';

const MAX_LINES = 5;

/**
 * Displays text description of a restaurant.  If text is more than 10 lines, text will be clipped, elippses added, "Read More" button rendered.
 * @param {Props_Description} props
 */
const Description = ({ text }) => {
	const translate = useTranslate();
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const [visibleLines, setVisibleLines] = useState(MAX_LINES);

	/**
	 * "Read More" button handler. Shows all description text.
	 */
	const expand = useCallback(() => {
		if (isTextExpanded) {
			setIsTextExpanded(false);
			setVisibleLines(MAX_LINES);
		} else {
			setIsTextExpanded(true);
			setVisibleLines(99999);
		}
	}, [isTextExpanded]);

	const classProps = classNames('restaurant-description column', isTextExpanded ? 'expanded' : 'collapsed'),
		buttonText = isTextExpanded ? translate('Details.readLess') : translate('Details.readMore');

	const preservedText = useMemo(
		() =>
			text.split('\n').map((line, i, arr) => {
				line = <span key={i}>{line}</span>;

				if (i === arr.length - 1) {
					return line;
				} else {
					return [line, <br key={i + 'br'} />];
				}
			}),
		[text]
	);

	return preservedText.length > 0 ? (
		<div className={classProps}>
			<Truncate
				lines={visibleLines}
				ellipsis={
					<React.Fragment>
						...
						<Button onClick={expand} id="read-more-button" className="more-button">
							{buttonText}
						</Button>
					</React.Fragment>
				}
			>
				{preservedText}
			</Truncate>
			{isTextExpanded && (
				<Button onClick={expand} id="read-more-button" className="more-button">
					{buttonText}
				</Button>
			)}
		</div>
	) : null;
};

Description.defaultProps = {
	text: 'Description'
};

/**
 * {@link Description} Props
 * @interface Props_Description
 * @property {function} translate
 * @property {string} [text="Description"]
 */
Description.propTypes = {
	// Optional
	text: PropTypes.string
};

export default Description;
