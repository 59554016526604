import React, { useMemo } from 'react';
import useRecommendationOptionsContext from 'reactQuery/context/RecommendationOptionsContext';
import { Checkboxes, Col, Row, Title } from 'components/atoms';

import styles from './style.module.scss';

/*
interface PositiveRecommendationFormFieldsProps {

}
*/

const PositiveRecommendationFormFields = (/*: PositiveRecommendationFormFieldsProps*/) => {
	const { positive } = useRecommendationOptionsContext();

	const positiveGroups = useMemo(() => {
		return positive.map(pos => {
			return {
				...pos,
				checkboxData: pos.categories.map(cat => {
					return {
						label: cat.name,
						value: cat.id
						// checked: cat.selected || false
					};
				})
			};
		});
	}, [positive]);

	return positiveGroups?.length ? (
		<Col gap="28px" fullWidth dataCy="positive-recommendation-form">
			{positiveGroups.map(group => {
				if (group.name.toLowerCase() === 'general') {
					return null;
				}
				return (
					<Col
						fullWidth
						style={{
							border: 'solid #ccc 1px',
							borderRadius: '4px'
						}}
						key={group.name}
						data-cy={`rec-group-${group.name}`}
					>
						<Row fullWidth>
							<Title align="left" size="h3" transform="none" className={styles.groupTitle}>
								{group.name}
							</Title>
						</Row>
						<Row className={styles.checkboxWrapper}>
							<Checkboxes dataCy="recommendation-checkboxes" name="for" data={group.checkboxData} />;
						</Row>
					</Col>
				);
			})}
		</Col>
	) : null;
};

// PositiveRecommendationFormFields.propTypes = {
// };

export default PositiveRecommendationFormFields;
