import { asyncComponent } from 'hoc';

const LazyForgottenPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "ForgottenPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyForgottenPage;
