import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ClusterMapMarker = ({
	activeCluster,
	className = '',
	clusterId,
	isCluster = true,
	isFavorite = false,
	name,
	onClusterClick,
	IMAGE_BASE_URL,
	id,
	lat,
	lng
}) => {
	const onClusterClickHandler = useCallback(
		event => {
			onClusterClick({ event, childProps: { lat, lng, id, name, isCluster, clusterId } });
		},
		[lat, lng, onClusterClick, id, isCluster, name, clusterId]
	);

	const classProps = classNames('cluster-map-marker', className, isFavorite ? 'favorite' : '');
	const imgSrc = isFavorite
		? `${IMAGE_BASE_URL}/icons/${activeCluster ? 'icon-favorite-selected-cluster.png' : 'heart-solid.svg'}`
		: `${IMAGE_BASE_URL}/icons/${activeCluster ? 'icon-selected-cluster.svg' : 'icon-cluster.png'}`;

	return (
		<div className={classProps} onClick={onClusterClickHandler} data-marker-zone>
			<img
				className="marker"
				src={imgSrc}
				data-marker-zone
				alt={activeCluster ? 'active cluster' : 'inactive cluster'}
			/>
			<div className="cluster-amount" data-marker-zone>
				{name}
			</div>
		</div>
	);
};

ClusterMapMarker.propTypes = {
	activeCluster: PropTypes.bool.isRequired,
	clusterId: PropTypes.number.isRequired,
	isCluster: PropTypes.bool.isRequired,
	name: PropTypes.number.isRequired,
	onClusterClick: PropTypes.func.isRequired,

	className: PropTypes.string,
	IMAGE_BASE_URL: PropTypes.string,
	id: PropTypes.string,
	lat: PropTypes.number,
	lng: PropTypes.number,
	isFavorite: PropTypes.bool
};

export default ClusterMapMarker;
