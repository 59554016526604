import BusinessSearchForm from './ui-component';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { getLocation } from 'actions/geo_location';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { hasProp } from 'utils/object';

/* eslint-disable no-mixed-spaces-and-tabs */
const getInitialValues = state =>
	hasProp(state, 'form.businessSearch.values') ? state.form.businessSearch.values : { find: '', near: '' };

const mapStateToProps = state => ({
	geoLocation: state.geoLocation,
	formValues: getInitialValues(state),
	initialValues: getInitialValues(state),
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	getLocation: async () => await getLocation(dispatch),
	setFieldValue: (field, val) => dispatch(change('businessSearch', field, val))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withRouter(
	withSizes(mapSizesToProps)(
		reduxForm({
			form: 'businessSearch',
			destroyOnUnmount: false
		})(connect(mapStateToProps, mapDispatchToProps)(BusinessSearchForm))
	)
);
