import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { useTranslate } from 'hooks';
import useGeneralContext from 'context/GeneralContext';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string/index';
import { useDispatch, useSelector } from 'react-redux';
import { updateHeader as updateHeaderAction } from 'actions/viewport';
import { history } from 'utils/router';
import { reset } from 'actions/registration';
import { AppStoreButtonLink, GooglePlayStoreButtonLink, Row } from 'components/atoms';
import { useUserContext } from 'context';
import { toggleModalOpen as toggleOnboardingModalOpen } from 'actions/profile_setup';

const Completion = ({ className, isSV }) => {
	const translate = useTranslate();
	const location = useLocation();
	const { isDoDMode, isDoorDashMode } = useGeneralContext();
	const { user } = useUserContext();
	const dispatch = useDispatch();
	const registeredAsIneligible = useSelector(state => state.registration.registeredAsIneligible);

	const updateHeader = useCallback(
		useMinimalHeaderFooter => {
			dispatch(updateHeaderAction(useMinimalHeaderFooter));
		},
		[dispatch]
	);

	useEffect(() => {
		updateHeader(true);

		return () => {
			updateHeader(false);
		};
	}, [updateHeader]);

	const connectedViaReferral = useMemo(() => Boolean(queryString.parse(location.search).token), [location]);

	const { classProps, companyCode, hasConnection, isConnectPage } = useMemo(() => {
		return {
			classProps: classNames(
				'completion',
				className,
				isDoDMode && 'dod',
				isDoorDashMode && !isSV && 'doordashComplete_lv doordash'
			),
			companyCode: user?.company?.code,
			isConnectPage: location.pathname === '/users/connect',
			hasConnection: user?.connections?.length > 0
		};
	}, [className, isDoDMode, isDoorDashMode, isSV, location, user]);

	const titleId = useMemo(() => {
		if (isDoDMode) {
			return 'Complete.title_no_cc'; // Let's Start Earning!
		} else if (isDoorDashMode) {
			return 'Complete.title_doordash';
		} else if (registeredAsIneligible) {
			return 'Complete.title_registered_ineligible'; //Thanks for your interest in myDinova Rewards
		} else {
			if (hasConnection) {
				return 'Complete.title'; //CONGRATULATIONS!
			} else {
				return 'Complete.title_no_cc'; // Let's Start Earning!
			}
		}
	}, [hasConnection, isDoDMode, isDoorDashMode, registeredAsIneligible]);

	const { descId, referral } = useMemo(() => {
		let _descId;
		let _referral = false;

		if (isDoDMode) {
			_descId = 'Complete.body_header_dod';
		} else if (isDoorDashMode) {
			_descId = 'Complete.body_header_doordash';
		} else if (
			!translate(`cobranding.companies.${companyCode}.page_copy.Complete.body_header`).includes('Missing')
		) {
			_descId = `cobranding.companies.${companyCode}.page_copy.Complete.body_header`;
		} else if (isConnectPage) {
			_descId = 'Complete.body_header_registered';
		} else if (connectedViaReferral) {
			if (hasConnection) {
				_descId = 'Complete.body_header_referred';
				_referral = true;
			} else {
				_descId = 'Complete.body_header_referred_no_cc';
			}
		} else {
			if (registeredAsIneligible) {
				_descId = 'Complete.body_header_registered_ineligible';
			} else {
				_descId = 'Complete.body_header';
			}
		}

		return {
			descId: _descId,
			referral: _referral
		};
	}, [
		isDoDMode,
		isDoorDashMode,
		companyCode,
		isConnectPage,
		connectedViaReferral,
		hasConnection,
		registeredAsIneligible,
		translate
	]);

	const onFinish = useCallback(() => {
		dispatch(toggleOnboardingModalOpen(true));
		if (location.pathname.indexOf('/users/connect') > -1) {
			history.push('/');
		} else {
			dispatch(reset());
			history.push('/mydinova');
		}
	}, [dispatch, location]);

	return (
		<div className={classProps}>
			<MetaTags tags={metaTagConfig} />

			<div className="inner-content">
				<h3 className="title" data-cy="completion-title">
					{translate(titleId)}
				</h3>
				<p className={classNames('desc', !referral && 'text-left')}>{translate(descId)}</p>
				<Button
					color="primary"
					className="z-depth-0 continue-btn"
					onClick={onFinish}
					data-cy="complete-registration"
				>
					{translate('Complete.button_registered')}
				</Button>

				{location.pathname === '/users/register' && (
					<>
						<span className="download-text">{translate('Complete.body_footer')}</span>
						<Row mt="15px" gap="8px" fullWidth justify="center">
							<AppStoreButtonLink />
							<GooglePlayStoreButtonLink />
						</Row>
					</>
				)}
			</div>
		</div>
	);
};

Completion.propTypes = {
	className: PropTypes.string,
	isSV: PropTypes.bool
};

export default Completion;
