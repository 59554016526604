import React, { useCallback } from 'react';
import { useTranslate } from 'hooks';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Button, Row, Text } from 'components/atoms';
import styles from './style.module.scss';
import useGeneralContext from 'context/GeneralContext';

/*
interface ToggleRecTypeButtonsProps {

}
*/

const ToggleRecTypeButtons = ({ onTypeSwitch }) => {
	const translate = useTranslate();
	const {
		recommendationModal: { setType, type }
	} = useGeneralContext();

	const switchType = useCallback(
		type => {
			setType(type);
			onTypeSwitch && onTypeSwitch(type);
		},
		[setType, onTypeSwitch]
	);

	const isPositiveRec = type === 'positive';

	return (
		<Row className={styles.recTypeToggles}>
			<Col xs="12">
				<Text size="sm" align="center">
					{translate(`Recommendations.editRecTypePrompt`)}
				</Text>
			</Col>
			<Row gap="37px" justify="center">
				<Button
					onClick={switchType.bind(this, 'positive')}
					dataCy="positive-recommendation-btn"
					variant={isPositiveRec ? 'outlined' : 'black-outlined'}
					size="large"
					className={styles.toggleBtn}
				>
					{translate('common.yes')}
				</Button>
				<Button
					onClick={switchType.bind(this, 'negative')}
					dataCy="negative-recommendation-btn"
					variant={isPositiveRec ? 'black-outlined' : 'outlined'}
					size="large"
					className={styles.toggleBtn}
				>
					{translate('common.no')}
				</Button>
			</Row>
		</Row>
	);
};

ToggleRecTypeButtons.propTypes = {
	onTypeSwitch: PropTypes.func
};

export default ToggleRecTypeButtons;
