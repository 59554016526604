import { startCase } from 'lodash';

/**
 * Creates data obj for "Happy Hour", "Live Music", and "Other" detailed amenities.
 * @param  {Object[]} detailed An array of restaurant.details.amenities objects from API.
 * @return {UIAmenity[]}       An object with text and title to use in rendering the UI.
 */
export const compileDetailedAmenities = detailed => {
	const entries = Object.entries(detailed);
	if (entries.length) {
		return entries.map(pair => {
			const key = pair[0];
			const value = pair[1];
			let title = startCase(key);
			let text = '';

			if (value) {
				if (value.details) {
					text = value.details;
				} else if (value.types) {
					text = value.types.map(t => t.name).join(',');
				}
			}

			return {
				title,
				text
			};
		});
	} else {
		return [];
	}
};

/**
 * Creates strings for "Catering", "Takeout", "Private Dining", "Alcohol", "Delivery", "Online Ordering", and "Outdoor Seating" brief amenities.
 * @param  {Object[]} brief An array of restaurant.details.amenities objects from API.
 * @return {String[]}       The text to render for this amenity brief.
 */
export const compileBriefAmenities = brief => {
	const entries = Object.entries(brief);
	if (entries.length) {
		return entries.reduce((accum, pair) => {
			const key = pair[0];
			const value = pair[1];

			if (!value) {
				return accum;
			}

			switch (key) {
				case 'alcohol': {
					const text = value.map(v => v.name).join(',');
					return [...accum, `${startCase(key)} - ${text}`];
				}
				case 'liveMusic': {
					if (value.length) {
						let text = value.map(v => v.notes).join(',');

						return [...accum, `Live Music${text ? ` - ${text}` : ''}`];
					} else {
						return accum;
					}
				}
				case 'seating': {
					const text = value.map(v => v.name).join(',');
					return [...accum, `Outdoor Seating - ${text}`];
				}
				case 'delivery': {
					if (value.types) {
						const text = value.types.map(v => v.name).join(',');
						return [...accum, `Delivery - ${text}`];
					} else {
						return [...accum, `Delivery`];
					}
				}

				default:
					return [...accum, startCase(key)];
			}
		}, []);
	} else {
		return [];
	}
};

/**
 * Prepare API restaurant details data for detailed amenitiy UI.
 * @param  {Object} state The curent Redux state.
 * @return {Object}       Happy Hour, Live Musiz, Other state.
 */
export const mapDetailedAmenitiesFromState = state => {
	return {
		happyHour: state.details.restaurant.amenities.entertainment.happyHour,
		liveMusic: state.details.restaurant.amenities.entertainment.music,
		other: state.details.restaurant.amenities.other
	};
};

/**
 * Prepare API restaurant details data for brief amenity UI.
 * @param  {Object} state The curent Redux state.
 * @return {Object}       Catering, Delivery, Takeout, Online Ordering, Private Dining, Outdoor Seating, and Alocohol state.
 */
export const mapBriefAmenitiesFromState = state => {
	return {
		alcohol: state.details.restaurant.amenities.entertainment.alcohol,
		catering: state.details.restaurant.amenities.catering,
		delivery: state.details.restaurant.amenities.delivery,
		liveMusic: state.details.restaurant.amenities.entertainment.music,
		onlineOrdering: state.details.restaurant.amenities.onlineOrdering,
		privateDining: state.details.restaurant.amenities.privateDining,
		seating: state.details.restaurant.amenities.entertainment.seating,
		takeout: state.details.restaurant.amenities.takeout,
		noContact: state.details.restaurant.amenities['no contact'],
		curbside: state.details.restaurant.amenities.curbside
	};
};
