const currencyFormat = (number, decimal = 0) => {
	return number
		.toFixed(decimal)
		.toString()
		.replace(/./g, (c, i, a) => {
			return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
		});
};

export default currencyFormat;
