import * as t from './types';

/**
 * Sets the show/hide state and the message string of the loading mask.
 * @param {boolean} loading If to show the loading mask.
 * @param {string} message  The text to show in the loading mask.
 */
export const setIsLoading = (loading, message = '', persist) => {
	return {
		type: t.IS_LOADING,
		payload: { loading, message, persist }
	};
};

/**
 * Sets API response error code to global error state.
 * @param {string|number} payload The API response error code. Ex: 500, 404.
 */
export const setError = payload => ({ type: t.SET_ERROR, payload });
