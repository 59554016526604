class BaseFilterGroup {
	constructor(props) {
		this.filterType = props.filterType;
	}

	activeCount = 0;
}

class BaseFilterItem {
	constructor(props) {
		Object.entries(props).forEach(([key, value]) => {
			this[key] = value;
			if (key === 'restaurantIds') {
				this.staticRestaurantIds = value;
			}
		});
	}

	get resultsCount() {
		return this.restaurantIds.size;
	}

	selected = false;
	value = null;
	id = null;
	dataAccr = null;
	filterFunc = function() {};
	restaurantIds = new Set();
	staticRestaurantIds = new Set();
}

export { BaseFilterGroup, BaseFilterItem };
