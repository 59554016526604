import React from 'react';
import { DiningOptionsList, ToGoOptionsList, DiningOptionsContactInfo } from 'components/molecules';
import PropTypes from 'prop-types';

// import "./style.scss";

/**
 * [DiningOptions description]
 * @extends Component
 */
const DiningOptions = ({ restaurant }) => {
	return (
		<div className={'dining-options'}>
			<DiningOptionsList restaurant={restaurant} />
			<ToGoOptionsList restaurant={restaurant} />
			<DiningOptionsContactInfo restaurant={restaurant} />
		</div>
	);
};

DiningOptions.defaultProps = {};

/**
 * [propTypes description]
 * @interface DiningOptionsProps
 *
 * @example {}
 */
DiningOptions.propTypes = {
	restaurant: PropTypes.object.isRequired
};

export default DiningOptions;
