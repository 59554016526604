/* eslint-disable */
/**********************
 * CONSTANTS
 **********************/
import DinovaClientAdmin from './DinovaClientAdmin';

/**********************
 * MAIN CODE BLOCK
 **********************/
const upload = async ({ idToken, username, file, processedData, supportedConnectionId }) => {
	try {
		console.log('params:', idToken, username, file, processedData, supportedConnectionId);
		// create client admin user wrapper
		const user = new DinovaClientAdmin();
		console.log('user:', user);

		// authenticate with the provided id token
		await user.authenticate(idToken);

		// add username and supportedConnection.id to aws dir path
		await user.upload(file, `/uploads/${username}/${supportedConnectionId}/`, processedData);

		return true;
	} catch (err) {
		console.log('AWS Upload Error');
		console.dir(err);
		return err;
	}
};

// Example id token below, will be expired though
// eyJraWQiOiJpQittMmtvendGY1Z6c0lvUERRQ1NSaHc5K0pkVngyVDN6OWRRNG54S2lrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0ODA3MTYwYy03NmY3LTQ1YTctOGM1NC1jMTYyMjFiZjMyZWMiLCJjdXN0b206cm9sZXMiOiIzLDExLDEyIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImN1c3RvbTphY2NlcHRlZF90YyI6InRydWUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV8yZ09wV1ZRSloiLCJjb2duaXRvOnVzZXJuYW1lIjoiZnhybGFicyt0MSIsImF1ZCI6IjI5bHExbHY2MDFncW44NXU1NGkzNGlwa3MiLCJldmVudF9pZCI6ImRlNGUzZGZkLTJmYjYtNDM5NC1hOTIxLTNjMjg3MmY4MTVlMyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTgzMTY4MDgzLCJleHAiOjE1ODMxNzE2ODMsImlhdCI6MTU4MzE2ODA4MywiZW1haWwiOiJyaWNoYXJkQGZ4cmxhYnMuY29tIn0.jeqWFbuXe-EnXyXcYwBS63P8bWT0yvjJhMGhKaJVR612Tyk6kMojjfgFRc7zqk0KFNra8kjRlAm8vAnr6K9SIctUjRIlSjc_9Dka4jyvHWDkkTEe5mylt1wQ6mJFslqN5nolboF0BxMsnU2ZhcYiTt7gHlX-q2ZRkexZZbr9pIkOyUiNVmWOII6GhtYYIYqwy9bvLULIuidAcm-CwsQcECYkhw61vDDHdd8fxF-hDMSTJ2uV2XYZwY2GR9bcG5YBs20_cDL-441Ny5vToB70-4p3Z4Z5lhOZE6l7V7-6qm3V9XRXh_dXQWhD6l6kCKKZqT0ukuaXLRpI1pgVrJ1C3g

export default upload;
