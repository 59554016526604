import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Asterisk } from 'components/atoms';
import { useTranslate } from 'hooks';

const RequiredFieldNote = ({ className }) => {
	const translate = useTranslate();
	const classProps = classNames('required-field-note', className);

	return (
		<div className={classProps}>
			<Asterisk />
			{translate('required.required')}
		</div>
	);
};

RequiredFieldNote.defaultProps = {
	className: ''
};

RequiredFieldNote.propTypes = {
	className: PropTypes.string
};

export default RequiredFieldNote;
