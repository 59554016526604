import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRecommendationOptionsQuery } from 'reactQuery/queries';

// interface useRecommendationOptionsContextProps {}
const useRecommendationOptionsContextValue = (/*:useRecommendationOptionsContextProps*/) => {
	const { data, isLoading } = useRecommendationOptionsQuery();

	return {
		isLoading,
		negative: data?.negative || [],
		positive: data?.positive || []
	};
};

const RecommendationOptionsContext = createContext({
	positive: [],
	negative: []
});

const RecommendationOptionsContextProvider = ({ children }) => {
	const value = useRecommendationOptionsContextValue();

	return <RecommendationOptionsContext.Provider value={value}>{children}</RecommendationOptionsContext.Provider>;
};

RecommendationOptionsContextProvider.propTypes = {
	children: PropTypes.node
};

const useRecommendationOptionsContext = () => {
	const context = useContext(RecommendationOptionsContext);

	return context;
};

const RecommendationOptionsContextConsumer = RecommendationOptionsContext.Consumer;

export { RecommendationOptionsContextConsumer, RecommendationOptionsContextProvider };
export default useRecommendationOptionsContext;
