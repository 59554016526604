import Cookies from 'universal-cookie';
import isNodeEnv from 'utils/isNodeEnv';

const cookies = new Cookies();

const setSecureCookie = ({ name, data, path, domain, secure = true }) => {
	cookies.set(name, data, {
		path,
		domain,
		secure: isNodeEnv('development') ? false : secure,
		sameSite: 'strict'
	});
};

export default setSecureCookie;
