import CateringSearchForm from './ui-component';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { getLocation } from 'actions/geo_location';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';

/* eslint-disable no-mixed-spaces-and-tabs */
const getInitialValues = state =>
	hasProp(state, 'form.cateringSearch.values') ? state.form.cateringSearch.values : { find: '', near: '' };

const mapStateToProps = state => ({
	geoLocation: state.geoLocation,
	formValues: getInitialValues(state),
	initialValues: getInitialValues(state),
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	getLocation: async () => await getLocation(dispatch),
	setFieldValue: (field, val) => dispatch(change('cateringSearch', field, val))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withRouter(
	withSizes(mapSizesToProps)(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(
			reduxForm({
				form: 'cateringSearch',
				destroyOnUnmount: false
			})(CateringSearchForm)
		)
	)
);
