import { Notifier } from '@airbrake/browser';
import isNodeEnv from 'utils/isNodeEnv';

const PROD_KEY = '802129ae93ca3b8bc5918c6e91d5139d',
	PROD_ID = 255923,
	DEV_KEY = '828d93f85f2afd4875ea6ed276084c71',
	DEV_ID = 260221;

const isProd = isNodeEnv('prodution') || isNodeEnv('prod');

const AirBrake = new Notifier({
	projectId: isProd ? PROD_ID : DEV_ID,
	projectKey: isProd ? PROD_KEY : DEV_KEY
});

AirBrake._dinovaEnv = isProd ? 'production' : 'dev';

export default AirBrake;
