import AutoEnrollUserFormWrapper from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { setModal, toggleModal } from 'actions/modal';

/**
 * Set component props from redux state
 * This is where we can get things like the logged in user, values from other forms (ex: CompanySelect)
 * We can pass anything we want here and it will show up as a component prop.
 */
const mapStateToProps = state => {
	return {
		complete: state.autoEnrollUserFormPage.complete,
		error: state.autoEnrollUserFormPage.error,
		isLoading: state.autoEnrollUserFormPage.isLoading,
		translate: getTranslate(state.locale),
		modal: state.modal,
		token: state.autoEnrollUserFormPage.token
	};
};

/**
 * Set component props with access to redux's action creator, the dispatch funciton.
 * This is where we can hook into redux actions (state updaters).
 * We can pass anything we want here and it will show up as a component prop.
 */
const mapDispatchToProps = dispatch => ({
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoEnrollUserFormWrapper));
