import * as t from '../actions/loader/types';
import * as registration from '../actions/registration/types';
import { hasProp } from 'utils/object';

/**
 * Redux_LoaderState
 * @interface Redux_LoaderState
 * @property {Boolean} [isBusy=true]
 * @property {String}  [message=""]
 * @property {Object}  [error=undefined]
 * @property {Boolean} [persisted=false]
 */

const loaderDefaultState = {
	error: undefined,
	isBusy: true,
	message: '',
	persisted: false
};

export default (state = loaderDefaultState, action) => {
	switch (action.type) {
		case t.IS_LOADING: {
			if (hasProp(action.payload, 'persist')) {
				return {
					...state,
					isBusy: action.payload.loading,
					message: action.payload.message,
					persisted: action.payload.persist
				};
			} else {
				if (state.persisted) {
					return { ...state, message: action.payload.message, isBusy: true };
				} else {
					return {
						...state,
						isBusy: action.payload.loading,
						message: action.payload.message,
						persisted: false
					};
				}
			}
		}
		case action.type.indexOf('SUCCESS') > -1: {
			return {
				...state,
				isBusy: false
			};
		}
		case action.type.indexOf('ERROR') > -1: {
			return {
				...state,
				isBusy: false,
				error: action.error
			};
		}
		case action.type.indexOf('REQUEST') > -1 || action.type.indexOf('START') > -1: {
			return {
				...state,
				isBusy: true
			};
		}
		case t.SET_ERROR:
			return {
				...state,
				error: action.payload
			};
		case registration.RESET: {
			return { ...state, error: undefined, message: '' };
		}
		default:
			return state;
	}
};
