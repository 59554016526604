import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslate } from 'hooks';

const ImagePromo = ({ ariaHidden, className = '', imageBaseUrl, isFoldPromo, translateKey }) => {
	const translate = useTranslate();
	const classProps = classnames('promo-banner image', className && className);

	let imageSrc, imageAlt;
	if (isFoldPromo) {
		imageSrc = `${imageBaseUrl}/${translate(`${translateKey}.fold.promoImage`)}`;
		imageAlt = `${imageBaseUrl}/${translate(`${translateKey}.fold.promoImageAlt`)}`;
	} else {
		imageSrc = `${imageBaseUrl}/${translate(`${translateKey}.promo.src`)}`;
		imageAlt = 'asdf';
	}

	return (
		<div className={classProps} aria-hidden={ariaHidden} tabIndex={ariaHidden ? -1 : undefined}>
			<div className="promo-banner-content">
				<div className="promo-image-wrapper">
					<img src={imageSrc} alt={imageAlt} />
				</div>
			</div>
		</div>
	);
};

ImagePromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	imageBaseUrl: PropTypes.string.isRequired,
	isFoldPromo: PropTypes.bool,
	translateKey: PropTypes.string.isRequired
};

export default ImagePromo;
