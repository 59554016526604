import React from 'react';
import PropTypes from 'prop-types';
import { PROGRAMATIC_BLUR } from 'utils/windowEvents';

class WithInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasFocus: false,
			showPassword: false
		};
	}

	componentDidMount = () => {
		window.addEventListener(PROGRAMATIC_BLUR, this.handleProgromaticBlur);
	};

	componentWillUnmount = () => {
		window.removeEventListener(PROGRAMATIC_BLUR, this.handleProgromaticBlur);
	};

	handleProgromaticBlur = e => {
		if (e.detail && this.input && e.detail === this.input.id) {
			e.isPropagationStopped = () => false;
			if (this.state.hasFocus) {
				this.input.blur(e);
				this.onBlur(e);
			}
		}
	};

	onFocus = e => {
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}

		if (!e.isPropagationStopped()) {
			this.setState({ hasFocus: true });
		}
	};

	onBlur = e => {
		if (e.relatedTarget && e.relatedTarget.classList.contains('suggestion-active')) {
			e.stopPropagation();
		}

		if (this.props.onBlur) {
			this.props.onBlur(e);
		}

		if (!e.isPropagationStopped()) {
			this.setState({ hasFocus: false });
		}
	};

	togglePassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};
}

WithInput.defaultProps = {};

WithInput.propTypes = {
	onBlur: PropTypes.func,
	onFocus: PropTypes.func
};

export default WithInput;
