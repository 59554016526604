import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './polyfills/ie/CustomEvent';
import 'react-smartbanner/src/styles/style.scss';
import React from 'react';
import Loader from 'components/atoms/Loader';
import App from 'components/environment/App';
import isNodeEnv from 'utils/isNodeEnv';
import { createRoot } from 'react-dom/client';
import { STORE } from 'components/environment/App/redux_store.js';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (isNodeEnv('production') || isNodeEnv('prod')) {
	require('styles/index.prod.scss');
} else {
	require('styles/index.dev.scss');
}

root.render(<Loader />);

document.addEventListener('DOMContentLoaded', function() {
	root.render(<App />);
});

if (window.Cypress) {
	window['store'] = STORE;
}
