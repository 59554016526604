import React, { useEffect, useState } from 'react';
import RestaurantHours from './ui-component';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
/* interface RestaurantHoursDataWrapperProps {
}*/
const RestaurantHoursDataWrapper = (props /*: RestaurantHoursDataWrapperProps*/) => {
	const [open, setOpen] = useState(true);
	const [hours, setHours] = useState([]);
	const translate = useSelector(state => getTranslate(state.locale));

	useEffect(() => {
		const googlePlacesId = props.restaurant.googlePlacesId;
		const google = window.google;
		const googlePlaces = google.maps.places;
		if (googlePlaces && googlePlacesId) {
			const service = new googlePlaces.PlacesService(document.createElement('div'));
			var request = {
				placeId: googlePlacesId,
				fields: ['opening_hours']
			};
			service.getDetails(request, (place, status) => {
				if (status == google.maps.places.PlacesServiceStatus.OK) {
					if (place.opening_hours?.weekday_text) {
						setHours(place.opening_hours?.weekday_text);
						setOpen(place.opening_hours.open_now);
					}
				} else {
					// error
				}
			});
			console.groupEnd();
		}
	}, [props.restaurant]);

	return <RestaurantHours {...props} hours={hours} hasHours={hours.length > 0} open={open} translate={translate} />;
};
RestaurantHoursDataWrapper.propTypes = {
	restaurant: PropTypes.object
	// googlePlacesId: PropTypes.string.isRequired
};
export default RestaurantHoursDataWrapper;
