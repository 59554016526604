import GoogleMap from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV, isMV, isLV } from 'utils/sizes';
import { setRecenterToMapMarker } from 'actions/business_search_map';

export const DEFAULT_CENTER = { lat: 0, lng: 0 };

const mapStateToProps = state => ({
	API_KEY: state.config.apiKey,
	centerToMapMarker: state.businessSearchMap.centerToMapMarker,
	geoLocation: { lat: state.geoLocation.lat, lng: state.geoLocation.lng }
});

const mapDispatchToProps = dispatch => ({
	setRecenterToMapMarker: async shouldCenter => await setRecenterToMapMarker(dispatch, shouldCenter)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(GoogleMap));
