import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';
import { Title } from 'components/atoms';
import { getOptions } from './utils';
import { useTranslate } from 'hooks';

// import ./style.scss

/**
 * ToGoOptionsList
 * @param {ToGoOptionsListProps} props
 */
const ToGoOptionsList = ({ imageBaseUrl, restaurant }) => {
	const translate = useTranslate();
	const { options } = useMemo(() => {
		const _options = getOptions(restaurant);

		return {
			options: [
				{
					title: 'Meal Kits',
					image: `${imageBaseUrl}/icons/meal kits.svg`,
					show: _options['Meal Kits']
				},
				{
					title: 'Alcohol',
					image: `${imageBaseUrl}/icons/alcohol.svg`,
					show: _options['Alcohol To-Go']
				},
				{
					title: 'Sealed Items',
					image: `${imageBaseUrl}/icons/sealed_items.svg`,
					show: _options['Sealed Packaging']
				},
				{
					title: 'Pantry Items',
					image: `${imageBaseUrl}/icons/pantry_items.svg`,
					show: _options['Pantry Items']
				}
			].filter(o => o.show)
		};
	}, [restaurant, imageBaseUrl]);

	const getImage = useCallback(option => option.image, []);
	const getOptionText = useCallback(option => option.title, []);

	return options.length > 0 ? (
		<div className={'togo-options-list'}>
			<Title transform="none" size="h4" className="mb-4" align="left">
				{translate('Details.togo-options.title')}
			</Title>
			<OptionsList
				options={options}
				getOptionText={getOptionText}
				useImage={true}
				getImage={getImage}
				className="togo-options"
				dataCy="togo-options"
			/>
		</div>
	) : null;
};

/**
 * ToGoOptionsListProps
 * @typedef {Object} ToGoOptionsListProps
 */
ToGoOptionsList.propTypes = {
	imageBaseUrl: PropTypes.string.isRequired,
	restaurant: PropTypes.object.isRequired
};

export default ToGoOptionsList;
