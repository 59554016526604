import React from 'react';
import PropTypes from 'prop-types';
import { Typography /*, TypographyProps*/ } from '@mui/material';
import classnames from 'classnames';
import styles from './style.module.scss';

/*
interface BodyProps extends TypographyProps {

}
*/

const Body = (
	{
		color,
		extraClassName,
		fullWidth,
		inline = false,
		textAlign = 'left',
		textTransform,
		weight,
		whiteSpace,
		...props
	} /*: BodyProps*/
) => {
	const className = classnames(color, extraClassName, {
		[styles.fullWidth]: fullWidth,
		[styles.inline]: inline,
		[weight]: weight
	});
	return <Typography {...props} className={className} style={{ textAlign, textTransform, whiteSpace }} />;
};

Body.propTypes = {
	extraClassName: PropTypes.string,
	color: PropTypes.oneOf(['black', 'error', 'primary', 'secondary']),
	fullWidth: PropTypes.bool,
	inline: PropTypes.bool,
	paragraph: PropTypes.bool,
	textAlign: PropTypes.oneOf(['center', 'left', 'right']),
	textTransform: PropTypes.oneOf(['none', 'uppercase']),
	weight: PropTypes.oneOf(['bold']),
	whiteSpace: PropTypes.oneOf(['break-spaces'])
	// variant: PropTypes.oneOf(['black', 'red', 'primary', 'secondary'])
};

export default Body;
