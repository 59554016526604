import ProfileSetupModal from './ui-component';
import { connect } from 'react-redux';
import { goToNextStep, toggleModalOpen, closeModal, finishOnboarding } from 'actions/profile_setup';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
	const mfaModalOpen = state.modal.id === 'mfa' && state.modal.isOpen;
	const setupModalOpen = !mfaModalOpen && state.profileSetup.open;

	const trueCount = state.profileSetup.completedSteps;

	return {
		currentStep: state.profileSetup.currentStep,
		didInit: state.profileSetup.didInit,
		mfaModalOpen,
		open: state.profileSetup.open,
		modalOpen: setupModalOpen,
		completedSteps: trueCount,
		totalSteps: 4
	};
};

const mapDispatchToProps = dispatch => ({
	goToNextStep: () => dispatch(goToNextStep()),
	toggleModalOpen: () => dispatch(toggleModalOpen()),
	closeModal: () => dispatch(closeModal()),
	finishOnboarding: () => dispatch(finishOnboarding())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileSetupModal));
