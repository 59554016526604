import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { TermsAndConditions } from 'components/molecules';
import { InputField } from 'components/organisms';

class GDPR extends React.Component {
	state = {
		optIn: {
			email: {
				value: undefined
			},
			offers: {
				value: undefined
			}
		},
		acceptedTerms: false,
		isValid: true
	};

	onRadioClick = (node, value) => {
		this.setState(
			{
				...this.state,
				optIn: {
					...this.state.optIn,
					[node]: {
						value: value
					}
				}
			},
			this.checkIsValid
		);
	};

	onCheckClick = () => {
		this.setState(
			{
				...this.state,
				acceptedTerms: !this.state.acceptedTerms
			},
			this.checkIsValid
		);
	};

	checkIsValid = () => {
		this.setState(
			{
				isValid: Boolean(
					(this.state.acceptedTerms && this.state.optIn.offers.value && this.state.optIn.email.value) || false
				)
			},
			this.updateParent
		);
	};

	updateParent() {
		this.props.updateGDPR(this.state);
	}
	render() {
		const { className, translate, showWarning } = this.props;
		const classProps = classNames('terms-and-conditions', className);

		return (
			<div className={classProps}>
				<Row>
					<Col xs="12">
						<InputField
							type="checkbox"
							options={[
								{
									label: this.props.translate('Registration.terms-label'),
									checked: this.state.acceptedTerms
								}
							]}
							onChange={e => {
								this.setState({
									acceptedTerms: e.target.checked
								});
							}}
							required
							hasError={showWarning && !this.state.acceptedTerms}
							error={this.props.translate('Registration.terms-label-error')}
							triggers={[<TermsAndConditions key="trigger-one" />]}
						/>
						<InputField
							type="radio"
							label={translate('Registration.email-summary-label')}
							inputAlign="inline"
							alignOptions="horizontal"
							onChange={e => this.onRadioClick('email', e.target.value)}
							value={this.state.optIn.email.value}
							hasError={showWarning && this.state.optIn.email.value === undefined}
							error={translate('Registration.optin-error')}
							options={[
								{ value: 'yes', displayValue: 'Yes' },
								{ value: 'no', displayValue: 'No' }
							]}
						/>
						<InputField
							type="radio"
							label={translate('Registration.offers-label')}
							inputAlign="inline"
							alignOptions="horizontal"
							onChange={e => this.onRadioClick('offers', e.target.value)}
							value={this.state.optIn.offers.value}
							hasError={showWarning && this.state.optIn.offers.value === undefined}
							error={translate('Registration.optin-error')}
							options={[
								{ value: 'yes', displayValue: 'Yes', id: 'yes1', name: 'should1' },
								{ value: 'no', displayValue: 'No', id: 'no1', name: 'should1' }
							]}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

GDPR.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func,
	showWarning: PropTypes.bool,
	updateGDPR: PropTypes.func
};

export default GDPR;
