import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Displays contact information. Ex: 'Main: 555-555-5555'.
 * @param {ContactItemProps} props
 */

const ContactItem = ({ contact: { icon, label, title, url } }) => {
	const classProps = classnames('contact-info-item', icon && 'has-icon');
	return (
		<a className={classProps} href={url} data-cy={`contact-item-link-${title}`}>
			{icon && <img className="icon" src={icon} data-cy={`contact-item-icon-${title}`} alt="" />}
			<span className="label" data-cy={`contact-item-label-${title}`}>{`${label} `}</span>
			<span className="value" data-cy={`contact-item-value-${title}`}>{`${title}`}</span>
		</a>
	);
};

ContactItem.defaultProps = {
	contact: {
		icon: '',
		label: 'Label:',
		title: '(555) 555-5555',
		url: ''
	}
};

/**
 * {@link Contact} Props
 * @interface IContact
 * @property {string} icon The icon to show next to the contact.
 * @property {string} label The name of the contact.
 * @property {string} title The value of the contact into, ex: 'example@email.com'
 * @property {string} url The url to go to when contact is click, ex: 'mailto:example@email.com'.
 */

/**
 * {@link ContactItem} Props
 * @interface Props_ContactItem
 * @property {IContact} contact {@link IContact}
 */
ContactItem.propTypes = {
	contact: PropTypes.shape({
		icon: PropTypes.string,
		label: PropTypes.string,
		title: PropTypes.string,
		url: PropTypes.string
	})
};

export default ContactItem;
