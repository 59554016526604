import React, { useCallback, useMemo } from 'react';
import { Button, Row } from 'components/atoms';
import styles from './style.module.scss';
import StepTemplate from '../../StepTemplate';
import PropTypes from 'prop-types';
import { ConnectToPointsFormNew } from 'components/organisms';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { RegisteredConnections } from 'components/molecules';
import { history } from 'utils/router';
import { useTranslate } from 'hooks';
import { sortUserConnections } from 'utils/user';
import { selectCompletedStepsFromUser } from 'actions/profile_setup/selectors';
import { useUserContext } from 'context';

const AddCreditCardStep = ({ closeProfileSetupModal, goToGreeting, setCardToEdit }) => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const { connection, complete } = useMemo(() => {
		return {
			connection: user ? sortUserConnections(user)[0] : {},
			complete: selectCompletedStepsFromUser(user).creditCardComplete
		};
	}, [user]);
	const translateBaseKey = 'ProfileSetup.modals.step2';

	const editCreditCard = useCallback(() => {
		setCardToEdit(connection);
		closeProfileSetupModal();
		history.push('/profile/payment/#/');
	}, [closeProfileSetupModal, connection, setCardToEdit]);

	const completeStep = useMemo(
		() => (
			<div className={styles.completedWrapper}>
				<div className={styles.completedContent}>
					<RegisteredConnections />
				</div>
				<Row className={styles.footer}>
					<div className={styles.btnWrapper}>
						<Button color="primary" onClick={editCreditCard}>
							{translate(`${translateBaseKey}.edit`)}
						</Button>
						<Button onClick={goToGreeting}>{translate(`${translateBaseKey}.cancel`)}</Button>
					</div>
				</Row>
			</div>
		),
		[editCreditCard, goToGreeting, translate]
	);

	const incompleteStep = useMemo(
		() => (
			<div className={styles.content}>
				<ConnectToPointsFormNew
					showRegistered={false}
					onSubmitSuccess={goToGreeting}
					buttons={({ submitDisabled }) => {
						return (
							<Row gap={'30px'} mt={4} justify="space-between" fullWidth>
								<Button variant="contained" type="submit" disabled={submitDisabled} fullWidth>
									{translate('common.submit')}
								</Button>
								<Button onClick={goToGreeting} fullWidth variant="outlined">
									{translate('common.cancelBtn')}
								</Button>
							</Row>
						);
					}}
				/>
			</div>
		),
		[goToGreeting, translate]
	);

	return (
		<StepTemplate title={translate(`${translateBaseKey}.title`)} variant={ProfileSetupSteps.ADD_CREDIT_CARD}>
			{complete && completeStep}
			{!complete && incompleteStep}
		</StepTemplate>
	);
};

AddCreditCardStep.propTypes = {
	closeProfileSetupModal: PropTypes.func.isRequired,
	goToGreeting: PropTypes.func.isRequired,
	setCardToEdit: PropTypes.func.isRequired
};

export default AddCreditCardStep;
