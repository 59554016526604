import * as t from '../actions/viewport/types';

/**
 * Redux_ViewportState
 * @interface Redux_ViewportState
 * @property {Boolean} [useMinimalHeaderFooter=true]
 * @property {Boolean} [mobileNavOpen=false]
 * @property {Boolean} [androidVersion=false]
 * @property {Boolean} [minimalLayout=false]
 */

const viewportDefaultState = {
	useMinimalHeaderFooter: true,
	mobileNavOpen: false,
	androidVersion: false,
	minimalLayout: false
};

export default (state = viewportDefaultState, action) => {
	switch (action.type) {
		case t.UPDATE_HEADER:
			return {
				...state,
				useMinimalHeaderFooter: action.payload.useMinimalHeaderFooter
			};
		case t.TOGGLE_NAV:
			return {
				...state,
				mobileNavOpen: !state.mobileNavOpen
			};
		case t.SET_ANDROID:
			return {
				...state,
				androidVersion: true
			};
		case t.SET_MINIMAL_LAYOUT:
			return {
				...state,
				minimalLayout: true,
				useMinimalHeaderFooter: true
			};
		default:
			return state;
	}
};
