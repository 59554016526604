import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { history } from 'utils/router';
import { useTranslate } from 'hooks';

/**
 * Header Level navigate button.  Goes back one step in history.
 *
 * @param {Props_BackButton} props
 */
const BackButton = ({
	baseRoute,
	// cateringIntegrationType,
	// isCateringSearchFlow,
	lastLocation = {},
	// lastSearch,
	onClick
	// useZupplerBack
}) => {
	const translate = useTranslate();
	const currentPath = window.location.pathname + window.location.hash;
	const lastPath = lastLocation ? lastLocation.pathname + lastLocation.hash : null;
	const wasOnCateringPage = lastLocation?.pathname === '/catering/';
	const isOnAboutUsPage = window.location.href.indexOf('about_us') > -1;
	/**
	 * Go back one step in react-router history stack.
	 * If history stack length is less than one, go back to this.props.baseRoute.
	 * @param  {Object} e Back button click event.
	 */
	const back = useCallback(
		e => {
			e.preventDefault();
			const isNotBackToSamePage = lastPath !== currentPath;

			if (history.length > 1 && lastLocation && isNotBackToSamePage) {
				if (wasOnCateringPage && isOnAboutUsPage) {
					// prevent toggle between about us and catering tabs.
					history.push(baseRoute);
				} else {
					history.goBack();
				}
			} else {
				history.push(baseRoute);
			}
		},
		[baseRoute, lastLocation, currentPath, isOnAboutUsPage, lastPath, wasOnCateringPage]
	);

	// const zupplerAboutBack = useCallback(
	// 	e => {
	// 		e.preventDefault();

	// 		if (!isCateringSearchFlow) {
	// 			history.replace(lastSearch);
	// 		} else {
	// 			const isOnCateringPage = currentPath === '/catering/#/';

	// 			if (isOnCateringPage) {
	// 				history.replace('/');
	// 			} else {
	// 				window.zupplerBridge && window.zupplerBridge.setPage('/#');
	// 				history.replace('/catering/#/');
	// 			}
	// 		}
	// 	},
	// 	[isCateringSearchFlow, lastSearch, currentPath]
	// );

	// const isNotOnCateringPage = currentPath !== '/catering/#/';

	// if (useZupplerBack && isNotOnCateringPage) {
	// 	return (
	// 		<>
	// 			<div
	// 				id="zuppler-navigation-back"
	// 				className="zuppler-dinova-link"
	// 				onClick={
	// 					(cateringIntegrationType === 'order' || cateringIntegrationType === 'user:login') &&
	// 					isCateringSearchFlow
	// 						? undefined
	// 						: zupplerAboutBack
	// 				}
	// 				hidden={isOnAboutUsPage}
	// 			/>
	// 			{isOnAboutUsPage && (
	// 				<a
	// 					className="back-link"
	// 					onClick={
	// 						cateringIntegrationType === 'order' || cateringIntegrationType === 'user:login'
	// 							? undefined
	// 							: zupplerAboutBack
	// 					}
	// 				>
	// 					<i className="fa fa-chevron-left" />
	// 					{translate('Details.back')}
	// 				</a>
	// 			)}
	// 		</>
	// 	);
	// }

	return (
		<a className="back-link" onClick={onClick || back}>
			<i className="fa fa-chevron-left" />
			{translate('Details.back')}
		</a>
	);
};

BackButton.defaultProps = {
	baseRoute: '/',
	useZupplerBack: false
};

/**
 * {@link BackButton} Props
 * @interface Props_BackButton
 * @property {Object} history React Router history object.
 * @property {String} [baseRoute] The "home" route. Base route will be used as back button goTo route if history.length < 1.
 */
BackButton.propTypes = {
	// Required
	// isCateringSearchFlow: PropTypes.bool.isRequired,
	lastLocation: PropTypes.object, // <- is required but if no history this will come in null
	// Optional
	baseRoute: PropTypes.string,
	// cateringIntegrationType: PropTypes.string,
	// lastSearch: PropTypes.string,
	onClick: PropTypes.func
	// useZupplerBack: PropTypes.bool
};

export default BackButton;
