const typographyTheme = {
	allVariants: {
		// '&.primary': {
		// color: '#4d4c48' // $black-text
		// },
		'&.black': {
			color: '#000000' // $black-text
		},

		'&.bold': {
			fontWeight: '400 !important'
		},
		'&.MuiTypography-paragraph': {
			whiteSpace: 'break-spaces !important'
		},
		'&.error': {
			color: '#e10600 !important' // $danger-red, $loading-red
		}
	},

	body1: {
		// '@media (min-width:600px)': {
		fontSize: 18,
		fontWeight: 300

		// }
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
	},
	body2: {
		// '@media (min-width:600px)': {
		fontSize: 16,
		fontWeight: 300

		// }
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
	},

	fontFamily: [
		// '-apple-system',
		// 'BlinkMacSystemFont',
		'Lato',
		// 'Roboto',
		// '"Helvetica Neue"',
		// 'Arial',
		'sans-serif'
		// '"Apple Color Emoji"',
		// '"Segoe UI Emoji"',
		// '"Segoe UI Symbol"'
	].join(',')
};

export default typographyTheme;
