import { useQuery } from './';
import { basePath } from 'actions/utils';

export const COMPANY_BY_CODE_QUERY_KEY = 'company_by_code_query';

/*
interface UseCompanyByCodeQuery {}
*/

const useCompanyByCodeQuery = ({ companyCode, quietLoading, setReduxIsLoading, setDataInRedux } = {}) => {
	return useQuery({
		// Req
		queryHandler: res => res.data.clients[0],
		queryKey: [COMPANY_BY_CODE_QUERY_KEY, companyCode],

		// Opt
		enabled: Boolean(companyCode),
		quietLoading,
		reduxLoadingMsg: 'Fetching Company...',
		reduxQueryDataAccr: query => query.data,
		setDataInRedux,
		setReduxIsLoading,
		urlBuilder: queryKey => {
			const _companyCode = queryKey[1];
			return `${basePath()}/clients/${_companyCode}`;
		}
	});
};

export default useCompanyByCodeQuery;
