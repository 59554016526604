import * as t from './types';

/**
 * IModal
 * @interface IModal
 * @property {string} id The unique id of the modal.
 * @property {string} header The text to show in the modal header.
 * @property {string} body The text to show in the modal body.
 * @property {string} primaryButtonText The text to show in the modal primary button.
 * @property {string} [secondaryButtonText=null] The text to show in the modal secondary button.
 * @property {string} [tertiaryButtonText=null] The text to show in the modal tertiary button.
 */

/**
 * Set modal state.
 * @param {IModal} modal The modal config object.
 */
export const setModal = modal => ({
	type: t.SET_VALUES,
	modal
});

/**
 * Sets the modals show/hide state.
 * @param  {Boolean} isOpen If to show modal.
 */
export const toggleModal = isOpen => {
	return {
		type: t.TOGGLE_MODAL,
		isOpen
	};
};

/**
 * Sets the autocomplete modals show/hide state. (Autocomplete modal is the company selector).
 * @param  {Boolean} isOpen If to show modal.
 */
export const toggleAutoCompleteModal = isOpen => ({
	type: t.TOGGLE_AUTOCOMPLETE,
	isOpen
});

// NOTE: This func isn't used.
export const selectAction = action => ({
	type: t.SELECT_ACTION,
	action
});

/**
 * Rest the modal to it's default state.
 */
export const resetModal = () => ({
	type: t.RESET_MODAL
});
