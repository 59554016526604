import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useGeneralContext from 'context/GeneralContext';
import { useTranslate } from 'hooks';

const Step = ({ className, registration }) => {
	const {
		authState: { isAuthed },
		isDoDMode,
		isDoorDashMode
	} = useGeneralContext();
	const translate = useTranslate();

	const classProps = classNames('step', className);
	const currentStep = registration.currentStep;

	let title,
		desc,
		helper = false,
		titleClass = 'registration-step-header';

	if (window.location.pathname === '/users/connect' && isAuthed) {
		title = translate('ConnectToPoints.header-title_registered');
		desc = translate('ConnectToPoints.header_registered');
		helper = translate('ConnectToPoints.ten_point_limit');
		titleClass += ' text-left';
		if (isDoDMode) {
			desc = translate('ConnectToPoints.header-dod');
			helper = false;
		}

		if (isDoorDashMode) {
			desc = translate('ConnectToPoints.header-doordash');
			helper = false;
		}
	} else {
		title = `${translate('RegistrationFlow.title')} ${translate('RegistrationFlow.step')} ${currentStep}`;
		desc = translate(`RegistrationFlow.steps.items.${currentStep}.desc`);

		if (isDoDMode && currentStep === 1) {
			desc = translate(`RegistrationFlow.steps.items.${currentStep}.dod_desc`);
		}

		if (isDoDMode && currentStep === 2) {
			desc = translate('ConnectToPoints.header-dod');
		}

		if (isDoorDashMode && currentStep === 2) {
			desc = translate('ConnectToPoints.header-doordash');
		}
	}

	return (
		<div className={classProps}>
			<h1 className={titleClass} data-cy="registration-step-header">
				{title}
			</h1>
			<p className="text-left">{desc}</p>
			{helper && <p className="text-left">{helper}</p>}
		</div>
	);
};

Step.defaultProps = {
	className: ''
};

Step.propTypes = {
	// Required
	registration: PropTypes.object.isRequired,

	// Optional
	className: PropTypes.string
};

export default Step;
