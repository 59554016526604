/* eslint-disable no-case-declarations */
import * as t from '../actions/landing_page_cards/types';

/**
 * [landingPageCardsDetaultState description]
 * @type {Object}
 */
const landingPageCardsDetaultState = {
	activeCard: undefined,
	showAbout: false
};

export default (state = landingPageCardsDetaultState, action) => {
	switch (action.type) {
		case t.SET_ACTIVE_CARD: {
			if (state.showAbout && action.payload !== state.activeCard) {
				return {
					...state,
					showAbout: false,
					activeCard: action.payload
				};
			} else {
				return {
					...state,
					activeCard: action.payload
				};
			}
		}

		case t.SET_SHOW_ABOUT: {
			const { showAbout, cardId } = action.payload;
			if (state.activeCard !== cardId && showAbout) {
				return {
					...state,
					activeCard: cardId,
					showAbout
				};
			} else {
				return {
					...state,
					showAbout: action.payload.showAbout
				};
			}
		}

		default:
			return state;
	}
};
