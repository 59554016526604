import { useEffect } from 'react';
/**
 *
 * @param {boolean} shouldDisable
 */
const useDisableScrolling = shouldDisable => {
	useEffect(() => {
		if (shouldDisable) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}

		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [shouldDisable]);
};

export default useDisableScrolling;
