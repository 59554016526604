import React from 'react';
import PropTypes from 'prop-types';
import { MetaTags, ModalPage, PageError, PasswordInput, TermsAndConditionsNew } from 'components/molecules';
import metaTagConfig from './meta-tags';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { Button as MyButton, Checkboxes, Col as MyCol, Radios, Row as MyRow, TextFieldNew } from 'components/atoms';
import { useTranslate } from 'hooks';
import { useSelector } from 'react-redux';
import {
	usernameRequirements,
	tAndCCheckboxData,
	monthlySummaryCheckboxesData,
	specialOffersCheckboxesData
} from './fieldOptions';
import useRegistrationFormFields from './hooks/useRegistrationFormFields';
import useRegistrationFormCancelModal from './hooks/useRegistrationFormCancelModal';

const RegistrationForm = ({ doAfterSubmit }) => {
	const { initialValues, isMutating, onSubmit } = useRegistrationFormFields({ doAfterSubmit });
	const {
		showCancelModal,
		isOpen: cancelModalIsOpen,
		onConfirmCancelRegistration
	} = useRegistrationFormCancelModal();

	const { registrationError } = useSelector(state => ({
		registrationError: state.registration.error
	}));

	const translate = useTranslate();

	return (
		<div className="registration-form-wrapper">
			<MetaTags tags={metaTagConfig} />
			<ModalPage
				isOpen={cancelModalIsOpen}
				primaryClick={() => {}}
				secondaryClick={onConfirmCancelRegistration}
			/>
			{registrationError && (
				<PageError
					data-cy="register-error"
					message={
						(registrationError && translate(`errors.codes.${registrationError}.message`)) ||
						translate('errors.page_level_message')
					}
				/>
			)}
			<Form
				initialValues={initialValues}
				onSubmit={onSubmit}
				render={({ handleSubmit, errors, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<MyRow justify="flex-end">
								<div className="Di_RequiredHeader">
									<span>*</span>
									{translate('required.required')}
								</div>
							</MyRow>
							<MyCol fullWidth gap="45px">
								<TextFieldNew
									name="username"
									label="Username"
									required
									value={values.username}
									requirements={usernameRequirements}
									titleKey={errors?.usernameRequirements?.titleKey}
									data-cy="register-username"
								/>
								<PasswordInput
									name="password"
									label="Password"
									required
									value={values?.password}
									data-cy="register-password"
								/>
								<TextField name="email" label="Work Email" required data-cy="register-email" />
								<TextField name="firstName" label="First Name" required data-cy="register-first-name" />
								<TextField name="lastName" label="Last Name" required data-cy="register-last-name" />
								<MyRow fullWidth justify="space-between" align="center">
									<Checkboxes
										name="tandc"
										data={tAndCCheckboxData}
										required
										dataCy="register-t-and-c"
									/>
									<TermsAndConditionsNew />
								</MyRow>
								<Radios
									label="I want a monthly email summary of my point status."
									name="monthly_summary"
									data={monthlySummaryCheckboxesData}
									data-cy="register-monthly-summary"
								/>
								<Radios
									label="I want occasional emails about offers, new restaurants, and specials."
									name="special_offers"
									data={specialOffersCheckboxesData}
									data-cy="register-special-offers"
								/>
							</MyCol>

							<MyRow mt="40px" gap="50px" justify="center" align="center">
								<MyButton
									variant="outlined"
									onClick={showCancelModal}
									id="register-cancel"
									disabled={isMutating}
									dataCy="register-cancel"
								>
									{translate('Registration.secondary_button')}
								</MyButton>

								<MyButton
									type="submit"
									id="register-submit"
									disabled={isMutating}
									dataCy="register-submit"
								>
									{translate('Registration.primary_button')}
								</MyButton>
							</MyRow>
						</form>
					);
				}}
			/>
		</div>
	);
};

RegistrationForm.defaultProps = {};

RegistrationForm.propTypes = {
	doAfterSubmit: PropTypes.func
};

export default RegistrationForm;
