import useQuery from './useQuery';
import axios from 'axios';
import { basePath_dashboard, AUTH_REQUEST_HEADER_ID } from 'actions/utils';

/*
interface useCompanyRebateSummaryQueryProps {}
*/

const COMPANY_REBATE_SUMMARY_QUERY_KEY = 'company_rebate_summary_query';

const useCompanyRebateSummaryQuery = ({ companyCode, month, year } /*:useCompanyRebateSummaryQueryProps*/) => {
	return useQuery({
		queryKey: [COMPANY_REBATE_SUMMARY_QUERY_KEY, { code: companyCode, month, year }],
		queryFn: async ({ queryKey }) => {
			const url = `transactions/client-rebate/summary`;
			const params = queryKey[1];

			return axios.post(`${basePath_dashboard()}${url}`, params, AUTH_REQUEST_HEADER_ID()).then(res => res.data);
		},
		enabled: Boolean(companyCode),
		reduxLoadingMsg: 'Loading Rebate Summary...',
		requiresAuth: true
	});
};

export default useCompanyRebateSummaryQuery;
