import ProfileSetupStepList from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { withRouter } from 'react-router';
import { closeModal } from 'actions/profile_setup';

const mapDispatchToProps = dispatch => ({
	completeStep: () => dispatch(completeStep(ProfileSetupSteps.EMAILS)),
	closeProfileSetupModal: () => dispatch(closeModal()),
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default withRouter(connect(null, mapDispatchToProps)(ProfileSetupStepList));
