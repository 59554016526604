import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslate } from 'hooks';

const BlurbPromo = ({ ariaHidden, className = '', translateKey }) => {
	const translate = useTranslate();
	const wrapperRef = createRef();
	const contentRef = createRef();

	// componentDidMount
	useEffect(() => {
		const wrapperHeight = wrapperRef.current.scrollHeight;

		wrapperRef.current.style.height = wrapperHeight + 'px';
		wrapperRef.current.style.width = wrapperHeight + 'px';
		wrapperRef.current.style.top = (wrapperHeight / 2.3) * -1 + 'px';
		wrapperRef.current.style.right = (wrapperHeight / 3) * -1 + 'px';

		contentRef.current.style.paddingTop = wrapperHeight / 2 + 'px';
		contentRef.current.style.paddingLeft = wrapperHeight / 7 + 'px';
	});

	const classProps = classnames('promo-banner blurb', className && className);

	return (
		<div className={classProps} ref={wrapperRef} aria-hidden={ariaHidden} tabIndex={ariaHidden ? -1 : undefined}>
			<div className="promo-banner-content" ref={contentRef}>
				<p>{translate(`${translateKey}.promo.blurb`)}</p>
			</div>
		</div>
	);
};

BlurbPromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	translateKey: PropTypes.string.isRequired
};

export default BlurbPromo;
