import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SearchCenterMarker = ({ className, IMAGE_BASE_URL }) => {
	const classProps = classNames('search-center-marker', className);
	const imgSrc = `${IMAGE_BASE_URL}/icons/icon-search-center.svg`;

	return <img className={classProps} src={imgSrc} alt="map center" />;
};

SearchCenterMarker.defaultProps = {
	className: ''
};

SearchCenterMarker.propTypes = {
	// Required
	IMAGE_BASE_URL: PropTypes.string.isRequired,

	// Optional
	className: PropTypes.string
};

export default SearchCenterMarker;
