import { passwordValidator } from 'utils/form/validators';

const validateRegistrationForm = vals => {
	const errors = {};

	const passwordError = passwordValidator(vals.password);
	if (passwordError) {
		errors.password = passwordError;
	}

	if (!vals.passwordConfirm) {
		errors.passwordConfirm = 'required.required';
	}

	if (vals.password.length && vals.passwordConfirm.length && !(vals.password === vals.passwordConfirm)) {
		errors.passwordConfirm = 'AutoEnrollUserForm.validToken.inputs.confirmPassword.error.mismatch';
	}

	if (!vals.terms) {
		errors.terms = 'required.required';
	}

	return errors;
};

export default validateRegistrationForm;
