export const INIT_ONBOARDING_STATE_FROM_USER = 'INIT_ONBOARDING_STATE_FROM_USER';
export const PROCEED = 'PROCEED';
export const TOGGLE_MODAL_OPEN = 'TOGGLE_MODAL_OPEN';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const GO_TO_STEP = 'GO_TO_STEP';
export const UPDATE_FORM = 'UPDATE_FORM';
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const DISMISS_FOREVER = 'DISMISS_FOREVER';
export const DEFAULT_ONBOARDING = 'DEFAULT_ONBOARDING';
export const FINISH_ONBOARDING = 'FINISH_ONBOARDING';
export const SET_COMPLETED_STEPS = 'SET_COMPLETED_STEPS';
export const RESET = 'RESET';
