import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { SHOW_LESS_ALL_FILTERS } from 'utils/windowEvents';

/**
 * [FilterGroup description]
 */
const FilterGroup = ({ className, concat, concatLength = 5, expandText, filterName, isSV, options }) => {
	const [showMore, setShowMore] = useState(false);

	// componentDidMount
	useEffect(() => {
		window.addEventListener(SHOW_LESS_ALL_FILTERS, showLess, false);

		//componentWillUnmount
		return () => {
			window.removeEventListener(SHOW_LESS_ALL_FILTERS, showLess);
		};
	});

	const toggleShowMore = useCallback(() => {
		setShowMore(prev => !prev);
	}, []);

	const showLess = useCallback(() => {
		setShowMore(false);
	}, []);

	const _options = useMemo(() => {
		if (concat) {
			if (showMore) {
				return options;
			} else {
				return options.slice(0, concatLength);
			}
		} else {
			return options;
		}
	}, [concat, concatLength, options, showMore]);

	const classProps = classnames(className && className, 'filter-group');
	const doConcat = options.length > concatLength;
	const filterTitle = filterName === 'covid' ? 'COVID-19 Practices' : startCase(filterName);

	return Object.values(_options).length > 0 ? (
		<div key={filterName} className={classProps} data-group={filterName} data-cy={`filter-group-${filterName}`}>
			<Text className={classnames('bold', isSV ? 'mb-3' : 'mb-1')}>{filterTitle}</Text>
			<ul>
				{_options}
				{concat && doConcat && (
					<Button onClick={toggleShowMore} className="more-button">
						{showMore ? expandText.open : expandText.closed}
					</Button>
				)}
			</ul>
		</div>
	) : null;
};

/**
 * [propTypes description]
 * @interface FilterGroupProps
 *
 * @example {}
 */
FilterGroup.propTypes = {
	className: PropTypes.string,
	concat: PropTypes.bool,
	concatLength: PropTypes.number.isRequired,
	expandText: PropTypes.shape({
		open: PropTypes.string,
		closed: PropTypes.string
	}),
	filterName: PropTypes.string.isRequired,
	isSV: PropTypes.bool.isRequired,
	options: PropTypes.array.isRequired
};

export default FilterGroup;
