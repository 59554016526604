import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from 'components/atoms';
import { DateGridItem } from 'components/molecules';
import classnames from 'classnames';
import { buildAboutRestaurantURL } from 'utils/url';
import { history } from 'utils/router';
import { hasProp } from 'utils/object';
import { useRecommendationOptionsQuery } from 'reactQuery/queries';
import useGeneralContext from 'context/GeneralContext';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const RecommendationItem = ({ isSV, item }) => {
	const translate = useSelector(state => getTranslate(state.locale));
	const { data } = useRecommendationOptionsQuery();
	const { options } = useMemo(
		() => ({
			options: data
				? [
						...data?.negative.reduce((accum, group) => [...accum, ...group.categories], []),
						...data?.positive.reduce((accum, group) => [...accum, ...group.categories], [])
				  ]
				: []
		}),
		[data]
	);

	const {
		recommendationModal: { showRecommendationsModalPrefilled }
	} = useGeneralContext();

	const recommendColClassProps = classnames('rec col-sm-2', item.recommended ? 'pos' : 'neg');

	const goToDetails = () => {
		let URL = buildAboutRestaurantURL({
			id: item.restaurant.id,
			name: item.restaurant.name,
			city: item.restaurant.address.city,
			state: hasProp(item.restaurant.address, 'state.abbreviation')
				? item.restaurant.address.state.abbreviation
				: ''
		});
		history.push(URL);
	};

	const recommendedFor = useMemo(() => {
		let final = [];
		item.for.forEach(f => {
			options.forEach(opt => {
				if (opt.id === f) {
					final.push(opt.name);
				}
			});
		});

		return final.join(', ');
	}, [item, options]);

	return (
		<div className="recommendation-item row" data-cy="recommendation-item">
			<div className={recommendColClassProps}>
				<Text size="sm" align="center" dataCy="did-recommend">
					{item.recommended ? 'Yes' : 'No'}
				</Text>
			</div>

			<DateGridItem date={item.date} dataCy="date" />

			{!isSV && (
				<div className="restaurant col-sm-3">
					<div>
						<Link
							size="sm"
							align="center"
							onClick={goToDetails}
							className="text text-center sm normal text-none"
							dataCy="restaurant-name"
						>
							{item.restaurant.name}
						</Link>
						<Text size="sm" align="center">
							<span
								className="restaurant-street"
								data-cy="restaurant-street"
							>{`${item.restaurant.address.street.join(', ')}`}</span>
							<span className="restaurant-state" data-cy="restaurant-city-state">
								{` ${item.restaurant.address.city} ${item.restaurant.address.state?.abbreviation}`}
							</span>
						</Text>
					</div>
				</div>
			)}

			{!isSV && (
				<div className="details col-sm-3">
					<Text size="sm" align="center" dataCy="restaurant-recommended-for">
						{recommendedFor}
					</Text>
				</div>
			)}

			{isSV && (
				<div className="details col-sm-6 col-md-3">
					<Link
						size="sm"
						align="center"
						onClick={goToDetails}
						className="text text-center sm normal text-none"
					>
						{item.restaurant.name}
					</Link>
					<Text size="xs" align="center">
						{`${item.restaurant.address.street.join(', ')} ${item.restaurant.address.city} ${
							hasProp(item.restaurant.address, 'state.abbreviation')
								? item.restaurant.address.state.abbreviation
								: ''
						}`}
					</Text>
				</div>
			)}

			<div className="edit col-sm-2">
				<Link
					onClick={() => {
						showRecommendationsModalPrefilled({
							type: item.recommended ? 'positive' : 'negative',
							recommendation: item
						});
					}}
					dataCy="edit"
				>
					<Text size="sm" align="center">
						{translate('common.editBtn')}
					</Text>
				</Link>
			</div>
		</div>
	);
};
RecommendationItem.defaultProps = {};
RecommendationItem.propTypes = {
	isSV: PropTypes.bool.isRequired,
	item: PropTypes.object.isRequired
};

export default RecommendationItem;
