import React from 'react';
import PropTypes from 'prop-types';
import { Field, SubmissionError } from 'redux-form';
import { Text } from 'components/atoms';
import { ReduxFormInput, Table, OnboardingAdminRow } from 'components/organisms';
import { startCase } from 'lodash';
import { history } from 'utils/router';
import { parseCSVFile } from 'utils/Onboarding/csv-processor';
import { EditProfileActions } from 'components/molecules';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

class OnboardingAdminList extends React.Component {
	state = {
		didSubmit: false,
		fileField: null,
		formError: null,
		hasErrors: null,
		processed: false,
		progress: 0,
		tableData: {
			headers: [],
			rows: []
		},
		uploadCount: 0,
		uploadError: false,
		selectedRestaurants: [],
		currentPage: 1,
		pageSize: 50,
		loading: false
	};

	componentDidMount = () => {
		this.initFileField();
		console.log('Loaded ListPage');
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState.currentPage != this.state.currentPage) {
			window.scrollTo(0, 0);
		}
	};

	initFileField = () => {
		const fileField = (
			<Field
				name="file"
				type="file"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'csv',
								fileType: 'text/csv',
								hasError: !field.meta.valid,
								error: field.meta.error ? this.props.translate(field.meta.error) : '',
								id: 'file',
								label: this.props.translate('AdminDashboard.OnboardingAdminPage.uploadBtn'),
								placeholder: false,
								name: 'file',
								onChange: this.onFileChange.bind(this, field),
								type: 'file'
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ fileField });
	};

	onFileChange = (field, e) => {
		field.input.onChange(e.target.files[0]);
		this.setState({ formError: false, hasErrors: false, didSubmit: false, processed: false });
	};

	handleCheckboxChange = (restaurantId, restaurantName, isChecked) => {
		this.setState(prevState => {
			const selectedRestaurants = isChecked
				? [...prevState.selectedRestaurants, { id: restaurantId, name: restaurantName }]
				: prevState.selectedRestaurants.filter(item => item.id !== restaurantId);

			return { selectedRestaurants };
		});
	};

	handlePageChange = newPage => {
		this.setState({
			currentPage: newPage
		});
	};

	handlePageJumpInputChange = e => {
		this.setState({ jumpToPage: e.target.value });
	};

	handlePageJump = () => {
		const { jumpToPage, tableData, pageSize } = this.state;
		const totalPages = Math.ceil(tableData.rows.length / pageSize);
		let pageNumber = parseInt(jumpToPage, 10);

		if (pageNumber >= 1 && pageNumber <= totalPages) {
			this.setState({ currentPage: pageNumber, jumpToPage: '' });
		} else {
			alert(`Please enter a valid page number between 1 and ${totalPages}`);
		}
	};

	getCurrentPageData = () => {
		const { currentPage, pageSize, tableData } = this.state;
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		return tableData.rows.slice(startIndex, endIndex);
	};

	downloadCSV = () => {
		const { selectedRestaurants } = this.state;
		if (selectedRestaurants.length === 0) {
			return;
		}

		const csvData = Papa.unparse({
			fields: ['restaurant_id', 'restaurant_name'],
			data: selectedRestaurants.map(item => [item.id, item.name])
		});

		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
		saveAs(blob, 'selected_restaurants.csv');
	};

	submit = async vals => {
		this.setState({ didSubmit: false, formError: false, hasErrors: false, loading: true });
		const errors = this.validate(vals);
		console.log('Errors:', errors);

		if (Object.keys(errors).length > 0) {
			this.setState({ loading: false });
			throw new SubmissionError(errors);
		} else {
			this.props.setIsLoading(true);
			try {
				const processed = await parseCSVFile(vals.file);
				console.log('Processed CSV:', processed); // Add this line for logging
				await this.handleProcessedResults(vals.file, processed);
			} catch (err) {
				console.log('error caught while processing csv file', err);
			} finally {
				this.props.setIsLoading(false);
				this.setState({ loading: false });
			}
		}
	};

	processCSVFile = async file => {
		const processed = await this.processCSVFile(file);
		return processed;
	};

	handleProcessedResults = async (file, processed) => {
		console.log('Processed results:', processed);

		if (processed && Array.isArray(processed.errors) && processed.errors.length > 0) {
			this.generateTableData(processed.errors, processed.error);
			this.setState({ hasErrors: true, processed });
		} else {
			const rows = processed.data.map(row => ({
				restaurantId: row.id,
				restaurantName: row.restaurant_name,
				logoURL: row.logo_url,
				cuisineURL: row.cuisine_url,
				restaurantURL: row.restaurant_url
			}));

			this.setState({
				tableData: { headers: this.state.tableData.headers, rows },
				didSubmit: true,
				hasErrors: false,
				uploadError: false,
				uploadedCount: processed.data.length,
				currentPage: 1 // Reset to the first page after processing
			});
		}
	};

	presentRenderedLayouts = async processed => {
		this.props.setIsLoading(true);

		const rows = processed.data.map(row => ({
			restaurantId: row.id,
			restaurantName: row.restaurant_name,
			logoURL: row.logo_url,
			cuisineURL: row.cuisine_url,
			restaurantURL: row.restaurant_url
		}));

		this.setState({
			tableData: { rows },
			didSubmit: true,
			hasErrors: false,
			uploadError: false,
			uploadedCount: processed.data.length
		});
		console.log(this.state);
		this.props.setIsLoading(false);
	};

	validate = vals => {
		let errors = {};

		if (!vals.file) {
			errors.file = 'required.required';
		}

		if (vals.file && vals.file.type !== 'text/csv' && vals.file.name.indexOf('.csv') === -1) {
			errors.file = 'AdminDashboard.OnboardingAdminPage.inputs.file.errors.invalidFileType';
		}

		console.log('Validation errors:', errors); // Add this line to log the validation errors

		return errors;
	};

	generateTableData = (errors, formError) => {
		const tableData = errors.reduce(
			(accum, error, i) => {
				let rowData = [];
				Object.keys(error).forEach(key => {
					if (key === 'row' || key === 'errors') {
						if (i === 0) {
							// create table headers from first error object, all error objects will have the same keys.
							if (key === 'row') {
								// make row the first header
								accum.headers.splice(0, 0, { title: startCase(key) });
							} else {
								// make errors the second header
								accum.headers.splice(1, 0, { title: startCase(key) });
							}
						}

						if (key === 'row') {
							// make row the column value
							rowData.splice(0, 0, error[key]);
						} else {
							// make errors the second column value
							rowData.splice(1, 0, error[key].join(', '));
						}
					}
				});
				accum.rows.push({ values: rowData });
				return accum;
			},
			{ headers: [], rows: [] }
		);

		return this.setState({ formError, tableData });
	};

	prepareForNewUpload = () => {
		this.props.setFormValue('file', null);
		this.setState({ formError: false, hasErrors: false, uploadError: false, didSubmit: false, fileField: null });
		this.initFileField();
	};

	leavePage = () => {
		history.push('/');
	};

	render = () => {
		const { translate } = this.props;
		const { currentPage, pageSize, tableData, loading } = this.state;
		const successfullySubmitted =
			!this.state.formError && !this.state.hasErrors && !this.state.uploadError && this.state.didSubmit;

		const currentPageData = this.getCurrentPageData();
		const totalPages = Math.ceil(tableData.rows.length / pageSize);

		return (
			<React.Fragment>
				{loading && (
					<div style={{ textAlign: 'center', margin: '20px 0' }}>
						<div className="spinner"></div> {/* Add your spinner component or HTML here */}
						<p>Processing...</p>
					</div>
				)}
				{successfullySubmitted && (
					<div>
						{this._successMessage(this.state.uploadedCount)}
						{/* Present the results */}
						{/* Pagination controls */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '20px 0'
							}}
						>
							<button disabled={currentPage === 1} onClick={() => this.handlePageChange(currentPage - 1)}>
								Previous
							</button>
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
								<a
									key={page}
									href="#"
									onClick={e => {
										e.preventDefault();
										this.handlePageChange(page);
									}}
									style={{
										margin: '0 5px',
										textDecoration: page === currentPage ? 'underline' : 'none'
									}}
								>
									{page}
								</a>
							))}
							<button
								disabled={currentPage === totalPages}
								onClick={() => this.handlePageChange(currentPage + 1)}
							>
								Next
							</button>
						</div>
						{currentPageData.map((row, index) => (
							<div
								key={index}
								style={{
									marginBottom: '20px',
									borderBottom: '1px solid #ccc',
									paddingBottom: '10px'
								}}
							>
								<OnboardingAdminRow
									restaurantId={row.restaurantId}
									restaurantName={row.restaurantName}
									index={index}
									cuisineURL={row.cuisineURL}
									logoURL={row.logoURL}
									onCheckboxChange={this.handleCheckboxChange}
									selectedRestaurants={this.state.selectedRestaurants}
								/>
							</div>
						))}

						{/* Pagination controls */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '20px 0'
							}}
						>
							<button disabled={currentPage === 1} onClick={() => this.handlePageChange(currentPage - 1)}>
								Previous
							</button>
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
								<a
									key={page}
									href="#"
									onClick={e => {
										e.preventDefault();
										this.handlePageChange(page);
									}}
									style={{
										margin: '0 5px',
										textDecoration: page === currentPage ? 'underline' : 'none'
									}}
								>
									{page}
								</a>
							))}
							<button
								disabled={currentPage === totalPages}
								onClick={() => this.handlePageChange(currentPage + 1)}
							>
								Next
							</button>
						</div>
						<button onClick={this.downloadCSV}>Download CSV</button>
					</div>
				)}
				{!successfullySubmitted && !loading && (
					<React.Fragment>
						<Text paragraph size="sm" className="mt-6">
							{translate('AdminDashboard.OnboardingAdminPage.formHelp')}
						</Text>
						<form onSubmit={this.props.handleSubmit(this.submit)} className="onboarding-form">
							{<React.Fragment>{this.state.fileField}</React.Fragment>}
							{this._errorMessage()}
							{this._uploadError()}
							<EditProfileActions
								updateButtonDisabled={this.state.hasErrors}
								updateButtonText={translate('common.submit')}
								onCancel={this.leavePage}
							/>
						</form>
					</React.Fragment>
				)}
				<Text paragraph align="center" className="mt-10 mb-0">
					{translate('AdminDashboard.OnboardingAdminPage.help')}
				</Text>
			</React.Fragment>
		);
	};

	_successMessage = uploadedCount => {
		return (
			<div className="sucess-wrapper" data-cy="success-wrapper">
				<Text dataCy="success-header" align="center">
					{this.props.translate('common.success')}
				</Text>
				<Text dataCy="success-message" align="center">
					{this.props
						.translate('AdminDashboard.OnboardingAdminPage.successMessage')
						.replace('${x}', uploadedCount)}
				</Text>
			</div>
		);
	};

	_errorMessage = () => {
		return this.state.hasErrors ? (
			<div className="table-wrapper mt-8" data-cy="error-wrapper">
				<Text align="center" size="sm" dataCy="generic-error" className="generic error pt-3">
					{this.props.translate('AdminDashboard.OnboardingAdminPage.errorsMessage')}
				</Text>
				<Table dataCy="onboarding-errors" {...this.state.tableData} />
			</div>
		) : null;
	};

	_uploadError = () => {
		return this.state.uploadError ? (
			<Text align="center" className="error" dataCy="upload-error">
				{this.props.translate('AdminDashboard.OnboardingAdminPage.permissionsError')}
			</Text>
		) : null;
	};
}

OnboardingAdminList.defaultProps = {};

OnboardingAdminList.propTypes = {
	formValues: PropTypes.object,
	idToken: PropTypes.string,
	initialValues: PropTypes.shape({
		file: PropTypes.object
	}).isRequired,
	setIsLoading: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired, // redux-form provided prop
	setFormValue: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default OnboardingAdminList;
