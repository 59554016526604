import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY, USER_FAVORITE_QUERY_KEY } from 'reactQuery/queries';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserMutationProps {}
*/

const useUserFavoriteRestaurantMutation = ({ onError, onSuccess, setErrorInRedux } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const rollBackMutation = useCallback(() => {
		queryClient.setQueryData(USER_FAVORITE_QUERY_KEY, old => old);
	}, [queryClient]);

	const mutation = useMutation({
		mutationFn: ({ favoriteValue, restaurantId }) => {
			if (favoriteValue) {
				// Add favorite
				return axios.post(
					`${basePath()}/users/restaurants/favorite`,
					JSON.stringify({ restaurantId }),
					AUTH_REQUEST_HEADER()
				);
			} else {
				// Remove favorite
				return axios.delete(`${basePath()}/users/restaurants/favorite/${restaurantId}`, AUTH_REQUEST_HEADER());
			}
		},
		onMutate: async variables => {
			const message = variables?.favoriteValue ? 'Adding Favorite...' : 'Removing Favorite...';
			dispatch(setIsLoading(true, message));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));
			// Update cache with old user data from before error
			rollBackMutation();

			if (setErrorInRedux) {
				setErrorInRedux(error);
			}

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			dispatch(setIsLoading(false));
			if (data.data.errors) {
				rollBackMutation();

				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({
					queryKey: [USER_QUERY_KEY]
				});
				queryClient.invalidateQueries({
					queryKey: [USER_FAVORITE_QUERY_KEY]
				});

				queryClient.refetchQueries({
					queryKey: [USER_QUERY_KEY]
				});

				queryClient.refetchQueries({
					queryKey: [USER_FAVORITE_QUERY_KEY]
				});

				onSuccess && onSuccess(data);
			}
		}
		// onSettled: (data, error /*variables, context*/) => {
		// if (data.errors) {
		// 	throw new Error(data.errors);
		// 	// rollBackMutation();
		// }
		// console.log('%c settled', 'color: orange', data, error);
		// Error or success... doesn't matter!
		// }
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		addOrRemoveFavorite: mutation.mutate
	};
};

export default useUserFavoriteRestaurantMutation;
