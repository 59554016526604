import React from 'react';
import PropTypes from 'prop-types';
import AirBrake from 'utils/airbrake';

class AirbrakeErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// Send error to Airbrake

		AirBrake.notify({
			error: error,
			params: { info: info },
			environment: AirBrake._dinovaEnv
		});

		/* eslint-disable no-debugger */ debugger;
	}

	render() {
		// commented out so we always show our app
		// if (this.state.hasError) {
		// 	// You can render any custom fallback UI
		// 	return <h1>Something went wrong.</h1>;
		// }
		return this.props.children;
	}
}

AirbrakeErrorBoundary.defaultProps = {};
AirbrakeErrorBoundary.propTypes = {
	children: PropTypes.node
};

export default AirbrakeErrorBoundary;
