import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { Promo } from 'components/molecules';
import { useTranslate } from 'hooks';

const Promos = ({ className }) => {
	const translate = useTranslate();
	const classProps = classNames('promos', className);

	const promosCount =
		parseInt(
			translate('Home.promos.count', null, {
				showMissingTranslationMsg: false
			})
		) || 0;

	return (
		promosCount > 0 && (
			<div className={classProps}>
				{range(promosCount).map((promoNumber, index) => (
					<Promo key={index} number={promoNumber + 1} />
				))}
			</div>
		)
	);
};

Promos.defaultProps = {
	className: ''
};

Promos.propTypes = {
	className: PropTypes.string
};

export default Promos;
