import { useQuery } from './';
import { AUTH_REQUEST_HEADER, basePath } from 'actions/utils';
import axios from 'axios';

export const COMPANY_LOOKUP_QUERY_KEY = 'company_lookup_query';

/*
interface useCompanyAutoCompleteQueryProps {}
*/

const useCompanyLookupQuery = ({ companyName, quietLoading, setReduxIsLoading, setDataInRedux } = {}) => {
	return useQuery({
		// Req
		queryHandler: res => res.data.clients[0],
		queryKey: [COMPANY_LOOKUP_QUERY_KEY, companyName],

		// Opt
		enabled: Boolean(companyName),
		quietLoading,
		reduxLoadingMsg: 'Fetching Company...',
		reduxQueryDataAccr: query => query.data,
		setDataInRedux,
		setReduxIsLoading,
		urlBuilder: queryKey => {
			const companyName = queryKey[1];
			return `${basePath()}/clients?search=${companyName}`;
		}
	});
};

export const companyLookupSyncQuery = companyName =>
	axios.get(`${basePath()}/clients?search=${companyName}`, AUTH_REQUEST_HEADER());

export default useCompanyLookupQuery;
