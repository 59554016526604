import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserMutationProps {}
*/

const useUserConnectionMutation = ({ onError, onSuccess, setErrorInRedux } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: connection => {
			return axios.post(`${basePath()}/users/connections`, JSON.stringify(connection), AUTH_REQUEST_HEADER());
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'Connecting User...'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			setErrorInRedux && setErrorInRedux(error);

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			dispatch(setIsLoading(false));
			if (data.data.errors) {
				// rollBackMutation();

				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({
					queryKey: [USER_QUERY_KEY]
				});
				queryClient.refetchQueries({
					queryKey: [USER_QUERY_KEY]
				});

				onSuccess && onSuccess(data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		createConnection: mutation.mutate
	};
};

export default useUserConnectionMutation;
