import { createTheme } from '@mui/material/styles';
import inputTheme from './input';
import typographyTheme from './typography';
import buttonTheme from './button';
import menuTheme from './menu';
console.log('typographyTheme', typographyTheme);
const theme = createTheme({
	components: {
		...inputTheme,
		...buttonTheme,
		...menuTheme
	},
	typography: typographyTheme
});

export default theme;
