import * as t from './types';
import { createAction } from 'redux-act';

/**
 * Load the config vars depending on the current env.
 */
export const loadConfig = createAction(t.LOAD_CONFIG, () => {
	let ENV;
	if (window.Cypress) {
		/* eslint-disable no-undef */
		ENV = Cypress.env().NODE_ENV || 'development';
		/* eslint-enable no-undef */
	} else {
		ENV = process.env.NODE_ENV || 'development';
	}

	// Determine which config file to load based on the environment
	// Default to Dev
	let config = null;

	if (ENV === 'uat') {
		//UAT
		config = require('../../config/config.uat')({ env: 'uat' });
	} else if (ENV === 'prod' || ENV === 'production') {
		// Prod
		config = require('../../config/config.prod')({ env: 'production' });
	} else {
		config = require('../../config/config.dev')({ env: 'dev' });
	}

	return config;
});
