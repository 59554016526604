import React from 'react';
import PropTypes from 'prop-types';
import { Body, Button, Row } from 'components/atoms';
import { TextField } from 'mui-rff';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const PHONE_INPUT_ID = 'input-phoneNumberField';

const PhoneNumberPage = ({ onCancelClick, value }) => {
	return (
		<div style={{ padding: '9px 16px 0' }}>
			<Grid2 container spacing={'20px'}>
				<Grid2 item xs={12}>
					<Row fullWidth mt={2} mb={2.5}>
						<Body weight={'bold'} textTransform="uppercase" color="black">
							Please enter your phone number
						</Body>
					</Row>
				</Grid2>
				<Grid2 item xs={12} style={{ marginBottom: '16px' }}>
					<TextField
						id={PHONE_INPUT_ID}
						// required
						name="phone"
						label="Mobile Number"
						helperText="ex: (555) 123-1234"
						value={value}
					/>
				</Grid2>
				<Grid2 item xs={12} lg={6} style={{ marginTop: '15px' }}>
					<Button
						fullWidth
						size="large"
						className="z-depth-0"
						onClick={onCancelClick}
						id="modal-secondary-button"
						variant="outlined"
					>
						Back
					</Button>
				</Grid2>
				<Grid2 item xs={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
					<Button
						fullWidth
						size="large"
						className="z-depth-0"
						variant="contained"
						id="modal-primary-button"
						type="submit"
					>
						Next
					</Button>
				</Grid2>
			</Grid2>
		</div>
	);
};

PhoneNumberPage.defaultProps = {};
PhoneNumberPage.propTypes = {
	onCancelClick: PropTypes.func,
	value: PropTypes.string.isRequired
};

export default PhoneNumberPage;
