import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Title } from 'components/atoms';
import { OptionsList } from 'components/organisms';
import { useRecommendationOptionsQuery } from 'reactQuery/queries';

const RestaurantRecommendations = ({ restaurant, isLV }) => {
	const { data } = useRecommendationOptionsQuery();
	const { hasPositiveRecommendations, positiveRecommendations, recommendations } = useMemo(
		() => ({
			hasPositiveRecommendations: (data?.positive || []).length > 0,
			positiveRecommendations: data?.positive || [],
			recommendations: restaurant?.recommendations || false
		}),
		[data, restaurant]
	);

	const compileGroups = useCallback(() => {
		let groups = Object.values(positiveRecommendations).reduce((accum, { name, categories }) => {
			categories.forEach(cat => {
				if (recommendations.positive.categories[cat.id]) {
					accum[name] = {
						...(accum[name] || {}),
						[cat.name]: recommendations.positive.categories[cat.id].total
					};
				}
			});
			return accum;
		}, {});

		return groups;
	}, [recommendations, positiveRecommendations]);

	const generateLists = useCallback(
		groups => {
			const classProps = classnames('details-recommendations', !isLV && 'single-col');

			let lists = Object.entries(groups).map(([groupName, groupValues], i) => {
				let options = Object.entries(groupValues).map(([catName, catTotal]) => {
					return `${catName} (${catTotal})`;
				});
				return (
					<div key={groupName} className="half-width">
						<Title align="left" transform="none" className={i > 1 ? 'mt-4' : ''}>
							{groupName}
						</Title>
						<OptionsList className={classProps} options={options} />
					</div>
				);
			});

			return lists;
		},
		[isLV]
	);

	const lists = useMemo(() => generateLists(compileGroups()), [compileGroups, generateLists]);

	if (!hasPositiveRecommendations) {
		return null;
	}

	return lists.length ? <div className="flex row-wrap justify-between">{lists}</div> : null;
};

/**
 * {@link RestaurantRecommendations} Props
 * @interface Props_RestaurantRecommendations
 * @property {string[]} [recommendations=[]]
 */
RestaurantRecommendations.propTypes = {
	// Required
	isLV: PropTypes.bool.isRequired,
	restaurant: PropTypes.object.isRequired
};

export default RestaurantRecommendations;
