import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RestaurantListMarker = ({ activePin, className, IMAGE_BASE_URL, onClick, markerId }) => {
	const isMarkerActive = activePin === markerId,
		classProps = classNames('restaurant-list-marker', isMarkerActive && 'active', className),
		imgSrc = `${IMAGE_BASE_URL}/icons/icon-map-pin-${isMarkerActive ? '' : 'in'}active.svg`,
		handleClick = async e => {
			e.stopPropagation();
			await onClick(e);
		};

	return (
		<img
			data-cy={`list-marker`}
			className={classProps}
			src={imgSrc}
			onClick={handleClick}
			alt={isMarkerActive ? 'active list item' : 'inactive list item'}
		/>
	);
};

RestaurantListMarker.defaultProps = {
	className: '',
	activePin: '',
	onClick: () => {}
};

RestaurantListMarker.propTypes = {
	// Required
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	markerId: PropTypes.string.isRequired,

	// Optional
	activePin: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func
};

export default RestaurantListMarker;
