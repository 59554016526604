import * as t from '../actions/registration/types';
import * as user from '../actions/users/types';

/**
 * Redux_RegistrationState
 * @interface Redux_RegistrationState
 * @property {number}        [currentStep=1]
 * @property {array}         [steps=[]]
 * @property {string|number} [selectedCard=undefined]
 * @property {object}        [cardDetails={}]
 * @property {object}        [user=undfined]
 * @property {string|number} [error=undefined]
 * @property {string}        [referral=undefined]
 */
const registrationReducerDefaultState = {
	currentStep: 1,
	steps: [
		{
			title: 'Get registered',
			text:
				'Registration is quick and easy! Simply opt in by completing the form below, and start earning points for every dollar you spend with your corporate credit card at Dinova restaurants.'
		},
		{
			title: 'Connect to points',
			text:
				'You’re so close! Let us know how you pay for business meals so we can award you points appropriately.'
		},
		{
			title: 'Share with others',
			text: ''
		}
	],
	selectedCard: undefined,
	cardDetails: {
		creditcard: '',
		visamc: '',
		employeeId: '',
		firstName: '',
		lastName: ''
	},
	user: undefined,
	error: undefined,
	referral: undefined,
	registeredAsIneligible: undefined
};

export default (state = registrationReducerDefaultState, action) => {
	switch (action.type) {
		case t.CHANGE_STEP:
			return {
				...state,
				currentStep: action.payload
			};
		case t.ASSIGN_USER:
			return {
				...state,
				user: action.user
			};
		case t.ASSIGN_CARD:
			return {
				...state,
				selectedCard: action.card
			};
		case t.ASSIGN_CARD_DETAILS:
			return {
				...state,
				cardDetails: action.details
			};
		case t.RESET:
			return registrationReducerDefaultState;
		case t.RESET_CARD:
			return {
				...state,
				selectedCard: undefined
			};
		case t.RESET_CARD_DETAILS:
			return {
				...state,
				cardDetails: registrationReducerDefaultState.cardDetails
			};
		case t.SET_ERROR:
			return {
				...state,
				error: action.payload
			};
		case t.SET_REFERRAL:
			return {
				...state,
				referral: action.payload
			};
		case t.SET_DID_REGISTER_WITH_INELIGIBLE: {
			return {
				...state,
				registeredAsIneligible: true
			};
		}
		case user.CLEAR_USER: {
			return {
				...state,
				selectedCard: undefined,
				user: undefined,
				cardDetails: {
					creditcard: '',
					visamc: '',
					employeeId: '',
					firstName: '',
					lastName: ''
				},
				referral: undefined
			};
		}

		default:
			return state;
	}
};
