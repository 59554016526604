import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { deleteAllConnectionsWindowEvent } from 'utils/windowEvents';
import { useTranslate } from 'hooks';
import { useUserConnectionDeleteAllMutation } from 'reactQuery/mutations';
import { useUserContext } from 'context';

const DeleteConnectionsWarningModal = ({ close, confirmHandler, setIsLoading, setCompanySearch, show }) => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const { connections, usersCompanyName } = useMemo(
		() => ({
			connections: user?.connections || [],
			usersCompanyName: user?.company?.name || ''
		}),
		[user]
	);

	const { deleteConnections } = useUserConnectionDeleteAllMutation();

	const confirm = async () => {
		// const promises = [];
		if (connections.length > 0) {
			setIsLoading(true, true);
		}
		deleteConnections(
			connections.map(c => c.id),
			{
				onSuccess: async () => {
					if (confirmHandler) {
						confirmHandler();
					}
					deleteAllConnectionsWindowEvent();
					setIsLoading(false, false);
					close();
				}
			}
		);
	};

	const cancel = () => {
		setCompanySearch(usersCompanyName);
		close();
	};

	return (
		<Modal className="delete_connections_warning_modal" isOpen={show} centered>
			<ModalHeader>{translate('EditPersonal.confirmCompanyChangeModal.title')}</ModalHeader>

			<ModalBody>{translate('EditPersonal.confirmCompanyChangeModal.body')}</ModalBody>

			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							<Button block color="primary" onClick={confirm} id="confirm-company-change">
								{translate('common.continueBtn')}
							</Button>
						</Col>
						<Col sm="12">
							<Button block color="secondary" onClick={cancel} id="cancel-company-change">
								{translate('common.cancelBtn')}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

DeleteConnectionsWarningModal.defaultProps = {};

DeleteConnectionsWarningModal.propTypes = {
	close: PropTypes.func.isRequired,
	confirmHandler: PropTypes.func,
	setIsLoading: PropTypes.func.isRequired,
	setCompanySearch: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired
};

export default DeleteConnectionsWarningModal;
