import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import {
	LazyAddCreditCard,
	LazyCompleteEmail,
	LazyCompleteProfile,
	LazyGreeting,
	LazyProfileSetupFinishStep
} from './components/Steps';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import styles from './style.module.scss';
import { useDisableScrolling, useTranslate } from 'hooks';
import CompleteSetupBanner from './components/CompleteSetupBanner';
import {
	SETUP_COMPLETE_URL_PARAM_NAME,
	SETUP_COMPLETE_URL_PARAM_VALUE
} from 'components/organisms/ProfileSetupModal/constants';
import queryString from 'query-string';
import { getLowerCasedParams } from 'utils/url';
import { selectCompletedStepsFromUser } from 'actions/profile_setup/selectors';
import { useDispatch } from 'react-redux';
import { initOnboardingStateFromUser } from 'actions/profile_setup';
import useGeneralContext from 'context/GeneralContext';
import { useUserContext } from 'context';

const ProfileSetupModal = ({
	currentStep,
	didInit,
	location,
	modalOpen: modalOpenProp,
	toggleModalOpen,
	closeModal,
	completedSteps,
	totalSteps
}) => {
	const translate = useTranslate();
	const {
		authState: { isAuthed }
	} = useGeneralContext();
	const { user, isLoading } = useUserContext();
	const dispatch = useDispatch();

	const [didInitModalOpen, setDidInitModalOpen] = useState(false);

	useEffect(() => {
		if (user && !didInit) {
			dispatch(initOnboardingStateFromUser(user));
		}
	}, [didInit, dispatch, user]);

	const { isSetupComplete, modalOpen, registrationDate, isFinishedViewed, showComplete } = useMemo(() => {
		const params = getLowerCasedParams();
		const isRegistration = location.pathname.indexOf('/users/register') > -1;
		let _isSetupComplete = false;
		let _registrationDate = null;
		let _isFinishedViewed = false;
		let _showComplete = false;

		if (user && !isLoading) {
			const { onboardingStatus } = user;
			const { viewCompletion } = onboardingStatus;
			_showComplete =
				onboardingStatus.optInNotification == true &&
				onboardingStatus.completeProfile == true &&
				onboardingStatus.favoriteRestaurant == true &&
				onboardingStatus.connectedCard == true;
			_registrationDate = user.registered.date;
			_isSetupComplete = !Object.values(selectCompletedStepsFromUser(user)).includes(false);
			_isFinishedViewed = viewCompletion;
		}

		return {
			modalOpen: !isRegistration && !isLoading && modalOpenProp && isAuthed && params.setupflow !== 'true',
			isSetupComplete: _isSetupComplete,
			registrationDate: _registrationDate,
			isFinishedViewed: _isFinishedViewed,
			showComplete: _showComplete
		};
	}, [isLoading, location, user, modalOpenProp, isAuthed]);

	const isSearchPage = location.pathname.includes('search');
	const isSetupFlowOnSearchPage = false;
	const isRegistrationFlow = location.pathname.indexOf('/users/register') > -1;
	const isSetupCompleteOnSearchPage =
		(queryString.parse(location.search)[SETUP_COMPLETE_URL_PARAM_NAME] ?? '') === SETUP_COMPLETE_URL_PARAM_VALUE;

	const checkEligibility = useCallback(() => {
		const translateBaseKey = 'ProfileSetup';
		const eligibleDate = new Date(translate(`${translateBaseKey}.startDate`)).valueOf();
		if (registrationDate === null) {
			return true;
		}
		return new Date(registrationDate).valueOf() >= eligibleDate;
	}, [registrationDate, translate]);

	const isEligible = useMemo(() => checkEligibility(), [checkEligibility]);

	useDisableScrolling(modalOpen && !isRegistrationFlow && isEligible);

	useEffect(() => {
		if (isSetupCompleteOnSearchPage) {
			// console.log('checking isSetupCompleteOnSearchPage');
			closeModal();
		} else if (isSetupFlowOnSearchPage && !modalOpen) {
			toggleModalOpen();
		}
	}, [isSetupCompleteOnSearchPage, isSetupFlowOnSearchPage, modalOpen, toggleModalOpen, closeModal]);

	useEffect(() => {
		// Check if all steps are completed)
		if (completedSteps === totalSteps && !isFinishedViewed) {
			if (isSearchPage && !modalOpen && !didInitModalOpen) {
				toggleModalOpen();
				setDidInitModalOpen(true);
			} else if (isSearchPage && modalOpen) {
				setDidInitModalOpen(true);
			}
		} else if (completedSteps == totalSteps) {
			closeModal();
		}
	}, [
		completedSteps,
		didInitModalOpen,
		totalSteps,
		toggleModalOpen,
		modalOpen,
		closeModal,
		isFinishedViewed,
		isSearchPage
	]);

	if (!isEligible) {
		// console.log('user is not eligibile for profile setup');
		return null;
	} else if (
		// (isAuthed && isFinishedViewed) ||
		!isAuthed ||
		isSetupFlowOnSearchPage ||
		// (isSetupComplete && isFinishedViewed) ||
		isRegistrationFlow
	) {
		// console.log('checking failed in second spot');
		return null;
	}

	return (
		<div>
			<Modal open={modalOpen} className={styles.modal} data-cy="profile-setup-modal">
				<div
					className={styles.modalContent}
					data-cy="content"
					style={
						{
							// maxHeight: currentStep === ProfileSetupSteps.ADD_CREDIT_CARD ? 'unset' : '390px'
						}
					}
				>
					{showComplete ? (
						<LazyProfileSetupFinishStep />
					) : (
						<>
							<button className={styles.closeBtn} onClick={() => toggleModalOpen()} data-cy="close-btn" />
							{currentStep === ProfileSetupSteps.GREETING && <LazyGreeting />}
							{currentStep === ProfileSetupSteps.PROFILE && <LazyCompleteProfile />}
							{currentStep === ProfileSetupSteps.ADD_CREDIT_CARD && <LazyAddCreditCard />}
							{currentStep === ProfileSetupSteps.EMAILS && <LazyCompleteEmail />}
						</>
					)}
				</div>
			</Modal>
			{!isLoading && !isSetupComplete && <CompleteSetupBanner {...(isSearchPage ? { mb: 16 } : {})} />}
		</div>
	);
};

ProfileSetupModal.propTypes = {
	currentStep: PropTypes.string,
	didInit: PropTypes.bool.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string
	}),
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func,
	closeModal: PropTypes.func,
	finishOnboarding: PropTypes.func,
	completedSteps: PropTypes.number,
	totalSteps: PropTypes.number
};

export default ProfileSetupModal;
