import SearchPageHeader from './ui-component';
import { connect } from 'react-redux';
import { withSizes } from 'react-sizes';
import { isSV } from 'utils/sizes';

const mapStateToProps = state => ({
	useMinimalLayout: state.viewport.minimalLayout
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(SearchPageHeader));
