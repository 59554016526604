import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { applyURLParams } from 'utils/url';
import { TabPanelBody, TabPanelHeader } from 'components/molecules';

const TabPanel = props => {
	const {
		baseRouteRedirect = {},
		bodyClass,
		headerClass,
		history,
		lastLocation,
		match,
		previousRouteRedirects,
		tabs = [],
		tabStyle
	} = props;
	const [configuredBaseRouteRedirect, setConfiguredBaseRouteRedirect] = useState({});

	useEffect(() => {
		if (baseRouteRedirect.from) {
			setConfiguredBaseRouteRedirect(configureBaseRouteRedirect(props));
		}
		handleBaseRouteRedirect();
		handlePreviousRouteRedirect();
	}, [
		baseRouteRedirect.from,
		configureBaseRouteRedirect,
		handleBaseRouteRedirect,
		handlePreviousRouteRedirect,
		props
	]);

	const configureBaseRouteRedirect = useCallback(
		propsArg => {
			const _props = propsArg || props;
			let [from, to] = applyURLParams(_props.match, [_props.baseRouteRedirect.from, _props.baseRouteRedirect.to]);

			return {
				from,
				to
			};
		},
		[props]
	);

	const handleBaseRouteRedirect = useCallback(() => {
		const {
			history,
			match: { url }
		} = props;
		const { from, to } = configuredBaseRouteRedirect;

		if (from === url) {
			history.replace(to);
		}
	}, [configuredBaseRouteRedirect, props]);

	const isValidPreviousRoute = useMemo(() => {
		const { pathname } = lastLocation || {};

		return (previousRouteRedirects || {})[pathname];
	}, [previousRouteRedirects, lastLocation]);

	const handlePreviousRouteRedirect = useCallback(() => {
		if (previousRouteRedirects && lastLocation) {
			const validPreviousRoute = isValidPreviousRoute;
			if (validPreviousRoute) {
				history.replace(validPreviousRoute);
			}
		}
	}, [history, isValidPreviousRoute, lastLocation, previousRouteRedirects]);

	return (
		<div className="tab-panel">
			{/*match.url === this.baseRouteRedirect.from && <Redirect replace to={this.baseRouteRedirect.to} />*/}
			<TabPanelHeader tabs={tabs} match={match} tabStyle={tabStyle} className={headerClass} />
			<TabPanelBody tabs={tabs} match={match} className={bodyClass} />
		</div>
	);
};

TabPanel.propTypes = {
	baseRouteRedirect: PropTypes.shape({
		from: PropTypes.string.isRequired,
		to: PropTypes.string.isRequired
	}),
	bodyClass: PropTypes.string,
	headerClass: PropTypes.string,
	history: PropTypes.object.isRequired, // react-router history object
	lastLocation: PropTypes.object, //
	match: PropTypes.object.isRequired, //react router match object
	previousRouteRedirects: PropTypes.object,
	tabs: PropTypes.array,
	tabStyle: PropTypes.string
};

export default TabPanel;
