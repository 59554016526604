import React from 'react';
import { InvisibleButton, Text } from 'components/atoms';
import styles from './style.module.scss';
import ProfileSetupProgressBar from '../../ProfileSetupProgressBar';
import ProfileSetupStepList from '../../ProfileSetupStepList';
import StepTemplate from '../../StepTemplate';
import PropTypes from 'prop-types';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { useTranslate } from 'hooks';

const TRANSLATE_BASE_KEY = 'ProfileSetup.modals.greeting';

const ProfileSetupGreetingStep = ({ dismissForever }) => {
	const translate = useTranslate();
	const points = translate('ProfileSetup.points');

	return (
		<StepTemplate
			dataCy="greeting"
			title={translate(`${TRANSLATE_BASE_KEY}.title`)}
			variant={ProfileSetupSteps.GREETING}
		>
			<div className={styles.content}>
				<ProfileSetupProgressBar />
				<Text className={styles.subTitle} weight="bold">
					{translate(`${TRANSLATE_BASE_KEY}.body`, { points })}
				</Text>
				<ProfileSetupStepList />
				<InvisibleButton onClick={dismissForever}>
					<Text className={styles.dismissBtn}>{translate(`ProfileSetup.dismissForever`)}</Text>
				</InvisibleButton>
			</div>
		</StepTemplate>
	);
};

ProfileSetupGreetingStep.propTypes = {
	dismissForever: PropTypes.func.isRequired
};

export default ProfileSetupGreetingStep;
