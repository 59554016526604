import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { completeStep } from 'actions/profile_setup';
import { useUserContext } from 'context';

// interface useInitCompletedOnboardingStepsProps {}

/* 
Make sure profile setup modal considers card step complete if user has already added a credit card.
*/
const useInitCompletedOnboardingSteps = (/*{  }:useInitCompletedOnboardingStepsProps*/) => {
	const dispatch = useDispatch();
	const { user } = useUserContext();
	const hasConnections = (user?.connections || []).length > 0;
	const { completeSteps } = useSelector(state => ({
		completeSteps: state.profileSetup.completeSteps
	}));

	useEffect(() => {
		if (hasConnections && !completeSteps.has(ProfileSetupSteps.ADD_CREDIT_CARD)) {
			dispatch(completeStep(ProfileSetupSteps.ADD_CREDIT_CARD));
		}
	}, [completeSteps, dispatch, hasConnections]);
};

export default useInitCompletedOnboardingSteps;
