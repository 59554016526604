const isNodeEnv = env => {
	return process.env.NODE_ENV === env;
};

export const isDev = () => {
	return process.env.NODE_ENV === 'development';
};

export const isLocal = () => {
	return (window?.location?.hostname || '') === 'dinova.local';
};

export default isNodeEnv;
