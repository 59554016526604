const onboardingData = {
	data: {
		restaurants: [
			{
				amenities: {
					delivery: {
						types: [
							{
								name: 'Self',
								id: 201
							}
						],
						url: 'https://www.blazepizza.com/order/action/delivery'
					},
					general: [
						{
							name: 'Dine-in',
							id: 1
						},
						{
							name: 'Curbside',
							id: 2
						},
						{
							name: 'Alcohol To-Go',
							id: 6
						}
					],
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {},
					onlineOrdering: {
						url: 'https://order.blazepizza.com/'
					},
					takeout: {
						url: 'https://order.blazepizza.com/'
					}
				},
				keywords: '',
				specials: {
					offers: []
				},
				description:
					'At Blaze Pizza, legit pizza at crazy fast speed is what we’re all about.  Fresh dough, made from scratch daily.  Real ingredients without artificial baggage.   Walk the line and choose any toppings you like – it’s all one price. Then it’s just 180 seconds in our blazing hot oven for fast-fire’d perfection.  Sound good? Then come check us out and build your own pizza at Blaze Pizza.',
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Fast Casual',
					id: 2
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'AMEX',
							id: 1
						},
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						}
					]
				},
				googlePlacesId: 'ChIJwRS20MYsDogR2pw-rsO7SC8',
				id: 'ac4722ad-1eca-5ee5-9e34-3ec3005f3eba',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Healthy',
						id: 4
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Blaze Pizza',
					id: 'ab6bf699-f076-51d1-b273-2f733ff0d54b'
				},
				menuSpecialties: [
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Salads',
						id: 377
					}
				],
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/logo/logo.jpg'
					},
					cuisineImage: {
						altText: 'Pepperoni Red Onion Pizza',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/main/BBQ-CHKN.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/logo/logo.jpg',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/main/BBQ-CHKN.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['24 South Clinton Street'],
					postalCode: '60661',
					state: {
						abbreviation: 'IL'
					}
				},
				social: {
					twitter: 'https://twitter.com/BlazePizza',
					facebook: 'https://www.facebook.com/BlazePizza',
					instagram: 'https://www.instagram.com/BlazePizza/'
				},
				cuisine: {
					name: 'Pizza',
					id: 15
				},
				dutyOfCareUpdateDate: '2020-08-12T00:00:00.000Z',
				lastUpdate: {
					date: '2021-11-01T17:58:15.141Z'
				},
				name: 'Blaze Pizza ',
				location: {
					lon: -87.641148,
					lat: 41.881206
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '(312) 766-1089',
							fax: ''
						},
						email: '',
						url: 'https://www.blazepizza.com/locations/'
					}
				},
				status: 1,
				distance: {
					span: '0.481284037791383',
					units: 'mi'
				}
			},
			{
				amenities: {
					delivery: {
						types: [
							{
								name: 'Self',
								id: 201
							}
						],
						url: 'https://www.blazepizza.com/order/action/delivery'
					},
					general: [
						{
							name: 'Dine-in',
							id: 1
						},
						{
							name: 'Curbside',
							id: 2
						},
						{
							name: 'Alcohol To-Go',
							id: 6
						}
					],
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {},
					onlineOrdering: {
						url: 'https://order.blazepizza.com/'
					},
					takeout: {
						url: 'https://order.blazepizza.com/'
					},
					menu: [
						{
							name: 'Online Menu',
							id: 302
						}
					]
				},
				keywords: '',
				specials: {
					offers: []
				},
				description:
					'At Blaze Pizza, legit pizza at crazy fast speed is what we’re all about.  Fresh dough, made from scratch daily.  Real ingredients without artificial baggage.   Walk the line and choose any toppings you like – it’s all one price. Then it’s just 180 seconds in our blazing hot oven for fast-fire’d perfection.  Sound good? Then come check us out and build your own pizza at Blaze Pizza.',
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Fast Casual',
					id: 2
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						},
						{
							name: 'AMEX',
							id: 1
						}
					]
				},
				googlePlacesId: 'ChIJyWahCpgsDogRDlqm-lA-EUg',
				id: 'ffec800f-7a72-5189-91e6-daf11a2ca016',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Healthy',
						id: 4
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Blaze Pizza',
					id: 'ab6bf699-f076-51d1-b273-2f733ff0d54b'
				},
				menuSpecialties: [
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Salads',
						id: 377
					}
				],
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/logo/logo.jpg'
					},
					cuisineImage: {
						altText: 'Pepperoni Red Onion Pizza',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/9e0d430b-b219-56b7-90a4-888e9f0410b8/images/main/peperoni-red-onion.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/logo/logo.jpg',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/ab6bf699-f076-51d1-b273-2f733ff0d54b/images/main/BBQ-CHKN.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['511 South State Street'],
					postalCode: '60605',
					state: {
						abbreviation: 'IL'
					}
				},
				social: {
					twitter: 'https://twitter.com/BlazePizza',
					facebook: 'https://www.facebook.com/BlazePizza',
					instagram: 'https://www.instagram.com/BlazePizza/'
				},
				cuisine: {
					name: 'Pizza',
					id: 15
				},
				dutyOfCareUpdateDate: '2020-08-12T00:00:00.000Z',
				lastUpdate: {
					date: '2021-09-20T15:44:55.625Z'
				},
				name: 'Blaze Pizza ',
				location: {
					lon: -87.627377,
					lat: 41.875293
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '(312) 683-9092',
							fax: ''
						},
						email: '',
						url: 'https://www.blazepizza.com/locations/'
					}
				},
				status: 1,
				distance: {
					span: '0.42038734201510497',
					units: 'mi'
				}
			},
			{
				amenities: {
					delivery: {
						url: 'https://www.pizzeriaunodue.com/'
					},
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {
						alcohol: [
							{
								name: 'Full-bar',
								id: 101
							}
						],
						happyHour: {
							details: 'Most Restaurants, 3pm -7pm and 9pm to close'
						}
					},
					onlineOrdering: {
						url: 'https://www.pizzeriaunodue.com/'
					},
					catering: {
						types: [
							{
								name: 'Self-Service',
								id: 1
							}
						],
						url: 'https://www.pizzeriaunodue.com/'
					},
					privateDining: {},
					venues: [
						{
							name: 'Semi-private Dining Events',
							id: 252
						}
					],
					takeout: {
						url: 'https://www.pizzeriaunodue.com/'
					}
				},
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/e86cd1d6-2810-5052-b8ef-4de06aef24b1/images/logo/logo.JPG'
					},
					cuisineImage: {
						altText: 'Pizzeria Due deep dish',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/e86cd1d6-2810-5052-b8ef-4de06aef24b1/images/main/Pizzeria Due2.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/e86cd1d6-2810-5052-b8ef-4de06aef24b1/images/logo/logo.JPG',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/e86cd1d6-2810-5052-b8ef-4de06aef24b1/images/main/Pizzeria Due2.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['619 North Wabash Avenue'],
					postalCode: '60611',
					state: {
						abbreviation: 'IL'
					}
				},
				specials: {
					offers: []
				},
				social: {
					twitter: 'https://twitter.com/unochicagogrill',
					facebook: 'https://www.facebook.com/UnoChicagoGrill/'
				},
				cuisine: {
					name: 'Pizza',
					id: 15
				},
				description:
					'It all began when Ike Sewell imagined a pizza unlike any other. Fresh dough with a tall edge, topped with homemade sauce and more cheese than you could believe. People have been lining up ever since.',
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Casual',
					id: 3
				},
				lastUpdate: {
					date: '2023-04-04T17:53:40.273Z'
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'AMEX',
							id: 1
						},
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						}
					]
				},
				name: 'Pizzeria Due',
				googlePlacesId: 'ChIJ8ymHBK0sDogRNEL-iqf1hpA',
				location: {
					lon: -87.6264703,
					lat: 41.8931452
				},
				id: 'e86cd1d6-2810-5052-b8ef-4de06aef24b1',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Pizzeria Due',
					id: 'f5e4a7de-73ac-5488-8be9-6046b15725bf'
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '312-943-2400',
							fax: '312-254-0390'
						},
						email: '',
						url: 'https://www.pizzeriaunodue.com/'
					}
				},
				status: 1,
				menuSpecialties: [
					{
						name: 'Cocktails',
						id: 361
					},
					{
						name: 'Pasta',
						id: 372
					},
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Sandwiches',
						id: 378
					},
					{
						name: 'Wings',
						id: 392
					}
				],
				distance: {
					span: '0.9244443204597445',
					units: 'mi'
				}
			},
			{
				amenities: {
					delivery: {
						url: 'https://www.pizzeriaunodue.com/'
					},
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {
						alcohol: [
							{
								name: 'Full-bar',
								id: 101
							}
						],
						happyHour: {
							details: 'Most Restaurants, 3pm -7pm and 9pm to close'
						}
					},
					onlineOrdering: {
						url: 'https://www.pizzeriaunodue.com/'
					},
					catering: {
						types: [
							{
								name: 'Self-Service',
								id: 1
							}
						],
						url: 'https://www.pizzeriaunodue.com/'
					},
					takeout: {
						url: 'https://www.pizzeriaunodue.com/'
					}
				},
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/3c8bf20b-8eeb-5a98-9543-0f742bfb5ea4/images/logo/logo.JPG'
					},
					cuisineImage: {
						altText: 'Deep-Dish-Chicago-Classic Pie',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/d97004d1-96ec-5055-9fdf-a4563c91b2f7/images/main/Deep-Dish-Chicago-Classic.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/3c8bf20b-8eeb-5a98-9543-0f742bfb5ea4/images/logo/logo.JPG',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/d97004d1-96ec-5055-9fdf-a4563c91b2f7/images/main/Deep-Dish-Chicago-Classic.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['29 East Ohio'],
					postalCode: '60611',
					state: {
						abbreviation: 'IL'
					}
				},
				specials: {
					offers: []
				},
				social: {
					twitter: 'https://twitter.com/unochicagogrill',
					facebook: 'https://www.facebook.com/UnoChicagoGrill/'
				},
				cuisine: {
					name: 'Pizza',
					id: 15
				},
				description:
					'It all began when Ike Sewell imagined a pizza unlike any other. Fresh dough with a tall edge, topped with homemade sauce and more cheese than you could believe. People have been lining up ever since.',
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Casual',
					id: 3
				},
				lastUpdate: {
					date: '2022-11-02T22:33:50.296Z'
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						},
						{
							name: 'AMEX',
							id: 1
						}
					]
				},
				name: 'Pizzeria Uno',
				googlePlacesId: 'ChIJ_2NJoa0sDogR4g520LwywQ4',
				location: {
					lon: -87.6269205,
					lat: 41.8922936
				},
				id: '3c8bf20b-8eeb-5a98-9543-0f742bfb5ea4',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Pizzeria Uno',
					id: 'd97004d1-96ec-5055-9fdf-a4563c91b2f7'
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '312-321-1000',
							fax: '312-321-1665'
						},
						email: '',
						url: 'https://www.pizzeriaunodue.com/'
					}
				},
				status: 1,
				menuSpecialties: [
					{
						name: 'Cocktails',
						id: 361
					},
					{
						name: 'Pasta',
						id: 372
					},
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Sandwiches',
						id: 378
					},
					{
						name: 'Wings',
						id: 392
					}
				],
				distance: {
					span: '0.8613804568140188',
					units: 'mi'
				}
			},
			{
				amenities: {
					entertainment: {
						alcohol: [
							{
								name: 'Full-bar',
								id: 101
							}
						],
						seating: [
							{
								name: 'Patio',
								id: 552
							}
						]
					},
					onlineOrdering: {
						url: 'https://jeffersontap.com/'
					},
					privateDining: {
						details: 'Up to 150 persons.'
					},
					takeout: {
						url: 'https://jeffersontap.com/'
					}
				},
				images: {
					logoImage: {
						altText: 'jtap logo',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/8292ee17-3a4a-54e1-a6ce-c19bb3ebe11a/images/logo/jtap.jpg'
					},
					cuisineImage: {
						altText: 'Bokampers wings',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/5c3bb08a-2499-5df5-8286-0236da5a167e/images/main/Bokamper Miramar wings.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/8292ee17-3a4a-54e1-a6ce-c19bb3ebe11a/images/logo/jtap.jpg',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/8292ee17-3a4a-54e1-a6ce-c19bb3ebe11a/images/main/Jefferson tap grill skillet[1].jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['325 North Jefferson Street'],
					postalCode: '60661',
					state: {
						abbreviation: 'IL'
					}
				},
				specials: {
					offers: []
				},
				social: {
					twitter: 'https://twitter.com/JeffersonTap1',
					facebook: 'https://www.facebook.com/jeffersontapandgrille/',
					instagram: 'https://www.instagram.com/jefferson_tap/'
				},
				cuisine: {
					name: 'American',
					id: 1
				},
				description:
					"Tucked away in Chicago's Fulton River District neighborhood since 2002, we are the perfect spot for a drink with friends, a cozy late-night dinner for two or a full blown celebration with all the bells and whistles. Enjoy fresh selections from our contemporary pub menu, weekly specials, seasonal menu, dog friendly patio and two full bars to keep your spirits high.",
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Casual',
					id: 3
				},
				lastUpdate: {
					date: '2023-02-22T17:18:28.814Z'
				},
				name: 'Jefferson Tap & Grille',
				googlePlacesId: 'ChIJxX302sgsDogRSGh85i6fXPk',
				location: {
					lon: -87.6426386,
					lat: 41.8875568
				},
				id: '8292ee17-3a4a-54e1-a6ce-c19bb3ebe11a',
				brand: {
					name: 'Jefferson Tap & Grille',
					id: '78c2d806-bdea-52be-9592-99ce84cfbac0'
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '312-648-0100',
							fax: ''
						},
						email: '',
						url: 'http://www.jeffersontap.com'
					}
				},
				status: 1,
				menuSpecialties: [
					{
						name: 'Pizza',
						id: 374
					}
				],
				distance: {
					span: '0.7438743790390704',
					units: 'mi'
				}
			},
			{
				amenities: {
					delivery: {
						types: [
							{
								name: '3rd Party',
								id: 202
							}
						],
						url: 'https://www.doordash.com/business/cosi-202779/'
					},
					general: [
						{
							name: 'Dine-in',
							id: 1
						}
					],
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {},
					onlineOrdering: {
						url: 'https://www.doordash.com/business/cosi-202779/'
					},
					catering: {
						url: 'https://catering.getcosi.com/'
					},
					takeout: {
						url: 'https://pickup.getcosi.com/'
					},
					menu: [
						{
							name: 'Online Menu',
							id: 302
						}
					]
				},
				keywords: '',
				specials: {
					offers: []
				},
				description:
					"Before there was Così, there was Drew Harré. At 17 he left West Aukland, New Zealand in search of the perfect sandwich. He was inspired by a friend who said to him one day “All I ever hear from you is that you can’t find a decent sandwich in Paris. So what are you going to do about it?” And so, the quest for the perfect sandwich began.\r\n\r\nAn amazing bread recipe was the first step. He had been told that behind the Piazza Nervona there was an old bakery where two brothers, Giuseppi and Silvano, were baking their family's flatbread, just as their father and his father before him had done. Drew became their apprentice, and before long, he became a veritable bread baking virtuoso. It was time to bring the flatbread to the French.\r\n\r\nll Drew needed now was a restaurant, a name and, well, some amazing sandwich ideas. Inspired by his travels, he translated his love of food into exotic combinations of fresh ingredients and herbs. And because you can't make authentic Italian bread without an authentic hearth, he had an Italian craftsman build him one. As for naming his bistro, Drew wanted a two-syllable word that reminded him of opera. Out of respect for his bread's Italian heritage, he decided to give the restaurant an Italian name. Then it came to him: Così fan Tutte, the opera. He'd call it Così for short. On May 1, 1989, he crossed his fingers and opened the doors of the first Così on rue de Seine. It became a much-loved spot, as it is to this day.",
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Fast Casual',
					id: 2
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						},
						{
							name: 'DISCOVER',
							id: 6
						},
						{
							name: 'AMEX',
							id: 1
						}
					]
				},
				googlePlacesId: 'ChIJp6pa2LosDogRIGZZAP3aw38',
				id: '16035522-3509-56ff-b94e-65af46a105ec',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Gluten-Sensitive',
						id: 13
					},
					{
						name: 'Healthy',
						id: 4
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Cosi',
					id: 'beae9c6e-a5c0-5dff-8a88-e1e3e1cd59a1'
				},
				menuSpecialties: [
					{
						name: 'Breakfast',
						id: 355
					},
					{
						name: 'Coffee',
						id: 362
					},
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Salads',
						id: 377
					},
					{
						name: 'Sandwiches',
						id: 378
					},
					{
						name: 'Soup',
						id: 383
					}
				],
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/beae9c6e-a5c0-5dff-8a88-e1e3e1cd59a1/images/logo/logo.jpg'
					},
					cuisineImage: {
						altText: 'sandwich',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/dbe49fb0-5049-5da0-8c35-ba127457f6f3/images/main/hootswings.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/beae9c6e-a5c0-5dff-8a88-e1e3e1cd59a1/images/logo/logo.jpg',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/brands/beae9c6e-a5c0-5dff-8a88-e1e3e1cd59a1/images/main/cosi.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['33 N. Dearborn Street'],
					postalCode: '60602',
					state: {
						abbreviation: 'IL'
					}
				},
				social: {
					twitter: 'https://twitter.com/get_cosi',
					facebook: 'https://www.facebook.com/getcosi',
					instagram: 'https://www.instagram.com/get_cosi/'
				},
				cuisine: {
					name: 'American',
					id: 1
				},
				lastUpdate: {
					date: '2023-02-07T22:07:39.303Z'
				},
				name: 'Cosi ',
				location: {
					lon: -87.631356,
					lat: 41.8829588
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '312-727-0290',
							fax: ''
						},
						email: '',
						url: 'http://www.getcosi.com'
					}
				},
				status: 1,
				distance: {
					span: '0.17997541844876633',
					units: 'mi'
				}
			},
			{
				amenities: {
					delivery: {
						types: [
							{
								name: '3rd Party',
								id: 202
							}
						],
						url: 'https://su-casa-mexican-restaurant.securebrygid.com/zgrid/proc/site/sitep.jsp'
					},
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {
						alcohol: [
							{
								name: 'Full-bar',
								id: 101
							}
						]
					},
					onlineOrdering: {
						url: 'https://su-casa-mexican-restaurant.securebrygid.com/zgrid/proc/site/sitep.jsp'
					},
					privateDining: {},
					venues: [
						{
							name: 'Semi-private Dining Events',
							id: 252
						}
					],
					takeout: {
						url: 'https://su-casa-mexican-restaurant.securebrygid.com/zgrid/proc/site/sitep.jsp'
					}
				},
				images: {
					logoImage: {
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/f698b27c-04d9-5f4c-a528-d748f31efb39/images/logo/logo.JPG'
					},
					cuisineImage: {
						altText: 'Moes steak burrito',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/brands/02e4dfb5-79a4-50d9-85a6-76163684e9f5/images/main/Moes-Steak-Burrito.jpg'
					},
					logo:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/f698b27c-04d9-5f4c-a528-d748f31efb39/images/logo/logo.JPG',
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/f698b27c-04d9-5f4c-a528-d748f31efb39/images/main/SuCasa chimichanga.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['49 East Ontario Street'],
					postalCode: '60611',
					state: {
						abbreviation: 'IL'
					}
				},
				specials: {
					offers: []
				},
				social: {
					facebook: 'https://www.facebook.com/SuCasaMexican/'
				},
				cuisine: {
					name: 'Mexican',
					id: 14
				},
				description:
					'Opened in the 1960s by Ike Sewell, better known for inventing ‘Chicago Style Deep-Dish Pizza’ at nearby Pizzeria Uno, we quickly became one of the most popular places for Mexican food in Chicago. The atmosphere is warm and inviting. Su Casa is truly a little slice of Mexico planted in the heart of a great city.\r\n\r\nWe are located in a converted 1867 carriage house, which adds plenty of character to your experience as you dine on traditional, Mexican fare. The burritos are not to be missed, and neither are the margaritas.',
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 0,
						categories: {}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Casual',
					id: 3
				},
				lastUpdate: {
					date: '2023-05-02T00:59:21.141Z'
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						},
						{
							name: 'AMEX',
							id: 1
						}
					]
				},
				name: 'Su Casa Mexican Cuisine',
				googlePlacesId: 'ChIJgYBP_awsDogRTo3IGNTb_sY',
				location: {
					lon: -87.626411781774,
					lat: 41.8933046655
				},
				id: 'f698b27c-04d9-5f4c-a528-d748f31efb39',
				dietaryPreferences: [
					{
						name: 'Gluten-free',
						id: 2
					},
					{
						name: 'Vegan/Vegetarian',
						id: 11
					}
				],
				brand: {
					name: 'Su Casa Mexican Cuisine',
					id: 'f7bcb55a-c358-5aa9-88ac-888fcc14397b'
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '617-323-9200',
							fax: ''
						},
						email: '',
						url: 'https://su-casa-mexican-restaurant.brygid.online/zgrid/proc/site/sitep.jsp'
					}
				},
				status: 1,
				menuSpecialties: [
					{
						name: 'Cocktails',
						id: 361
					},
					{
						name: 'Tacos',
						id: 388
					}
				],
				distance: {
					span: '0.9358577611974844',
					units: 'mi'
				}
			},
			{
				amenities: {
					general: [
						{
							name: 'Dine-in',
							id: 1
						}
					],
					attire: [
						{
							name: 'Casual',
							id: 151
						}
					],
					entertainment: {
						alcohol: [
							{
								name: 'Full-bar',
								id: 101
							}
						],
						seating: [
							{
								name: 'Al fresco',
								id: 551
							}
						]
					},
					privateDining: {
						details: '200'
					},
					venues: [
						{
							name: 'Semi-private Dining Events',
							id: 252
						}
					]
				},
				images: {
					cuisineImage: {
						altText: 'Mother Hubbards dirty totzz',
						url:
							'https://dinova-restaurants-prod.imgix.net/restaurants/locations/f973cdc0-efbc-5cf9-96b6-ba4c490d1c00/images/main/Mother Hubbards Sports Pub dirty totzz.jpg'
					},
					main:
						'https://dinova-restaurants-prod.imgix.net/restaurants/locations/f973cdc0-efbc-5cf9-96b6-ba4c490d1c00/images/main/Mother Hubbards Sports Pub dirty totzz.jpg'
				},
				address: {
					country: {
						iso: 'US'
					},
					city: 'Chicago',
					street: ['5 W Hubbard St'],
					postalCode: '60654',
					state: {
						abbreviation: 'IL'
					}
				},
				specials: {
					offers: []
				},
				social: {
					facebook: 'https://www.facebook.com/MHSPChicago/',
					instagram: 'https://www.instagram.com/motherhubbards/'
				},
				cuisine: {
					name: 'American',
					id: 1
				},
				description:
					"Mother Hubbard's Sports Pub, a gathering place for locals and tourists alike for over 20 years and counting. We’ve been voted by Restaurant Guru as a top restaurant three years running (2019-2021)! More satellite feeds and more TVs! Yes, you can get your game by your table. Really! Just ask.",
				source: {
					name: 'Dinova',
					id: 0
				},
				networks: [
					{
						name: 'Mastercard',
						id: 1
					},
					{
						name: 'Chase',
						id: 2
					},
					{
						name: 'Citi',
						id: 3
					}
				],
				recommendations: {
					positive: {
						total: 1,
						categories: {
							'1': {
								total: 1
							},
							'2': {
								total: 1
							},
							'3': {
								total: 1
							},
							'4': {
								total: 1
							},
							'6': {
								total: 1
							},
							'8': {
								total: 1
							},
							'9': {
								total: 1
							},
							'10': {
								total: 1
							},
							'14': {
								total: 1
							},
							'16': {
								total: 1
							},
							'30': {
								total: 1
							},
							'32': {
								total: 1
							}
						}
					}
				},
				safetyMeasures: {},
				segment: {
					name: 'Casual',
					id: 3
				},
				lastUpdate: {
					date: '2023-03-23T23:42:29.411Z'
				},
				paymentMethods: {
					creditCards: [
						{
							name: 'AMEX',
							id: 1
						},
						{
							name: 'VISA',
							id: 2
						},
						{
							name: 'MC',
							id: 3
						}
					]
				},
				name: "Mother Hubbard's Sports Pub",
				googlePlacesId: 'ChIJZ6EtAq4sDogR1HFg0MbZe6g',
				location: {
					lon: -87.6283519649648,
					lat: 41.8899345123398
				},
				id: 'f973cdc0-efbc-5cf9-96b6-ba4c490d1c00',
				brand: {
					name: "Mother Hubbard's Sports Pub",
					id: 'f973cdc0-efbc-5cf9-96b6-ba4c490d1c00'
				},
				integrations: {
					zuppler: {
						id: null
					}
				},
				contacts: {
					general: {
						numbers: {
							main: '312-828-0007',
							fax: ''
						},
						email: '',
						url: 'https://motherhubbardschicago.com/'
					}
				},
				status: 1,
				menuSpecialties: [
					{
						name: 'Hot dogs',
						id: 369
					},
					{
						name: 'Pizza',
						id: 374
					},
					{
						name: 'Sandwiches',
						id: 378
					},
					{
						name: 'Wings',
						id: 392
					}
				],
				distance: {
					span: '0.6842833471317376',
					units: 'mi'
				}
			}
		],
		page: {
			total: 8,
			start: 1,
			end: 8,
			count: 8
		},
		response: {
			code: 200,
			msg: 'Success'
		}
	}
};

export default onboardingData;
