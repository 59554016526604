const MuiFormHelperTextTheme = {
	styleOverrides: {
		root: {
			fontSize: '14px',
			color: '#4d4c48' // $black-text
		}
	}
};

export default MuiFormHelperTextTheme;
