import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
/*
interface UseUserPasswordMutationProps {}
*/

const useUserPasswordMutation = (
	{ onError, onSuccess, setErrorInRedux, setDataInRedux, setReduxIsLoading } = {} /*:UseUserPasswordMutationProps*/
) => {
	const mutation = useMutation({
		mutationFn: changedPassword => {
			return axios.put(`${basePath()}/users/password`, JSON.stringify(changedPassword), AUTH_REQUEST_HEADER());
		},
		onMutate: () => {
			setReduxIsLoading && setReduxIsLoading(true, 'Updating password...');
		},
		onError: (error /*, variables, context*/) => {
			// console.log('%c error', 'color: orange', error);
			// An error happened!
			if (setErrorInRedux) {
				setErrorInRedux(error);
			}

			onError && onError(error);
		},
		onSuccess: (data /*, variables, context*/) => {
			if (data.data.errors) {
				onError && onError(data);
			} else {
				onSuccess && onSuccess(data);

				if (setDataInRedux) {
					setDataInRedux(data);
				}
			}
		},
		onSettled: () => {
			setReduxIsLoading && setReduxIsLoading(false);
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		updateUserPassword: mutation.mutate
	};
};

export default useUserPasswordMutation;
