import SearchModify from './ui-component';
import withSizes from 'react-sizes';
import { isLV, isSV } from 'utils/sizes';

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes),
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(SearchModify);
