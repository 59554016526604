import RecommendationEndorsement from './ui-component';

import withSizes from 'react-sizes';
import { isSV /*, isMV, isLV*/ } from 'utils/sizes';

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(RecommendationEndorsement);
