import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Card } from 'components/organisms';
import { getLowerCasedParams } from 'utils/url';

class Cards extends Component {
	componentDidMount = () => {
		this.initDefaultTab();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.location.search !== this.props.location.search) {
			const params = getLowerCasedParams();
			if (params.tab) {
				this.initDefaultTab();
			} else {
				this.props.setActiveCard();
			}
		}
	};

	tabs = {
		business: '0',
		catering: '1',
		dineassist: '2',
		'0': 'business',
		'1': 'catering',
		'2': 'dineassist'
	};

	initDefaultTab = () => {
		const params = getLowerCasedParams();
		const me = this;
		if (params.tab) {
			// use a time out here to allow redux enough time to finish updating before dispatching setActiveCard
			setTimeout(() => {
				me.props.setActiveCard(me.tabs[params.tab]);
			}, 10);
		} else {
			if (me.props.activeCard && params.tab !== me.tabs[me.props.activeCard]) {
				me.props.history.replace(`/?tab=${me.tabs[me.props.activeCard]}`);
			} else {
				return;
			}
		}
	};

	buildCardsFromLangFile = () => {
		const { translate } = this.props;
		const count = translate('landingPage.cards.count');

		let cardEls = [];
		for (let i = 0; i < count; i++) {
			const cardKey = translate(`landingPage.cards.cards.${i}.cardKey`);
			const ariaHidden = this.props.activeCard === undefined ? false : this.props.activeCard != i;
			cardEls.push(<Card key={`card-${cardKey}`} index={`${i}`} ariaHidden={ariaHidden} />);
		}

		return cardEls;
	};

	render() {
		const { activeCard } = this.props;

		return (
			<div className={classnames('cards', activeCard && 'has-active', activeCard && activeCard)} data-cy="cards">
				{this.buildCardsFromLangFile()}
			</div>
		);
	}
}

Cards.defaultProps = {
	cards: []
};

Cards.propTypes = {
	// cards: PropTypes.object.isRequired,
	activeCard: PropTypes.string,
	/* eslint-disable react/no-unused-prop-types */
	// used as me.props.history so this prop type fails
	history: PropTypes.object.isRequired, // react-router withRouter prop
	// used in as me.setActiveCard so this prop type fails
	setActiveCard: PropTypes.func.isRequired,
	/* eslint-enable react/no-unused-prop-types */
	translate: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired // react-router withRouter prop
};

export default Cards;
