import React /*{ PropsWithChildren }*/ from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { Box } from '@mui/material';

// interface RowProps extends PropsWithChildren {
//   align?: "center";
//   fullWidth?: boolean;
//   gap?: number;
// }

const Row = (
	{ align = 'center', children, fullHeight, fullWidth, gap, justify, style, wrap = true, ...rowProps } /*: RowProps*/
) => {
	return (
		<Box
			className={classnames(rowProps.extraClassName, styles.row, {
				[`${styles[`align-${align}`]}`]: align,
				[styles.fullWidth]: fullWidth,
				[styles.fullHeight]: fullHeight,
				[`${styles[`justify-${justify}`]}`]: justify
			})}
			style={{ ...style, gap, wrap: wrap ? 'wrap' : 'nowrap' }}
			{...rowProps}
		>
			{children}
		</Box>
	);
};

Row.propTypes = {
	align: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'baseline']),
	children: PropTypes.node,
	fullHeight: PropTypes.bool,
	fullWidth: PropTypes.bool,
	gap: PropTypes.number,
	justify: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
	style: PropTypes.object,
	wrap: PropTypes.bool
};

export default Row;
