import React, { useCallback, useEffect } from 'react';
import { BasicModal } from 'components/molecules';
import { Text, Title } from 'components/atoms';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useForceShowPointsEligibility, useInitCompletedOnboardingSteps, useTranslate } from 'hooks';

// import './style.scss';

const PointsElegibilityModal = ({ onClose, show }) => {
	const translate = useTranslate();
	useInitCompletedOnboardingSteps();
	useForceShowPointsEligibility();

	// componentDidMount
	useEffect(() => {
		window.addEventListener('keypress', handleKeyPress);

		// componentWillUnmount
		return () => {
			window.removeEventListener('keypress', handleKeyPress);
		};
	}, [handleKeyPress]);

	const handleKeyPress = useCallback(
		e => {
			if (show && e.key === 'Enter') {
				onClose();
			}
		},
		[onClose, show]
	);

	return (
		<BasicModal
			show={show}
			onClose={onClose}
			className="points-elegibility-modal"
			dataCy="points-eligibility-modal"
			center
		>
			<div className="points-elegibility-content-wrapper">
				<Title size="h3">{translate('points-eligibility-modal.header')}</Title>
				<Text weight="bold" size="sm" align="center">
					{translate('points-eligibility-modal.sub-header')}
				</Text>
				<Text size="sm">{translate('points-eligibility-modal.content')}</Text>
				<Button color="primary" onClick={onClose} data-cy="close">
					OKAY
				</Button>
			</div>
		</BasicModal>
	);
};

PointsElegibilityModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired
};

export default PointsElegibilityModal;
