import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuthState } from 'hooks';

// interface useAuthContextProps {}
const useAuthContextValue = (/*:useAuthContextProps*/) => {
	const authState = useAuthState();

	return authState;
};

const AuthContext = createContext({
	idToken: null,
	isAdminUser: false,
	isAuthed: false,
	errorCode: null,
	lastLogin: null,
	permissions: [],
	refreshingUser: false
});

const AuthContextProvider = ({ children }) => {
	const value = useAuthContextValue();

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node
};

const useAuthContext = () => {
	const context = useContext(AuthContext);

	return context;
};

const AuthContextConsumer = AuthContext.Consumer;

export { AuthContextConsumer, AuthContextProvider };
export default useAuthContext;
