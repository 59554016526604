import Layout from './ui-component';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { toggleMobileNav, setAndroid, setMinimalLayout } from 'actions/viewport';

const mapStateToProps = state => ({
	androidVersion: state.viewport.androidVersion,
	minimalLayout: state.viewport.minimalLayout,
	currentLanguage: getActiveLanguage(state.locale).code,
	IMAGE_BASE_URL: state.config.imageBaseUrl,
	isBusy: state.loader.isBusy,
	loadingMessage: state.loader.message,
	mobileNavOpen: state.viewport.mobileNavOpen,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	toggle: () => dispatch(toggleMobileNav()),
	setAndroid: () => dispatch(setAndroid()),
	setMinimalLayout: () => dispatch(setMinimalLayout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
