// "babel-core": "^6.26.3",
import selectFilter from 'utils/search_new/utils/selectFilter';
import { deselectFilter } from 'utils/search_new/utils';
import filterRestaurants from 'utils/search_new/utils/filterRestaurants';
import updateFiltersFromURLParams from 'utils/search_new/utils/updateFiltersFromURLParams';
import updateSavedFiltersArray from 'utils/search_new/utils/updateSavedFiltersArray';
import { buildRestaurantFilters } from 'utils/search_new/utils';
import updateURLSearchParams from 'utils/search_new/utils/updateURLSearchParams';
/**
 * Some filters share filter categories.  Ex: amenities.Private Dining and recommendations.Private Dining.
 * Use this func to transform the name shown in the UI into a unique name.
 * @param  {[string]} dataAccr the filter data accr. Ex: recommendtaions.Catering
 * @return {[string|boolean]}  returns false if special label is not present, returns special label if is present.
 */
export const getSpecialFilterLabel = dataAccr => {
	const specialFilters = {
		'recommendations.Catering': 'Recommendations - Catering',
		'recommendations.Private Dining': 'Recommendations - Private Dining'
	};
	return specialFilters[dataAccr] ? specialFilters[dataAccr] : false;
};

export {
	buildRestaurantFilters,
	deselectFilter,
	filterRestaurants,
	selectFilter,
	updateFiltersFromURLParams,
	updateSavedFiltersArray,
	updateURLSearchParams
};
