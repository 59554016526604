const MuiFormLabelTheme = {
	styleOverrides: {
		root: {
			'&.Mui-focused': {
				color: '#4d4c48' // $black-text
			}
		},
		asterisk: {
			color: '#e10600' // $danger-red
		}
	}
};

export default MuiFormLabelTheme;
