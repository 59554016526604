import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { Button } from 'reactstrap';
import { ModalPage } from 'components/molecules';
import { useTranslate } from 'hooks';

/**
 * [COVIDRestaurantUniquePrecautions description]
 */
const COVIDRestaurantUniquePrecautions = ({ modal, otherSteps, setModal, toggleModal }) => {
	const translate = useTranslate();
	const showMore = useCallback(() => {
		toggleModal(true);
	}, [toggleModal]);

	useEffect(() => {
		if (otherSteps) {
			setModal({
				id: 'covid-learn-more',
				header: translate('Details.covid.learnMoreModal.header'),
				body: (
					<Text weight={'thin'} className="preserve-text">
						{otherSteps}
					</Text>
				),
				primaryButtonText: 'close',
				secondaryButtonText: false
			});
		}
	}, [otherSteps, setModal, translate]);

	return otherSteps ? (
		<React.Fragment>
			<ModalPage isOpen={modal.isOpen} closeOnOutsideClick={true} />
			<div className="COVIDRestaurantUniquePrecautions flex">
				<Text weight="bold" className="mb-0">
					{translate('Details.covid.info')}
					<Button className="more-button bold ml-2 static" onClick={showMore}>
						{translate('Details.covid.learn-more')}
					</Button>
				</Text>
			</div>
		</React.Fragment>
	) : null;
};

/**
 * [propTypes description]
 * @interface COVIDRestaurantUniquePrecautionsProps
 *
 * @example {}
 */
COVIDRestaurantUniquePrecautions.propTypes = {
	modal: PropTypes.object.isRequired,
	otherSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired
};

export default COVIDRestaurantUniquePrecautions;
