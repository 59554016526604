import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({
	className,
	align,
	children,
	dataCy,
	dangerouslySetInnerHTML,
	onClick,
	paragraph,
	size,
	style,
	transform,
	weight,
	dataTag,
	dataValue
}) => {
	const dataTags = [];
	if (dataTag != '' && dataValue != '') {
		dataTags['data-' + dataTag] = dataValue;
	}

	const classProps = classNames(
		'text',
		paragraph && 'd-block',
		align && `text-${align}`,
		className,
		size && size,
		weight && weight,
		transform && `text-${transform}`
	);

	return dangerouslySetInnerHTML ? (
		<p
			className={classProps}
			onClick={onClick}
			data-cy={dataCy}
			dangerouslySetInnerHTML={dangerouslySetInnerHTML}
			style={style}
			{...dataTags}
		/>
	) : (
		<p className={classProps} onClick={onClick} data-cy={dataCy} style={style} {...dataTags}>
			{children}
		</p>
	);
};

Text.defaultProps = {
	align: null,
	className: '',
	children: '',
	paragraph: false,
	size: 'md',
	style: null,
	transform: 'none',
	weight: 'normal'
};

Text.propTypes = {
	align: PropTypes.oneOf(['left', 'center', 'right']),
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	dataCy: PropTypes.string,
	dangerouslySetInnerHTML: PropTypes.shape({ __html: PropTypes.string }),
	onClick: PropTypes.func,
	paragraph: PropTypes.bool,
	size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
	style: PropTypes.object,
	transform: PropTypes.oneOf(['none', 'capitalize', 'lowercase', 'uppercase', 'italic']),
	weight: PropTypes.oneOf(['bold', 'normal', 'thin']),
	dataTag: PropTypes.string,
	dataValue: PropTypes.string
};

export default Text;
