import {
	cardIdValidator,
	creditCardNumberValidator,
	lengthValidator,
	nameOnCardValidator
} from 'utils/form/validators';
import { hasProp } from 'utils/object';
import { getInternalConnectionType } from 'utils/card';

const validateConnectToPointsForm = ({ createError, isDoDMode = false, translate }) => values => {
	const idType = getInternalConnectionType(values.selectedCard);
	const fullNumberRequired =
		values.selectedCard?.fullNumberRequired === undefined ? true : values.selectedCard?.fullNumberRequired;
	const errors = {};

	// Selected Card Validate
	if (!values.selectedCard) {
		errors.selectedCard = translate(`ConnectToPoints.inputs.creditcardType.error`);
	}

	// Card Name Validate
	if (!isDoDMode) {
		let nameOnCardError = nameOnCardValidator(values.nameOnCard);
		if (nameOnCardError) {
			errors.nameOnCard = nameOnCardError;
		}
	} else {
		let firstNamePassesLength = lengthValidator(values.firstName, 1);
		if (!firstNamePassesLength) {
			errors.firstName = translate(`ConnectToPoints.inputs.firstName.errorText`);
		}

		let lastNamePassesLength = lengthValidator(values.lastName, 1);
		if (!lastNamePassesLength) {
			errors.lastName = translate(`ConnectToPoints.inputs.lastName.errorText`);
		}
	}

	// Card Number Validate
	const card = hasProp(values, 'selectedCard') ? values.selectedCard : {};
	card.fullNumberRequired = fullNumberRequired;
	let cardNumberError = creditCardNumberValidator(card, values.cardNumber);
	if (createError) {
		errors.cardNumber = translate('ConnectToPoints.inputs.creditcard.connectionExists');
	} else if (cardNumberError) {
		errors.cardNumber = translate(cardNumberError);
	}

	// Card ID Validate
	if (idType === 'email') {
		let emailError = cardIdValidator(values.email, 'email');
		if (emailError) {
			errors.email = emailError;
		}
	} else if (idType === 'empID') {
		let employeeIdError = cardIdValidator(values.employeeId, 'empID');
		if (employeeIdError) {
			errors.employeeId = employeeIdError;
		}
	}

	return errors;
};

export default validateConnectToPointsForm;
