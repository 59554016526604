const MuiAutocompleteTheme = {
	styleOverrides: {
		endAdornment: {
			'& .MuiSvgIcon-root': {
				color: '#4d4c48', // $black-text
				height: 24,
				width: 24
			}
		},
		paper: {
			border: 'solid #1c871c 3px', // $dinova-green
			borderRadius: '0 0 5px 5px'
		},
		popper: {
			top: '-3px !important'
		},
		loading: {
			fontWeight: 700
		},
		listbox: {
			padding: 0
		},
		noOptions: {
			fontWeight: 700
		},
		option: {
			padding: '15px !important',
			fontWeight: 700,
			fontSize: 16,
			backgroundColor: 'transparent',
			'&[aria-selected=true]': {
				backgroundColor: '#1c871c !important', // $dinova-green
				color: 'white !important'
			},

			'&.Mui-focused': {
				backgroundColor: '#1c871c !important', // $dinova-green
				color: 'white !important',

				'&[aria-selected=true]': {
					backgroundColor: '#1c871c !important', // $dinova-green
					color: 'white !important'
				}
			}
		}
	}
};

export default MuiAutocompleteTheme;
