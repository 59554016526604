import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Text } from 'components/atoms';
import PropTypes from 'prop-types';
import StepTemplate from '../../StepTemplate';
import { CheckboxInput } from 'components/molecules';
import { useUserMutation } from 'reactQuery/mutations';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';
import FormServerError from '../../FormServerError';

const MODAL_TRANSLATE_KEY = 'ProfileSetup.modals.step1';
const FORM_TRANSLATE_KEY = `${MODAL_TRANSLATE_KEY}.form.fields`;

const CompleteProfile = ({
	completeStep,
	doNotPlanCateringOrEvents,
	doesOrderCatering,
	doesSchedulePrivateEvents,
	updateForm
}) => {
	const [error, setError] = useState(null);
	const translate = useTranslate();
	const { updateUser } = useUserMutation({
		onSuccess: () => {
			completeStep();
		},
		onError: e => {
			setError(e.data.errors[0] || e.status);
		}
	});
	const { user } = useUserContext();

	const handleChange = useCallback(
		({ name, checked }) => {
			updateForm({ field: name, value: !checked });
		},
		[updateForm]
	);

	const CHECKBOXES = useMemo(
		() => [
			{
				checked: doesOrderCatering,
				label: translate(`${FORM_TRANSLATE_KEY}.doesOrderCatering.label`),
				name: 'doesOrderCatering'
			},
			{
				checked: doesSchedulePrivateEvents,
				label: translate(`${FORM_TRANSLATE_KEY}.doesSchedulePrivateEvents.label`),
				name: 'doesSchedulePrivateEvents'
			},
			{
				checked: doNotPlanCateringOrEvents,
				label: translate(`${FORM_TRANSLATE_KEY}.doNotPlanCateringOrEvents.label`),
				name: 'doNotPlanCateringOrEvents'
			}
		],
		[doesOrderCatering, doesSchedulePrivateEvents, doNotPlanCateringOrEvents, translate]
	);

	const doCompleteStep = useCallback(() => {
		const checkedItems = CHECKBOXES.reduce((result, checkbox) => {
			if (checkbox.checked) {
				if (checkbox.name === 'doesOrderCatering') {
					return [...result, { id: '1' }];
				} else if (checkbox.name === 'doesSchedulePrivateEvents') {
					return [...result, { id: '2' }];
				}
			}
			return result;
		}, []);

		updateUser({ diningTypes: checkedItems });
	}, [CHECKBOXES, updateUser]);

	const formIsValid = doesOrderCatering || doesSchedulePrivateEvents || doNotPlanCateringOrEvents;

	useEffect(() => {
		if (user.diningTypes.some(x => x.id === 1)) {
			updateForm({ field: 'doesOrderCatering', value: true });
		}
		if (user.diningTypes.some(x => x.id === 2)) {
			updateForm({ field: 'doesSchedulePrivateEvents', value: true });
		}
		if (user.diningTypes.count === 0) {
			updateForm({ field: 'doNotPlanCateringOrEvents', value: true });
		}
	}, [updateForm, user]);

	return (
		<StepTemplate
			dataCy="complete-profile"
			title={translate(`${MODAL_TRANSLATE_KEY}.title`)}
			onSubmit={doCompleteStep}
			submitDisabled={!formIsValid}
		>
			<div className={styles.content}>
				<div className={styles.formError}>
					<FormServerError error={error} />
				</div>
				<Text weight="300" className={styles.body}>
					{translate(`${MODAL_TRANSLATE_KEY}.body`)}
				</Text>
				<div>
					{CHECKBOXES.map(checkbox => (
						<CheckboxInput
							key={checkbox.label}
							className={styles.checkbox}
							checked={checkbox.checked}
							onChange={handleChange}
							option={{
								label: checkbox.label,
								checked: checkbox.checked,
								name: checkbox.name
							}}
						/>
					))}
				</div>
			</div>
		</StepTemplate>
	);
};

CompleteProfile.propTypes = {
	completeStep: PropTypes.func,
	doNotPlanCateringOrEvents: PropTypes.bool,
	doesOrderCatering: PropTypes.bool,
	doesSchedulePrivateEvents: PropTypes.bool,
	updateForm: PropTypes.func
};

export default CompleteProfile;
