const formFieldRequirements = (translate, errors) => {
	const newPasswordErrors = errors.newPassword || [];

	return {
		title: translate('Registration.requirement-boxes.password.title'),
		requirements: [
			{
				type: 'length',
				characters: 10,
				status: newPasswordErrors.includes('length') ? 'invalid' : 'valid',
				text: translate('Registration.requirement-boxes.password.1')
			},
			{
				type: 'contains_number',
				status: newPasswordErrors.includes('contains_number') ? 'invalid' : 'valid',
				text: translate('Registration.requirement-boxes.password.2')
			},
			{
				type: 'contains_lowercase',
				status: newPasswordErrors.includes('contains_lowercase') ? 'invalid' : 'valid',
				text: translate('Registration.requirement-boxes.password.3')
			},
			{
				type: 'contains_uppercase',
				status: newPasswordErrors.includes('contains_uppercase') ? 'invalid' : 'valid',
				text: translate('Registration.requirement-boxes.password.4')
			},
			{
				type: 'contains_special',
				status: newPasswordErrors.includes('contains_special') ? 'invalid' : 'valid',
				text: translate('Registration.requirement-boxes.password.5')
			}
		],
		passwordIsVisible: true
	};
};

export default formFieldRequirements;
