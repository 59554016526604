import CompleteProfile from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { updateOnboardingStatus } from 'actions/users';
import { ProfileSetupSteps } from 'reducers/profile_setup';

const mapDispatchToProps = dispatch => ({
	completeStep: doEmail => {
		dispatch(completeStep(doEmail ? ProfileSetupSteps.EMAILS : ProfileSetupSteps.EMAILS_NOT_READY));
		const onboardingStatus = { optInNotification: doEmail };
		dispatch(updateOnboardingStatus({ onboardingStatus }));
	},
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default connect(null, mapDispatchToProps)(CompleteProfile);
