import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { hasProp } from 'utils/object';
import { cloneDeep, merge } from 'lodash';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserMutationProps {}
*/

const useUserMutation = (
	{ quietLoading, onError, onSuccess, setErrorInRedux, setDataInRedux } = {} /*:useUserMutationProps*/
) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const rollBackMutation = useCallback(() => {
		queryClient.setQueryData([USER_QUERY_KEY], old => old);
	}, [queryClient]);

	const mutation = useMutation({
		mutationFn: userDetails => {
			if (hasProp(userDetails, 'diningTypes')) {
				userDetails.diningTypes = userDetails.diningTypes.map(id => {
					if (id?.id) {
						return id;
					}
					return {
						id
					};
				});
			}

			if (hasProp(userDetails, 'phone.mobile')) {
				const number = userDetails.phone.mobile;
				if (number.slice(0, 2) === '1 ') {
					userDetails.phone.mobile = number.slice(2);
				}
			}
			return axios.patch(`${basePath()}/users`, JSON.stringify(userDetails), AUTH_REQUEST_HEADER());
		},
		onMutate: async variables => {
			if (!quietLoading) {
				dispatch(setIsLoading(true, 'Updating User...'));
			}
			// Cancel current queries for the user
			await queryClient.cancelQueries({ queryKey: [USER_QUERY_KEY] });

			// Create optimistic user
			const optimisticUser = cloneDeep(variables);

			if (hasProp(optimisticUser, 'preferences.notification')) {
				Object.entries(optimisticUser.preferences.notification).forEach(([key, value]) => {
					if (!value.value) {
						optimisticUser.preferences.notification[key] = { value };
					}
				});
			}

			// Update user with optimistic user
			queryClient.setQueryData([USER_QUERY_KEY], currentUser => {
				return merge(currentUser, optimisticUser);
			});
			// Return context with the optimistic todo
			return { optimisticUser };
		},
		onError: (error, variables, context) => {
			// An error happened!
			if (!quietLoading) {
				dispatch(setIsLoading(false));
			}
			// Update cache with old user data from before error
			rollBackMutation();

			if (setErrorInRedux) {
				setErrorInRedux(error);
			}

			onError && onError(error);
			console.log(`rolling back optimistic update with id ${context.id}`);
		},
		onSuccess: (data /*variables, context*/) => {
			if (!quietLoading) {
				dispatch(setIsLoading(false));
			}
			if (data.data.errors) {
				rollBackMutation();

				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });

				queryClient.setQueryData([USER_QUERY_KEY], () => data.data.user);
				// END - Update cache with new user data

				onSuccess && onSuccess(data);
				// Boom baby!
				if (setDataInRedux) {
					setDataInRedux(data);
				}
			}
		}
		// onSettled: (data, error /*variables, context*/) => {
		// if (data.errors) {
		// 	throw new Error(data.errors);
		// 	// rollBackMutation();
		// }
		// console.log('%c settled', 'color: orange', data, error);
		// Error or success... doesn't matter!
		// }
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		updateUser: mutation.mutate
	};
};

export default useUserMutation;
