import { useQuery } from './';
import { basePath } from 'actions/utils';

export const USER_REWARDS_QUERY_KEY = 'user_rewards';

const useUserRewardsQuery = ({ setReduxIsLoading, setDataInRedux } = {}) => {
	const url = `${basePath()}/users/rewards`;

	return useQuery({
		// Req
		queryHandler: res => res.data.rewards,
		queryKey: USER_REWARDS_QUERY_KEY,
		url,

		// Opt
		// quietLoading: boolean,
		reduxLoadingMsg: 'Loading Rewards...',
		reduxQueryDataAccr: query => query.data.rewards,
		requiresAuth: true,
		setDataInRedux,
		setReduxIsLoading
		// urlBuilder: (queryKey) => string
	});
};

export default useUserRewardsQuery;
