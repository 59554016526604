import qs from 'query-string';

const updateURLSearchParams = (filters, props) => {
	let filterParams = Object.entries(
		filters.reduce((accum, f) => {
			let masterKey = f.filterKey.split('.')[0];
			let subKey = f.filterKey
				.split('.')
				.slice(1)
				.join('.');

			if (accum[masterKey]) {
				accum[masterKey].push(subKey);
			} else {
				accum[masterKey] = [subKey];
			}

			return accum;
		}, {})
	).reduce((accum, [key, value]) => {
		return {
			...accum,
			[key]: value.join(',')
		};
	}, {});

	let searchPageParams = {};
	// props = { center, near, location, keywords, zoom }
	Object.entries(props).forEach(([key, value]) => {
		if (value) {
			if (key === 'location' || key === 'center') {
				if (!(value.lat === undefined) && !(value.lng === undefined)) {
					searchPageParams[key] = `${value.lat},${value.lng}`;
				}
			} else {
				searchPageParams[key] = value;
			}
		}
	});

	let queryParams = Object.assign({}, filterParams, searchPageParams);
	let urlParams = qs.stringify(queryParams);

	return urlParams;
};

export default updateURLSearchParams;
