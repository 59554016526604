import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PageHeaderTitle } from 'components/atoms';

const Body = ({
	className = '',
	minWidth,
	maxWidth = 1440,
	leftPageHeaderLinks,
	title,
	help,
	rightPageHeaderLinks,
	bodyContent
}) => {
	const componentClasses = classNames('container', className);
	const style = { minWidth: minWidth, maxWidth: maxWidth, justifyContent: 'center' };

	return (
		<div className={componentClasses}>
			<div className="row">
				<div className="col">
					<header className="header-bar" style={style}>
						{leftPageHeaderLinks?.props?.children && <div className="col-2">{leftPageHeaderLinks}</div>}
						<div className={'col-7'}>
							<PageHeaderTitle>{title}</PageHeaderTitle>
						</div>
						{help && <div className="col-2 help">{help}</div>}
						{rightPageHeaderLinks && <div className="col-2">{rightPageHeaderLinks}</div>}
					</header>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="body" style={style}>
						{bodyContent}
					</div>
				</div>
			</div>
		</div>
	);
};

Body.propTypes = {
	className: PropTypes.string,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
	leftPageHeaderLinks: PropTypes.node,
	title: PropTypes.node,
	help: PropTypes.node,
	rightPageHeaderLinks: PropTypes.node,
	bodyContent: PropTypes.node
};

export default Body;
