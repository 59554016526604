import React from 'react';
import { Radios /*CheckboxData,*/ } from 'mui-rff';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classnames from 'classnames';
/*
interface CheckboxesNewProps {

}
*/
const CheckedIcon = () => {
	return <div className={classnames(styles.radio, styles.checkedIcon)} />;
};

const UnCheckedIcon = () => {
	return <div className={classnames(styles.radio, styles.unCheckedIcon)} />;
};

const RadiosNew = ({ label, name, required, data, ...others } /*: CheckboxesNewProps*/) => {
	return (
		<Radios
			label={label}
			name={name}
			required={required}
			data={data}
			icon={<UnCheckedIcon />}
			checkedIcon={<CheckedIcon />}
			{...others}
			sx={classnames(styles.radio)}
			radioGroupProps={{
				className: styles.radioGroup,
				row: true
			}}
		/>
	);
};

RadiosNew.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	)
};

export default RadiosNew;
