const MuiMenuItemTheme = {
	styleOverrides: {
		root: {
			// padding: '15px !important',
			fontWeight: 400,
			fontSize: 18,
			backgroundColor: 'white !important',
			'&[aria-selected=true]': {
				// 	backgroundColor: '#1c871c !important', // $dinova-green
				backgroundColor: '#f2f0ef !important' //'white !important'
			},

			'&:hover': {
				backgroundColor: '#f2f0ef !important' //'#1c871c !important', // $dinova-green
			},

			'&.Mui-focused': {
				backgroundColor: '#f2f0ef !important' //'#1c871c !important', // $dinova-green
				// color: 'white !important',

				// '&[aria-selected=true]': {
				// backgroundColor: '#1c871c !important', // $dinova-green
				// color: 'white !important'
				// }
			}
		}
	}
};

export default MuiMenuItemTheme;
