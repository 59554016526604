import * as t from './types';
import { history } from 'utils/router';

export const setActiveCard = (index, cardURL) => {
	if (index && cardURL) {
		history.replace(`/?tab=${cardURL}`);
	} else if (!index) {
		history.replace(`/`);
	}
	return {
		type: t.SET_ACTIVE_CARD,
		payload: index
	};
};

export const setShowAbout = (showAbout, cardId) => {
	return {
		type: t.SET_SHOW_ABOUT,
		payload: { showAbout, cardId }
	};
};
