import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from 'actions/modal';
import { useTranslate } from 'hooks';

// import PropTypes from 'prop-types'

/*
interface ConfirmDiscardModalProps {

}
*/

const useConfirmDiscardModal = (/*: ConfirmDiscardModalProps*/) => {
	const dispatch = useDispatch();
	const translate = useTranslate();

	const initUnsavedChangesModal = useCallback(() => {
		dispatch(
			setModal({
				id: 'unsaved-changes-modal',
				header: translate('ConnectToPoints.modals.unsavedChanges.header'),
				body: translate('ConnectToPoints.modals.unsavedChanges.body'),
				primaryButtonText: translate('ConnectToPoints.modals.unsavedChanges.primaryButtonText'),
				secondaryButtonText: translate('ConnectToPoints.modals.unsavedChanges.secondaryButtonText')
			})
		);
	}, [dispatch, translate]);

	useEffect(() => {
		initUnsavedChangesModal();
	}, [initUnsavedChangesModal]);
};

// useConfirmDiscardModal.propTypes = {

// }

export default useConfirmDiscardModal;
