/* eslint-disable no-case-declarations */
import * as t from '../actions/company_select/types';

/**
 * Redux_CompanyState
 * @interface Redux_CompanyState
 * @property {object}  [selected={}]
 * @property {array} [suggestions=[]]
 * @property {boolean} [isLoading=false]
 * @property {boolean} [hasError=false]
 * @property {string} [errorText='']
 */
const companySelectDefaultState = {
	errorText: '',
	hasError: false,
	isLoading: false,
	search: '',
	selected: {},
	suggestions: [],
	value: ''
};

export default (state = companySelectDefaultState, action) => {
	switch (action.type) {
		case t.SET_SEARCH_COMPANY_VALUE: {
			return {
				...state,
				search: action.payload
			};
		}
		case t.FETCH_COMPANIES_START:
			return {
				...companySelectDefaultState,
				isLoading: true,
				search: action.payload,
				suggestions: []
			};
		case t.FETCH_COMPANIES_SUCCESS:
			return {
				...state,
				selected: {},
				suggestions: action.payload,
				isLoading: false,
				hasError: false,
				errorText: ''
			};
		case t.FETCH_COMPANIES_FAILURE:
			return {
				...companySelectDefaultState,
				isLoading: false,
				hasError: true,
				errorText: action.payload
			};
		case t.SELECT_COMPANY: {
			return {
				...state,
				suggestions: [action.payload],
				selected: action.payload,
				isLoading: false,
				hasError: false,
				errorText: '',
				search: action.payload?.name || ''
			};
		}

		default:
			return state;
	}
};
