import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';
import * as u from './utils';

/**
 * DiningOptionsList
 * @param {DiningOptionsListProps} props
 */
const DiningOptionsList = ({ restaurant, isLV }) => {
	const { options } = useMemo(() => {
		const hasCurbsidePickup = u.getHasCurbsidePickup(restaurant);
		const hasDriveThru = u.getHasDriveThru(restaurant);
		const hasDelivery = u.getHasDelivery(restaurant);
		const hasDineIn = u.getHasDineIn(restaurant);
		const hasTakeout = u.getHasTakeout(restaurant);

		return {
			options: [
				{
					title: 'Dine-In',
					checked: hasDineIn,
					show: hasDineIn
				},
				{
					title: 'Drive Thru',
					checked: hasDriveThru,
					show: hasDriveThru
				},
				{
					title: 'Takeout',
					checked: hasTakeout,
					show: !hasCurbsidePickup && !hasDriveThru && hasTakeout
				},
				{
					title: 'Curbside Pickup',
					checked: hasCurbsidePickup,
					show: hasCurbsidePickup
				},
				{
					title: u.getDeliveryTitle(restaurant),
					checked: hasDelivery,
					show: hasDelivery
				}
			].filter(o => o.show)
		};
	}, [restaurant]);
	const getIcon = useCallback(option => (option.checked ? 'fa fa-check' : 'fa fa-times'), []);

	const getOptionText = useCallback(option => option.title, []);

	return options.length > 0 ? (
		<div className={'dining-options-list'}>
			<OptionsList
				options={options}
				getOptionText={getOptionText}
				useIcon={true}
				getIcon={getIcon}
				className="dining-options"
				dataCy="dining-options"
				getOptionClass={() => (isLV ? 'full-width' : 'half-width')}
			/>
		</div>
	) : null;
};

/**
 * DiningOptionsListProps
 * @typedef {Object} DiningOptionsListProps
 */
DiningOptionsList.propTypes = {
	restaurant: PropTypes.object.isRequired,
	isLV: PropTypes.bool.isRequired
};

export default DiningOptionsList;
