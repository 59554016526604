const MuiButtonTheme = {
	styleOverrides: {
		root: {
			fontSize: '16px',
			padding: '15px 28px',
			borderRadius: '0px',
			borderColor: '#1c871c', //$dinova-green
			borderWidth: 2,

			'&.fullWidth': {
				width: '100%'
			}
		},
		sizeLarge: {
			fontSize: '20px'
		},
		sizeXsmall: {
			fontSize: '14px'
		},
		contained: {
			backgroundColor: '#1c871c', //$dinova-green
			borderStyle: 'solid',
			'&:hover': {
				backgroundColor: '#1c871c !important', //$dinova-green
				color: 'white'
			},

			'&.Mui-disabled': {
				backgroundColor: '#1c871c !important', //$dinova-green
				color: 'white !important',
				opacity: 0.6
			}
		},
		'black-outlined': {
			backgroundColor: 'white',
			color: 'black',
			borderStyle: 'solid',
			borderColor: 'black',
			borderWidth: 2,
			'&:hover': {
				backgroundColor: 'white !important',
				color: 'black !important'
			}
		},
		'nav-item': {
			padding: '4px 20px',
			borderRadius: '20px',
			textTransform: 'none',
			color: 'white',
			backgroundColor: '#1c871c', //$dinova-green
			'&:hover': {
				color: 'white',
				backgroundColor: '#1c871c !important' //$dinova-green
			}
		},
		outlined: {
			backgroundColor: 'white',
			// borderColor: '#1c871c', //$dinova-green
			// borderWidth: 2,
			color: '#1c871c', //$dinova-green
			'&:hover': {
				backgroundColor: 'white !important', //$dinova-green
				borderColor: '#1c871c', //$dinova-green
				borderWidth: 2
			},

			'&.Mui-disabled': {
				backgroundColor: 'white !important', //$dinova-green
				borderColor: '#1c871c !important' //$dinova-green
			}
		},
		text: {
			margin: '-15px -28px',
			textTransform: 'none',
			fontSize: 18,
			lineHeight: '24px',

			'&.MuiButton-sizeSmall': {
				fontSize: 16,
				fontWeight: 300,
				textDecoration: 'underline'
			},

			'&.MuiButton-sizeXsmall': {
				fontSize: 16,
				fontWeight: 300,
				textDecoration: 'underline'
			}
		}
	}
};

export default MuiButtonTheme;
