import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from 'components/atoms';
import { OptionsList } from 'components/organisms';
import WithDropdown from 'components/molecules/Dropdown/withDropdown';
import classnames from 'classnames';
import { CollapseWithClose } from 'components/molecules';

// import "./style.scss";

/**
 * [RestaurantHours description]
 * @extends Component
 */
class RestaurantHours extends WithDropdown {
	getHours = () => {
		return this.props.hours;
	};

	getOptionClass = option => {
		let hours = this.getHours();
		if (option.indexOf(hours[moment().day()]) > -1) {
			return 'bold';
		} else {
			return '';
		}
	};

	getIsOpenHours = () => {
		return this.props.open;
	};

	render() {
		return this.props.isLV ? this.largeView() : this.smallView();
	}

	smallView = () => {
		// if (this.props.hours.length === 0) {
		// 	return null;
		// }

		const { translate } = this.props,
			{ height, openSpeed, isOpen } = this.state,
			collapseClassProps = classnames('hours-panel-wrapper', isOpen && 'open'),
			triggerIcon = this.state.isOpen ? 'up' : 'down',
			hours = this.getHours(),
			isOpenHours = this.getIsOpenHours();

		return (
			this.props.hasHours && (
				<div className={'restaurant-hours'}>
					<div
						ref={c => (this.dropdown_trigger = c)}
						data-cy="hours-btn"
						data-toggle="collapse"
						type="button"
						onClick={this.toggleDropdown}
						className="hours-btn"
					>
						<Text className={`bold ${isOpenHours ? 'green-text' : 'red-text'}`} ref={c => (this.title = c)}>
							{isOpenHours
								? `${translate('common.open')} ${translate('common.now')}`
								: translate('common.closed')}
						</Text>
						<div className={`caret ${triggerIcon}`} />
					</div>
					<div className={collapseClassProps}>
						<CollapseWithClose
							content={
								<OptionsList
									options={hours}
									className="hours single-col bulletless"
									dataCy="hours"
									getOptionClass={this.getOptionClass}
								/>
							}
							height={height}
							isOpen={isOpen}
							onClick={this.onHelpClick}
							openSpeed={openSpeed}
							refSetter={c => (this.dropdown = c)}
							dataCy={'hours'}
						/>
					</div>
				</div>
			)
		);
	};

	largeView = () => {
		const { translate } = this.props;
		const { height, openSpeed, isOpen } = this.state;
		const collapseClassProps = classnames('hours-panel-wrapper', isOpen && 'open');
		const triggerIcon = this.state.isOpen ? 'up' : 'down';
		const hours = this.getHours();
		let day = moment().day();
		if (day === 0) {
			day = 7;
		} else {
			day -= 1;
		}
		const today = hours && hours.length > 0 ? hours[day] : null;
		const isOpenHours = this.getIsOpenHours();

		return (
			this.props.hasHours && (
				<div className={'restaurant-hours'}>
					<div>
						<Text className="bold" ref={c => (this.title = c)}>
							{translate('common.hours')}:{' '}
						</Text>
						<div
							ref={c => (this.dropdown_trigger = c)}
							data-cy="hours-btn"
							data-toggle="collapse"
							type="button"
							onClick={this.toggleDropdown}
							className="hours-btn"
						>
							<Text className="today">{today}</Text>
							<div className={`caret ${triggerIcon}`} />
						</div>
						<div className={collapseClassProps}>
							<CollapseWithClose
								content={
									<OptionsList
										options={hours}
										className="hours single-col bulletless"
										dataCy="hours"
										getOptionClass={this.getOptionClass}
									/>
								}
								height={height}
								isOpen={isOpen}
								onClick={this.onHelpClick}
								openSpeed={openSpeed}
								refSetter={c => (this.dropdown = c)}
								dataCy={'hours'}
							/>
						</div>
					</div>
					<Text transform="uppercase" className={`ml-4 bold ${isOpenHours ? 'green-text' : 'red-text'}`}>
						{isOpenHours
							? `${translate('common.open')} ${translate('common.now')}`
							: translate('common.closed')}
					</Text>
				</div>
			)
		);
	};
}

RestaurantHours.defaultProps = {};

/**
 * [propTypes description]
 * @interface RestaurantHoursProps
 *
 * @example {}
 */
RestaurantHours.propTypes = {
	googlePlacesId: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired
};

export default RestaurantHours;
