import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { useTranslate } from 'hooks';

const DashboardPromotions = ({ className, config }) => {
	const translate = useTranslate();

	const classProps = classNames('dashboard-promo', className);

	return (
		<div className={classProps}>
			{translate('Dashboard.numberOfPromotions') === '1' && (
				<Row className="no-gutters">
					<Col md="12">
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={translate('Dashboard.promotion1')}
							data-tag={translate('Dashboard.promotion1-tag')}
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/one-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion1-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/one-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion1-tag')}
							/>
						</a>
					</Col>
				</Row>
			)}
			{translate('Dashboard.numberOfPromotions') === '2' && (
				<Row className="no-gutters">
					<Col md="6" sm="12">
						<a
							href={translate('Dashboard.promotion2')}
							data-tag={translate('Dashboard.promotion2-tag')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/two-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion2-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/two-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion2-tag')}
							/>
						</a>
					</Col>
					<Col md="6" sm="12">
						<a
							href={translate('Dashboard.promotion3')}
							data-tag={translate('Dashboard.promotion3-tag')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/three-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion3-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/three-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion3-tag')}
							/>
						</a>
					</Col>
				</Row>
			)}
			{translate('Dashboard.numberOfPromotions') === '3' && (
				<Row className="no-gutters">
					<Col md="12" sm="12" className="order-sm-2 order-md-1">
						<a
							href={translate('Dashboard.promotion1')}
							data-tag={translate('Dashboard.promotion1-tag')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/one-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion1-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/one-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion1-tag')}
							/>
						</a>
					</Col>
					<Col md="6" sm="12" className="no-margin order-sm-1 order-md-2">
						<a
							href={translate('Dashboard.promotion2')}
							data-tag={translate('Dashboard.promotion2-tag')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/two-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion2-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/two-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion2-tag')}
							/>
						</a>
					</Col>
					<Col md="6" sm="12" className="no-margin order-3">
						<a
							href={translate('Dashboard.promotion3')}
							data-tag={translate('Dashboard.promotion3-tag')}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="Di_ShowLargePromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/three-lv.png?auto=enhace`}
								alt={translate('Dashboard.promotion3-tag')}
							/>
							<img
								className="Di_ShowSmallPromo"
								src={`${config.cdn_basePath}/mydinova/Promotions/three-sv.png?auto=enhace`}
								alt={translate('Dashboard.promotion3-tag')}
							/>
						</a>
					</Col>
				</Row>
			)}
		</div>
	);
};

DashboardPromotions.propTypes = {
	className: PropTypes.string,
	config: PropTypes.object.isRequired
};

export default DashboardPromotions;
