import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import classnames from 'classnames';
import { hasProp } from 'utils/object';

// import ./style.scss

/**
 * RestaurantCuisines
 * @param {RestaurantCuisinesProps} props
 */
const RestaurantCuisines = ({ restaurant, isLV }) => {
	const { cuisineAndMS } = useMemo(() => {
		let cuisines = [];

		hasProp(restaurant, 'cuisine.name') && cuisines.push(restaurant.cuisine.name);

		if (hasProp(restaurant, 'menuSpecialties')) {
			restaurant.menuSpecialties.forEach(item => {
				cuisines.push(item.name);
			});
		}

		return { cuisineAndMS: cuisines.join(', ') };
	}, [restaurant]);

	return (
		<Text className={classnames('details-cuisines', !isLV && 'mb-0')} align={'left'} dataCy="details-page-cuisines">
			{cuisineAndMS}
		</Text>
	);
};

RestaurantCuisines.defaultProps = {};

/**
 * RestaurantCuisinesProps
 * @typedef {Object} RestaurantCuisinesProps
 */
RestaurantCuisines.propTypes = {
	restaurant: PropTypes.object.isRequired,
	isLV: PropTypes.bool.isRequired
};

export default RestaurantCuisines;
