import React from 'react';
import PropTypes from 'prop-types';
import { Caret } from 'components/atoms';
import { CollapseWithClose } from 'components/molecules';
import WithDropdown from 'components/molecules/Dropdown/withDropdown';

/**
 * TermsAndConditions - A Dropdown/CollapseWithClose used to show/hide Terms and Conditions to the user.
 * @extends WithDropdown
 * @param {TermsAndConditions_Props} props
 */
class TermsAndConditions extends WithDropdown {
	render() {
		const { showCaret, showTriggerText, translate, triggerOpenText, triggerClosedText } = this.props;
		let { isOpen } = this.state;
		if (this.props.isOpen !== undefined) {
			isOpen = this.props.isOpen;
		}
		let text;
		if (isOpen) {
			if (triggerOpenText) {
				text = triggerOpenText;
			} else {
				text = translate('common.hide').toUpperCase();
			}
		} else {
			if (triggerClosedText) {
				text = triggerClosedText;
			} else {
				text = translate('common.view').toUpperCase();
			}
		}

		return (
			<React.Fragment>
				<div className="trigger" ref={c => (this.dropdown_trigger = c)} onClick={this.toggleDropdown}>
					{showTriggerText && <p className="link collapsible">{text}</p>}
					{showCaret && <Caret isOpen={isOpen} />}
				</div>
				<div className="trigger-panel-wrapper">
					<CollapseWithClose
						content={translate('Terms.terms')}
						height={this.state.height}
						isOpen={isOpen}
						onClick={this.toggleDropdown}
						openSpeed={this.state.openSpeed}
						refSetter={c => (this.dropdown = c)}
					/>
				</div>
			</React.Fragment>
		);
	}
}

TermsAndConditions.defaultProps = {
	showCaret: true,
	showTriggerText: true
};

/**
 * TermsAndConditions
 * @interface {TermsAndConditions_Props}
 * @property {function} translate - Redux localize
 */
TermsAndConditions.propTypes = {
	isOpen: PropTypes.bool,
	showCaret: PropTypes.bool,
	showTriggerText: PropTypes.bool,
	toggleDropdown: PropTypes.func,
	translate: PropTypes.func,
	triggerOpenText: PropTypes.string,
	triggerClosedText: PropTypes.string
};

export default TermsAndConditions;
