import * as t from '../actions/modal/types';

/**
 * Redux_ModalState
 * @interface Redux_ModalState
 * @property {string|numer}  [id=undefined]
 * @property {sting}         [header=undefined]
 * @property {string}        [body=""]
 * @property {string}        [primaryButtonText="OK"]
 * @property {string}        [secondaryButtonText=undfined]
 * @property {string}        [tertiaryButtonText=undefined]
 * @property {boolean}       [isOpen=false]
 * @property {boolean}       [autoCompleteIsOpen=false]
 * @property {string}        [selectedAction='']
 * @property {string}        [type=undefined]
 */
const modalDefaultState = {
	modalsShown: [],
	id: undefined,
	header: undefined,
	body: '',
	primaryButtonText: 'OK',
	secondaryButtonText: undefined,
	tertiaryButtonText: undefined,
	isOpen: false,
	autoCompleteIsOpen: false,
	selectedAction: '',
	type: undefined
};

export default (state = modalDefaultState, action) => {
	switch (action.type) {
		case t.SET_VALUES:
			return {
				...state,
				...action.modal
			};
		case t.TOGGLE_MODAL: {
			const newModalsShown = [...state.modalsShown];
			if (action.isOpen && !newModalsShown.includes(state.id)) {
				newModalsShown.push(state.id);
			}

			return {
				...state,
				modalsShown: newModalsShown,
				isOpen: action.isOpen
			};
		}
		case t.TOGGLE_AUTOCOMPLETE:
			return {
				...state,
				autoCompleteIsOpen: action.isOpen
			};
		case t.SELECT_ACTION:
			return {
				...state,
				selectedAction: action.action
			};
		case t.RESET_MODAL:
			return modalDefaultState;
		default:
			return state;
	}
};
