export const CONNECTION_CREATED = 'connection_created';
export const COMPANY_SUBMIT_NO_VAL = 'company_select_submit_noval';
export const DELETE_ALL_CONNECTIONS = 'delete_all_connections';
export const PROGRAMATIC_BLUR = 'programatic_blur';
export const RECOMMENDATION_SUBMIT = 'recommendation_submit';
export const SHOW_LESS_ALL_FILTERS = 'show_less_all_filters';
// export const CSV_RESULT_PROCESSED = 'CSV_RESULT_PROCESSED';
// Fire event so Recommendation Component can now when to hide itself. - after submitting a recommendation, remove the "Would you leave a recommendation Yes No" from UI.
export const companySelectSubmitNoValWindowEvent = () => {
	fireWindowEvent(COMPANY_SUBMIT_NO_VAL);
};

export const connectionCreatedWindowEvent = args => {
	fireWindowEvent(CONNECTION_CREATED, args);
};

export const deleteAllConnectionsWindowEvent = () => {
	fireWindowEvent(DELETE_ALL_CONNECTIONS);
};

export const programaticBlurWindowEvent = args => {
	fireWindowEvent(PROGRAMATIC_BLUR, args);
};

export const recommendationSubmitWindowEvent = args => {
	fireWindowEvent(RECOMMENDATION_SUBMIT, args);
};

export const closeFilterGroups = () => {
	fireWindowEvent(SHOW_LESS_ALL_FILTERS);
};

const fireWindowEvent = (name, detail) => {
	var event = new CustomEvent(name, {
		detail
	});

	window.dispatchEvent(event);
};

export default fireWindowEvent;
