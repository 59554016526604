import { hasProp } from 'utils/object';
import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { amenitiesFilterCheck, amenitiesGeneralFilterCheck } from 'utils/search_new/utils/filterFuncs';

const diningOptsFilterAggregate = (filter, restaurant, filterConfig) => {
	let diningOptionsIds = [2, 5]; // Curbside, Drive-thru
	if (restaurant.amenities.general) {
		restaurant.amenities.general.forEach(opt => {
			if (diningOptionsIds.indexOf(opt.id) > -1) {
				let groupSubAccr = opt.name;
				if (opt.name === 'Drive thru') {
					groupSubAccr = 'Drive-Thru';
				}
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr,
					id: opt.id,
					masterAccr: 'amenities',
					subAccr: 'general',
					value: opt.name,
					filterFunc: amenitiesGeneralFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}
	if (restaurant.amenities.takeout && Object.keys(restaurant.amenities.takeout).length > 0) {
		addOrUpdateMasterFilter({
			filter,
			groupTitle: filterConfig.groupTitle,
			groupSubAccr: 'takeout',
			masterAccr: 'amenities',
			subAccr: 'takeout',
			filterFunc: amenitiesFilterCheck,
			restaurantId: restaurant.id
		});
	}

	if (hasProp(restaurant, 'amenities.delivery')) {
		addOrUpdateMasterFilter({
			filter,
			groupTitle: filterConfig.groupTitle,
			groupSubAccr: 'delivery',
			masterAccr: 'amenities',
			subAccr: 'delivery',
			filterFunc: amenitiesFilterCheck,
			restaurantId: restaurant.id
		});
	}
};

export default diningOptsFilterAggregate;
