import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, Title } from 'components/atoms';
import { PaymentNotConnected, ProfilePaymentIneligibilityMessage } from 'components/molecules';
import { history } from 'utils/router';
import { getLangFileCardLabelKey } from 'utils/card';
import { WithHelp } from 'components/molecules';
import { useCompanyByCodeQuery } from 'reactQuery/queries';
import useGeneralContext from 'context/GeneralContext';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';
import { useUserConnectionDeleteOneMutation } from 'reactQuery/mutations';

const ProfilePaymentInfo = ({ setCardToEdit }) => {
	const translate = useTranslate();
	const { isDoDMode } = useGeneralContext;
	const { user } = useUserContext();

	const { deleteConnection } = useUserConnectionDeleteOneMutation();

	const { userCompany, connections, isMerck, name } = useMemo(
		() => ({
			userCompany: user?.company,
			connections: user?.connections,
			isMerck: user?.company?.name === 'Merck',
			name: user?.name
		}),
		[user]
	);

	// const [connectionsCount, setConnectionsCount] = useState(undefined);
	const [supportedConnectionCardTypes, setSupportedConnectionCardTypes] = useState([]);
	const [ineligibleConnectionCardTypes, setIneligibleConnectionCardTypes] = useState([]);
	const [hasEligible, setHasEligible] = useState(true);
	const [helpOpen, _setHelpOpen] = useState(false);

	const { data: company } = useCompanyByCodeQuery({ companyCode: userCompany?.code });
	useEffect(() => {
		if (company) {
			let _hasEligible = false;
			let _supportedConnectionCardTypes = [];
			let _ineligibleConnectionCardTypes = new Set();
			(company.supportedConnections || []).forEach(c => {
				_supportedConnectionCardTypes.push(c.cardType);
			});
			connections.forEach(c => {
				if (_supportedConnectionCardTypes.indexOf(c.cardType) === -1) {
					_ineligibleConnectionCardTypes.add(c.cardType);
				} else {
					_hasEligible = true;
				}
			});

			// setConnectionsCount((company.supportedConnections || []).length);
			setSupportedConnectionCardTypes(_supportedConnectionCardTypes);
			setIneligibleConnectionCardTypes(_ineligibleConnectionCardTypes);
			setHasEligible(_hasEligible);
		}
	}, [connections, company]);

	// useEffect(() => {
	// 	if (!connectionsCount && userCompany.code) {
	// 		let _hasEligible = false;
	// 		getCompany(userCompany.code).then(res => {
	// 			let _supportedConnectionCardTypes = [];
	// 			let _ineligibleConnectionCardTypes = new Set();
	// 			(res.supportedConnections || []).forEach(c => {
	// 				_supportedConnectionCardTypes.push(c.cardType);
	// 			});
	// 			connections.forEach(c => {
	// 				if (_supportedConnectionCardTypes.indexOf(c.cardType) === -1) {
	// 					_ineligibleConnectionCardTypes.add(c.cardType);
	// 				} else {
	// 					_hasEligible = true;
	// 				}
	// 			});
	// 			setConnectionsCount((res.supportedConnections || []).length);
	// 			setSupportedConnectionCardTypes(_supportedConnectionCardTypes);
	// 			setIneligibleConnectionCardTypes(_ineligibleConnectionCardTypes);
	// 			setHasEligible(_hasEligible);
	// 		});
	// 	}
	// }, [userCompany, connections]);

	const onEdit = useCallback(
		_connection => {
			setCardToEdit(_connection);
			history.push('/profile/payment/#/');
		},
		[setCardToEdit]
	);

	const onDelete = useCallback(
		_connection => {
			deleteConnection(_connection.id);
		},
		[deleteConnection]
	);

	const setHelpOpen = useCallback(
		index => {
			if (helpOpen === index) {
				_setHelpOpen(false);
			} else {
				_setHelpOpen(index);
			}
		},
		[helpOpen]
	);

	return isMerck ? null : (
		<div className="view-profile-section view-profile-payment section" data-cy="payment">
			<header className="list-header">
				<Title size="h2" align="left">
					{translate(`Profile.payment.title_new`)}
				</Title>
			</header>
			<div className="profile-section-list profile-payment-list">
				{connections.map((connection, index) => {
					let cardName = '';
					if (isDoDMode) {
						cardName = `${name.first} ${name.middle} ${name.last}`;
					} else {
						cardName = connection.nameOnCard;
					}
					const isEligible =
							supportedConnectionCardTypes.length > 0 &&
							supportedConnectionCardTypes.indexOf(connection.cardType) > -1,
						wrapperClassProps = classnames(
							!isEligible && 'ineligible has-help',
							helpOpen === index && 'help-open'
						),
						classProps = classnames('profile-section-item');

					return (
						<div key={connection.id} className={wrapperClassProps} data-cy={`connection-${connection.id}`}>
							{!isEligible && (
								<WithHelp
									id={`profile-connection-item-${index}-help`}
									help={translate('RegisteredConnections.ineligibleConnectionHelp')}
									onWrapperClick={setHelpOpen.bind(this, index)}
								/>
							)}
							<div className="item" data-cy={`profile-connection-item-${index}`}>
								<div>
									<Link
										className={`profile-connection-item-edit-${index} goto-edit`}
										onClick={
											isEligible ? onEdit.bind(this, connection) : onDelete.bind(this, connection)
										}
										id={`connection-edit-${index}`}
										dataCy={isEligible ? 'edit' : 'delete'}
									>
										{isEligible ? translate('common.editBtn') : translate('common.delete')}
									</Link>
								</div>
								<div>
									<div className={classProps}>
										<Title inline size="h3" align="left" dataCy="cardType">
											{connection.cardType
												? translate(
														`ConnectToPoints.inputs.creditcard.${getLangFileCardLabelKey(
															connection.cardType
														)}`
														/* eslint-disable no-mixed-spaces-and-tabs */
												  )
												: translate('ConnectToPoints.inputs.creditcard.label')}
										</Title>
										<span id={'company-field'} className="item-value" data-cy="last4">
											{`XXXX-XXXX-XXXX-${connection.last4}`}
										</span>
									</div>
									<div className={classProps}>
										<Title inline size="h3" align="left">
											{translate('Profile.payment.labels.cardholderName')}
										</Title>
										<span id={'company-field'} className="item-value" data-cy="cardholderName">
											{cardName}
										</span>
									</div>
									{connection.employeeId && (
										<div className={classProps}>
											<Title inline size="h3" align="left">
												{translate('Profile.payment.labels.employeeId')}
											</Title>
											<span id={'employeeId-field'} className="item-value">
												{connection.employeeId}
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>

			<ProfilePaymentIneligibilityMessage
				ineligibleConnectionCardTypes={ineligibleConnectionCardTypes}
				supportedConnectionCardTypes={supportedConnectionCardTypes}
				hasEligible={hasEligible}
			/>

			{((isDoDMode && connections.length < 1) || (!isDoDMode && connections.length < 10)) && (
				<div className="center">
					<PaymentNotConnected showNotConnectedMessage={connections.length === 0} />
				</div>
			)}
		</div>
	);
};

ProfilePaymentInfo.propTypes = {
	setCardToEdit: PropTypes.func.isRequired
};

export default ProfilePaymentInfo;
