const MuiOutlinedInputTheme = {
	styleOverrides: {
		input: {
			padding: '13px 17px',
			fontWeight: 400
		},
		notchedOutline: {
			borderColor: '#aaa !important',
			transition: 'border-width ease-in-out 150ms, border-color ease-in-out 150ms',

			'& legend': {
				fontSize: 15,
				fontFamily: "'Lato', sans-serif !important",
				fontWeight: 300
			}
		},
		root: {
			// height: '55px'
		}
	}
};

export default MuiOutlinedInputTheme;
