import { phoneValidator } from 'utils/form/validators';

const validateMFAForm = vals => {
	const errors = {};

	const phoneValid = phoneValidator(vals);
	if (!phoneValid) {
		errors.phone = 'Please enter a valid phone number.';
	}

	return errors;
};

export default validateMFAForm;
