import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputLabel } from 'components/atoms';
import { hasProp } from 'utils/object';

import './styles/main.scss';

/**
 * Checkbox Input - A single checkbox input item.
 * @param {CheckboxInput_Props} props
 */
const CheckboxInput = ({
	className,
	checked,
	dataCy,
	hasError,
	id,
	inputAlign,
	isReduxForm,
	name,
	onBlur,
	onChange,
	onFocus,
	required,
	option
}) => {
	const wrapperClassProps = classnames('checkbox-option-wrapper', className);
	const itemClassProps = classnames('checkbox', hasError && 'error', required && 'required', inputAlign);

	const isChecked = useMemo(() => {
		let _isChecked = hasProp(option, 'checked') ? option.checked : checked;
		if (_isChecked === null || _isChecked === undefined) _isChecked = false;

		return _isChecked;
	}, [checked, option]);

	const handleChange = isReduxForm ? onChange : onChange.bind(this, option);
	const _dataCy = dataCy || option.dataCy || name || option.name;

	return (
		<div className={wrapperClassProps} data-cy={`${_dataCy}-checkbox-input-wrapper`}>
			<input
				data-cy={`${_dataCy}-input`}
				className={itemClassProps}
				type="checkbox"
				checked={isChecked}
				name={name || null}
				id={id || null}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={handleChange}
			/>
			<InputLabel
				dataCy={`${_dataCy}`}
				hasError={hasError}
				optionLabel={true}
				isReduxForm={isReduxForm}
				htmlFor={id}
				text={option.label}
				type="checkbox"
			/>
			{required && <span className="required">*</span>}
		</div>
	);
};

CheckboxInput.defaultProps = {};

/**
 * [propTypes description]
 * @interface CheckboxInput_Props
 * @param {string}    [className]  Additional classnames to add to the checkbox input.
 * @param {boolean}   [checked]    If this checkbox is selected.
 * @param {boolean}   [hasError]   If this checkbox has an error.
 * @param {string}    [id]         The id of this checkbox.
 * @param {string}    [inputAlign] How to align the checkbox and label. If 'inline', checkbox will show to the left of label.  If null, chekcbox will show under label.
 * @param {string}    [isReduxForm] If this input is part of a redux form.
 * @param {string}    name       The name of this checkbox.
 * @param {function}  [onBlur]     Checkbox onBlur handler.
 * @param {function}  onChange   Checkbox onChange handler.
 * @param {function}  [onFocus]    Checkbox onFocus handler.
 * @param {boolean}   [required]   If this checkbox is required.
 * @param {object}    option     The data object for this checkbox.
 */
CheckboxInput.propTypes = {
	checked: PropTypes.bool,
	className: PropTypes.string,
	dataCy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hasError: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputAlign: PropTypes.oneOf(['inline']),
	isReduxForm: PropTypes.bool,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	option: PropTypes.object,
	required: PropTypes.bool
};

export default CheckboxInput;
