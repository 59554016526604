import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Body, Button, Col, Row } from 'components/atoms';
import { TextField } from 'mui-rff';

export const CODE_INPUT_ID = 'code';

const CodePage = ({ error, phoneNumber, onCancelClick }) => {
	return (
		<div>
			<ModalHeader>Enter your authentication code</ModalHeader>
			<ModalBody>
				<Body variant="body2">To make sure your myDinova is secure, we need to verify your identity.</Body>
				<Body variant="body2">
					Enter the verification code we texted to (***)***-
					{phoneNumber ? phoneNumber.substr(phoneNumber.length - 4, 4) : `0000`}
				</Body>

				<Row mt={3}>
					<TextField
						name={CODE_INPUT_ID}
						id={CODE_INPUT_ID}
						label="Authentication Code"
						error={error}
						helperText={error}
					/>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Col gap="16px" fullWidth>
					<Row fullWidth mt="8px" px={'8px'}>
						<Button fullWidth className="z-depth-0" type="submit" color="primary" id="modal-primary-button">
							Verify
						</Button>
					</Row>
					{phoneNumber && !phoneNumber.includes('*') && (
						<Row fullWidth align="baseline" gap="3px">
							<Body variant="body2">{`Didn't receive a code? Click `}</Body>
							<Button variant="text" size="small" onClick={onCancelClick}>
								here{' '}
							</Button>
							<Body variant="body2">to try again.</Body>
						</Row>
					)}
				</Col>
			</ModalFooter>
		</div>
	);
};

CodePage.defaultProps = {};
CodePage.propTypes = {
	phoneNumber: PropTypes.string,
	onCancelClick: PropTypes.func,
	error: PropTypes.oneOf([false, PropTypes.oneOfType[PropTypes.string]])
};

export default CodePage;
