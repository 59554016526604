import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Container, Tab, Tabs } from '@mui/material';
import TabPanel from './TabPanel';
import classnames from 'classnames';

const a11yProps = (tabPanelName, index) => ({
	id: `${tabPanelName}-tab-${index}`,
	'aria-controls': `${tabPanelName}-tabpanel-${index}`
});

const TabPanels = ({ className, subHeader, tabs, tabPanelName, tabUrls, urlToTab }) => {
	const history = useHistory();
	const [currentTab, setTab] = useState(false);
	const [didInitURL, setDidInitURL] = useState(false);
	const [tabsVisited, setTabsVisited] = useState(new Set());

	useLayoutEffect(() => {
		if (!didInitURL) {
			let tab = urlToTab[history.location.pathname];
			setTab(tab);
			updateTabsVisited(tab);
			setDidInitURL(true);
		}
	}, [didInitURL, history.location.pathname, updateTabsVisited, urlToTab]);

	/*
	 * To decrease load time and API calls, only render tabs that have been requested/visited.
	 */
	const updateTabsVisited = useCallback(
		newTab => {
			let updatedTabs = new Set(tabsVisited);
			updatedTabs.add(newTab);
			setTabsVisited(updatedTabs);
		},
		[tabsVisited]
	);

	const switchTab = (e, newTab) => {
		history.push(tabUrls[newTab]);
		updateTabsVisited(newTab);
		setTab(newTab);
	};

	let showSubheader = currentTab ? tabs[currentTab].showSubheader : true;
	if (showSubheader === undefined) {
		showSubheader = true;
	}

	return (
		<Container className={classnames('tab-panels', className)}>
			<AppBar position="static">
				<Tabs centered value={currentTab} onChange={switchTab} arial-label={`${tabPanelName} tab panel`}>
					{tabs.map((tab, i) => (
						<Tab label={tab.label} {...a11yProps(tabPanelName, i)} key={`${tabPanelName}-tab-${i}`} />
					))}
				</Tabs>
			</AppBar>
			{showSubheader && subHeader && subHeader}
			{tabs.map((tab, i) =>
				/* Make sure the tab has been requested/visited before rendering */
				tabsVisited.has(i) ? (
					<TabPanel value={currentTab} index={i} key={`${tabPanelName}-tabPanel-${i}`}>
						{tab.Component}
					</TabPanel>
				) : null
			)}
		</Container>
	);
};
TabPanels.defaultProps = {};
TabPanels.propTypes = {
	className: PropTypes.string,
	tabPanelName: PropTypes.string.isRequired,
	subHeader: PropTypes.node,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			Component: PropTypes.node,
			showSubheader: PropTypes.bool
		})
	).isRequired,
	tabUrls: PropTypes.object,
	urlToTab: PropTypes.object
};

export default TabPanels;
