import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import {
	amenitiesEntertainmentCheck,
	amenitiesFilterCheck,
	amenitiesSeatingCheck
} from 'utils/search_new/utils/filterFuncs';

const ameitiesFilterAggregate = (filter, restaurant, filterConfig) => {
	Object.entries(restaurant.amenities).forEach(([key, value]) => {
		if (filterConfig.filterAggregateIgnoreKeys && filterConfig.filterAggregateIgnoreKeys.indexOf(key) > -1) {
			return;
		}

		if (key === 'entertainment') {
			if (value.music && value.music.length > 0) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: 'music',
					masterAccr: 'amenities.entertainment',
					subAccr: 'music',
					filterFunc: amenitiesEntertainmentCheck,
					restaurantId: restaurant.id
				});
			}
			if (value.alcohol && value.alcohol.length > 0) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: 'alcohol',
					masterAccr: 'amenities.entertainment',
					subAccr: 'alcohol',
					filterFunc: amenitiesEntertainmentCheck,
					restaurantId: restaurant.id
				});
			}

			if (value.seating && value.seating.length > 0) {
				value.seating.forEach(seat => {
					addOrUpdateMasterFilter({
						filter,
						groupTitle: filterConfig.groupTitle,
						groupSubAccr: seat.name,
						id: seat.id,
						masterAccr: 'amenities.entertainment',
						subAccr: 'seating',
						value: seat.name,
						filterFunc: amenitiesSeatingCheck,
						restaurantId: restaurant.id
					});
				});
			}

			if (value.happyHour && value.happyHour.length > 0) {
				console.log('happyHour');
				/* eslint-disable */ debugger; /* eslint-enable */
			}
		} else if (key === 'attire' || key === 'venues' || key === 'menu') {
			return;
		} else if (key === 'delivery') {
			// delivery filter will be in the "Dining Options" group
			return;
		} else {
			if (Object.keys(value || {}).length > 0) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: key,
					masterAccr: 'amenities',
					subAccr: key,
					filterFunc: amenitiesFilterCheck,
					restaurantId: restaurant.id
				});
			}
		}
	});
};

export default ameitiesFilterAggregate;
