import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { OptionsList } from 'components/organisms';
import { hasProp } from 'utils/object';

/**
 * List of available dietary preferences.  Used in {@link DetailsPage}.
 * @param {DietaryPreferencesProps} props
 */
const DietaryPreferences = ({ restaurant }) => {
	const dietaryPrefs = useMemo(() => {
		return hasProp(restaurant, 'dietaryPreferences')
			? Object.values(restaurant.dietaryPreferences).map(v => v.name)
			: [];
	}, [restaurant]);

	return dietaryPrefs.length > 0 ? <OptionsList options={dietaryPrefs} dataCy="dietary-prefs" /> : null;
};

/**
 * {@link DietaryPreferences} Props
 * @interface Props_DietaryPreferences
 * @property {string[]} [dietaryPrefs=[]]
 */
DietaryPreferences.propTypes = {
	// Optional
	restaurant: PropTypes.object.isRequired
};

export default DietaryPreferences;
