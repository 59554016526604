import { useQuery } from '.';
import { basePath } from 'actions/utils';

export const USER_FAVORITE_QUERY_KEY = 'user_favorites_query';

const useUserFavoritesQuery = ({ enabled, quietLoading, setReduxIsLoading, setDataInRedux } = {}) => {
	return useQuery({
		// Req
		queryHandler: res => res.data.restaurants,
		queryKey: USER_FAVORITE_QUERY_KEY,
		url: `${basePath()}/users/restaurants/favorite`,
		// Opt
		enabled,
		quietLoading,
		reduxLoadingMsg: 'Loading Profile...',
		reduxQueryDataAccr: query => query.data,
		requiresAuth: true,
		setDataInRedux,
		setReduxIsLoading
		// urlBuilder: (queryKey) => string
	});
};

export default useUserFavoritesQuery;
