import { useQuery } from './';
import { basePath } from 'actions/utils';
import { isDev } from 'utils/isNodeEnv';

export const USER_QUERY_KEY = 'users_query';

const useUsersQuery = ({ enabled, quietLoading, setReduxIsLoading, setDataInRedux } = {}) => {
	const query = useQuery({
		// Req
		queryHandler: res => {
			return {
				...res.data.user,
				...(isDev()
					? {
							// points: 802
							// points: 0
							// connections: [
							// 	{
							// 		id: 39000,
							// 		type: 1,
							// 		cardType: 3,
							// 		connectionDate: '2024-01-18T20:26:53.343Z',
							// 		last4: '2075',
							// 		nameOnCard: 'rosePetals800 rosePetals800',
							// 		issuer: 'Citi Bank',
							// 		fullNumberRequired: true,
							// 		company: {
							// 			code: 'UBER',
							// 			name: 'Uber'
							// 		}
							// 	}
							// ]
					  }
					: {})
			};
		},
		queryKey: USER_QUERY_KEY,
		url: `${basePath()}/users`,
		// Opt
		enabled,
		quietLoading,
		reduxLoadingMsg: 'Loading Profile...',
		reduxQueryDataAccr: query => query.data,
		requiresAuth: true,
		setDataInRedux,
		setReduxIsLoading
		// urlBuilder: (queryKey) => string
	});

	return query;
};

export default useUsersQuery;
