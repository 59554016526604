import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { cuisineFilterCheck } from 'utils/search_new/utils/filterFuncs';

const cuisineFilterAggregate = (filter, restaurant, filterConfig) => {
	addOrUpdateMasterFilter({
		filter,
		groupTitle: filterConfig.groupTitle,
		groupSubAccr: restaurant.cuisine.name,
		id: restaurant.cuisine.id,
		masterAccr: 'cuisine',
		value: restaurant.cuisine.name,
		filterFunc: cuisineFilterCheck,
		restaurantId: restaurant.id
	});
};

export default cuisineFilterAggregate;
