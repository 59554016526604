import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { basePath } from 'actions/utils';
import { useDispatch } from 'react-redux';
import { setError, setIsLoading } from 'actions/loader';
/*
interface useCreateSubscriptionMutationProps {}
*/

const useCreateSubscriptionMutation = ({ onError, onSuccess } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();

	const mutation = useMutation({
		mutationFn: formData => {
			console.log('%c create subscription mutation:', 'color: yellow', formData);
			return axios.post(`${basePath()}/users`, JSON.stringify(formData));
			// return new Promise(resolve => {
			// console.log('%c create subscription mutation:', 'color: yellow', formData);
			// 	setTimeout(() => {
			// 		resolve({ data: 'success' });
			// 	}, 300);
			// });

			// return await axios.post(`${basePath()}/users/CreateSubscription`, JSON.stringify(formData));
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'Creating User...'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			onError && onError(error);
			dispatch(setError(error.response.status));
		},
		onSuccess: (response /*variables, context*/) => {
			const data = response.data;
			dispatch(setIsLoading(false));

			// onSuccess && onSuccess(data);
			if (response.data.errors) {
				dispatch(setError(response.data.response.code));

				onError && onError(response);
			} else {
				onSuccess && onSuccess(data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		createSubscription: mutation.mutate
	};
};

export default useCreateSubscriptionMutation;
