import * as t from '../actions/promotions/types';

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */
const promotionsInitialState = {
	start: '',
	end: '',
	isRunning: false,
	isLoading: true,
	home: {
		background_image: '',
		title: '',
		body: ''
	},
	details: {
		overlay_image: '',
		title: '',
		body: ''
	},
	banner: ''
};

export default (state = promotionsInitialState, action) => {
	switch (action.type) {
		case t.INIT_PROMOTION: {
			const promotion = action.payload;
			if (!promotion) {
				return { ...promotionsInitialState, isLoading: false };
			} else {
				return { ...promotion, isLoading: false };
			}
		}

		default:
			return state;
	}
};
