import React, { createRef, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { BusinessSearchForm, SearchPageQuickFilters } from 'components/organisms';
import { useTranslate } from 'hooks';

const SearchTopBar = ({
	addFilter,
	removeFilter,
	children,
	className = '',
	isSV,
	onSearch,
	searchErrType,
	userFilters
}) => {
	const translate = useTranslate();
	const innerWrapper = createRef();

	const renderLV = useCallback(() => {
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={innerWrapper}>
					<div className={classnames(!isSV && 'flex align-center')}>
						<Text className="mb-0 mr-4 no-wrap bold">{translate('Home.businessSearch.formLabel')}</Text>
						<BusinessSearchForm
							onSubmit={onSearch}
							submitButtonText={translate('common.searchCptl')}
							whiteBackground
						/>
					</div>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					{children && <div className="bottom-bar">{children}</div>}
				</div>
			</div>
		);
	}, [children, innerWrapper, className, isSV, onSearch, searchErrType, translate]);

	const renderSV = useCallback(() => {
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={innerWrapper}>
					<BusinessSearchForm
						onSubmit={onSearch}
						submitButtonText={translate('common.searchCptl')}
						whiteBackground
					/>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					<SearchPageQuickFilters
						addFilter={addFilter}
						removeFilter={removeFilter}
						userFilters={userFilters}
					/>
					{children && <div className="bottom-bar">{children}</div>}
				</div>
			</div>
		);
	}, [addFilter, children, innerWrapper, removeFilter, userFilters, className, onSearch, searchErrType, translate]);

	return isSV ? renderSV() : renderLV();
};

SearchTopBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isSV: PropTypes.bool,
	onSearch: PropTypes.func.isRequired,
	searchErrType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setupFlow: PropTypes.bool,
	userFilters: PropTypes.object.isRequired
};

export default SearchTopBar;
