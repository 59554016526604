import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CheckboxInput, InputError } from 'components/molecules';
import { hasProp } from 'utils/object';

import './style.scss';

/**
 * Component to display a group of checkbox inputs.
 * @param {CheckboxGroup_Props}  props
 * @example <CheckboxGroup
	name="business_expenses1"
	id="business_expenses1"
	alignOptions="vertical"
	hasError={false}
	error="This is an error message"
	value={'agreed'}
	options={[{label: 'Do you agree?', value: 'agreed', checked: true}]}
	onChange={() => {}}
/>
 */
const CheckboxGroup = ({
	alignOptions,
	className,
	dataCy,
	hasError,
	error,
	label,
	inputAlign,
	name,
	options,
	onBlur,
	onChange,
	onFocus,
	required,
	value
}) => {
	const isSingle = options.length === 1;
	const classProps = classnames(
		'checkbox-group',
		hasError && 'error',
		required && 'required',
		inputAlign,
		isSingle && 'single',
		className
	);
	const containerClassProps = classnames('input-container', classProps);
	const optionsWrapperClassProps = classnames('checkbox-options', alignOptions);
	const labelClassProps = classnames('input-label checkbox-group', required && 'required', hasError && 'error');

	return (
		<div className={containerClassProps} data-cy={`${dataCy}-checkbox-wrapper`}>
			<p data-cy={`${dataCy}-label`} className={labelClassProps}>
				{label}
			</p>

			{hasError && inputAlign !== 'inline' && !isSingle && <InputError text={error} />}
			<div className={optionsWrapperClassProps} data-cy={`${dataCy}-checkbox-options-wrapper`}>
				{options.map((o, i) => {
					let checked = hasProp(value, `${name}-${i}`)
						? value[`${name}-${i}`]
						: hasProp(o, 'checked')
						? o.checked
						: false;

					return (
						<CheckboxInput
							dataCy={dataCy}
							option={o}
							required={required}
							hasError={isSingle && hasError}
							key={`checkbox-${name}-${i}`}
							index={i}
							name={o.name}
							id={o.id}
							onFocus={onFocus}
							onBlur={onBlur}
							onChange={onChange}
							checked={o.checked !== undefined ? o.checked : checked}
						/>
					);
				})}
			</div>
			{hasError && (inputAlign === 'inline' || isSingle) && <InputError dataCy={dataCy} text={error} />}
		</div>
	);
};

CheckboxGroup.defaultProps = {};

/**
 * [propTypes description]
 * @interface CheckboxGroup_Props
 * @param {string}    [alignOptions] Align options 'vertical' or 'horizontal'.
 * @param {string}    [className]    Additional class names to add to the checkbox group wrapper div.
 * @param {boolean}   [hasError]     If this input group has an error.
 * @param {string}    [error]        Error message to display if this input group hasError.
 * @param {string}    label        The label text for this input group.
 * @param {string}    [inputAlign]   'inline' or null.  If 'inline', will show checkbox next to label.  If null, will show checkbox under label.
 * @param {string}    [name]         The name of this input group.
 * @param {array}     [options]      An array of options to create checkboxes for in this input group.
 * @param {function}  [onBlur]       Input onBlur handler func.
 * @param {funciton}  onChange     Input onChange handler func.
 * @param {function}  [onFocus]      Input onFocus handler func.
 * @param {boolean}   [required]     If this input group requires user input.
 * @param {string}    value        The current value of this input group.
 */
CheckboxGroup.propTypes = {
	// optional
	alignOptions: PropTypes.oneOf(['horizontal', 'vertical']),
	className: PropTypes.string,
	dataCy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.string,
	hasError: PropTypes.bool,
	inputAlign: PropTypes.oneOf(['inline']), // for radio espcially, where we align label and options on same row.
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayValue: PropTypes.string,
			id: PropTypes.string,
			name: PropTypes.string,
			value: PropTypes.string
		})
	),
	required: PropTypes.bool,
	value: PropTypes.object
};

export default CheckboxGroup;
