import React, { useCallback } from 'react';
import useCompanyAutocomplete from './useCompanyAutocomplete';
import PropTypes from 'prop-types';
// import { Company } from '@/types';
// import { HelpIcon } from '@/components/dinova';
import { Autocomplete, HelpIcon, Row } from 'components/atoms';
import { ReadOnlyInput } from 'components/molecules';

// export interface Company {
//   name: string;
//   code: string;
// }

// interface CompanyAutocompleteProps {
//   initialSelectedValue?: Company.Company;
//   name?: string;
// }

const CompanyAutocomplete = (
	{ initialSelectedValue, name = 'company_autocomplete', readOnly = false } /*: CompanyAutocompleteProps*/
) => {
	const getOptionLabel = useCallback(opt => opt.name || '', []);

	const isOptionEqualToValue = useCallback((option, value) => option.code === value?.code, []);

	const onFocus = e => {
		e.preventDefault();
		e.stopPropagation();
	};
	const onBlur = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	return readOnly ? (
		<ReadOnlyInput
			labelKey="Profile.personal.labels.company"
			value={initialSelectedValue?.name}
			dataCy="company-select"
		/>
	) : (
		<Row fullWidth align="flex-start">
			<Autocomplete
				dataCy="company-autocomplete"
				fullWidth
				name={name}
				label="Enter the name of your company"
				loadingText="Searching companies..."
				noOptionsText={didSearch => {
					return didSearch ? 'No suggestions' : 'Search companies';
				}}
				onBlur={onBlur}
				onFocus={onFocus}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={isOptionEqualToValue}
				initialSearch={initialSelectedValue?.name}
				initialSelectedValue={initialSelectedValue}
				// @ts-ignore
				query={useCompanyAutocomplete}
			/>
			<HelpIcon
				color="secondary"
				text={
					<p color="inherit" style={{ margin: 0 }}>
						{`If you don't see your company, try entering the name of your parent company or an alterate spelling. It may also be that your employer is not yet eligible for myDinova. If you are not able to find your company, you can contact us at `}
						<a href="mailto:support@myDinova.com">support@myDinova.com</a>.
					</p>
				}
			/>
		</Row>
	);
};

CompanyAutocomplete.propTypes = {
	initialSelectedValue: PropTypes.shape({
		name: PropTypes.string,
		code: PropTypes.string
	}),
	name: PropTypes.string,
	readOnly: PropTypes.bool
};

export default CompanyAutocomplete;
