import { asyncComponent } from 'hoc';

const LazyLoginPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "LoginPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyLoginPage;
