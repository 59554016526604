import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { ReferSharing } from 'components/organisms';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

/**
 * Displays the logged in users referral link if one is available. This component is styled specifically for the HelpFAQ page.
 * @param {FAQReferACoworkerProps} props
 */
const FAQReferACoworker = ({ className = '' }) => {
	const translate = useTranslate();
	const { user } = useUserContext();

	const { referral } = useMemo(
		() => ({
			referral: user?.urls?.referral || false
		}),
		[user]
	);

	const classProps = classnames('faq-referral', className);

	return referral ? (
		<div className={classProps}>
			<Title transform="none">{translate('Profile.referral.title')}</Title>
			<ReferSharing showLearnMore={true} link={referral} showSocial={false} />
		</div>
	) : null;
};

/**
 * FAQReferACoworker Props
 * @interface FAQReferACoworkerProps
 * @property {string} className
 * @property {func} translate
 */
FAQReferACoworker.propTypes = {
	className: PropTypes.string
};

export default FAQReferACoworker;
