import React, { createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hasProp } from 'utils/object';
import { Text } from 'components/atoms';
import { InputError } from 'components/molecules';
import { Button } from 'reactstrap';

const FileInput = ({
	className,
	dataCy,
	error,
	fileType,
	hasError,
	id,
	label = 'Lorem.. Label',
	name,
	onChange,
	placeholder = 'Lorem.. Placeholder',
	required,
	value
}) => {
	const inputRef = createRef();

	const handleInputClick = useCallback(
		e => {
			e.preventDefault();

			inputRef?.current && inputRef?.current.click();
		},
		[inputRef]
	);

	const fileName = hasProp(value, 'name') ? value.name : placeholder;
	const fileNameClassProps = classnames('label mb-0', required && 'required');

	return (
		<div
			className={classnames('file-input-wrapper', className && className)}
			id={id}
			data-cy={`file-input-wrapper-${dataCy}`}
		>
			<div className="file-input-container">
				<div className="flex align-center">
					<Button className="label" size="lg" color="primary" onClick={handleInputClick}>
						<label className="pointer mb-0" htmlFor={name} data-cy={`label-${dataCy}`}>
							{label}
						</label>
					</Button>
					<Text dataCy={`value-${dataCy}`} className={fileNameClassProps}>
						{fileName}
					</Text>
				</div>
				<input
					data-cy={`input-${dataCy}`}
					id={name}
					name={name}
					type="file"
					onChange={onChange}
					hidden="true"
					ref={inputRef}
					accept={fileType ? fileType : undefined}
				/>
			</div>
			{hasError && <InputError text={error} dataCy={`file-${name}-error`} />}
		</div>
	);
};

FileInput.defaultProps = {
	label: 'Lorem.. Label',
	placeholder: 'Lorem.. Placeholder'
};

FileInput.propTypes = {
	className: PropTypes.string,
	dataCy: PropTypes.string,
	error: PropTypes.string,
	fileType: PropTypes.string,
	hasError: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.object
};

export default FileInput;
