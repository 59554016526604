import { hasProp } from 'utils/object';
import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { safetyMeasuresFilterCheck } from 'utils/search_new/utils/filterFuncs';

const safetyPracticesFilterAggregate = (filter, restaurant, filterConfig) => {
	let sanitizationIds = [600, 602]; // All Staff Wear Masks, Contactless Payment;
	if (hasProp(restaurant, 'safetyMeasures.sanitization')) {
		restaurant.safetyMeasures.sanitization.forEach(opt => {
			if (sanitizationIds.indexOf(opt.id) > -1) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: opt.name,
					masterAccr: 'safetyMeasures',
					subAccr: 'sanitization',
					id: opt.id,
					value: opt.name,
					filterFunc: safetyMeasuresFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}

	let dinerScreeningIds = [700]; // Temperature Check;
	if (hasProp(restaurant, 'safetyMeasures.dinerScreening')) {
		restaurant.safetyMeasures.dinerScreening.forEach(opt => {
			if (dinerScreeningIds.indexOf(opt.id) > -1) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: opt.name,
					masterAccr: 'safetyMeasures',
					subAccr: 'dinerScreening',
					id: opt.id,
					value: opt.name,
					filterFunc: safetyMeasuresFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}

	let socialDistancingIds = [655]; // Outdoor Seating
	if (hasProp(restaurant, 'safetyMeasures.socialDistancing')) {
		restaurant.safetyMeasures.socialDistancing.forEach(opt => {
			if (socialDistancingIds.indexOf(opt.id) > -1) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: opt.name,
					masterAccr: 'safetyMeasures',
					subAccr: 'socialDistancing',
					id: opt.id,
					value: opt.name,
					filterFunc: safetyMeasuresFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}
};

export default safetyPracticesFilterAggregate;
