import COVIDSafetyMeasures from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	lastUpdated: hasProp(state, 'details.restaurant.dutyOfCareUpdateDate')
		? state.details.restaurant.dutyOfCareUpdateDate
		: false
});

export default connect(mapStateToProps)(COVIDSafetyMeasures);
