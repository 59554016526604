import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import FirstPage from './FirstPage';
import PhoneNumberPage from './PhoneNumberPage';
import CodePage from './CodePage';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { normalizeInput } from './utils';
import { useMFAModalPage, SCREEN_MAP } from './hooks';

const MFAModalPage = props => {
	const { config, modal } = props;
	const { hasError, initialPhoneNumber, isRequired, onDontAskChange, onCancel, onSubmit, screen } = useMFAModalPage(
		props
	);

	const { header, body, id, isOpen: modalIsOpen, secondaryButtonText } = modal;

	return (
		<Modal
			isOpen={modalIsOpen}
			toggle={() => {}}
			fade={true}
			centered
			id={id}
			className="mfa-modalpage"
			data-cy="mfa-modal"
		>
			<div className="Di_MyDinovaLogo mb-5">
				<img
					src={`${config.cdn_basePath}/logos/myDinova_logo_final.svg`}
					alt="myDinova Rewards logo"
					width="192px"
				/>
			</div>
			<Form
				validate={() => {}}
				onSubmit={onSubmit}
				initialValues={{
					phone: initialPhoneNumber || ''
				}}
				render={({ handleSubmit, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<OnChange name="dontAsk">{onDontAskChange}</OnChange>
							<div className={`wrapper screen-${SCREEN_MAP[screen]}`}>
								<div id="start" className="screen">
									<FirstPage
										body={body}
										header={header}
										secondaryButtonText={secondaryButtonText}
										onCancelClick={onCancel}
										isRequired={isRequired}
									/>
								</div>
								<div id="phoneNumber" className="screen">
									<PhoneNumberPage value={normalizeInput(values.phone)} onCancelClick={onCancel} />
								</div>
								<div id="code" className="screen">
									<CodePage phoneNumber={values.phone} onCancelClick={onCancel} error={hasError} />
								</div>
							</div>
						</form>
					);
				}}
			/>
		</Modal>
	);
};

MFAModalPage.propTypes = {
	config: PropTypes.object,
	/* eslint-disable-next-line react/no-unused-prop-types*/
	hasError: PropTypes.oneOf([false, PropTypes.oneOfType[PropTypes.string]]),
	/* eslint-disable-next-line react/no-unused-prop-types*/
	isLoginFlow: PropTypes.bool,
	modal: PropTypes.object.isRequired,
	/* eslint-disable-next-line react/no-unused-prop-types*/
	onPhoneNumberPageCancel: PropTypes.func,
	/* eslint-disable-next-line react/no-unused-prop-types*/
	section: PropTypes.string
};

export default MFAModalPage;
