import React, { useMemo, useState } from 'react';
import { ClientItem } from 'components/atoms';
import { useCompanyRosterQuery } from 'reactQuery/queries';

/**
 * Used in {@link DetailsPage} to display contact information.
 * @param {ClientInfoProps} props
 */
const ClientInfo = () => {
	const { data } = useCompanyRosterQuery();
	const [letter, setLetter] = useState('');

	const filtered = useMemo(() => {
		return letter === '' ? data : data.filter(client => client.name.toUpperCase().startsWith(letter));
	}, [data, letter]);

	const distinctLetters = useMemo(() => [...new Set(data.map(client => client.name.charAt(0)).sort())], [data]);

	return (
		<div className="details-contact">
			<div className="contact-letters mb-5">
				<span>
					<button onClick={setLetter.bind(this, '')}>All&nbsp;</button>
				</span>
				{distinctLetters.map((letter, i) => {
					return (
						<span key={`${i}`}>
							<button onClick={setLetter.bind(this, letter)}>
								{letter === '' ? 'All' : letter}&nbsp;
							</button>
						</span>
					);
				})}
			</div>
			<div className="contact-info">
				<ul>
					{filtered.map((client, i) => {
						return (
							<li key={`contact-listitem-${i}`}>
								<ClientItem key={`contact-info-${i}`} client={client} />
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

/**
 * {@link ClientInfo} Props
 * @interface Props_ClientInfo
 */
ClientInfo.propTypes = {};

export default ClientInfo;
