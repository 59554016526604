import BackButton from './ui-component';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';

const mapStateToProps = state => ({
	// lastSearch: state.searchPage.lastSearch,
	isCateringSearchFlow: state.landingCards.activeCard === '1'
	// cateringIntegrationType: state.catering.integrationType
});

export default withRouter(connect(mapStateToProps)(withLastLocation(BackButton)));
