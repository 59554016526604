import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RING_TYPES } from 'components/ecosystems/DashboardPage/useDashboardPage';
import { ConnectionsRingSVG, PointsGetConnectedRingSVG, PointsRingSVG } from './components';

const PointsRing = ({ className = '', points, ringType }) => {
	const classProps = classNames('points-ring', className);
	const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

	const ringComponent = useMemo(() => {
		if (ringType === RING_TYPES.NO_POINTS) {
			return <PointsRingSVG />;
		}

		if (ringType === RING_TYPES.NO_CONNECTIONS) {
			return <ConnectionsRingSVG />;
		}

		if (ringType === RING_TYPES.HAS_POINTS || ringType === RING_TYPES.NO_POINTS_NO_CONNECTIONS) {
			return <PointsGetConnectedRingSVG points={points} />;
		}
	}, [points, ringType]);

	const circleClassName = useMemo(() => {
		if (![RING_TYPES.HAS_POINTS, RING_TYPES.NO_POINTS_NO_CONNECTIONS].includes(ringType)) {
			return 'partial';
		}
		return 'circle-back';
	}, [ringType]);

	return (
		<div className={classProps + ' outer'} data-cy="points-ring-wrapper">
			<svg
				viewBox="0 0 140 140"
				preserveAspectRatio="xMaxYMin meet"
				width={isIE11 ? '380' : null}
				height={isIE11 ? '380' : null}
				data-cy="points-ring-svg"
			>
				<g>
					<circle
						r="48%"
						cx="50%"
						cy="50%"
						className={circleClassName}
						transform={isIE11 ? 'rotate(-61, 70, 70)' : null}
					/>
					{ringComponent}
				</g>
			</svg>
		</div>
	);
};

PointsRing.propTypes = {
	className: PropTypes.string,
	points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ringType: PropTypes.string.isRequired
};

export default PointsRing;
