/* eslint-disable no-case-declarations */
import * as t from 'actions/auto_enroll_user_form_page/types';

/**
 * Redux_AutoEnrollUserFormPageState
 * @interface Redux_AutoEnrollUserFormPageState
 * @property {string}  [state]
 */
const AutoEnrollUserFormPageDefaultState = {
	isLoading: false,
	complete: false,
	error: false,
	linkSending: false,
	linkSent: false,
	tokenStatus: false,
	token: null
};

export default (state = AutoEnrollUserFormPageDefaultState, action) => {
	switch (action.type) {
		case t.CHECK_AUTO_ENROLL_TOKEN_START: {
			return {
				...state,
				isLoading: true,
				loadingMessage: 'Validating token'
			};
		}
		case t.CHECK_AUTO_ENROLL_TOKEN_SUCCESS: {
			return {
				...state,
				isLoading: false,
				tokenStatus: action.payload.tokenStatus,
				token: action.payload.token
			};
		}
		case t.CHECK_AUTO_ENROLL_TOKEN_ERROR: {
			return {
				...state,
				isLoading: false,
				tokenStatus: action.payload
			};
		}
		case t.CREATE_AUTO_ENROLL_USER_START: {
			return {
				...state,
				isLoading: true,
				loadingMessage: 'Updating password'
			};
		}
		case t.CREATE_AUTO_ENROLL_USER_SUCCESS:
			return {
				...state,
				complete: true,
				error: false,
				isLoading: false
			};
		case t.CREATE_AUTO_ENROLL_USER_ERROR:
			return {
				...state,
				complete: false,
				error: action.payload,
				isLoading: false
			};
		case t.GENERATE_NEW_AUTO_ENROLL_TOKEN_START: {
			return {
				...state,
				linkSent: false,
				error: false,
				isLoading: true,
				loadingMessage: 'Generating token'
			};
		}
		case t.GENERATE_NEW_AUTO_ENROLL_TOKEN_SUCCESS: {
			return {
				...state,
				linkSent: true,
				isLoading: false
			};
		}
		case t.GENERATE_NEW_AUTO_ENROLL_TOKEN_ERROR: {
			return {
				...state,
				linkSent: false,
				error: action.payload,
				isLoading: false
			};
		}
		default:
			return state;
	}
};
