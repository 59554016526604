import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink, Link } from 'react-router-dom';

/**
 * Header content (tabs) to render inside of a {@link TabPanel}.
 * @param {TabPanelHeaderProps} props
 */
const TabPanelHeader = ({ className, match, tabs, tabStyle }) => {
	const classProps = classnames('tab-panel-header', tabStyle, className);

	return tabs.length > 0 ? (
		<div className={classProps}>
			<div className="tabs">
				{tabs.map((tab, i) => {
					const [URL, isActive] = tab.applyURLParams(tab, match);
					const activeClass = classnames('tab', isActive && 'active');
					if (tab.hidden === 'true' || tab.hidden === true) {
						return null;
					}
					if (tab.useHref) {
						// use a tag
						const href = `${window.location.origin}${URL}`,
							isActive = window.location.href.indexOf(URL) !== -1,
							classProps = classnames('tab', isActive && 'active');
						return (
							<a className={classProps} href={href} key={href}>
								{tab.title}
							</a>
						);
					} else {
						// use react-router nav link
						return (
							<NavLink key={`tab-${i}-${tab.title}`} to={URL} className={activeClass} exact tag={Link}>
								{tab.title}
							</NavLink>
						);
					}
				})}
			</div>
		</div>
	) : null;
};

TabPanelHeader.defaultProps = {
	tabs: [],
	tabStyle: ''
};

/**
 * {@link TabPanelHeader}
 * @interface Props_TabPanelHeader
 * @property {object} match React router match object
 * @property {Tab[]} tabs
 */
TabPanelHeader.propTypes = {
	className: PropTypes.string,
	match: PropTypes.object.isRequired,
	tabs: PropTypes.array,
	tabStyle: PropTypes.string
};

export default TabPanelHeader;
