import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PageHeaderLinks = ({ className, children }) => {
	const classProps = classNames('page-header-links', className);

	return <div className={classProps}>{children}</div>;
};

PageHeaderLinks.defaultProps = {
	className: ''
};

PageHeaderLinks.propTypes = {
	// Required
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.oneOf([false])]).isRequired,

	// Optional
	className: PropTypes.string
};

export default PageHeaderLinks;
