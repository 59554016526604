const commonConfig = require('./config.common');
const { merge } = require('lodash');

module.exports = function(options) {
	return merge({}, commonConfig(options), {
		// dev config here
		api_basePath_myDinova: 'https://dev.dinova.com/api-mydinova/v1',
		api_basePath_restaurant: 'https://dev.dinova.com/api-search/v2/',
		api_basePath_restaurant_v1: 'https://dev.dinova.com/api-restaurant/v1/',
		api_basePath_dashboard: 'https://dev.dinova.com/api-dashboard/v1/',
		api_basePath_zuppler: '//order.biznettechnologies.com',
		zuppler_dataChannelPath: 'http://api.zuppler.com/v3/channels/conshymenus.json',
		cdn_basePath: 'https://dinova-images-dev.imgix.net',
		imageBaseUrl: 'https://dinova-images-dev.imgix.net',
		content_paths: [
			process.env.REACT_APP_USE_DEV_LANG_CONFIG
				? 'http://localhost:5001/'
				: 'https://public-assets.dev.dinova.com/mydinova/v2/apps.json'
			// 'https://s3.amazonaws.com/dinova-public-assets-dev/mydinova/mydinova_copy.json',
			// 'https://s3.amazonaws.com/dinova-public-assets-dev/mydinova/search_dinova_copy.json'
		], // translation strings
		web_basePath: 'https://web.dev.dinova.com/',
		currentEnv: 'dev',
		color: 'green',
		ga_key: 'UA-47151707-6',
		gtm_key: 'GTM-576P6RK', // -- from head (feature/merge-web)
		api_key: 'AIzaSyDqABjy832-iA5bPCaMxHjn7TA3aBqLTbk',
		header_logo_link: 'https://www.dinova.com/',
		// gtm_key: 'GTM-576P6RK' -- same as prod - from master
		federated_identity_pool: 'us-east-1:db7b8603-a9b2-436e-b5a6-094dccc5119c',
		aws_connect_bucket: 'dinova-client-portal-storage-dev'
	});
};
