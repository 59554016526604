// import Promise from 'bluebird';
import { chain, filter, take } from 'lodash';

export const LOCAL_STORAGE_RECENT_SEARCH_KEY = '-recentSearches';
export const loadRecentSearches = (hasGeolocation, recent = 'all', localStorageKey) => {
	// load recent searches from localStorage
	let recentSearches =
		JSON.parse(window.localStorage.getItem(`${localStorageKey}${LOCAL_STORAGE_RECENT_SEARCH_KEY}`)) || [];

	// filter by recent
	if (recent !== 'all') {
		recentSearches = filter(recentSearches, item => {
			return item[recent] && item[recent].length > 0;
		});
	}

	recentSearches = chain(recentSearches)
		.reverse()
		.uniqBy(recent === 'all' ? 'message' : recent)
		.value();

	// get last 5 and filter it if not allow geolocation
	recentSearches = hasGeolocation
		? take(recentSearches, 5)
		: chain(recentSearches)
				.filter(item => {
					if (recent === 'near') {
						return item.near !== '';
					} else {
						return true;
					}
				})
				.take(5)
				.value();

	// reverse list
	return recentSearches;
};

const updateLocalStorageArray = (key, data) => {
	let dataArray = JSON.parse(window.localStorage.getItem(key)) || [];
	dataArray.push(data);
	window.localStorage.setItem(key, JSON.stringify(dataArray));
};

export const updateRecentSearchs = (key, data, allowDuplicate = false) => {
	const LOCAL_STORAGE_KEY = `${key}${LOCAL_STORAGE_RECENT_SEARCH_KEY}`;

	let currentSearchesString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
	if (!allowDuplicate) {
		let dataString = JSON.stringify(data);
		if (currentSearchesString && currentSearchesString.indexOf(dataString) > -1) {
			return;
		} else {
			updateLocalStorageArray(LOCAL_STORAGE_KEY, data);
		}
	} else {
		updateLocalStorageArray(LOCAL_STORAGE_KEY, data);
	}
};
