import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserMutationProps {}
*/

const useUserConnectionDeleteAllMutation = ({ onError, onSuccess, setErrorInRedux } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async connectionIds => {
			const result = await Promise.allSettled(
				connectionIds.map(async connectionId => {
					try {
						const response = await axios.delete(
							`${basePath()}/users/connections/${connectionId}`,
							AUTH_REQUEST_HEADER()
						);
						return response.data.response;
					} catch (err) {
						console.error(`I'm down, this time. ${err}`);
					}
				})
			);
			return await result;
			// return axios.post(`${basePath()}/users/connectionIdss`, JSON.stringify(connectionIds), AUTH_REQUEST_HEADER());
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'removing payment information'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			setErrorInRedux && setErrorInRedux(error);

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			const allPassed = data.filter(d => d.status === 'fulfilled').length === data.length;
			dispatch(setIsLoading(false));

			if (!allPassed) {
				// rollBackMutation();

				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({
					queryKey: [USER_QUERY_KEY]
				});
				queryClient.refetchQueries({
					queryKey: [USER_QUERY_KEY]
				});

				onSuccess && onSuccess(data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		deleteConnections: mutation.mutate
	};
};

export default useUserConnectionDeleteAllMutation;
