import React, { useMemo } from 'react';
import { useTranslate } from 'hooks';
import useGeneralContext from 'context/GeneralContext';
import { useUserContext } from 'context';

const WelcomePhrase = () => {
	const {
		authState: { isAuthed }
	} = useGeneralContext();
	const translate = useTranslate();
	const { user } = useUserContext();
	const { firstName, points } = useMemo(
		() => ({
			firstName: user?.name?.first || '',
			points: user?.points || '0'
		}),
		[user]
	);

	//
	const userName = ` ${firstName || ''} • `,
		_points = (points && Number.parseInt(points).toLocaleString() + ' points') || '',
		useSimpleHeader =
			window.location.hash.indexOf('/checkout/') > -1 || window.location.hash.indexOf('/start/') > -1;

	return isAuthed && !useSimpleHeader ? (
		<span className="welcome-phrase" data-cy="welcome-phrase">
			<span className="welcome">{translate('header.welcome')}</span>
			{userName}
			<span data-cy="points">{_points}</span>
		</span>
	) : null;
};

export default WelcomePhrase;
