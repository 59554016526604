import { useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'hooks';
import { useCompanyByCodeQuery } from 'reactQuery/queries';
import useGeneralContext from 'context/GeneralContext';
import { toggleModal } from 'actions/modal';
import { useDispatch } from 'react-redux';
import { getInitialValuesFromUser, validate } from '../utils';
import { history } from 'utils/router';
import { useLocation } from 'react-router-dom';
import { useUserConnectionMutation, useUserMutation } from 'reactQuery/mutations';
import { hasProp } from 'utils/object';
import { useUserContext } from 'context';

// interface useCreateConnectionFormProps {}

const useCreateConnectionForm = ({ nextStep, onSubmitSuccess } /*:useCreateConnectionFormProps*/) => {
	const dispatch = useDispatch();
	const translate = useTranslate();
	const location = useLocation();
	// const { isAdminUser } = useAuthState();
	const { user /*isLoading: userIsLoading*/ } = useUserContext();
	const { isDoDMode } = useGeneralContext();

	const [createError, setCreateError] = useState(false);
	const clearError = useCallback(() => {
		setCreateError(false);
	}, []);

	// useConfirmDiscardModal();

	const getError = useCallback(
		code => {
			let message;

			if (code) {
				switch (Number.parseInt(code)) {
					case 409:
						message = translate(`errors.codes.${code}.message_2`);
						break;
					default:
						message = translate(`errors.codes.${code || 500}.message`);
						break;
				}
			} else {
				message = translate('errors.page_level_message');
			}

			return message;
		},
		[translate]
	);

	const { updateUser } = useUserMutation();
	const { createConnection } = useUserConnectionMutation({
		onSuccess: onSubmitSuccess
	});

	const companyQuery = useCompanyByCodeQuery({ companyCode: user?.company?.code });
	const company = companyQuery?.data;

	const isJenna = hasProp(user, 'userName') && user.userName === 'yellowbananas';

	const _companyName = company?.companyName || user?.company?.name || '';

	const dodConnection = useCallback(
		values => ({
			company: values.company.code,
			id: values.selectedCard.id,
			number: values.cardNumber,
			firstName: values.firstName,
			middleName: values.middleName,
			lastName: values.lastName
		}),
		[]
	);

	const userConnection = useCallback(
		values => ({
			company: values.company.code,
			id: values.selectedCard.id,
			nameOnCard: values.nameOnCard,
			number: values.cardNumber,
			...(values.empId ? { employeeId: values.empId } : {}),
			...(values.email ? { email: values.email } : {})
		}),
		[]
	);

	const onSubmitError = useCallback(
		(e, cb) => {
			setCreateError(getError(e?.response?.status));
			cb && cb();
		},
		[setCreateError, getError]
	);

	const onSubmit = useCallback(
		(values, _, completeSubmit) => {
			setCreateError(null);
			let connection = userConnection(values);

			if (isDoDMode) {
				connection = dodConnection(values);
			}

			createConnection(connection, {
				onSuccess: completeSubmit,
				onError: e => {
					onSubmitError(e, completeSubmit);
				}
			});
		},
		[createConnection, dodConnection, userConnection, isDoDMode, onSubmitError]
	);

	const initialValues = useMemo(() => getInitialValuesFromUser({ company, user }), [company, user]);
	const isRegistration = useMemo(() => location.pathname.indexOf('register') > -1, [location]);
	const maxConnectionsReached = useMemo(() => user?.connections?.length >= 10, [user]);

	const onNextClick = useCallback(
		({ dirty, valid, values }) => {
			setCreateError(null);
			if (!dirty) {
				proceed();
			} else {
				if (valid) {
					let onSuccess = proceed;
					let connection = userConnection(values);

					if (isDoDMode) {
						// TODO: Move updating the user name to the BE
						// update user's first, middle, last to be the same as here.
						onSuccess = updateUserThenProceed;
						connection = dodConnection(values);
					}

					return createConnection(connection, {
						onSuccess,
						onError: onSubmitError
					});
				} else {
					if (isDoDMode) {
						if (!values.firstName && !values.middleName && !values.lastName && !values.cardNumber) {
							proceed();
							return;
						}
					} else {
						if (!values.nameOnCard && !values.cardNumber) {
							proceed();
							return;
						}
					}
					// has errors
					// show modal warning of unsaved changes
					dispatch(toggleModal(true));
				}
			}
		},
		[
			createConnection,
			dispatch,
			dodConnection,
			userConnection,
			isDoDMode,
			proceed,
			updateUserThenProceed,
			onSubmitError
		]
	);

	const updateUserThenProceed = useCallback(
		values => {
			// TODO: Move updating the user info to the BE
			updateUser(
				{
					name: {
						first: values.firstName,
						middle: values.middleName,
						last: values.lastName
					}
				},
				{
					onSuccess: proceed
				}
			);
		},
		[updateUser, proceed]
	);

	const proceed = useCallback(() => {
		if (!isRegistration) {
			history.push('/profile');
		} else {
			nextStep();
		}
	}, [isRegistration, nextStep]);

	return {
		_companyName,
		clearError,
		company,
		createError,
		initialValues,
		maxConnectionsReached,
		onNextClick,
		onSubmit,
		validate,
		isSuperUser: isJenna
	};
};

export default useCreateConnectionForm;
