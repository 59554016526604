import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import CompanySelectModal from './ui-component';
import { useUserMutation } from 'reactQuery/mutations';
import { useUserContext } from 'context';

/*
interface CompanySelectModalDataWrapperProps {

}
*/

const CompanySelectModalDataWrapper = (props /*: CompanySelectModalDataWrapperProps*/) => {
	const { user } = useUserContext();

	const moreProps = useMemo(
		() => ({
			user,
			userCompany: user?.company
		}),
		[user]
	);

	const { updateUser } = useUserMutation();

	const _updateUser = useCallback(
		(userData, cb) => {
			updateUser(userData, {
				onSuccess: cb
			});
		},
		[updateUser]
	);

	return <CompanySelectModal {...props} {...moreProps} updateUser={_updateUser} />;
};

CompanySelectModalDataWrapper.propTypes = {
	company: PropTypes.string
};

export default CompanySelectModalDataWrapper;
