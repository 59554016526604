import React from 'react';
import PropTypes from 'prop-types';
import ICONS from './payment-icons';

/**
 * List the payment types accepted by a restaurant.
 * @param {PaymentTypesProps} props
 */
const PaymentTypes = ({ cards }) => {
	return cards.length ? (
		<div className="payment-types">
			{cards.map((card, i) => {
				const iconCardName = card.name.toLowerCase();

				return ICONS[iconCardName] ? (
					<div
						key={`payment-type-${i}`}
						className="payment-type flex justify-start align-center pt-2 pb-2"
						data-cy={`payment-type-${ICONS[iconCardName].title}`}
					>
						<img
							className="credit-card-img"
							src={ICONS[iconCardName].icon}
							alt={''}
							data-cy={`payment-type-icon-${ICONS[iconCardName].title}`}
						/>
						<span className="credit-card-name" data-cy={`payment-type-name-${ICONS[iconCardName].title}`}>
							{ICONS[iconCardName].title}
						</span>
					</div>
				) : null;
			})}
		</div>
	) : null;
};

PaymentTypes.defaultProps = {
	cards: []
};

/**
 * Cards used in {@link PaymentTypes} and {@link PaymentTypesProps}
 * @interface ICard
 * @property {string} name The name of the card. Ex: "Visa".
 */

/**
 * {@link PaymentTypes} Props
 * @interface Props_PaymentTypes
 * @property {Card[]} [cards=[]]
 */
PaymentTypes.propTypes = {
	// Optional
	cards: PropTypes.array
};

export default PaymentTypes;
