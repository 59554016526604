import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { DashboardGetConnectedButtons, PointsRing } from 'components/molecules';
import { RING_TYPES } from 'components/ecosystems/DashboardPage/useDashboardPage';
import { history } from 'utils/router';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

const DashboardPoints = ({
	points,
	primaryButtonText,
	secondaryButtonText,
	singleButtonText,
	ringType,
	onClick,
	androidVersion
}) => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const companyCode = user?.company?.code;

	const handleClick = e => {
		e.preventDefault();

		if (window.Android) {
			window.Android.showSearch();
		} else {
			history.push('/');
		}
	};

	const canRedeem = translate('cobranding.companies.' + companyCode + '.allows_redemption') == 'false' ? false : true;

	return (
		<div className="container-fluid">
			<Row>
				<Col md="12">
					<PointsRing points={points} ringType={ringType} />
				</Col>
			</Row>
			{ringType === RING_TYPES.NO_POINTS_NO_CONNECTIONS && (
				<DashboardGetConnectedButtons canRedeem />
				/*<Row className="Di_mt5px Di_ActionBttns">
					<Col xs="12">
						<Button className="z-depth-0" color="primary" onClick={() => history.push('/rewards')}>
							{primaryButtonText}
						</Button>

						<Button className="z-depth-0" onClick={() => history.push('/history')}>
							{secondaryButtonText}
						</Button>
					</Col>
				</Row>
				*/
			)}
			{ringType === RING_TYPES.NO_POINTS && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button
							className="z-depth-0"
							color="primary"
							onClick={handleClick}
							id="no-points-button"
							data-cy="get-points-btn"
						>
							{singleButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{ringType === RING_TYPES.HAS_POINTS && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col xs="12">
						{canRedeem && (
							<Button
								className="z-depth-0"
								color="primary"
								onClick={() => history.push('/rewards')}
								id="points-rewards-link"
								data-cy="get-rewards-btn"
							>
								{primaryButtonText}
							</Button>
						)}

						<Button
							className="z-depth-0"
							onClick={() => history.push('/history')}
							id="points-history-link"
							data-cy="view-history-btn"
						>
							{secondaryButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{ringType === RING_TYPES.NO_CONNECTIONS && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button
							className="z-depth-0"
							color="primary"
							onClick={onClick}
							id="no-connections-link"
							data-cy="create-connection-btn"
						>
							{singleButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{androidVersion && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button
							className="z-depth-0"
							onClick={() => history.push('/profile')}
							data-cy="go-to-profile-btn"
						>
							Profile
						</Button>
					</Col>
				</Row>
			)}
		</div>
	);
};

DashboardPoints.propTypes = {
	points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	primaryButtonText: PropTypes.string.isRequired,
	secondaryButtonText: PropTypes.string.isRequired,
	singleButtonText: PropTypes.string.isRequired,
	ringType: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	androidVersion: PropTypes.bool.isRequired
};

export default DashboardPoints;
