import RegisteredConnections from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => {
	return {
		imageBaseUrl: state.config.imageBaseUrl
	};
};

export default connect(mapStateToProps /*, mapDispatchToProps*/)(RegisteredConnections);
