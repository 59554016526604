import * as t from './types';

/**
 * Toggles the open/close state of the mobile hamburger nav menu.
 */
export const toggleMobileNav = () => {
	return {
		type: t.TOGGLE_NAV
	};
};

/**
 * Set which header to use, desktop or tablet/mobile.
 * @param  {boolean} useMinimalHeaderFooter        If to show hamburger menu. Shown when in tablet/mobile view.
 */
export const updateHeader = useMinimalHeaderFooter => {
	return {
		type: t.UPDATE_HEADER,
		payload: { useMinimalHeaderFooter }
	};
};

/**
 * Set if user is using an android device.
 * @param {boolean} payload If user is using android device or not.
 */
export const setAndroid = payload => ({ type: t.SET_ANDROID, payload });

/**
 * Set if user is using an android device.
 * @param {boolean} payload If user is using android device or not.
 */
export const setMinimalLayout = payload => ({ type: t.SET_MINIMAL_LAYOUT, payload });
