import React from 'react';
import classnames from 'classnames';
import { LoadingBase } from 'components/atoms';
import { BasicModal, PageError } from 'components/molecules';
import { useTranslate } from 'hooks';
import useGeneralContext from 'context/GeneralContext';
import useRecommendationOptionsContext from 'reactQuery/context/RecommendationOptionsContext';
import PositiveRecommendationFormFields from './components/PositiveRecommendationFormFields/index.js';
import NegativeRecommendationFormFields from './components/NegativeRecommendationFormFields/index.js';
import RecommendationsModalHeader from './components/RecommendationsModalHeader';
import RecommendationsModalFooter from './components/RecommendationsModalFooter';
import { Form } from 'react-final-form';
import useRecommendationForm from './hooks/useRecommendationForm';

import { Row } from 'components/atoms';
import styles from './style.module.scss';
import isBool from 'utils/isBool.js';

const RecommendationsModal = () => {
	const translate = useTranslate();
	const {
		authState: { isAuthed },
		recommendationModal: { formError, show, type }
	} = useGeneralContext();

	const { initialValues, isMutating, onCancel, onSubmit, onTypeSwitch, validate } = useRecommendationForm();

	const { isLoading } = useRecommendationOptionsContext();

	const recPages = ['restaurants', 'history', 'recommendations'];
	let isRecPage = false;
	recPages.forEach(page => {
		if (window.location.pathname.indexOf(page) > -1) {
			isRecPage = true;
		}
	});

	const showModal = show && isRecPage;

	return isAuthed ? (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
			render={({ errors, dirtyFieldsSinceLastSubmit, form, handleSubmit, submitFailed }) => {
				const showNoneSelectedError =
					(isBool(dirtyFieldsSinceLastSubmit?.for) ? dirtyFieldsSinceLastSubmit?.for : true) &&
					submitFailed &&
					errors.noneSelected;

				return (
					<form onSubmit={handleSubmit}>
						<BasicModal
							show={showModal}
							showClose={false}
							className={classnames(styles.recommendationsModal, 'recommendations_modal_new')}
							dataCy="recommendations-modal"
						>
							{/* Header */}
							<div className={styles.header}>
								<RecommendationsModalHeader onTypeSwitch={onTypeSwitch.bind(this, form.restart)} />
							</div>

							{/* Content */}
							<div className={classnames(styles.content, 'custom-scroll')}>
								{!isMutating && isLoading && (
									<Row fullWidth justify="center" align="center" p="25% 0 0 ">
										<LoadingBase message={translate('Recommendations.loadingForm')} />
									</Row>
								)}
								{isMutating && <LoadingBase message={translate('Recommendations.submittingForm')} />}
								{!isLoading && (
									<div className={styles.recForm}>
										{type === 'positive' ? (
											<PositiveRecommendationFormFields />
										) : (
											<NegativeRecommendationFormFields />
										)}
									</div>
								)}
								{showNoneSelectedError && (
									<div className={styles.formError} data-cy="form-error">
										<PageError message={translate('Recommendations.none-selected')} />
									</div>
								)}
								{formError && (
									<div className={styles.formError} data-cy="form-error">
										<PageError message={formError.msg} />
									</div>
								)}
							</div>

							{/* Footer */}
							<div className={styles.footer}>
								<RecommendationsModalFooter
									isMutating={isMutating}
									onCancel={onCancel.bind(this, form.restart)}
								/>
							</div>
						</BasicModal>
					</form>
				);
			}}
		/>
	) : null;
};

RecommendationsModal.defaultProps = {};

// RecommendationsModal.propTypes = {
// };

export default RecommendationsModal;
