/**
 *
 * @param {boolean | object} isForUI When validate is used for form fields, pass isForUI = true.  When for FormFieldRequirements, pass form values for isForUI.
 * @param {object | undefined} vals When validate is used for form fields, this will be the form values.  When used for FormFieldRequirements, this will be undefined.
 * @returns
 */

const uiErrors = ['Required', 'Passwords do not match'];

const validateEditSecurityForm = (isForUI, vals) => {
	let valsToCheck = vals;
	let _isForUI = isForUI;
	if (!vals) {
		valsToCheck = isForUI;
		_isForUI = false;
	}

	const { confirmPassword = '', currentPassword = '', newPassword = '' } = valsToCheck;
	const errors = {
		confirmPassword: [],
		currentPassword: [],
		newPassword: []
	};

	const newPasswordContainsNumber = newPassword.match(/\d/);
	const newPasswordContainsLowercase = newPassword.match(/[a-z]/);
	const newPasswordContainsUppercase = newPassword.match(/[A-Z]/);
	const newPasswordContainsSpecial = newPassword.match(/(?=.*[!@#$%^&*-])/);

	// New Password
	if (!newPassword || !newPassword.length) {
		errors.newPassword.push('Required');
	}
	if (newPassword.length < 10) {
		errors.newPassword.push('length');
	}
	if (!newPasswordContainsNumber) {
		errors.newPassword.push('contains_number');
	}
	if (!newPasswordContainsLowercase) {
		errors.newPassword.push('contains_lowercase');
	}
	if (!newPasswordContainsUppercase) {
		errors.newPassword.push('contains_uppercase');
	}
	if (!newPasswordContainsSpecial) {
		errors.newPassword.push('contains_special');
	}

	// New and Confirm Password
	if (newPassword && confirmPassword && newPassword !== confirmPassword) {
		errors.newPassword.push(_isForUI ? 'Passwords do not match' : 'mismatch');
		errors.confirmPassword.push(_isForUI ? 'Passwords do not match' : 'mismatch');
	}

	// Confirm Password
	if (!confirmPassword || !confirmPassword.length) {
		errors.confirmPassword.push('Required');
	}

	// Current Password
	if (!currentPassword.length) {
		errors.currentPassword.push('Required');
	}

	Object.entries(errors).forEach(([key, value]) => {
		if (_isForUI) {
			errors[key] = errors[key].filter(error => uiErrors.includes(error));
		}
		if (value.length === 0) {
			if (_isForUI) {
				delete errors[key];
			}
		}
	});

	return errors;
};

export default validateEditSecurityForm;
