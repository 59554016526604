import { useEffect, useMemo } from 'react';
import { useUsersQuery } from 'reactQuery/queries';
import usePrevious from './usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';

// interface useUserProps {}

const useUser = (/*: useUserProps*/) => {
	const { show } = useSelector(state => state.pointsElegibilityModal);
	const dispatch = useDispatch();
	const userQuery = useUsersQuery();
	const prevCompany = usePrevious(userQuery?.data?.company);
	const prevCompanyCode = usePrevious(userQuery?.data?.company?.code);
	const currentCompany = useMemo(() => userQuery?.data?.company, [userQuery?.data?.company]);
	const currentCompanyCode = useMemo(() => userQuery?.data?.company?.code, [userQuery?.data?.company?.code]);

	useEffect(() => {
		if (prevCompanyCode && currentCompanyCode && prevCompanyCode !== currentCompanyCode) {
			/*
			 If the user changes companies to one that is not eligible for myDinova rewards,
			 show a modal explaining.
			*/
			if (!show && !currentCompany?.myDinova?.eligible) {
				dispatch(toggleShowPointsElegibilityModal(true));
			}
		}
	}, [dispatch, prevCompany, prevCompanyCode, currentCompany, currentCompanyCode, show]);

	//
	// ---***--- Uncomment to force an mfaRequired company ---***---
	//
	// const userData = useMemo(() => {
	// 	return {
	// 		...userQuery?.data,
	// 		company: {
	// 			...userQuery?.data?.company,
	// 			security: {
	// 				...userQuery?.data?.company?.security,
	// 				mfaRequired: true
	// 			}
	// 		}
	// 	};
	// }, [userQuery?.data]);

	const value = useMemo(
		() => ({
			...userQuery,
			isLoading: ['pending', 'loading'].includes(userQuery.status),
			isRefetching: userQuery.isRefetching && userQuery.status !== 'success',
			user: userQuery?.data
		}),
		[userQuery]
	);

	return value;
};

export default useUser;
