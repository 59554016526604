import createSagaMiddleware from 'redux-saga';
import reduxMulti from 'redux-multi';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'reducers';
import moment from 'moment';
import { refresh } from 'actions/users';
import isNodeEnv from 'utils/isNodeEnv';
import { isEqual } from 'lodash';

export const LOGGER = createLogger({ collapsed: true });
export const SAGA = createSagaMiddleware();

let previousAction = null;
const thing = (/*store*/) => next => action => {
	if (isEqual(previousAction, action)) {
		// console.log('dispatching duplicate - previous action', previousAction);
		// console.log('dispatching duplicate - new action', action);
		// console.log('next state', store.getState());
	}
	previousAction = action;
	// console.log('dispatching', action);
	let result = next(action);

	return result;
};

export const checkToken = store => next => action => {
	if (
		action.type !== 'REFRESH_USER_RUNNING' &&
		action.type !== 'REFRESH_TIME' &&
		action.type !== 'REFRESH_USER' &&
		action.type !== 'AUTH_USER'
	) {
		const tokenStart = store.getState().users.authenticated_time;
		const duration = moment().diff(tokenStart, 'minutes');
		if (tokenStart) {
			if (duration >= 10) {
				if (!store.getState().users.refreshingUser) {
					store.dispatch(refresh());
				}
			}
		}
	}

	let result = next(action);
	return result;
};

const doApplyMiddleware = () => {
	return applyMiddleware(thunk, reduxMulti, SAGA, /*LOGGER,*/ /*this.logger,*/ thing, checkToken);
};

// <--- Redux Beacon
const store = isNodeEnv('development') ? composeWithDevTools(doApplyMiddleware()) : doApplyMiddleware();
// create store with dev tools and middleware
export const STORE = createStore(rootReducer, store);
