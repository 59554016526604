import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

/*
interface CancelModalProps {

}
*/

const CancelModal = ({ confirmCancel, denyCancel, show } /*: CancelModalProps*/) => {
	const translate = useSelector(state => getTranslate(state.locale));

	return (
		<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
			<ModalHeader>{translate(`ConnectToPoints.modals.cancel.header`)}</ModalHeader>
			<ModalBody>{translate(`ConnectToPoints.modals.cancel.body`)}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							{/* Go Back To Select Company Button */}
							<Button block color="primary" onClick={denyCancel}>
								{translate(`ConnectToPoints.modals.cancel.primaryButtonText`)}
							</Button>
						</Col>
						<Col sm="12">
							{/* Cancel Select Company Button */}
							<Button block color="secondary" onClick={confirmCancel}>
								{translate(`ConnectToPoints.modals.cancel.secondaryButtonText`)}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

CancelModal.propTypes = {
	confirmCancel: PropTypes.func.isRequired,
	denyCancel: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired
};

export default CancelModal;
