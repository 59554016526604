import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useTranslate } from 'hooks';
import styles from './style.module.scss';

const EditProfileActions = ({
	cancelButtonText,
	className,
	errorMsg,
	isLoading,
	onUpdate,
	onCancel,
	reverse,
	updateButtonText,
	updateButtonDisabled
}) => {
	const translate = useTranslate();
	const classProps = classNames('edit-profile-actions', className);
	const submitButtonProps = useMemo(
		() => ({
			type: onUpdate ? undefined : 'submit',
			onClick: onUpdate,
			disabled: updateButtonDisabled
		}),
		[onUpdate, updateButtonDisabled]
	);

	return reverse ? (
		<>
			{errorMsg && (
				<div className={styles.formError} data-cy="form-error-message">
					{errorMsg}
				</div>
			)}
			<div className={classProps}>
				<Button onClick={onCancel} disabled={isLoading} data-cy="cancel">
					{cancelButtonText ? cancelButtonText : translate('common.cancelBtn')}
				</Button>
				<Button disabled={isLoading} {...submitButtonProps} color="primary" data-cy="update">
					{updateButtonText ? updateButtonText : translate('common.updateBtn')}
				</Button>
			</div>
		</>
	) : (
		<>
			{errorMsg && (
				<div className={styles.formError} data-cy="form-error-message">
					{errorMsg}
				</div>
			)}
			<div className={classProps}>
				<Button disabled={isLoading} {...submitButtonProps} color="primary" data-cy="update">
					{updateButtonText ? updateButtonText : translate('common.updateBtn')}
				</Button>
				<Button onClick={onCancel} disabled={isLoading} data-cy="cancel">
					{cancelButtonText ? cancelButtonText : translate('common.cancelBtn')}
				</Button>
			</div>
		</>
	);
};

EditProfileActions.defaultProps = {
	className: '',
	updateButtonDisabled: false
};

EditProfileActions.propTypes = {
	cancelButtonText: PropTypes.string,
	className: PropTypes.string,
	errorMsg: PropTypes.string,
	isLoading: PropTypes.bool,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	reverse: PropTypes.bool,
	updateButtonText: PropTypes.string,
	updateButtonDisabled: PropTypes.bool
};

export default EditProfileActions;
