import ProfilePaymentInfo from './ui-component';
import { connect } from 'react-redux';
import { setModal, toggleModal } from 'actions/modal';
import { setCardToEdit } from 'actions/edit_payment';

const mapStateToProps = state => ({
	modal: state.modal
});

const mapDispatchToProps = dispatch => ({
	setCardToEdit: card => dispatch(setCardToEdit(card)),
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePaymentInfo);
