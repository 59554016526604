import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from 'components/atoms';

/**
 * List item to render inside of {@link OptionsList}
 * @param {OptionItemProps} props
 */
const OptionItem = ({
	className,
	dataCy,
	getIcon,
	getImage,
	getOptionClass,
	getOptionText,
	option,
	useIcon,
	useImage
}) => {
	const classProps = classNames('option-item', className, getOptionClass && getOptionClass(option)),
		optionText = getOptionText ? getOptionText(option) : option;

	return (
		<li className={classProps} data-cy={`option-item-${dataCy}`}>
			{useIcon && <i className={getIcon(option)} />}
			{useImage && <img src={getImage(option)} alt={optionText} />}
			<Text className="mb-0">{optionText}</Text>
		</li>
	);
};

OptionItem.defaultProps = {
	className: '',
	option: ''
};

/**
 * {@link OptionItem} Props
 * @interface PropsOptionItem
 * @property {string} [className=""] Additional classNames to add to the list item.
 * @property {string} [option=""] The text to display as the option.
 */
OptionItem.propTypes = {
	className: PropTypes.string,
	dataCy: PropTypes.string,
	option: PropTypes.string,
	useIcon: PropTypes.bool,
	getIcon: PropTypes.func, // is required if useIcon === true
	getOptionClass: PropTypes.func,
	getOptionText: PropTypes.func,
	useImage: PropTypes.bool,
	getImage: PropTypes.string // required if useImage === true
};

export default OptionItem;
