import React, { useEffect } from 'react';
import { Text } from 'components/atoms';
import styles from './style.module.scss';
import StepTemplate from '../../StepTemplate';
import PropTypes from 'prop-types';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { useUserMutation } from 'reactQuery/mutations';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';

const TRANSLATE_BASE_KEY = 'ProfileSetup.modals.finish';

const ProfileSetupFinishStep = ({ completeOnboarding }) => {
	const translate = useTranslate();
	const { updateUser } = useUserMutation();
	const points = translate('ProfileSetup.points');
	const { user } = useUserContext();
	const viewCompletion = user?.onboardingStatus?.viewCompletion;

	useEffect(() => {
		if (!viewCompletion) {
			updateUser({ onboardingStatus: { viewCompletion: true } });
		}
	}, [viewCompletion, updateUser]);

	return (
		<StepTemplate
			title={translate(`${TRANSLATE_BASE_KEY}.title`)}
			variant={ProfileSetupSteps.FINISH}
			onSubmit={completeOnboarding}
		>
			<div className={styles.content}>
				<Text className={styles.subTitle} weight="bold">
					{translate(`${TRANSLATE_BASE_KEY}.body`, { points })}
				</Text>
			</div>
		</StepTemplate>
	);
};

ProfileSetupFinishStep.propTypes = {
	completeOnboarding: PropTypes.func
};

export default ProfileSetupFinishStep;
