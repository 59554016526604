import SearchTopBar from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV, isMV } from 'utils/sizes';

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes)
});

const mapStateToProps = state => ({
	hasGeoLocation: state.geoLocation.hasGeoLocation
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(SearchTopBar));
