import CompleteProfile from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { updateOnboardingStatus } from 'actions/users';

const mapStateToProps = state => {
	return {
		doesOrderCatering: state.profileSetup.form.doesOrderCatering,
		doesSchedulePrivateEvents: state.profileSetup.form.doesSchedulePrivateEvents,
		doNotPlanCateringOrEvents: state.profileSetup.form.doNotPlanCateringOrEvents
	};
};

const mapDispatchToProps = dispatch => ({
	completeStep: () => {
		dispatch(completeStep(ProfileSetupSteps.PROFILE));
		dispatch(updateOnboardingStatus({ onboardingStatus: { completeProfile: true } }));
	},
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);
