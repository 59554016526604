import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/atoms';
import { TextField } from 'mui-rff';
import { useTranslate } from 'hooks';
import { getInternalConnectionType } from 'utils/card';
import {
	CardholderNameInput,
	CardNumberField,
	CreditCardTypeSelect,
	RegisteredConnections
} from 'components/molecules';
import { Button } from 'components/atoms';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from 'react-router-dom';
import { useUserContext } from 'context';

/*
interface NotDoDConnectToPointsFormFieldsProps {

}
*/

const NotDoDConnectToPointsFormFields = (
	{
		cardTypes = [],
		hideButtons,
		disabled,
		onNextClick,
		selectedCard,
		showRegistered = true
	} /*: NotDoDConnectToPointsFormFieldsProps */
) => {
	const { user } = useUserContext();
	const location = useLocation();
	const isRegistration = useMemo(() => location.pathname.indexOf('register') > -1, [location]);
	const translate = useTranslate();

	const idType = getInternalConnectionType(selectedCard?.type);

	const showNextButton = useMemo(() => {
		if (isRegistration) {
			return user?.connections?.length > 0;
		}
		return true;
	}, [isRegistration, user?.connections]);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={showRegistered ? 6 : 12}>
				<Grid item xs={12}>
					<Col gap={24}>
						<CreditCardTypeSelect cardTypes={cardTypes} disabled={disabled} selectedCard={selectedCard} />
						<CardholderNameInput disabled={disabled} />
						{selectedCard && (
							<>
								<CardNumberField disabled={disabled} selectedCard={selectedCard} />
								{idType === 'empID' && (
									<TextField
										label={translate('ConnectToPoints.inputs.employeeId.label')}
										name="empId"
										required={true}
										data-cy="employee-id"
										disabled={disabled}
									/>
								)}
								{idType === 'email' && (
									<TextField
										label={translate('ConnectToPoints.inputs.employeeEmail.label')}
										name="email"
										required={true}
										data-cy="email"
										disabled={disabled}
									/>
								)}
							</>
						)}
						{!hideButtons && (
							<Row gap={16} fullWidth style={{ paddingRight: 86 }}>
								<Button
									variant="outlined"
									id="add-card-connect-to-points"
									fullWidth
									type="submit"
									dataCy="submit-connect-to-points"
									disabled={disabled}
								>
									{translate('Profile.payment.notConnected.connectBtn')}
								</Button>
								{showNextButton && (
									<Button fullWidth onClick={onNextClick} dataCy="next">
										{translate(`ConnectToPoints.primary_button`)}
									</Button>
								)}
							</Row>
						)}
					</Col>
				</Grid>
			</Grid>
			{showRegistered && (
				<Grid item xs={12} md={6}>
					<Grid item xs={12}>
						<RegisteredConnections />
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

NotDoDConnectToPointsFormFields.propTypes = {
	cardTypes: PropTypes.array,
	disabled: PropTypes.bool,
	hideButtons: PropTypes.bool,
	onNextClick: PropTypes.func.isRequired,
	selectedCard: PropTypes.object,
	showRegistered: PropTypes.bool
};

export default NotDoDConnectToPointsFormFields;
