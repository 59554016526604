import RegistrationForm from './ui-component';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { withRouter } from 'react-router-dom';

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(withRouter(RegistrationForm));
