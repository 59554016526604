import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
// import { User } from '@/types/user';
import { useUserMutation } from 'reactQuery/mutations';
import { Checkboxes, Col } from 'components/atoms';
import { CompanyAutocomplete } from 'components/organisms';
import { getInitialValuesFromUser, validate } from './utils';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { useTranslate } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, toggleModal } from 'actions/modal';
import { history } from 'utils/router';
import { ModalPage } from 'components/molecules';
import { toggleShowDeleteConnectionsWarningModal } from 'actions/delete_connections_warning_modal';
import { phoneFormatter } from 'utils/formatters';
import { useUserContext } from 'context';

const checkboxData /*: CheckboxData[] */ = [
	{
		label: 'I order catering (for my office and/or for my clients)',
		value: 1, //'catering'
		'data-cy': 'catering'
	},
	{
		label: 'I plan or schedule private dining events',
		value: 2, //'private_dining'
		'data-cy': 'private_dining'
	}
];

/*
interface EditPersonalFormProps {}
*/

const EditPersonalForm = (/*: EditPersonalFormProps*/) => {
	const { modalOpen } = useSelector(state => ({
		modalOpen: state.modal.isOpen
	}));
	const dispatch = useDispatch();
	const translate = useTranslate();
	const { user, isLoading, isFetched } = useUserContext();
	const userLoading = !isFetched || isLoading;

	const onSuccess = useCallback(() => {
		history.push('/profile');
	}, []);

	const { isMutating, updateUser } = useUserMutation({ quietLoading: true, onSuccess });
	const loading = userLoading || isMutating;

	const initialValues /*: User*/ = useMemo(() => getInitialValuesFromUser(user), [user]);

	const onSubmit = useCallback(
		(values /*: User*/) => {
			const userCompany = user?.company;
			const userHasConnections = user?.connections?.length > 0;
			const selectedCompany = values?.company;
			const companyChanged = userCompany?.code && userCompany?.code !== selectedCompany?.code;
			if (userHasConnections && companyChanged) {
				/*
				 * if company changes and user has connections
				 * show delete connections warning.
				 *
				 * The modal will handle the confirm state and delete
				 * any existing connections.
				 *
				 * When the modal finishes, this callback will be called.
				 */
				dispatch(
					// TODO: Move deleting connections to the BE so we can just update the company from the FE and not have to also delete the connections.
					toggleShowDeleteConnectionsWarningModal(true, () => {
						updateUser(values, {
							onSuccess: () => {
								history.push('/profile');
							}
						});
					})
				);
			} else {
				updateUser(values);
			}
		},
		[dispatch, updateUser, user]
	);

	const initCancelModal = useCallback(() => {
		dispatch(
			setModal({
				id: translate('Profile.modals.cancel.id'),
				header: translate('Profile.modals.cancel.header'),
				body: translate('Profile.modals.cancel.body'),
				primaryButtonText: translate('common.continueBtn'),
				secondaryButtonText: translate('common.cancelBtn')
			})
		);
	}, [dispatch, translate]);

	const onCancel = useCallback(
		dirty => {
			if (dirty) {
				// show confirm cancel changes modal
				initCancelModal();
				dispatch(toggleModal(true));
			} else {
				// go back to previous page
				history.goBack();
			}
		},
		[dispatch, initCancelModal]
	);

	const onConfirmCancel = useCallback(() => {
		dispatch(toggleModal(false));
		history.goBack();
	}, [dispatch]);

	return (
		<Col gap={24} align="stretch">
			<RequiredFieldNote />
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				validate={validate}
				render={({ handleSubmit, valid, dirty, values }) => {
					return (
						<form onSubmit={handleSubmit} noValidate>
							<Col gap={15}>
								<TextField label="First Name" name="name.first" required={true} data-cy="firstName" />
								<TextField label="Last Name" name="name.last" required={true} data-cy="lastName" />
								<CompanyAutocomplete name="company" initialSelectedValue={initialValues?.company} />
								<TextField
									label="Work Email"
									name="email.primary"
									required={true}
									data-cy="primaryEmail"
								/>
								<TextField label="Alternate Email" name="email.alternate" data-cy="altEmail" />
								<TextField
									label="Mobile Phone"
									name="phone.mobile"
									data-cy="phone"
									InputProps={{
										value: phoneFormatter(values?.phone?.mobile)
									}}
								/>
								<TextField
									label="Zip Code"
									name="address.mailing.postalCode"
									required={true}
									data-cy="zip"
								/>
								<Checkboxes
									name="diningTypes"
									required={true}
									data={checkboxData}
									dataCy="diningTypes"
								/>
							</Col>
							<EditProfileActions onCancel={onCancel.bind(this, dirty)} isLoading={loading || !valid} />
						</form>
					);
				}}
			/>
			{/* Confirm Cancel Modal */}
			<ModalPage isOpen={modalOpen} secondaryClick={onConfirmCancel} />
			<p className="note form-note">
				<span className="cross-mark">&#10013;&#xFE0E;</span>
				{translate('EditPersonal.note')}
			</p>
		</Col>
	);
};

export default EditPersonalForm;
