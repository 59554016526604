import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslate } from 'hooks';

const DashboardPromotions = ({ config }) => {
	const translate = useTranslate();

	return (
		<div>
			{(translate('Dashboard.numberOfPromotions') === '3' ||
				translate('Dashboard.numberOfPromotions') === '1') && (
				<Row>
					<Col md="12">
						<img src={`${config.cdn_basePath}/mydinova/Promotions/one-lv.png?auto=enhace`} />
					</Col>
				</Row>
			)}
			{(translate('Dashboard.numberOfPromotions') === '3' ||
				translate('Dashboard.numberOfPromotions') === '2') && (
				<Row className="no-gutters">
					<Col md="6" className="no-margin">
						<img src={`${config.cdn_basePath}/mydinova/Promotions/two-lv.png?auto=enhace`} />
					</Col>
					<Col md="6" className="no-margin">
						<img src={`${config.cdn_basePath}/mydinova/Promotions/three-lv.png?auto=enhace`} />
					</Col>
				</Row>
			)}
		</div>
	);
};

DashboardPromotions.propTypes = {
	config: PropTypes.object.isRequired
};

export default DashboardPromotions;
