import { setModal, toggleModal } from 'actions/modal';
import { useTranslate } from 'hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'utils/router';

// interface useEditSecurityFormCancelModalProps {}

const useEditSecurityFormCancelModal = (/*:useEditSecurityFormCancelModalProps*/) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const CANCEL_MODAL_ID = translate('Profile.modals.cancel.id');
	const { modal } = useSelector(state => ({ modal: state.modal }));

	const closeCancelDialogue = useCallback(() => {
		dispatch(toggleModal(false));
	}, [dispatch]);

	const onDiscardChanges = useCallback(() => {
		closeCancelDialogue();
		history.goBack();
	}, [closeCancelDialogue]);

	const initCancelModal = useCallback(() => {
		dispatch(
			setModal({
				id: CANCEL_MODAL_ID,
				header: translate('Profile.modals.cancel.header'),
				body: translate('Profile.modals.cancel.body'),
				primaryButtonText: translate('common.continueBtn'),
				secondaryButtonText: translate('common.cancelBtn')
			})
		);
	}, [CANCEL_MODAL_ID, dispatch, translate]);

	const showCancelDialogue = useCallback(() => {
		initCancelModal();
		dispatch(toggleModal(true));
	}, [dispatch, initCancelModal]);

	const onCancel = useCallback(
		dirty => {
			if (dirty) {
				showCancelDialogue();
			} else {
				closeCancelDialogue();
				history.goBack();
			}
		},
		[closeCancelDialogue, showCancelDialogue]
	);

	return {
		CANCEL_MODAL_ID,
		onCancel,
		onDiscardChanges,
		showCancelModal: modal.isOpen && modal.id === CANCEL_MODAL_ID
	};
};

export default useEditSecurityFormCancelModal;
