import React from 'react';
import { BasicModal } from 'components/molecules';
import { Link, Row, Text, Title } from 'components/atoms';
import { Button } from 'reactstrap';
import useGeneralContext from 'context/GeneralContext';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const RecommendationsThankYouModal = () => {
	const translate = useSelector(state => getTranslate(state.locale));
	const {
		recommendationModal: { showThankYou: show, toggleShowThankYou }
	} = useGeneralContext();

	const pointVal = translate('Recommendations.pointVal'),
		isFromTransactionRecommendation = window.location.pathname.indexOf('history') > -1,
		thankYouMsg = translate(
			`Recommendations.thankYouMessage-${isFromTransactionRecommendation ? 'Transactions' : 'Details'}`
		);

	return (
		<BasicModal
			className="recommendations-ty-modal"
			show={show}
			showClose={false}
			maxWidth={'550px'}
			dataCy="rec-thankyou-modal"
		>
			<Title size="h2" align="center">
				{translate('common.thankYou')}
			</Title>
			<Text size="sm">{thankYouMsg}</Text>
			{isFromTransactionRecommendation && (
				<Link>
					<Text size="sm">
						{translate('Recommendations.thankYouPointsReceived').replace('{points}', pointVal)}
					</Text>
				</Link>
			)}
			<Row fullWidth justify="center">
				<Button
					className={'submit'}
					color="primary"
					onClick={toggleShowThankYou.bind(this, false)}
					data-cy="continue"
				>
					{translate('common.continueBtn')}
				</Button>
			</Row>
		</BasicModal>
	);
};

RecommendationsThankYouModal.defaultProps = {};

RecommendationsThankYouModal.propTypes = {};

export default RecommendationsThankYouModal;
