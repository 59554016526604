import RestaurantListItemNew from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { getTranslate } from 'react-localize-redux';
import { saveSearchState, setActivePin, setMapCardDisplayed } from 'actions/search_page';
import { isSV, isMV, isLV } from 'utils/sizes';
import { setCenter, setRecenterToMapMarker } from 'actions/business_search_map';

const mapStateToProps = state => ({
	IMAGE_BASE_URL: state.config.cdn_basePath,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	saveSearchState: () => dispatch(saveSearchState()),
	setRecenterToMapMarker: async shouldCenter => await setRecenterToMapMarker(dispatch, shouldCenter),
	setActivePin: pin => dispatch(setActivePin(pin)),
	setCenter: async center => await setCenter(dispatch, center),
	setMapCardDisplayed: isDisplayed => dispatch(setMapCardDisplayed(isDisplayed))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(RestaurantListItemNew));
