/* eslint-disable react/display-name */

import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { USER_QUERY_KEY } from 'reactQuery/queries';

const withRefreshUser = WrappedComponent => props => {
	const queryClient = useQueryClient();
	const refreshUser = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
	}, [queryClient]);

	return <WrappedComponent {...props} refreshUser={refreshUser} />;
};

export default withRefreshUser;
