import React from 'react';
import PropTypes from 'prop-types';
import { CollapsiblePanel } from 'components/molecules';
import { BusinessSearchMap } from 'components/organisms';

/**
 * SearchPageMapPanel
 * @param {SearchPageMapPanelProps} props
 */
const SearchPageMapPanel = ({ currentLocationSearch, filteredResults, onMapChange, searchThisArea }) => {
	return (
		<CollapsiblePanel isOpen={true}>
			<BusinessSearchMap
				onMapChange={onMapChange}
				searchThisArea={searchThisArea}
				currentLocationSearch={currentLocationSearch}
				results={filteredResults}
			/>
		</CollapsiblePanel>
	);
};

SearchPageMapPanel.defaultProps = {};

/**
 * SearchPageMapPanelProps
 * @typedef {Object} SearchPageMapPanelProps
 */
SearchPageMapPanel.propTypes = {
	currentLocationSearch: PropTypes.func.isRequired,
	filteredResults: PropTypes.array.isRequired,
	onMapChange: PropTypes.func.isRequired,
	searchThisArea: PropTypes.func.isRequired
};

export default SearchPageMapPanel;
