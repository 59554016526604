export const emailValidator = (email, returnValRequired = false) => {
	if (!email) {
		return returnValRequired ? 'Please enter a valid email' : false;
	}

	if (
		/^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
			email
		)
	) {
		return returnValRequired ? undefined : true;
	}

	return returnValRequired ? 'Please enter a valid email' : false;
};

export default emailValidator;
