import useQuery from './useQuery';
import axios from 'axios';
import { basePath_dashboard, AUTH_REQUEST_HEADER_ID } from 'actions/utils';

/*
interface useCompanyTransactionDetailsQueryProps {}
*/

const COMPANY_TRANSACTION_DETAILS_QUERY_KEY = 'company_transaction_details_query';

const useCompanyTransactionDetailsQuery = (
	{ companyCode, month, year } /*:useCompanyTransactionDetailsQueryProps*/
) => {
	return useQuery({
		queryKey: [COMPANY_TRANSACTION_DETAILS_QUERY_KEY, { code: companyCode, month, year }],
		queryFn: async ({ queryKey }) => {
			const url = `transactions/client-rebate/report`;
			const params = queryKey[1];

			return axios.post(`${basePath_dashboard()}${url}`, params, AUTH_REQUEST_HEADER_ID()).then(res => res.data);
		},
		enabled: Boolean(companyCode),
		reduxLoadingMsg: 'Loading Transaction Details...',
		requiresAuth: true
	});
};

export default useCompanyTransactionDetailsQuery;
