import React, { Component } from 'react';
import { Loader } from 'components/atoms';

export default function asyncComponent(getComponent) {
	class AsyncComponent extends Component {
		state = { Component: null };
		componentDidMount = async () => {
			if (!this.state.Component) {
				try {
					const component = await getComponent();
					if (!this.isUnmounting) {
						this.setState({ Component: component });
					}
				} catch (e) {
					console.log('e', e);
					window.location.reload(true);
				}
			}
		};

		componentWillUnmount = () => {
			this.isUnmounting = true;
		};

		render() {
			const { Component } = this.state;
			if (Component) {
				return <Component {...this.props} />;
			}
			return <Loader />;
		}
	}
	return AsyncComponent;
}
