import { useCallback, useMemo } from 'react';
import useGeneralContext from 'context/GeneralContext';
import { useUserRecommendationMutation } from 'reactQuery/mutations';

// interface useRecommendationFormProps {}

const useRecommendationForm = (/*:useRecommendationFormProps*/) => {
	const {
		recommendationModal: {
			closeModal,
			prefill,
			recommendationToEdit,
			restaurantId,
			setFormError,
			setError,
			toggleShowThankYou,
			type
		}
	} = useGeneralContext();

	const onSuccess = useCallback(() => {
		closeModal();
		toggleShowThankYou(true);
	}, [closeModal, toggleShowThankYou]);

	const onError = useCallback(
		e => {
			console.dir(e);
			setFormError(e.response.data.response);
			setError(false);
		},
		[setFormError, setError]
	);

	const { submitRecommendation, isMutating } = useUserRecommendationMutation({
		onSuccess,
		onError
	});

	const initialValues = useMemo(() => {
		const isPositiveRec = type === 'positive';
		if (prefill) {
			return {
				recommended: recommendationToEdit.recommended,
				for: recommendationToEdit.for,
				feedback: recommendationToEdit.feedback
			};
		}
		return {
			recommended: isPositiveRec,
			for: [],
			feedback: ''
		};
	}, [prefill, recommendationToEdit, type]);

	const onTypeSwitch = useCallback(
		(restartForm, type) => {
			const isRecommended = type === 'positive';
			restartForm({
				recommended: isRecommended,
				for: [],
				feedback: ''
			});
			setFormError(false);
		},
		[setFormError]
	);

	const validate = useCallback(values => {
		const errors = {};

		if (!values.for.length) {
			errors.noneSelected = true;
		}

		return errors;
	}, []);

	const onCancel = useCallback(
		restartForm => {
			restartForm();
			closeModal();
		},
		[closeModal]
	);

	const onSubmit = useCallback(
		(values, form) => {
			form.restart();
			setFormError(false);
			// POST: v1/users/recommendations/<restaurantId>
			// BODY: {"recommended":true,"for":[32,2,11,7,16]}
			submitRecommendation({
				restaurantId,
				form: values,
				type
			});
		},
		[restaurantId, setFormError, submitRecommendation, type]
	);

	return {
		initialValues,
		isMutating,
		onCancel,
		onSubmit,
		onTypeSwitch,
		validate
	};
};

export default useRecommendationForm;
