import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CompassIcon = ({ className, imageBaseURL, onClick }) => {
	const classProps = classnames('compass-icon', className && className);

	return (
		<div className={classProps} onClick={onClick}>
			<img src={`${imageBaseURL}/icons/icon-compass-white.svg`} alt="center map" />
		</div>
	);
};

CompassIcon.defaultProps = {};

CompassIcon.propTypes = {
	className: PropTypes.string,
	imageBaseURL: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default CompassIcon;
