import * as t from './types';
import { setIsLoading, setError } from '../loader';
import { update } from 'actions/users';

export const goToNextStep = () => ({ type: t.PROCEED, payload: 'PROCEED' });

export const toggleModalOpen = (/*newOpen*/) => ({ type: t.TOGGLE_MODAL_OPEN, payload: false /*newOpen*/ });

export const closeModal = () => ({ type: t.CLOSE_MODAL });

export const goToStep = step => ({ type: t.GO_TO_STEP, payload: step });

export const updateForm = ({ field, value }) => ({
	type: t.UPDATE_FORM,
	payload: { field, value }
});

export const completeStep = step => ({ type: t.COMPLETE_STEP, payload: step });

export const dismissForever = () => async dispatch => {
	try {
		let userDetails = { onboardingStatus: { stop: true } };
		dispatch(setIsLoading(true, 'updating user'));
		await dispatch(update(userDetails, null, true, true, true));
		dispatch({ type: t.DISMISS_FOREVER });
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
		} else {
			dispatch(setError(500));
		}
	} finally {
		dispatch(setIsLoading(false));
	}
};

export const finishOnboarding = () => ({ type: t.FINISH_ONBOARDING });

export const setCompletedStep = completedStepCount => ({ type: t.SET_COMPLETED_STEPS, payload: completedStepCount });

export const initOnboardingStateFromUser = user => ({ type: t.INIT_ONBOARDING_STATE_FROM_USER, payload: user });
