import { useCallback, useMemo } from 'react';
import { hasProp } from 'utils/object';
import { getLowerCasedParams } from 'utils/url';
import useGeneralContext from 'context/GeneralContext';
import { useUserRegistrationMutation } from 'reactQuery/mutations';

// interface useRegistrationFormFieldsProps {}

const useRegistrationFormFields = ({ doAfterSubmit } /*:useRegistrationFormFieldsProps*/) => {
	const completeRegistrationStepOne = useCallback(
		res => {
			if (hasProp(res, 'response.code') && res.response.code === 200) {
				let isMyDinovaEligible = true;

				if (doAfterSubmit) {
					doAfterSubmit(isMyDinovaEligible);
				}
			} else {
				window.scrollTo(0, 0);
			}
		},
		[doAfterSubmit]
	);

	const { registerUser, isMutating } = useUserRegistrationMutation({
		onSuccess: res => completeRegistrationStepOne(res)
	});
	const { isDoDMode } = useGeneralContext();

	const initialValues = useMemo(() => {
		let parsed = getLowerCasedParams();

		return {
			companyCode: hasProp(parsed, 'companycode') ? parsed.companycode : '',
			firstName: hasProp(parsed, 'first') ? parsed.first : '',
			lastName: hasProp(parsed, 'last') ? parsed.last : '',
			email: hasProp(parsed, 'wemail') ? parsed.wemail : '',
			campaign: hasProp(parsed, 'utm_campaign') ? parsed.utm_campaign : '',
			registrationPath:
				hasProp(parsed, 'redirect') && parsed.redirect.indexOf('catering') > -1 ? 'catering' : 'mydinova',
			referralToken: hasProp(parsed, 'token') ? parsed.token : '',

			tandc: [],
			username: '',
			password: '',
			monthly_summary: '',
			special_offers: ''
		};
	}, []);

	const onSubmit = useCallback(
		vals => {
			const userData = {
				name: {
					first: vals.firstName,
					last: vals.lastName
				},
				phone: {
					mobile: vals.phone || ''
				},
				email: {
					primary: vals.email
				},
				company: isDoDMode ? 'DOD' : undefined,
				preferences: {
					notification: {
						monthlySummary: vals.monthly_summary,
						specialOffers: vals.special_offers
					}
				},
				userName: vals.username,
				password: vals.password,
				campaign: {
					id: vals.campaign
				},
				registrationPath: vals.registrationPath,
				token: vals.referralToken,

				acceptedTC: true
			};

			registerUser(userData);
		},
		[isDoDMode, registerUser]
	);

	return {
		initialValues,
		isMutating,
		onSubmit
	};
};

export default useRegistrationFormFields;
