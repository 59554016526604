import { useQuery } from './';
import { basePath_restaurant } from 'actions/utils';

// interface useRestaurantDetailsQueryProps {}

export const RESTAURANT_DETAILS_QUERY = 'restaurant_details';

const useRestaurantDetailsQuery = (
	{ onSuccess, restaurantId, setReduxIsLoading } /*:useRestaurantDetailsQueryProps*/
) => {
	return useQuery({
		queryHandler: res => res.data.restaurants[0],
		queryKey: [RESTAURANT_DETAILS_QUERY, restaurantId],

		onSuccess,
		reduxLoadingMsg: 'Loading Restaurant Details...',
		setReduxIsLoading,
		urlBuilder: queryKey => {
			if (!queryKey[1]) {
				throw new Error('Restaurant id is required to fetch restaurant details');
			}
			const baseUrl = basePath_restaurant() + 'restaurants/';
			const url = `${baseUrl}${queryKey[1]}`;

			return url;
		}
	});
};

export default useRestaurantDetailsQuery;
