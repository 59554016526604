import React /* ,{ PropsWithChildren }*/ from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/*
interface ColProps extends PropsWithChildren {
  align?: "flex-start";
  gap?: number;
}
*/

const Col = (
	{ align = 'flex-start', children, dataCy, fullHeight, fullWidth, gap, justify, ...others } /*: ColProps*/
) => {
	return (
		<Box
			data-cy={dataCy}
			style={{ gap }}
			{...others}
			className={classnames(
				others.extraClassName,
				styles.col,
				styles[`align-${align}`],
				styles[`justify-${justify}`],
				{
					[styles.fullWidth]: fullWidth,
					[styles.fullHeight]: fullHeight
				}
			)}
		>
			{children}
		</Box>
	);
};

Col.propTypes = {
	align: PropTypes.oneOf(['flex-start', 'stretch']),
	children: PropTypes.node,
	dataCy: PropTypes.string,
	fullHeight: PropTypes.bool,
	fullWidth: PropTypes.bool,
	gap: PropTypes.number,
	justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly'])
};

export default Col;
