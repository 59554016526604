import MFAModalPage from './data-wrapper';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import { reduxForm } from 'redux-form';
import { hasProp } from 'utils/object';
import { refresh } from 'actions/users';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const mapDispatchToProps = dispatch => ({
	toggleModal: isOpen => dispatch(toggleModal(isOpen)),
	refresh: () => dispatch(refresh())
});

const mapStateToProps = state => ({
	modal: state.modal,
	config: state.config,
	formVals: hasProp(state, 'form.mfaForm.values') ? state.form.mfaForm.values : {},
	initialValues: {
		dontAsk: cookies.get('dontAsk') === 'true'
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'mfaForm' })(MFAModalPage));
