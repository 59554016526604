import SearchPageFilterPanel from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';

const mapStateToProps = state => ({
	showFilter: state.searchPage.showFilter
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(SearchPageFilterPanel));
