import SimpleMapMarker from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { saveSearchState } from 'actions/search_page';

const mapStateToProps = state => ({
	IMAGE_BASE_URL: state.config.cdn_basePath
});

const mapDispatchToProps = dispatch => ({
	saveSearchState: () => dispatch(saveSearchState())
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(SimpleMapMarker));
