import { hasProp } from 'utils/object';
import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { amenitiesGeneralFilterCheck, safetyMeasuresFilterCheck } from 'utils/search_new/utils/filterFuncs';

const deliveryOptsFilterAggregate = (filter, restaurant, filterConfig) => {
	const sanitizationIds = [603]; // Sealed Packaging
	const generalIds = [6, 7, 8]; // Alcohol To-Go, Meal Kits, Pantry Items
	if (hasProp(restaurant, 'safetyMeasures.sanitization')) {
		restaurant.safetyMeasures.sanitization.forEach(opt => {
			if (sanitizationIds.indexOf(opt.id) > -1) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: opt.name,
					id: opt.id,
					masterAccr: 'safetyMeasures',
					subAccr: 'sanitization',
					value: opt.name,
					filterFunc: safetyMeasuresFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}

	if (hasProp(restaurant, 'amenities.general')) {
		restaurant.amenities.general.forEach(opt => {
			if (generalIds.indexOf(opt.id) > -1) {
				addOrUpdateMasterFilter({
					filter,
					groupTitle: filterConfig.groupTitle,
					groupSubAccr: opt.name,
					id: opt.id,
					masterAccr: 'amenities',
					subAccr: 'general',
					value: opt.name,
					filterFunc: amenitiesGeneralFilterCheck,
					restaurantId: restaurant.id
				});
			}
		});
	}
};

export default deliveryOptsFilterAggregate;
