import { connect } from 'react-redux';
import ProfileSetupFinishStep from './ui-component';
import { dismissForever } from 'actions/profile_setup';
import { updateOnboardingStatus } from 'actions/users';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { completeStep } from 'actions/profile_setup';

const mapStateToProps = state => {
	return {
		dismissedForever: state.profileSetup.dismissedForever
	};
};

const mapDispatchToProps = dispatch => ({
	completeOnboarding: () => {
		dispatch(dismissForever());
		dispatch(completeStep(ProfileSetupSteps.FINISH));
		dispatch(updateOnboardingStatus({ onboardingStatus: { finishOnboarding: true } }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetupFinishStep);
