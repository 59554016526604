import DietaryPreferences from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	dietaryPrefs: hasProp(state.details, 'restaurant.dietaryPreferences')
		? Object.values(state.details.restaurant.dietaryPreferences).map(v => v.name)
		: []
});

export default connect(mapStateToProps)(DietaryPreferences);
