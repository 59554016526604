import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import WithInput from './withInput';
import { InputLabel } from 'components/atoms';
import { InputError, InputFieldFocusBorder } from 'components/molecules';

// import './styles/main.scss';

/**
 * Common input component for text, textarea, email, and password.
 * @extends WithInput
 * @param {InputProps} props
 */
class Input extends WithInput {
	constructor(props) {
		super(props);
		this.input = React.createRef();
	}

	setRef = cmp => {
		this.input = cmp;

		if (this.props.refSetter) {
			this.props.refSetter(cmp);
		}
	};

	render() {
		const {
			className,
			error,
			dataCy,
			disabled,
			hasError,
			id,
			label,
			maxLength,
			name,
			onChange,
			onErrorUsePlacehodler,
			onKeyDown,
			placeholder,
			required,
			value,
			readOnly
		} = this.props;

		let type = this.props.type;
		let inputType = type;
		if (type === 'textarea') {
			type = 'text';
		}

		const classProps = classnames(
			type,
			!label && 'no-label',
			type !== inputType && inputType,
			(type === 'password' || type === 'email' || type === 'typeahead') && 'text',
			(type === 'typeahead') & 'select',
			hasError && 'error',
			required && 'required',
			this.state.hasFocus && 'focused',
			(value.length || placeholder.length) && 'has-value',
			className,
			onErrorUsePlacehodler && 'use-placholder'
		);

		const containerClassProps = classnames('input-container', classProps);

		const inputClassProps = classnames('input', classProps);
		let inputVal = value,
			placeholderVal = placeholder;

		if (hasError && onErrorUsePlacehodler) {
			inputVal = error;
			if (this.state.hasFocus) {
				inputVal = value;
			}
		}

		if (hasError && onErrorUsePlacehodler) {
			placeholderVal = error;
		}

		return (
			<div data-cy={`${dataCy}-input-wrapper`} className={containerClassProps}>
				{label && <InputFieldFocusBorder labelText={label} dataCy={dataCy} />}
				{label && <InputLabel dataCy={dataCy} text={label} hasError={hasError} type={type} htmlFor={id} />}
				{inputType === 'textarea' ? (
					<textarea
						ref={this.setRef}
						data-cy={dataCy}
						className={inputClassProps}
						disabled={disabled}
						id={id}
						name={name}
						onBlur={this.onBlur}
						onChange={onChange}
						onFocus={this.onFocus}
						onKeyDown={onKeyDown}
						value={value}
					/>
				) : (
					<input
						ref={this.setRef}
						data-cy={dataCy}
						className={inputClassProps}
						disabled={disabled}
						id={id}
						maxLength={maxLength}
						name={name}
						onBlur={this.onBlur}
						onChange={onChange}
						onFocus={this.onFocus}
						onKeyDown={onKeyDown}
						placeholder={placeholderVal}
						type={type}
						autoComplete={type === 'password' ? 'new-password' : 'off'}
						value={inputVal}
						readOnly={readOnly}
					/>
				)}
				{hasError && !onErrorUsePlacehodler && inputType !== 'textarea' && (
					<InputError text={error} dataCy={`${dataCy}-error`} />
				)}
			</div>
		);
	}
}

Input.defaultProps = {
	className: '',
	error: null,
	hasError: false,
	iconBaseURL: '',
	id: null,
	name: 'input',
	label: 'Label',
	onBlur: () => {},
	onChange: () => {},
	onFocus: () => {},
	onKeyDown: () => {},
	placeholder: '',
	required: false,
	type: 'text',
	value: '',
	readOnly: false
};

/**
 * Input Props
 * @interface Input_Props
 * @property {string}   className     Additional classname to add to the input wrapper.
 * @property {string}   error         The error message text.
 * @property {boolean}  hasError      If this input has an error.
 * @property {string}   id            The id for this input.
 * @property {string}   name          The name for this input.
 * @property {string}   label         The text to show in the label for this input.
 * @property {function} onBlur        Input blur handler.
 * @property {function} onChange      Input change handler.
 * @property {function} onFocus       Input focus handler.
 * @property {function} onKeyDown     Input keydown handler.
 * @property {string}   placeholder   Input placeholder text.
 * @property {boolean}  required      If this input is required.
 * @property {string}   type          The type of input.  Can be 'text', 'email', 'password', 'textarea'.
 * @property {string}   value         The current value of the input.
 */
Input.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	hasError: PropTypes.bool,
	id: PropTypes.string,
	maxLength: PropTypes.number,
	name: PropTypes.string,
	label: PropTypes.any,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onErrorUsePlacehodler: PropTypes.bool,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.string
};

export default Input;
