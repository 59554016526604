import CompanySelectModal from './data-wrapper';
import { connect } from 'react-redux';
import { toggleShowCompanySelectModal } from 'actions/company_select_modal';
import { startChangeStep, assignCard, setDidRegisterWithIneligible } from 'actions/registration';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';
import { selectCompany } from 'actions/company_select';
import { toggleShowDeleteConnectionsWarningModal } from 'actions/delete_connections_warning_modal';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
	...state.companySelectModal
});

const mapDispatchToProps = dispatch => ({
	closeCompanySelectModal: () => dispatch(toggleShowCompanySelectModal(false)),
	setCompany: company => {
		dispatch(selectCompany(company)); // new Redux store comapnySelect

		if (company?.supportedConnections?.length === 1) {
			dispatch(assignCard(company.supportedConnections[0]));
		}
	},
	setDidRegisterWithIneligible: () => dispatch(setDidRegisterWithIneligible()),
	showCancelModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'cancel'));
		}, 400);
	},
	showDeleteConnectionsWarningModal: confirmHandler =>
		dispatch(
			toggleShowDeleteConnectionsWarningModal(true, () => {
				console.log('%c DONE', 'color: yellow');
				confirmHandler();
			})
		),
	showSkipModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'skip'));
		}, 400);
	},
	showSelectModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'select'));
		}, 400);
	},
	showMerckModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'merck'));
		}, 400);
	},
	showUnconnectedModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'unconnected'));
		}, 400);
	},
	startChangeStep: step => dispatch(startChangeStep(step)),
	showPointsElegibilityModal: () => dispatch(toggleShowPointsElegibilityModal(true)),
	showConfirmCompanyChangeToIneligibleModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'confirm_ineligible_company_change'));
		}, 400);
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanySelectModal));
