/* eslint-disable react/prop-types */
import React from 'react';

const IconCheckMark = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.7 43.28" data-cy="icon-check-mark" alt="checkmark">
		<title>icon-checkmark-black</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<polygon
					fill={props.fill}
					points="19.66 43.28 0 23.62 4.24 19.38 19.66 34.8 54.45 0 58.7 4.24 19.66 43.28"
				/>
			</g>
		</g>
	</svg>
);

export default IconCheckMark;
