import { useQuery } from './';
// import { Company } from "@/types";
import { basePath } from 'actions/utils';

// const basePath = 'https://dev.dinova.com/api-mydinova/v1';

export const queryKey = 'company_auto_complete_query';

/*
interface UseCompanyAutocompleteQueryProps {
  searchValue?: string;
  quietLoading?: boolean;
}
*/

const initialProps = {
	enabled: true,
	quietLoading: true
}; /* as UseCompanyAutocompleteQueryProps*/

const useCompanyAutocompleteQuery = ({
	// enabled,
	quietLoading,
	searchValue
} /*: UseCompanyAutocompleteQueryProps*/ = initialProps) => {
	return useQuery({
		queryHandler: (res /*: any*/) => {
			return res.data.suggestions /* as Company.Company[]*/;
		},
		queryKey: [queryKey, searchValue],
		enabled: Boolean(searchValue),
		quietLoading,
		requiresAuth: true,
		urlBuilder: queryKey => {
			// console.log('%c queryKey', 'color: lightblue', queryKey);
			const companyName = queryKey[1];
			return `${basePath()}/clients/autocomplete?input=${companyName}`;
		}
	});
};

export default useCompanyAutocompleteQuery;
