import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isIE } from 'utils/browser';

const Picture = ({ alt, children, className, dataCy, isLV, mobileSrc, tabletSrc, desktopSrc, size }) => {
	return isIE() ? (
		<div>
			<img
				className={classnames('picture', className && className)}
				src={isLV ? desktopSrc : mobileSrc}
				alt={alt}
				style={size}
				data-cy={dataCy ? dataCy : 'picture'}
			/>
			{children && children}
		</div>
	) : (
		<picture>
			<source media="(max-width: 768px)" srcSet={mobileSrc} />
			<source media="(max-width: 1024px)" srcSet={tabletSrc} />
			<source media="(min-width: 1025px)" srcSet={desktopSrc} />
			<img
				data-cy={dataCy ? dataCy : 'picture'}
				className={classnames('picture', className && className)}
				src={'https://via.placeholder.com/100/FFFFFF/FFFFFF?Text=Digital.com'}
				alt={alt}
				style={size}
			/>
			{children && children}
		</picture>
	);
};

Picture.defaultProps = {};

Picture.propTypes = {
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	dataCy: PropTypes.string,
	isLV: PropTypes.bool,
	mobileSrc: PropTypes.string.isRequired,
	tabletSrc: PropTypes.string.isRequired,
	desktopSrc: PropTypes.string.isRequired,
	size: PropTypes.shape({
		width: PropTypes.string,
		height: PropTypes.string
	})
};

export default Picture;
