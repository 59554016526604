import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { dietaryPreferencesFilterCheck } from 'utils/search_new/utils/filterFuncs';

const dietaryPrefsFilterAggregate = (filter, restaurant, filterConfig) => {
	if (restaurant.dietaryPreferences) {
		restaurant.dietaryPreferences.forEach(dietPref => {
			addOrUpdateMasterFilter({
				filter,
				groupTitle: filterConfig.groupTitle,
				groupSubAccr: dietPref.name,
				id: dietPref.id,
				masterAccr: 'dietaryPreferences',
				value: dietPref.name,
				filterFunc: dietaryPreferencesFilterCheck,
				restaurantId: restaurant.id
			});
		});
	}
};

export default dietaryPrefsFilterAggregate;
