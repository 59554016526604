import {
	containsLowerCaseValidator,
	containsNumberValidator,
	containsUpperCaseValidator,
	containsSpecialValidator,
	lengthValidator
} from 'utils/form/validators';

const passwordValidator = (password, length = 10) => {
	return containsLowerCaseValidator(password) &&
		containsNumberValidator(password) &&
		containsUpperCaseValidator(password) &&
		containsSpecialValidator(password) &&
		lengthValidator(password, length)
		? undefined
		: 'Please enter a valid password.';
};

export default passwordValidator;
