import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';
import { Button, Row } from 'components/atoms';

/*
interface RecommendationsModalFooterProps {

}
*/

const RecommendationsModalFooter = ({ isMutating, onCancel } /*: RecommendationsModalFooterProps*/) => {
	const translate = useTranslate();

	return (
		<Row fullWidth gap="24px">
			<Button fullWidth disabled={isMutating} color="primary" type="submit" dataCy="submit" size="large">
				{translate('common.continueBtn')}
			</Button>
			<Button fullWidth disabled={isMutating} variant="outlined" onClick={onCancel} dataCy="cancel" size="large">
				{translate('common.cancelBtn')}
			</Button>
		</Row>
	);
};

RecommendationsModalFooter.propTypes = {
	isMutating: PropTypes.bool.isRequired,
	onCancel: PropTypes.func
};

export default RecommendationsModalFooter;
