import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/molecules/Accordion';

/**
 * withAccordion is an HOC that applies an accordoin wrapper.
 * @name HOC
 * @param {Object} wrappedComponent A React component. This will be the child of the accordion.
 * @param {AccordionProps} accordionProps An object describing the accordion's initial state.
 */
const withAccordion = (wrappedComponent, accordionProps) => {
	return <Accordion {...accordionProps}>{wrappedComponent}</Accordion>;
};

withAccordion.defaultProps = {
	wrappedComponent: [],
	accordionProps: {
		canCollapse: true,
		children: [],
		className: '',
		expanded: true,
		showTitle: true,
		striped: false,
		title: 'withAccordionHOC',
		titleClass: false
	}
};

withAccordion.propTypes = {
	wrappedComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	accordionProps: PropTypes.shape({
		canCollapse: PropTypes.bool,
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
		className: PropTypes.string,
		expanded: PropTypes.bool,
		showTitle: PropTypes.bool,
		striped: PropTypes.bool,
		title: PropTypes.string,
		titleAlign: PropTypes.oneOf(['left', 'center', 'right']),
		titleClass: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string])
	})
};

export default withAccordion;
