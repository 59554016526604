import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { translationExists } from 'utils/localize';
import { useTranslate } from 'hooks';

const translateOptions = {
	showMissingTranslationMsg: false
};

const DinovaMetaTags = ({ tags = [] }) => {
	const translate = useTranslate();

	const titleMeta = useCallback(
		(meta, index) => {
			return translationExists(meta.translateKey) ? (
				<title key={`title-${index}`}>
					{`${translate(meta.translateKey, null, translateOptions)} ${
						meta.additionalContent !== undefined ? meta.additionalContent : ''
					}`}
				</title>
			) : null;
		},
		[translate]
	);

	const metaTag = useCallback(
		(meta, index) => {
			return (
				<meta
					key={`meta-${index}`}
					name={meta.name}
					content={`YYYY - ${translate(meta.translateKey, null, translateOptions)} ${meta.additionalContent}`}
					property={meta.property || null}
				/>
			);
		},
		[translate]
	);

	return (
		<MetaTags>
			{tags.map((meta, i) => {
				if (meta.type === 'title') {
					return titleMeta(meta, i);
				} else if (meta.type === 'meta') {
					return metaTag(meta, i);
				}
			})}
		</MetaTags>
	);
};

/**
 * MetaConfig - Config object for generating a meta tag.
 * @type {Object} MetaConfig
 * @property {string} type         The type of meta tag, can be "title" or "meta".
 * @property {string} translateKey The data accessor for the translated/localized value of this meta tag.
 * @property {string} [name]       The name of the meta tag.  Note: This is only required if type = 'meta'.
 * @property {string} [property]   The property of the meta tag
 * @property {string} additionalContent Any additional content to be appended
 */

/**
 * DinovaMetaTags Props
 * @interface Props_DinovaMetaTags
 * @property {MetaConfig[]} tags
 * @property {function}     translate
 */
DinovaMetaTags.propTypes = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			translateKey: PropTypes.string.isRequired,
			name: PropTypes.string,
			property: PropTypes.string,
			additionalContent: PropTypes.string
		})
	)
};

export default DinovaMetaTags;
