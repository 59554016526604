import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RecommendationItem, Text } from 'components/atoms';
import { GridHeader, List } from 'components/molecules';
import { LoadingBase } from 'components/atoms';
import { RecommendationsThankYouModal } from 'components/organisms';
import { useTranslate } from 'hooks';

const LABELS = [
	{ text: 'Recommend', colClass: 'col-sm-2' },
	{ text: 'Date', colClass: 'col-sm-2' },
	{ text: 'Restaurant', hidden: isSV => isSV, colClass: 'col-sm-3' },
	{ text: 'Details', colClass: 'col-sm-6 col-md-3' },
	{ text: '', colClass: 'col-sm-2 col-md-2' }
];

const RecommendationsPageList = ({ isLoading, items }) => {
	const translate = useTranslate();
	const { hasItems, mergedMonths } = useMemo(() => {
		const _hasItems = items && Object.keys(items).length > 0;

		if (_hasItems) {
			return {
				hasItems: _hasItems,
				mergedMonths: Object.values(items).reduce((accum, item) => {
					return [...item, ...accum];
				}, [])
			};
		}

		return { _hasItems: false, mergedMonths: [] };
	}, [items]);

	return (
		<div className="recomendations-page-list" data-cy="recommendations-list">
			<RecommendationsThankYouModal />
			<GridHeader labels={LABELS} withBorder={false} type="table" />
			{isLoading && <LoadingBase className="pt-5" message={translate('Recommendations.loadingMsg')} />}
			{!isLoading && hasItems && (
				<List
					key={'rec-list-items'}
					itemComponent={RecommendationItem}
					items={mergedMonths}
					listId="recommendations-page-list"
					isLoading={isLoading}
				>
					list
				</List>
			)}
			{!isLoading && !hasItems && (
				<Text className="no-activity" size="sm">
					{translate('RecommendationsPage.no-recommendations')}
				</Text>
			)}
		</div>
	);
};

RecommendationsPageList.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	items: PropTypes.object
};

export default RecommendationsPageList;
