import { containsNoSpaceValidator, lengthValidator } from 'utils/form/validators';

export const usernameRequirements = [
	{
		validator: value => lengthValidator(value, 3),
		textKey: 'Registration.requirement-boxes.username.1'
	},
	{
		validator: value => containsNoSpaceValidator(value),
		textKey: 'Registration.requirement-boxes.username.2'
	}
];

export const tAndCCheckboxData = [
	{
		label: 'Accept Terms and Conditions',
		value: 'tandc',
		name: 'tandc'
	}
];

export const monthlySummaryCheckboxesData = [
	{ label: 'Yes', value: 'email' },
	{ label: 'No', value: 'none' }
];

export const specialOffersCheckboxesData = [
	{ label: 'Yes', value: 'email' },
	{ label: 'No', value: 'none' }
];
