import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { useTranslate } from 'hooks';

const RecommendationEndorsement = ({ isSV }) => {
	const translate = useTranslate();
	const text = translate('Recommendations.recommendQuestion');

	if (isSV) {
		return (
			<div className="endorse">
				<Text dataCy="recommend-question" size="xs">
					{text}
				</Text>
			</div>
		);
	}

	return (
		<div className="endorse">
			<div className="flex justify-center align-center">
				<Text dataCy="recommend-question" size="xs">
					{text}
				</Text>
			</div>
		</div>
	);
};

RecommendationEndorsement.propTypes = {
	isSV: PropTypes.bool.isRequired
};

export default RecommendationEndorsement;
