// "use client";
// import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip, { /*TooltipProps,*/ tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import styles from './styles.module.scss';

/*
interface HelpIconProps {
  size?: number;
}
*/

const HelpIcon = ({ color = 'primary', size = 30, text } /*: HelpIconProps*/) => {
	const HtmlTooltip = styled(({ className, ...props } /*: TooltipProps*/) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			marginTop: 100,
			color: '#4d4c48' /*$dinova-green*/,
			maxWidth: 400,
			backgroundColor: '#edf5ed',
			fontSize: '16px',
			padding: '20px'
		},
		[`& .${tooltipClasses.arrow}::before`]: {
			backgroundColor: '#edf5ed',
			width: 17,
			height: 12
		}
	}));

	const iconSrc =
		color === 'primary'
			? 'https://dinova-images-dev.imgix.net/icons/icon-help-green.svg'
			: 'https://dinova-images-dev.imgix.net/icons/icon-help.svg';

	return (
		<div>
			<HtmlTooltip
				arrow
				PopperProps={{
					disablePortal: true,
					className: styles.popper
				}}
				placement="bottom-end"
				title={text}
			>
				<IconButton disableRipple style={{ backgroundColor: 'transparent' }}>
					<img
						alt="help"
						style={{
							height: size,
							width: size
						}}
						src={iconSrc}
					/>
				</IconButton>
			</HtmlTooltip>
		</div>
	);
};

HelpIcon.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary']),
	size: PropTypes.number,
	text: PropTypes.oneOf([PropTypes.string, PropTypes.node])
};

export default HelpIcon;
