const updateSavedFiltersArray = (filter, selectedFiltersArray) => {
	if (filter.selected) {
		let saved = new Set(selectedFiltersArray);
		saved.add(filter);
		return Array.from(saved);
	} else {
		return selectedFiltersArray.filter(f => {
			let sameDataAccr = f.dataAccr === filter.dataAccr;
			let sameFilterKey = f.filterKey === filter.filterKey;
			let sameId = f.id === filter.id;
			let sameValue = f.value === filter.value;

			return !(sameDataAccr && sameFilterKey && sameId && sameValue);
		});
	}
};

export default updateSavedFiltersArray;
