import React from 'react';
import { Button, Row, Col, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslate } from 'hooks';

const MissingRegistration = ({ className, onClick }) => {
	const translate = useTranslate();

	// TODO maybe classProps should be applied to parent element
	const classProps = classNames('missing-registration', 'container-fluid Di_HeadSpace', className);

	return (
		<div className={classProps}>
			<Row>
				<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
					<CardBody>
						<div className="container-fluid">
							<Row>
								<Col>
									<div className="Di_HomeText">{translate('Transactions.not-connected-message')}</div>
								</Col>
							</Row>
							<Row>
								<Col md="12" xs="12" className="text-center">
									<Button className="z-depth-0 center-block" color="primary" onClick={onClick}>
										{translate('Transactions.not-connected-button')}
									</Button>
								</Col>
							</Row>
						</div>
					</CardBody>
				</div>
			</Row>
		</div>
	);
};

MissingRegistration.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
};

export default MissingRegistration;
