import { getVal } from 'utils/object';

const selectFilter = (filter, currentFilters) => {
	if (filter.forEach) {
		filter.forEach(f => {
			currentFilters = selectFilter(f, currentFilters);
		});
	} else {
		let currentFilter = getVal(currentFilters, filter.filterKey);
		if (currentFilter) {
			currentFilter.selected = true;
			currentFilters[filter.filterKey.split('.')[0]].activeCount += 1;
		}
	}

	return currentFilters;
};

export default selectFilter;
