import { difMins } from 'utils/time';
import { hasProp } from 'utils/object';

export const DAY_IN_MINS = 1440;

export const shouldDoTimeInMinsDependantRequest = (URL, minsTimeout, getState) => {
	const requestsState = getState().requests;

	if (hasProp(requestsState[URL], 'lastRequest')) {
		if (requestsState[URL]) {
			let minsSinceLastRequest = difMins(requestsState[URL].lastRequest, new Date());
			return minsSinceLastRequest >= minsTimeout;
		} else {
			return true;
		}
	} else {
		if (requestsState[URL]) {
			let minsSinceLastRequest = difMins(requestsState[URL], new Date());
			return minsSinceLastRequest >= minsTimeout;
		} else {
			return true;
		}
	}
};
