import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Displays contact information. Ex: 'Main: 555-555-5555'.
 * @param {ClientItemProps} props
 */

const ClientItem = ({ client: { name, website } }) => {
	const classProps = classnames('contact-info-item');
	return (
		<a className={classProps} href={website} data-cy={`contact-item-link-${name}`}>
			<span className="label" data-cy={`contact-item-label-${name}`} id={`${name.charAt(0)}`}>
				&nbsp;{`${name} `}
			</span>
		</a>
	);
};

ClientItem.defaultProps = {
	client: {
		name: '',
		website: '',
		isPrivate: false
	}
};

/**
 * {@link Client} Props
 * @interface IClient
 * @property {string} name The name of the client.
 * @property {boolean} isPrivate Is the client shown in default search
 * @property {string} website The url to go to when client is click.
 */

/**
 * {@link ClientItem} Props
 * @interface Props_ClientItem
 * @property {IClient} client {@link IClient}
 */
ClientItem.propTypes = {
	client: PropTypes.shape({
		name: PropTypes.string,
		isPrivate: PropTypes.bool,
		website: PropTypes.string
	})
};

export default ClientItem;
