import { useQuery } from './';
import { basePath } from 'actions/utils';

export const USER_TRANSACTIONS_QUERY_KEY = 'user_transactions';

const useUserTransactionsQuery = ({ date, setReduxIsLoading, setDataInRedux, quietLoading }) => {
	// Queries
	return useQuery({
		// Req
		queryHandler: res => res.data.transactions,
		queryKey: [USER_TRANSACTIONS_QUERY_KEY, date],
		// url: `${basePath()}/users/transactions`,
		// Opt
		quietLoading,
		reduxLoadingMsg: 'Loading Transaction History...',
		reduxQueryDataAccr: query => query.data.transactions,
		requiresAuth: true,
		setDataInRedux,
		setReduxIsLoading,
		urlBuilder: queryKey => {
			let url = `${basePath()}/users/transactions`;
			if (queryKey[1]) {
				url = `${url}?date=${queryKey[1]}`;
			}

			return url;
		}
	});
};

export default useUserTransactionsQuery;
