import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text, Title } from 'components/atoms';
import { TabPanelHeader, TabPanelBody } from 'components/molecules';
import { MoreHelp } from 'components/molecules';
import { Row, Col } from 'reactstrap';
import { Ads } from 'components/organisms';
import { FAQReferACoworker } from 'components/organisms';
import isNodeEnv from 'utils/isNodeEnv';

class HelpFAQRouteTabPanel extends React.Component {
	contactInfoKey = () => {
		const { match } = this.props;
		const isCateringPage = match.url.indexOf('catering') > -1;
		const isPrivateDiningPage = match.url.indexOf('dine-assist') > -1;
		const isRewardsPage = match.url.indexOf('rewards') > -1;

		if (isCateringPage) {
			return 'catering';
		} else if (isPrivateDiningPage) {
			return 'privateDining';
		} else if (isRewardsPage) {
			return 'myDinova';
		} else {
			return 'business';
		}
	};

	adDataKey = () => {
		return `HelpFAQ.${this.contactInfoKey()}FAQ`;
	};

	dineAssistFormLink = () => {
		const isProd = isNodeEnv('prod') || isNodeEnv('production');
		return isProd
			? 'https://apps.dinova.com/dine-assist/start-planning'
			: 'https://apps.dev.dinova.com/dine-assist/start-planning';
	};

	dineAssistHowItWorksSteps = () => {
		const privateDiningHowItWorksCount = this.props.translate('HelpFAQ.privateDiningFAQ.howItWorks.count');
		let items = [];
		for (let i = 1; i <= privateDiningHowItWorksCount; i++) {
			let text = this.props.translate(`HelpFAQ.privateDiningFAQ.howItWorks.${i}`);
			if (i === 1) {
				let formLinkStart = text.indexOf('${formLink}'),
					textFront = text.slice(0, formLinkStart),
					textBack = text.slice(formLinkStart + '${formLink}'.length);

				items.push(
					<li>
						<Text size="sm">
							{textFront}{' '}
							<a href={this.dineAssistFormLink()} rel="noopener noreferrer" target="_blank">
								{this.dineAssistFormLink()}
							</a>{' '}
							{textBack}
						</Text>
					</li>
				);
			} else {
				items.push(
					<li>
						<Text size="sm">{text}</Text>
					</li>
				);
			}
		}
		return items;
	};

	dineAssistHowItWorksSection = () => {
		return this.contactInfoKey() === 'privateDining' ? (
			<Row className="mt-10">
				<Col sm="11" md="9" lg="8" className="center-margin">
					<Title size="h3">#IBookedIt</Title>
				</Col>
				<Col sm="11" md="9" lg="8" className="center-margin">
					<Text align="center" size="sm">
						{this.props.translate('HelpFAQ.privateDiningFAQ.howItWorks.description')}
					</Text>
				</Col>
				<Col sm="11" md="9" lg="8" className="center-margin">
					<Title size="h4">{this.props.translate('HelpFAQ.privateDiningFAQ.howItWorks.title')}</Title>
				</Col>
				<Col sm="11" md="9" lg="8" className="center-margin">
					<ol>{this.dineAssistHowItWorksSteps()}</ol>
				</Col>
			</Row>
		) : null;
	};

	render() {
		const { match, tabs, translate } = this.props,
			referralClassProps = classnames(match.url.indexOf('rewards') !== -1 && 'hidden'),
			// adsClassProps = classnames(match.url.indexOf('business') > -1 && 'hidden');
			adsClassProps = classnames('hidden'),
			adsHidden = true;

		return (
			<div className="helpfaq tab-panel">
				<TabPanelHeader tabs={tabs} tabStyle="center primary" />
				{this.dineAssistHowItWorksSection()}
				<Row>
					<Col sm="12" lg="8">
						<TabPanelBody tabs={tabs} />
					</Col>
					<Col sm="12" lg="4">
						<MoreHelp
							dataKey={this.contactInfoKey()}
							title={translate(`HelpFAQ.${this.contactInfoKey()}FAQ.moreHelp.title`)}
							help={translate(`HelpFAQ.${this.contactInfoKey()}FAQ.moreHelp.help`)}
						/>
						<FAQReferACoworker className={referralClassProps} />
						{!adsHidden && <Ads dataKey={this.adDataKey()} className={adsClassProps} />}
					</Col>
				</Row>
			</div>
		);
	}
}

HelpFAQRouteTabPanel.defaultProps = {};

HelpFAQRouteTabPanel.propTypes = {
	match: PropTypes.object.isRequired,
	tabs: PropTypes.array.isRequired,
	translate: PropTypes.object.isRequired
};

export default HelpFAQRouteTabPanel;
