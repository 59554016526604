import * as t from '../actions/geo_location/types';

/**
 * Redux_GeoLocationState
 * @interface Redux_GeoLocationState
 */

const geoLocationDefaultState = {
	wasDenied: false,
	isLoading: false,
	hasGeoLocation: false,
	lat: 0,
	lng: 0,
	error: false
};

export default (state = geoLocationDefaultState, action) => {
	switch (action.type) {
		case t.FETCH_GEO_LOCATION: {
			if (action.payload.coords) {
				let newState = {
					hasGeoLocation: true,
					isLoading: false,
					lat: action.payload.coords.latitude,
					lng: action.payload.coords.longitude,
					wasDenied: false,
					error: false
				};

				return newState;
			} else {
				const newState = {
					...geoLocationDefaultState,
					wasDenied: true
				};

				return newState;
			}
		}
		case t.FETCH_GEO_LOCATION_START: {
			let newState = {
				...state,
				isLoading: true,
				hasGeoLocation: false,
				wasDenied: false,
				error: false
			};

			return newState;
		}
		case t.GEOLOCATION_SERVICES_PERMISSIONS_DENIED: {
			return {
				...geoLocationDefaultState,
				wasDenied: true
			};
		}
		case t.FETCH_GEO_LOCATION_ERROR: {
			return {
				...geoLocationDefaultState,
				isLoading: false,
				hasGeoLocation: false,
				error: action.payload.message,
				wasDenied: action.payload.code === 1
			};
		}
		default:
			return state;
	}
};
