import Papa from 'papaparse'; // https://www.papaparse.com/docs#config

export const parseCSVFile = file => {
	return new Promise((resolve, reject) => {
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: results => {
				console.log('CSV parse complete:', results); // Add this line for logging
				if (results.errors.length) {
					reject(results.errors);
				} else {
					resolve(results); // Make sure to resolve with the complete results
				}
			},
			error: error => {
				reject(error);
			}
		});
	});
};
