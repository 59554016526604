import React from 'react';
import PropTypes from 'prop-types';

// import './style.scss';

/**
 * InputTrigger - CTA component for inputs.
 * @param {string}   alt     Alt for the CTA icon img.
 * @param {funciton} onClick On trigger click handler.
 * @param {string}   src     The icon img src.
 * @param {string}   [text]    The text (if any) to show below the CTA icon.
 */
const InputTrigger = ({ alt, dataCy, onClick, src, text }) => (
	<div className="input-trigger" data-cy={`${dataCy}-trigger`}>
		<img onClick={onClick} src={src} alt={alt} />
		{text && <p>{text}</p>}
	</div>
);

InputTrigger.defaultProps = {
	onClick: () => {},
	src: '',
	text: ''
};

InputTrigger.propTypes = {
	alt: PropTypes.string,
	dataCy: PropTypes.string,
	onClick: PropTypes.func,
	src: PropTypes.string,
	text: PropTypes.string
};

export default InputTrigger;
