import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { useTranslate } from 'hooks';

const RewardsErrorMsg = ({ className }) => {
	const translate = useTranslate();
	const classProps = classNames('rewards-error-msg container', className);

	return (
		<div className={classProps}>
			<div className="row">
				<div className="col">
					<Title size="h2">{translate('RewardError.msgTitle')}</Title>
					{translate('RewardError.msg')}
				</div>
			</div>
		</div>
	);
};

RewardsErrorMsg.defaultProps = {
	className: ''
};

RewardsErrorMsg.propTypes = {
	className: PropTypes.string
};

export default RewardsErrorMsg;
