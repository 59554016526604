import { BaseFilterItem } from 'utils/search_new/classes';

const addOrUpdateMasterFilter = ({
	filter,
	filterFunc,
	groupTitle,
	groupSubAccr,
	id,
	masterAccr,
	subAccr,
	value,
	filterOptionDisplayValue,
	selectedFilterDisplayValue,
	restaurantId
}) => {
	if (groupSubAccr) {
		if (groupSubAccr === 'favorite') {
			// console.log('%c filter', 'color: limegreen', filter);
			// console.log('%c groupTitle', 'color: limegreen', groupTitle);
			// console.log('%c groupSubAccr', 'color: limegreen', groupSubAccr);
		}
		if (filter[groupTitle][groupSubAccr]) {
			// filter[groupTitle][groupSubAccr].resultsCount += 1;
			filter[groupTitle][groupSubAccr].restaurantIds.add(restaurantId);
			filter[groupTitle][groupSubAccr].staticRestaurantIds.add(restaurantId);
		} else {
			filter[groupTitle][groupSubAccr] = new BaseFilterItem({
				// resultsCount: 1,
				selected: false,
				value: value || subAccr,
				id,
				dataAccr: subAccr ? `${masterAccr}.${subAccr}` : `${masterAccr}`,
				filterKey: `${groupTitle}.${groupSubAccr}`,
				filterOptionDisplayValue,
				selectedFilterDisplayValue,
				filterFunc,
				restaurantIds: new Set([restaurantId]),
				staticRestaurantIds: new Set([restaurantId])
			});
		}
	} else {
		console.log('this shouldnt happen');
		// if (filter[groupTitle]) {
		// 	filter[groupTitle].resultsCount += 1;
		// } else {
		// 	filter[groupTitle] = new BaseFilterItem({
		// 		resultsCount: 1,
		// 		selected: false,
		// 		value: value || subAccr,
		// 		id,
		// 		dataAccr: subAccr ? `${masterAccr}.${subAccr}` : `${masterAccr}`,
		// 		filterKey: `${groupTitle}`,
		// 		filterOptionDisplayValue,
		// 		selectedFilterDisplayValue,
		// 		filterFunc
		// 	});
		// }
	}
};

export default addOrUpdateMasterFilter;
