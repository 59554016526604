/***********************
 * MODULES
 ***********************/
const AWS = require('aws-sdk');
const jwt = require('jsonwebtoken');
import { STORE } from 'components/environment/App/redux_store.js';
AWS.config.update({ region: 'us-east-1' });

// required since cognito identity js file is bade for browsers not node
//global.fetch 	= require('node-fetch');

/***********************
 * CLASSES
 ***********************/
class DinovaFederatedUser {
	/***********************
	 * INSTANCE FUNCTIONS
	 ***********************/
	async authenticate(token, identityPoolId) {
		//let config = STORE.getState().config;
		let config = STORE.getState().config;
		identityPoolId = config['federated_identity_pool'];

		// validate params
		if (!token) {
			throw new Error('Missing or invalid token');
		}

		if (!identityPoolId) {
			throw new Error('Missing or invalid identityPoolId');
		}

		// decode token - this does NOT verify it
		const payload = jwt.decode(token);

		if (!payload) {
			throw new Error('Could not decode the provided token, is it valid?');
		}

		// verify token type
		if (payload.token_use != 'id') {
			throw new Error(
				`Wrong token, federated users require an id token, but an ${payload.token_use} token was provided`
			);
		}

		// create and assign credentials object from existing login
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: identityPoolId,
			Logins: {
				[payload.iss.substr(8)]: token
			}
		});

		await AWS.config.credentials.getPromise();
	}

	get identityId() {
		return AWS.config.credentials.identityId;
	}
}

/***********************
 * EXPORTS
 ***********************/
export default DinovaFederatedUser;
