/* eslint-disable no-case-declarations */
import * as t from '../actions/favorites_page/types';
import { isUndefined } from 'lodash';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const searchPageDefaultState = {
	showFilter: window.innerWidth > 768
};

export default (state = searchPageDefaultState, action) => {
	switch (action.type) {
		case t.TOGGLE_SHOW_FAVORITES_FILTER: {
			let showFilter = action.payload;
			if (isUndefined(showFilter)) {
				showFilter = !state.showFilter;
			}
			return {
				...state,
				showFilter
			};
		}

		default:
			return state;
	}
};
