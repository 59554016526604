import React from 'react';
import PropTypes from 'prop-types';
import HelpButton from 'components/molecules/HelpButton';
import classnames from 'classnames';

/**
 * WithHelp
 * @constructor
 * @param {WithHelpProps} props
 * @param {object} [props.children] The element to render associated with this help button.
 * @param {string} [props.help] The text to render in the help modal.
 *
 * @example <WithHelp help="This is help text.">
	<div>This is the child to associate help with.</div>
</WithHelp>
 */
const WithHelp = ({ children, className, help, id, isOpen, onHelpClick, onWrapperClick, title }) => {
	const classProps = classnames('with-help', className && className);

	return (
		<div className={classProps} onClick={onWrapperClick}>
			{children && <div className="children">{children}</div>}
			<HelpButton help={help} id={id} title={title} isOpen={isOpen} onHelpClick={onHelpClick} />
		</div>
	);
};

WithHelp.defaultProps = {
	help: 'This is help text.'
};

/**
 * WithHelp Props
 * @interface Props_WithHelp
 * @property {string} id The id for this help button.
 * @property {object} [children] The element to render associated with this help button.
 * @property {string} [help] The text to render in the help modal.
 */
WithHelp.propTypes = {
	// Required
	id: PropTypes.string.isRequired,

	// Optional
	children: PropTypes.node,
	className: PropTypes.string,
	help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	title: PropTypes.string,
	onWrapperClick: PropTypes.func,
	onHelpClick: PropTypes.func,
	isOpen: PropTypes.bool
};

export default WithHelp;
