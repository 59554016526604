import * as t from '../actions/user_recommendations/types';
import moment from 'moment';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [recommendations=[]]
 * @property {boolean} [isLoading=false]
 * @property {boolean|string} [error=false]
 */
const userRecommendationsDefaultState = {
	// recommendations: {},
	isLoading: true,
	error: false,
	editRecDate: null,
	restaurantIdsWithRecs: [] // partial list - used for transaction history to determine if user left rec during 5 min BE wait for update period
};

export default (state = userRecommendationsDefaultState, action) => {
	switch (action.type) {
		case t.SHOW_RECOMMENDATIONS_MODAL_PREFILLED:
			return {
				...state,
				editRecDate: action.payload.recommendation.date
			};
		case t.TOGGLE_SHOW_RECOMMENDATIONS_MODAL: {
			return {
				...state,
				editRecDate: action.payload.show ? state.editRecDate : null
			};
		}
		case t.SUBMIT_RECOMMENDATION_SUCCESS: {
			if (window.location.pathname.indexOf('recommendations') > -1) {
				let newState = Object.assign({}, state);
				let year = moment(state.editRecDate).year(),
					month = moment(state.editRecDate).month() + 1;
				let recs = newState.recommendations[year][month];

				recs.map(rec => {
					if (rec.restaurant.id === action.payload.restaurantId) {
						rec.for = action.payload.recommendations.for;
						rec.recommended = action.payload.recommendations.recommended;
						if (action.payload.recommendations.feedback || action.payload.recommendations.feedback === '') {
							rec.feedback = action.payload.recommendations.feedback;
						}
					}
					return rec;
				});

				return newState;
			}
			return { ...state, restaurantIdsWithRecs: [...state.restaurantIdsWithRecs, action.payload.restaurantId] };
		}
		// case t.FETCH_USER_RECOMMENDATIONS_START:
		// 	return {
		// 		...state,
		// 		isLoading: true,
		// 		error: false
		// 	};
		// case t.FETCH_USER_RECOMMENDATIONS_SUCCESS: {
		// 	const recommendations = action.payload;

		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 		recommendations,
		// 		error: false
		// 	};
		// }
		// case t.FETCH_USER_RECOMMENDATIONS_FAILURE:
		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 		error: action.payload
		// 	};
		default:
			return state;
	}
};
