/* eslint-disable react/prop-types */
import React from 'react';

const IconWarning = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width}
		height={props.height}
		viewBox="0 0 135 135"
		data-cy="icon-warning"
		alt="warning"
	>
		<title>icon - warning</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					fill={props.fill}
					d="M130.14,111.51,73,4.36A8.23,8.23,0,0,0,65.69,0h0a8.22,8.22,0,0,0-7.26,4.35L1,111.51a8.24,8.24,0,0,0,7.26,12.13H122.86a8.26,8.26,0,0,0,7.28-12.13ZM7.24,116.4,65.68,7.24,123.87,116.4Z"
				/>
				<path
					fill={props.fill}
					d="M70.58,86.43V36.17a5,5,0,0,0-5-5h0a5,5,0,0,0-5,5V86.43a5,5,0,0,0,5,5h0A5,5,0,0,0,70.58,86.43Z"
				/>
				<circle fill={props.fill} cx="65.55" cy="103.05" r="6.48" />
			</g>
		</g>
	</svg>
);

export default IconWarning;
