import React from 'react';
import styles from './style.module.scss';
import { selectCompletedStepsCountFromUser } from 'actions/profile_setup/selectors';
import { useSelector } from 'react-redux';
import { useUserContext } from 'context';

const ProfileSetupProgressBar = () => {
	const totalSteps = useSelector(state => state.profileSetup.totalSteps);
	const { user } = useUserContext();
	const completedStepsCount = selectCompletedStepsCountFromUser(user);
	const completedPercent = (completedStepsCount / totalSteps) * 100;

	return (
		<div className={styles.progressBar}>
			<div className={styles.completedPercent} style={{ width: `${completedPercent}%` }} />
		</div>
	);
};

export default ProfileSetupProgressBar;
