import React from 'react';
import PropTypes from 'prop-types';
import { Select as MUISelect } from 'mui-rff';
import { MenuItem } from '@mui/material';
import Caret from '../Caret';

/*
interface SelectProps {

}
*/

const Select = ({ dataCy, options, ...others } /*: SelectProps*/) => {
	return (
		<MUISelect
			{...others}
			data-cy={dataCy}
			IconComponent={({ className }) => <Caret isOpen={className.includes('iconOpen')} />}
			MenuProps={{
				'data-cy': `${dataCy}-select-menu`,
				classes: {
					paper: 'select-menu'
				}
			}}
		>
			{options.map(option => (
				<MenuItem key={option.label} value={option.value} data-cy={`${dataCy}-option`}>
					{option.label}
				</MenuItem>
			))}
		</MUISelect>
	);
};

Select.propTypes = {
	dataCy: PropTypes.string,
	options: PropTypes.arrayOf([
		{ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string }
	])
};

export default Select;
