import SearchPageResultsPanel from './ui-component';
import { connect } from 'react-redux';
import { toggleShowList } from 'actions/search_page';

const mapStateToProps = state => ({
	activePin: state.searchPage.activePin,
	showFilter: state.searchPage.showFilter,
	showList: state.searchPage.showList
});

const mapDispatchToProps = dispatch => ({
	toggleShowList: show => dispatch(toggleShowList(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageResultsPanel);
