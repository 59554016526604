import {
	containsLowerCaseValidator,
	containsNumberValidator,
	containsUpperCaseValidator,
	containsSpecialValidator,
	lengthValidator
} from 'utils/form/validators';

const passwordWarning = password => {
	const passwordWarning = {
		requirements: [
			{
				status: lengthValidator(password, 10) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.password.1'
			},
			{
				status: containsNumberValidator(password) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.password.2'
			},
			{
				status: containsLowerCaseValidator(password) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.password.3'
			},
			{
				status: containsUpperCaseValidator(password) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.password.4'
			},
			{
				status: containsSpecialValidator(password) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.password.5'
			}
		],
		titleKey: 'Registration.requirement-boxes.password.title'
	};

	return passwordWarning;
};

export default passwordWarning;
