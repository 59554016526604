export const getPhoneOrFax = str => {
	return str.replace(/(?!^)*[^0-9+]/g, '');
};

export const formatPhoneNumber = phoneNumberString => {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
};

export const onlyNumbers = value => {
	if (!value) {
		return '';
	}

	return value.replace(/[^\d]/g, '');
};

// formatPhoneNumber('+12345678900') // => "+1 (234) 567-8900"
// formatPhoneNumber('2345678900')   // => "(234) 567-8900"

export const validateEmail = email => {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return true;
	}

	return false;
};
