import LocationAutoComplete from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import { getLocation } from 'actions/geo_location';
import { getTranslate } from 'react-localize-redux';

import {
	fetchLocationCompletions,
	initLocationAutoCompleteInstance,
	setActiveSuggestion,
	suggestionsItemClick
} from 'actions/location_autocomplete';

const mapStateToProps = (state, ownProps) => {
	return {
		suggestions: hasProp(state, `locationAutocomplete.${ownProps.reduxId}`)
			? state.locationAutocomplete[ownProps.reduxId].suggestions
			: [],
		showLoader: hasProp(state, `locationAutocomplete.${ownProps.reduxId}`)
			? state.locationAutocomplete[ownProps.reduxId].showLoader
			: false,
		activeItem: hasProp(state, `locationAutocomplete.${ownProps.reduxId}`)
			? state.locationAutocomplete[ownProps.reduxId].activeItem
			: false,
		geoLocationDenied: state.geoLocation.wasDenied,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocationCompletions: (search, showLoader) =>
		dispatch(fetchLocationCompletions(ownProps.reduxId, search, showLoader)),
	getLocation: async () => await getLocation(dispatch),
	initLocationAutoCompleteInstance: (currentLocationItem, noSuggestionsFoundItem) =>
		dispatch(initLocationAutoCompleteInstance(ownProps.reduxId, currentLocationItem, noSuggestionsFoundItem)),
	setActiveSuggestion: activeItem => dispatch(setActiveSuggestion(ownProps.reduxId, activeItem)),
	suggestionsItemClick: suggestion => dispatch(suggestionsItemClick(ownProps.reduxId, suggestion))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationAutoComplete);
