import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import classnames from 'classnames';

const InvisibleButton = ({ children, dataCy, fullWidth, onClick }) => {
	return (
		<button
			className={classnames(styles.invisibleButton, { [styles.fullWidth]: fullWidth })}
			onClick={onClick}
			data-cy={dataCy}
		>
			{children}
		</button>
	);
};

InvisibleButton.defaultProps = {};

InvisibleButton.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	dataCy: PropTypes.string,
	fullWidth: PropTypes.bool,
	onClick: PropTypes.func
};

export default InvisibleButton;
