/* eslint-disable no-unused-vars */
/***********************
 * CONSTANTS
 ***********************/
import DinovaFederatedUser from './DinovaFederatedUser';
import AWS from 'aws-sdk';
import { STORE } from 'components/environment/App/redux_store.js';

import * as fs from 'fs'; // requires node > 11

/***********************
 * CLASSES
 ***********************/
class DinovaClientAdmin extends DinovaFederatedUser {
	/***********************
	 * OVERRIDDEN FUNCTIONS
	 ***********************/
	async authenticate(token, identityPoolId) {
		let config = STORE.getState().config;
		const CLIENT_PORTAL_STORAGE_BUCKET = config['aws_connect_bucket'];

		await super.authenticate(token, identityPoolId);

		this.S3 = new AWS.S3({ apiVersion: '2006-03-01', params: { Bucket: CLIENT_PORTAL_STORAGE_BUCKET } });
	}

	/***********************
	 * INSTANCE FUNCTIONS
	 ***********************/
	async upload(file, path, processedData) {
		// remove initial / if present
		path = path.startsWith('/') ? path.substr(1) : path;

		// add trailing / if not present
		path = path.endsWith('/') ? path : path + '/';

		// add timestamp
		let filePath = file.name
			.split('/')
			.pop()
			.replace('.csv', `.${Date.now()}.csv`);
		let dirPath = `${path}${filePath}`;

		return await this.S3.upload({
			Key: dirPath,
			Body: processedData
		}).promise();
	}

	// async listFiles(params) {
	// 	return await this.S3.listObjectsV2(params).promise();
	// }
}

/***********************
 * EXPORTS
 ***********************/
export default DinovaClientAdmin;
