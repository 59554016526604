import { getVal } from 'utils/object';

export const restaurantValIdArrayCheck = (restaurant, filter) => {
	const rVal = getVal(restaurant, filter.dataAccr);
	if (!rVal) {
		return false;
	} else {
		return rVal.filter(v => v.id === filter.id).length > 0;
	}
};

export const restaurantValIdCheck = (restaurant, filter) => {
	let rVal = getVal(restaurant, filter.dataAccr);
	if (!rVal) {
		return false;
	}
	return rVal.id === filter.id;
};

export const recommendationsFilterCheck = (restaurant, filter) => {
	const rVal = getVal(restaurant, filter.dataAccr);
	if (!rVal) {
		return false;
	} else {
		return rVal[filter.id] ? true : false;
	}
};

export const amenitiesFilterCheck = (restaurant, filter) => {
	const rVal = getVal(restaurant, filter.dataAccr);
	if (!rVal) {
		return false;
	} else {
		return Object.keys(rVal).length > 0;
	}
};

export const amenitiesEntertainmentCheck = (restaurant, filter) => {
	let rVal = getVal(restaurant, filter.dataAccr);
	if (!rVal) {
		return false;
	}
	return rVal && rVal.length > 0 ? true : false;
};

export const favoriteFilterCheck = restaurant => {
	return restaurant?.user?.favorite === true;
};

export const safetyMeasuresFilterCheck = restaurantValIdArrayCheck;

export const dietaryPreferencesFilterCheck = restaurantValIdArrayCheck;

export const amenitiesGeneralFilterCheck = restaurantValIdArrayCheck;

export const amenitiesSeatingCheck = restaurantValIdArrayCheck;

export const cuisineFilterCheck = restaurantValIdCheck;
