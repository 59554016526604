import React from 'react';
import { CollapsiblePanel } from 'components/molecules';
import { RestaurantResultsList } from 'components/organisms';
import PropTypes from 'prop-types';

/**
 * SearchPageResultsPanel
 * @param {SearchPageResultsPanelProps} props
 */
const SearchPageResultsPanel = ({ activePin, didFetch, isLoading, results, showFilter, showList, toggleShowList }) => {
	return (
		<CollapsiblePanel isOpen={showList} toggle onToggleClick={toggleShowList}>
			<RestaurantResultsList
				activePin={activePin}
				showFilter={showFilter}
				results={results}
				didFetch={didFetch}
				showFavoriteIcon
				isLoading={isLoading}
			/>
		</CollapsiblePanel>
	);
};

SearchPageResultsPanel.defaultProps = {};

/**
 * SearchPageResultsPanelProps
 * @typedef {Object} SearchPageResultsPanelProps
 */
SearchPageResultsPanel.propTypes = {
	activePin: PropTypes.string,
	didFetch: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	results: PropTypes.array.isRequired,
	showFilter: PropTypes.bool.isRequired,
	showList: PropTypes.bool.isRequired,
	toggleShowList: PropTypes.func.isRequired
};

export default SearchPageResultsPanel;
