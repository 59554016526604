import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

const NoResponseNoticeMsg = ({ className }) => {
	const translate = useTranslate();
	const classProps = classNames('no-response-notice-msg', className);

	return <span className={classProps}>{translate('NoResponse.noticeMsg')}</span>;
};

NoResponseNoticeMsg.defaultProps = {
	className: ''
};

NoResponseNoticeMsg.propTypes = {
	className: PropTypes.string
};

export default NoResponseNoticeMsg;
