import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

/*
interface InformInitialSelectOfIneligibleCompanyModalProps {

}
*/

const InformInitialSelectOfIneligibleCompanyModal = (
	{ confirmCompanyChangeToIneligible, show, showSelectModal } /*: InformInitialSelectOfIneligibleCompanyModalProps*/
) => {
	const translate = useSelector(state => getTranslate(state.locale));

	return (
		<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
			<ModalHeader>{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.header`)}</ModalHeader>
			<ModalBody>{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.body`)}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							{/* Confirm Switch to Ineligible Company Button */}
							<Button block color="primary" onClick={confirmCompanyChangeToIneligible}>
								{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.primaryButtonText`)}
							</Button>
						</Col>
						<Col sm="12">
							{/* Cancel Switch to Ineligible Company Button */}
							<Button block color="secondary" onClick={showSelectModal}>
								{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.secondaryButtonText`)}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

InformInitialSelectOfIneligibleCompanyModal.propTypes = {
	confirmCompanyChangeToIneligible: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showSelectModal: PropTypes.func.isRequired
};

export default InformInitialSelectOfIneligibleCompanyModal;
