import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import classnames from 'classnames';
import moment from 'moment';
import { Title } from 'components/atoms';
import { useTranslate } from 'hooks';

const DatePaginator = ({ className, IMAGE_BASE_URL, nextSelect, onDateChange, previousSelect, selectedDate, type }) => {
	const translate = useTranslate();
	const [date, setDate] = useState(moment(selectedDate));

	useEffect(() => {
		if (selectedDate && selectedDate !== date) {
			setDate(selectedDate);
		}
	}, [date, selectedDate]);

	const previous = useCallback(() => {
		const _date = moment(date).subtract(1, `${type}s`);

		if (previousSelect) {
			previousSelect(_date);
		} else if (onDateChange) {
			onDateChange(_date);
		} else {
			setDate(_date);
		}
	}, [date, onDateChange, previousSelect, type]);

	const next = useCallback(() => {
		const _date = moment(date).add(1, `${type}s`);

		if (nextSelect) {
			nextSelect(_date);
		} else if (onDateChange) {
			onDateChange(_date);
		} else {
			setDate(_date);
		}
	}, [date, onDateChange, nextSelect, type]);

	const { monthString, yearString, currentMonthSelected, currentYearSelected } = useMemo(
		() => ({
			classProps: classnames('date-paginator', className, type),
			monthString: moment(date).format('MMMM'),
			yearString: moment(date).format('YYYY'),
			currentMonthSelected: date ? new Date().getMonth() === moment(date).month() : true,
			currentYearSelected: date ? new Date().getFullYear() === moment(date).year() : true
		}),
		[className, date, type]
	);

	const { classProps, nextClassProps } = useMemo(
		() => ({
			classProps: classnames('date-paginator', className, type),
			nextClassProps: classnames('next', currentMonthSelected && currentYearSelected && 'invisible')
		}),
		[className, currentMonthSelected, currentYearSelected, type]
	);

	return (
		<div className={classProps}>
			<p className="prev" onClick={previous} data-cy="previous-date">
				<img
					src={`${IMAGE_BASE_URL}/graphics/icon-arrow-left.svg`}
					alt={translate(`DatePaginator.previous.${type}`)}
				/>
			</p>
			<Title size="h2" dataCy="current-date">
				{type === 'month' && monthString + ' '}
				{yearString}
			</Title>
			<p className={nextClassProps} onClick={next} data-cy="next-date">
				<img
					src={`${IMAGE_BASE_URL}/graphics/icon-arrow-right.svg`}
					alt={translate(`DatePaginator.next.${type}`)}
				/>
			</p>
		</div>
	);
};

DatePaginator.defaultProps = {
	className: ''
};

DatePaginator.propTypes = {
	className: PropTypes.string,
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	nextSelect: PropTypes.func,
	onDateChange: PropTypes.func,
	previousSelect: PropTypes.func,
	selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), momentPropTypes.momentObj, PropTypes.string]),
	type: PropTypes.oneOf(['month', 'year'])
};

export default DatePaginator;
