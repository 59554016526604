import { useEffect } from 'react';
import { refresh } from 'actions/users';
import { useDispatch, useSelector } from 'react-redux';

// interface useAuthStateProps {}

const useAuthState = (/*:useAuthStateProps*/) => {
	const dispatch = useDispatch();

	const authState = useSelector(state => {
		return {
			idToken: state?.users?.idToken,
			isAdminUser: (state.users?.permissions || []).indexOf('50') > -1,
			isAuthed: Boolean(state.users?.authenticated),
			errorCode: state.users?.errorCode,
			lastLogin: state.users?.lastLogin,
			permissions: state.users?.permissions || [],
			refreshingUser: state.users?.refreshingUser
		};
	});

	useEffect(() => {
		if (!authState.refreshingUser && authState.isAuthed && (!authState.idToken || !authState.lastLogin)) {
			dispatch(refresh());
		}
	}, [authState.refreshingUser, authState.isAuthed, authState.idToken, authState.lastLogin, dispatch]);

	return authState;
};

export default useAuthState;
