import * as t from './types';
import axios from 'axios';
import { basePath_restaurant, AUTH_REQUEST_HEADER } from 'actions/utils';
import Cookies from 'universal-cookie';
import onboardingData from 'utils/onboardingData';
import { setIsLoading } from 'actions/loader';

export const searchRestaurants = ({ lat, lng, uom = 'mi', keywords, radius, mock }) => async dispatch => {
	dispatch(setIsLoading(true, 'Getting Restaurants'));
	dispatch({
		type: t.SEARCH_RESTAURANTS_START
	});

	var URL = `${basePath_restaurant()}restaurants/search?location=${lat},${lng}&uom=${uom}`;
	let cookies = new Cookies();

	if (keywords && keywords.length > 0) {
		URL += `&keywords=${keywords}`;
	}

	if (radius && radius.length > 0) {
		URL += `&radius=${radius}`;
	}

	if (cookies.get('mceasy')) {
		URL += '&network=mceasy';
	}
	if (cookies.get('visaedge')) {
		URL += '&network=visaedge';
	}

	var results = mock ? onboardingData : await axios.get(URL, AUTH_REQUEST_HEADER()).then(URL);

	// console.log('r', results);
	// console.log('%c ****SearchRestaurants - Stopping the loader****', 'color:blue');
	dispatch(setIsLoading(false));

	if (results.data.response.code === 200) {
		dispatch({
			type: t.SEARCH_RESTAURANTS_SUCCESS,
			payload: results.data.restaurants
		});

		return results.data.restaurants;
	} else {
		console.log('Something went wrong on search:', results);
		dispatch({
			type: t.SEARCH_RESTAURANTS_ERROR,
			payload: { error: results.data.response.code, message: results.data.response.message }
		});
	}
};

export const toggleShowFilter = show => ({
	type: t.TOGGLE_SHOW_FILTER,
	payload: show
});

export const toggleShowList = show => {
	window.localStorage.setItem('search-page-show-list', show);
	return {
		type: t.TOGGLE_SHOW_LIST,
		payload: show
	};
};

export const setActivePin = pin => ({
	type: t.SET_ACTIVE_PIN,
	payload: pin
});

export const setMapCardDisplayed = isDisplayed => ({
	type: t.SET_MAP_CARD_DISPLAYED,
	payload: isDisplayed
});

export const setSearchErrorType = errorType => ({
	type: t.SET_SEARCH_PAGE_ERROR_TYPE,
	payload: errorType
});

export const saveSearchState = () => ({
	type: t.SAVE_SEARCH_PAGE_STATE
});

export const hydrateFromSavedSearchState = () => ({
	type: t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE
});

export const setLastSearch = href => ({
	type: t.SET_LAST_BUSINESS_SEARCH,
	payload: href
});
