import * as t from '../actions/company_select_modal/types';
import * as user from '../actions/users/types';

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */
const companySelectModalInitialState = {
	show: false,
	type: 'select'
};

export default (state = companySelectModalInitialState, action) => {
	switch (action.type) {
		case t.TOGGLE_SHOW_COMPANY_SELECT_MODAL: {
			const { show, type } = action.payload;

			return {
				show,
				type: show === false ? '' : type || state.type
			};
		}
		case user.AUTH_USER: {
			if (action.payload.access === '') {
				return companySelectModalInitialState;
			}
			return state;
		}
		default:
			return state;
	}
};
