import Bowser from 'bowser';

const bowser = Bowser.getParser(window.navigator.userAgent);
const browser = bowser.getBrowser();
const platform = bowser.getPlatform();

export const isIE = () => browser.name.indexOf('Internet Explorer') !== -1;

export const isEdge = () => browser.name.indexOf('Microsoft Edge') !== -1;

export const isFireFox = () => browser.name.indexOf('Firefox') !== -1;

export const isSafari = () => browser.name.indexOf('Safari') !== -1;

export const isMobile = () => platform.type === 'mobile';

export const isDesktop = () => platform.type === 'desktop';
