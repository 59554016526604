import FavoriteRestaurantButton from './ui-component';
import { connect } from 'react-redux';
import { updateOnboardingStatus } from 'actions/users';
import { completeStep } from 'actions/profile_setup';
import { ProfileSetupSteps } from 'reducers/profile_setup';

const mapDispatchToProps = dispatch => ({
	updateOnboardingStatus: () => {
		dispatch(completeStep(ProfileSetupSteps.FAVORITE));
		dispatch(updateOnboardingStatus({ onboardingStatus: { favoriteRestaurant: true } }));
	},
	completeStep: () => dispatch(completeStep(ProfileSetupSteps.FAVORITE))
});

export default connect(null, mapDispatchToProps)(FavoriteRestaurantButton);
// export default FavoriteRestaurantButton;
