import { useQuery } from './';
import { basePath } from 'actions/utils';

export const COMPANY_ROSTER_QUERY_KEY = 'company_roster_query';

const useCompanyRosterQuery = ({ setReduxIsLoading, setDataInRedux } = {}) => {
	return useQuery({
		// Req
		queryHandler: res => res.data.clients,
		queryKey: [COMPANY_ROSTER_QUERY_KEY],
		// url,

		// Opt
		authType: 'id',
		requiresAuth: false,
		reduxLoadingMsg: 'Loading Company Roster...',
		reduxQueryDataAccr: query => query.data,
		setReduxIsLoading,
		setDataInRedux,
		urlBuilder: () => {
			return `${basePath()}/clients/roster`;
		}
	});
};

export default useCompanyRosterQuery;
