import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';
import { useUserContext } from 'context';

// interface useForceShowPointsEligibilityProps {}

/* LEGACY: This may not be needed after react-query refactor.
		Because update user does not return the udpated user data, we don't know if the update includes a change from
		a myDinova eligible company to a non myDinova eligible company.
		To make sure we show the points ineligible modal after the personal information form is submitted,
		compare current state.users.user to the new user on action.payload to see if action.payload includes
		a new company and if that company is eligible.
		If it is not eligible, we force the pointsElegibilityModal to show.
	*/

const useForceShowPointsEligibility = (/*{  }:useForceShowPointsEligibilityProps*/) => {
	const { user, isLoading } = useUserContext();
	const dispatch = useDispatch();

	const { isOpen } = useSelector(state => {
		return { isOpen: state.profileSetup.open };
	});

	const { notEligible, notParticipant } = useMemo(() => {
		return {
			notEligible: user?.company?.myDinova ? !user?.company?.myDinova?.eligible : false,
			notParticipant: user?.company?.myDinova ? user?.company?.myDinova?.participant !== 2 : false
		};
	}, [user?.company]);

	useEffect(() => {
		if (!isOpen && !isLoading && (notEligible || notParticipant)) {
			dispatch(toggleShowPointsElegibilityModal(true));
		}
	}, [isLoading, notEligible, notParticipant, isOpen, dispatch]);
};

export default useForceShowPointsEligibility;
