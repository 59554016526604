import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { favoriteFilterCheck } from 'utils/search_new/utils/filterFuncs';

const favoriteFilterAggregate = (filter, restaurant, filterConfig) => {
	if (restaurant?.user?.favorite) {
		addOrUpdateMasterFilter({
			filter,
			groupTitle: filterConfig.groupTitle,
			groupSubAccr: 'favorite',
			masterAccr: 'user',
			subAccr: 'favorite',
			filterFunc: favoriteFilterCheck,
			restaurantId: restaurant.id
		});
	}
};

export default favoriteFilterAggregate;
