import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Caret that will indicate if item is opened or closed.
 * @param {CaretProps} props
 */
const Caret = ({ dataCy, isOpen }) => {
	const classProps = classNames('accordion__arrow', isOpen ? 'open' : 'closed');
	return <div className={classProps} role="presentation" data-cy={`caret-${dataCy}`} />;
};

Caret.defaultProps = {
	isOpen: false
};

/**
 * {@link Caret} Props
 * @interface Props_Caret
 * @property {boolean} [isOpen=true] If Caret should point up or down. If true, Caret points up, to indicate that clicking will close item. If false, Caret points down, to indicate that clicking will open item.
 *
 */
Caret.propTypes = {
	dataCy: PropTypes.string,
	isOpen: PropTypes.bool
};

export default Caret;
