import { useDispatch } from 'react-redux';
import { login } from 'actions/users';
import { useUserConnectMFAMutation } from 'reactQuery/mutations';
import { useUserContext } from 'context';

// interface useMFAMutationsProps {}

const useMFAMutations = ({ onError, screen, setScreen } /*:useMFAMutationsProps*/) => {
	const { user } = useUserContext();
	const dispatch = useDispatch();

	const { connectMFA } = useUserConnectMFAMutation({
		onError: error => {
			onError && onError(error);
			switch (error.response.status) {
				case 200:
					dispatch(
						login(user, response => {
							if (response) {
								setScreen('code');
							}
						})
					);

					return;
				case 411:
					if (screen === 'phoneNumber') {
						setScreen('code');
					} else {
						// debugger;
					}
					return;
				default:
					console.log('Error:', error.response);
					return;
			}
		}
	});

	return {
		connectMFA
	};
};

export default useMFAMutations;
