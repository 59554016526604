export const ADD_BUSINESS_FILTER = 'ADD_BUSINESS_FILTER';
export const APPLY_URL_SEARCH_FILTERS = 'APPLY_URL_SEARCH_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_SEARCH_PAGE_SAVE_STATE = 'CLEAR_SEARCH_PAGE_SAVE_STATE';
export const FILTER_WORKING = 'FILTER_WORKING';
export const HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE = 'HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE';
export const REMOVE_BUSINESS_FILTER = 'REMOVE_BUSINESS_FILTER';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_BUSINESS_SEARCH_RESULTS = 'SET_BUSINESS_SEARCH_RESULTS';
export const SET_BUSINESS_SEARCH_FIND = 'SET_BUSINESS_SEARCH_FIND';
export const SET_BUSINESS_SEARCH_NEAR = 'SET_BUSINESS_SEARCH_NEAR';
export const SAVE_SEARCH_PAGE_STATE = 'SAVE_SEARCH_PAGE_STATE';
