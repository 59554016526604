import React from 'react';
import { useUser } from 'hooks';
import MFAModalPage from './ui-component';

/*
interface MFAModalPageDataWrapperProps {

}
*/

const MFAModalPageDataWrapper = (props /*: MFAModalPageDataWrapperProps*/) => {
	const { user } = useUser;
	return <MFAModalPage {...props} user={user} />;
};

export default MFAModalPageDataWrapper;
