const getMockGeolocation = () => {
	return {
		coords: {
			accuracy: 57.51,
			altitude: null,
			altitudeAccuracy: null,
			heading: null,
			latitude: 33.9861464,
			longitude: -83.9785599,
			speed: null
		},
		timestamp: 1705536412448
	};
};

export default getMockGeolocation;
