import React from 'react';
import PropTypes from 'prop-types';
import { Button as MUIButton } from '@mui/material';
import classnames from 'classnames';
import { useTranslate } from 'hooks';

/*
interface ButtonProps {

}
*/

const Button = ({ dataCy, fullWidth, variant = 'contained', ...props } /*: ButtonProps*/) => {
	const translate = useTranslate();
	const className = classnames(props.className, { fullWidth });
	let children = props.children;
	if (props.type === 'submit' && !children) {
		children = translate('common.submit');
	}

	return (
		<MUIButton {...props} className={className} variant={variant} disableElevation data-cy={dataCy}>
			{children}
		</MUIButton>
	);
};

Button.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	fullWidth: PropTypes.bool,
	type: PropTypes.string,
	variant: PropTypes.oneOf(['black-outlined', 'nav-item', 'contained', 'outlined', 'text'])
};

export default Button;
