import * as t from '../actions/points_elegibility_modal/types';

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */
const pointsElegibilityModalInitialState = {
	show: false
};

export default (state = pointsElegibilityModalInitialState, action) => {
	switch (action.type) {
		case t.TOGGLE_SHOW_POINTS_ELEGIBILITY_MODAL:
			return {
				show: action.payload
			};
		default:
			return state;
	}
};
