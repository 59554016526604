import { useCallback, useMemo, useRef, useState } from 'react';
import { setModal, toggleModal } from 'actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import isBool from 'utils/isBool';
import { history } from 'utils/router';
import { useUserDisconnectMFAMutation } from 'reactQuery/mutations';
import { useUserContext } from 'context';

// interface useEditSecurityMFAModalProps {}

export const MFA_MODAL_ID = 'mfa';

const useEditSecurityMFAModal = (/*:useEditSecurityMFAModalProps*/) => {
	const dispatch = useDispatch();
	const { modal } = useSelector(state => ({ modal: state.modal }));
	const { user } = useUserContext();
	const onCancelCB = useRef(() => {});
	const [hasError, setHasError] = useState(false);

	const { mfaEnabled } = useMemo(
		() => ({
			mfaEnabled: user?.security?.mfaEnabled
		}),
		[user]
	);

	const handleResponse = useCallback(
		response => {
			if (response.code === 200) {
				setHasError(false);
				history.goBack();
			} else if (response.msg) {
				setHasError(response.msg);
			} else {
				setHasError('Something unexpected happened.  Please try again after some time.');
			}
		},
		[setHasError]
	);

	const { disconnectMFA } = useUserDisconnectMFAMutation({
		onSuccess: handleResponse,
		onError: handleResponse
	});

	const onToggleMFA = useCallback(
		modalOpen => {
			if (mfaEnabled) {
				disconnectMFA();
			} else {
				const newOpen = isBool(modalOpen) ? modalOpen : !modal.isOpen;
				if (!newOpen) {
					onCancelCB.current && onCancelCB.current();
				}
				dispatch(toggleModal(newOpen));
			}
		},
		[dispatch, mfaEnabled, modal, disconnectMFA]
	);

	const openMFAModal = useCallback(() => {
		dispatch(
			setModal({
				id: 'mfa',
				header:
					'Security is important to us, which is why we strongly recommend enabling multi-factor authentication on your account.',
				body: `Click below to enable SMS Authentication or skip this step if not ready to enable on your account. In the future, you can activate this feature from your Security settings in your Account Profile page.`,
				primaryButtonText: 'ok',
				secondaryButtonText: 'skip'
			})
		);
		onToggleMFA();
	}, [dispatch, onToggleMFA]);

	const onUpdateMFA = useCallback(
		(mfa, cb) => {
			if (mfa.enabled) {
				openMFAModal();
			} else {
				onToggleMFA(false);
			}
			onCancelCB.current = cb;
		},
		[openMFAModal, onToggleMFA]
	);

	return {
		hasError,
		openMFAModal,
		onToggleMFA,
		onUpdateMFA,
		showMFAModal: modal.isOpen && modal.id === MFA_MODAL_ID
	};
};

export default useEditSecurityMFAModal;
