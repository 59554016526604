import { useCallback, useState } from 'react';
import { useDisableScrolling } from 'hooks';

// interface useRecommendationModalProps {}

const useRecommendationModal = (/*:useRecommendationModalProps*/) => {
	const [prefill, setPrefill] = useState(false);
	const [prefillDate, setPrefillDate] = useState(null);
	const [recommendationToEdit, setRecommendationToEdit] = useState(null);
	const [show, setShow] = useState(false);
	const [type, setType] = useState('positive');
	const [error, setError] = useState(false);
	const [errorCode, setErrorCode] = useState(false);
	const [formError, setFormError] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [restaurantId, setRestaurantId] = useState(false);

	useDisableScrolling(show);

	const toggleShow = useCallback(
		({ prefill: newPrefill, show: newShow, type: newType, restaurantId: newRestaurantId = null }) => {
			if (newShow === false) {
				setShow(false);
				setType('positive');
				setError(false);
				setErrorCode(false);
				setRestaurantId(newRestaurantId);
			} else {
				setShow(newShow);
				setType(newType);
				setPrefill(newPrefill);
				setRestaurantId(newRestaurantId);
			}
		},
		[]
	);

	const toggleShowThankYou = useCallback(newShowThankYou => {
		setError(false);
		setShowThankYou(newShowThankYou);
	}, []);

	const showRecommendationsModalPrefilled = useCallback(({ recommendation, type: newType }) => {
		setError(false);
		setPrefill(true);
		setPrefillDate(recommendation?.date);
		setRecommendationToEdit(recommendation);
		setRestaurantId(recommendation?.restaurant?.id);
		setShow(true);
		setShowThankYou(false);
		setType(newType);
	}, []);

	const submitNoneSelected = useCallback(() => {
		setShowThankYou(false);
		setError('NONE_SELECTED');
		setErrorCode('NONE_SELECTED');
	}, []);

	const submitFailure = useCallback(({ error: newError, errorCode: newErrorCode }) => {
		setError(newError);
		setShowThankYou(false);
		setErrorCode(newErrorCode);
	}, []);

	const closeModal = useCallback(() => {
		toggleShow({ show: false, type: '' });
	}, [toggleShow]);

	return {
		closeModal,
		prefill,
		prefillDate,
		recommendationToEdit,
		formError,
		setFormError,
		setType,
		show,
		type,
		error,
		setError,
		errorCode,
		showThankYou,
		restaurantId,
		toggleShow,
		toggleShowThankYou,
		setErrorCode,
		showRecommendationsModalPrefilled,
		submitNoneSelected,
		submitFailure
	};
};

export default useRecommendationModal;
