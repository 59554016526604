import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/atoms';
import { TextField } from 'mui-rff';
import { useTranslate } from 'hooks';
import { Button } from 'components/atoms';
import { CardNumberField, CreditCardTypeSelect, RegisteredConnections } from 'components/molecules';
import Grid from '@mui/material/Unstable_Grid2';
/*
interface DoDConnectToPointsFormFieldsProps {

}
*/

const DoDConnectToPointsFormFields = (
	{
		cardTypes,
		disabled,
		hideButtons,
		onNextClick,
		selectedCard,
		showRegistered = true
	} /*: DoDConnectToPointsFormFieldsProps*/
) => {
	const translate = useTranslate();

	return showRegistered ? (
		<Grid container spacing={4}>
			<Grid item xs={12} md={12}>
				<Col gap={15}>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-firstName.label')}
						name="firstName"
						required={true}
						disabled={disabled}
					/>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-middleName.label')}
						name="middleName"
						required={true}
						disabled={disabled}
					/>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-lastName.label')}
						name="lastName"
						required={true}
						disabled={disabled}
					/>
					<CreditCardTypeSelect cardTypes={cardTypes} disabled={disabled} selectedCard={selectedCard} />
					<CardNumberField disabled={disabled} selectedCard={selectedCard} />
					<Button
						style={{ minWidth: 185, maxWidth: '50%', marginLeft: 'auto', marginRight: 85 }}
						onClick={onNextClick}
						// className="mt-0 ml-0 mr-0 full-width"
						id="next-connect-to-points"
						color={'primary'}
						variant="contained"
						disabled={disabled}
					>
						{translate(`ConnectToPoints.primary_button`)}
					</Button>
				</Col>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid item xs={12}>
					<RegisteredConnections />
				</Grid>
			</Grid>
		</Grid>
	) : (
		<Grid container spacing={4}>
			<Grid item xs={12} md={6}>
				<Col gap={15}>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-firstName.label')}
						name="firstName"
						required={true}
						disabled={disabled}
					/>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-middleName.label')}
						name="middleName"
						required={true}
						disabled={disabled}
					/>
					<TextField
						label={translate('ConnectToPoints.inputs.dod-lastName.label')}
						name="lastName"
						required={true}
						disabled={disabled}
					/>
				</Col>
			</Grid>

			<Grid item xs={12} md={6}>
				<Col gap={15}>
					<CreditCardTypeSelect cardTypes={cardTypes} disabled={disabled} selectedCard={selectedCard} />
					<CardNumberField disabled={disabled} selectedCard={selectedCard} />
					{!hideButtons && (
						<Button
							style={{ minWidth: 185, maxWidth: '50%', marginLeft: 'auto', marginRight: 85 }}
							onClick={onNextClick}
							// className="mt-0 ml-0 mr-0 full-width"
							id="next-connect-to-points"
							color={'primary'}
							variant="contained"
							disabled={disabled}
						>
							{translate(`ConnectToPoints.primary_button`)}
						</Button>
					)}
				</Col>
			</Grid>
		</Grid>
	);
};

DoDConnectToPointsFormFields.propTypes = {
	cardTypes: PropTypes.array,
	disabled: PropTypes.bool,
	hideButtons: PropTypes.bool,
	onNextClick: PropTypes.func.isRequired,
	selectedCard: PropTypes.object,
	showRegistered: PropTypes.bool
};

export default DoDConnectToPointsFormFields;
