import AutoEnrollUserExpiredToken from './ui-component';
import { connect } from 'react-redux';
import { generateNewAutoEnrollToken } from 'actions/auto_enroll_user_form_page';

const mapStateToProps = state => ({
	error: state.autoEnrollUserFormPage.error,
	isLoading: state.autoEnrollUserFormPage.isLoading,
	linkSent: state.autoEnrollUserFormPage.linkSent
});

const mapDispatchToProps = dispatch => ({
	generateNewAutoEnrollToken: userData => dispatch(generateNewAutoEnrollToken(userData))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoEnrollUserExpiredToken);
