import { addOrUpdateMasterFilter } from 'utils/search_new/utils';
import { recommendationsFilterCheck } from 'utils/search_new/utils/filterFuncs';

const recommendationsFilterAggregate = (filter, restaurant, filterConfig, recOptions) => {
	if (restaurant.recommendations.positive.total > 0) {
		Object.keys(restaurant.recommendations.positive.categories).forEach(catId => {
			let selectedFilterDisplayValue = false;
			if (recOptions[catId] === 'Catering') {
				selectedFilterDisplayValue = 'Recommendations - Catering';
			} else if (recOptions[catId] === 'Private Dining') {
				selectedFilterDisplayValue = 'Recommendations - Private Dining';
			}
			if (!recOptions[catId]) {
				// id 13 doesn't seem to exist
				return;
			}
			addOrUpdateMasterFilter({
				filter,
				groupTitle: filterConfig.groupTitle,
				groupSubAccr: recOptions[catId],
				id: catId,
				masterAccr: 'recommendations',
				subAccr: 'positive.categories',
				value: recOptions[catId],
				selectedFilterDisplayValue,
				filterFunc: recommendationsFilterCheck,
				restaurantId: restaurant.id
			});
		});
	}
};

export default recommendationsFilterAggregate;
