import React, { Component } from 'react';
// import PropTypes from "prop-types";

// import "./style.scss";

/**
 * [FilterBar description]
 * @extends Component
 */
class FilterBar extends Component {
	render() {
		return <div className={'FilterBar'}>FilterBar</div>;
	}
}

FilterBar.defaultProps = {};

/**
 * [propTypes description]
 * @interface FilterBarProps
 *
 * @example {}
 */
FilterBar.propTypes = {};

export default FilterBar;
