import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { InputField } from 'components/organisms';
import { Asterisk, Title } from 'components/atoms';
import { currencyFormat } from 'utils/formatters';
import { useTranslate } from 'hooks';

const SelectRewardOptions = ({ className, cards, onClick, userPoints, value }) => {
	const translate = useTranslate();
	const classProps = classNames('select-reward-options', className);

	return (
		<div className={classProps}>
			<Title className="list-title" align="left" size="h3">
				{translate('Order.listTitle')}
				<Asterisk />
			</Title>
			<ul className="list">
				<InputField
					type="radio"
					onChange={onClick}
					options={cards.map(card => {
						const amount = Number.parseInt(card.amount);
						const points = Number.parseInt(card.points);
						// console.log('%c card', 'color: orange', card);
						return {
							value: parseInt(card.points),
							checked: value === points,
							displayValue: `${currencyFormat(points)} ${translate('Order.pointsSuffix')}`,
							info: (
								<span>
									<span className="money">
										${currencyFormat(amount)} {translate('Order.moneySuffix')}
									</span>{' '}
									&nbsp;
									<span className="points">
										({currencyFormat(points)} {translate('RewardView.pointsSuffix')})
									</span>
								</span>
							),
							id: `${card.id}`,
							dataCy: parseInt(card.amount),
							disabled: userPoints < points
						};
					})}
				/>
			</ul>
		</div>
	);
};

SelectRewardOptions.defaultProps = {
	className: '',
	cards: []
};

SelectRewardOptions.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
	userPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	cards: PropTypes.array
};

export default SelectRewardOptions;
