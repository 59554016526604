import RegistrationSteps from './ui-component';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code
});

export default connect(mapStateToProps)(RegistrationSteps);
