import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputField } from 'components/organisms';

const CCTextBox = ({
	cardTypes,
	className,
	disabled,
	errorText,
	example,
	fullNumberRequired,
	help,
	id,
	isValid,
	label,
	name,
	onBlur,
	onChange,
	onFocus,
	required,
	toggle,
	value
}) => {
	const ccClass = cardTypes.length === 1 ? cardTypes[0] : undefined;

	const classProps = classnames(className, ccClass, cardTypes.length === 1 && 'cc-labeled');

	return (
		<InputField
			dataCy={`cctextbox-${id}`}
			className={classProps}
			onFocus={onFocus}
			onBlur={onBlur}
			onChange={onChange}
			name={name}
			maxLength={fullNumberRequired ? (ccClass === 'amex' ? 15 : 16) : 4}
			id={id}
			error={errorText}
			value={value}
			label={label}
			help={help}
			example={example}
			hasError={!isValid}
			disabled={disabled}
			required={required}
			toggle={toggle}
		/>
	);
};

CCTextBox.defaultProps = {
	className: '',
	example: '',
	fullNumberRequired: true,
	mark: 'asterisk'
};

CCTextBox.propTypes = {
	cardTypes: PropTypes.array,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	errorText: PropTypes.string,
	example: PropTypes.string,
	fullNumberRequired: PropTypes.bool.isRequired,
	help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	id: PropTypes.string,
	isValid: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	required: PropTypes.bool,
	toggle: PropTypes.bool,
	value: PropTypes.string
};

export default CCTextBox;

// import { some } from 'lodash';

// const isAcceptedCardType = value => {
// 	let acceptedCreditCards = {
// 		visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
// 		mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
// 		amex: /^3[47][0-9]{13}$/,
// 		discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
// 		diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
// 		jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
// 	};

// 	// loop through the keys (visa, mastercard, amex, etc.)
// 	return some(acceptedCreditCards, cardRegex => cardRegex.test(value));
// };

// const validateCard = value => {
// 	// if has letters
// 	if (!/\d/.test(value)) {
// 		return false;
// 	}
// 	// remove all non digit characters
// 	value = value.replace(/\D/g, '');
// 	let sum = 0,
// 		shouldDouble = false;
// 	// loop through values starting at the rightmost side
// 	let valid, accepted;
// 	if (this.props.fullNumberRequired) {
// 		for (let i = value.length - 1; i >= 0; i--) {
// 			let digit = parseInt(value.charAt(i));

// 			if (shouldDouble) {
// 				if ((digit *= 2) > 9) digit -= 9;
// 			}

// 			sum += digit;
// 			shouldDouble = !shouldDouble;
// 		}

// 		(valid = sum % 10 === 0), (accepted = this.isAcceptedCardType(value));
// 	} else {
// 		(valid = value.length === 4), (accepted = valid);
// 	}

// 	return valid && accepted;
// };
