/* eslint-disable no-control-regex */
export const isRecipientName = (name, match = false) => {
	const regex = /^([A-z\d\s]+)$/;
	return match ? regex.match(name) : regex.test(name);
};

// use `import isEmail from 'validator/lib/isEmail';` instead
//
// export const isEmail = (email, match = false) => {
// 	const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// 	return match ? regex.match(email) : regex.test(email);
// };

export const stringToBoolean = str => {
	switch (str.toLowerCase().trim()) {
		case 'true':
		case 'yes':
		case '1':
			return true;
		case 'false':
		case 'no':
		case '0':
		case null:
			return false;
		default:
			return Boolean(str);
	}
};
