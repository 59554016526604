import * as t from './types';

export const selectCompany = company => ({
	type: t.SELECT_COMPANY,
	payload: company
});

export const setCompanySearch = text => ({
	type: t.SET_SEARCH_COMPANY_VALUE,
	payload: text
});
