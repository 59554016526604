import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Col, Row, Text } from 'components/atoms';
import PropTypes from 'prop-types';
import { Switch } from '@mui/material';
import StepTemplate from '../../StepTemplate';
import { useUserMutation } from 'reactQuery/mutations';
import { useTranslate } from 'hooks';
import { useUserContext } from 'context';
import FormServerError from '../../FormServerError';

const CompleteEmail = ({ completeStep, updateForm }) => {
	const [value, setValue] = useState(defaultOptInToEmails ?? undefined);
	useEffect(() => {
		if (user && value === undefined) {
			setValue(defaultOptInToEmails);
		}
	}, [defaultOptInToEmails, user, value]);

	const translate = useTranslate();
	const [error, setError] = useState(null);
	const { updateUser } = useUserMutation({
		onSuccess: () => {
			completeStep(value);
		},
		onError: e => {
			setError(e.data.errors[0] || e.status);
		}
	});
	const { user } = useUserContext();

	const { defaultOptInToEmails } = useMemo(() => {
		const specialOffers = user?.preferences?.notification?.specialOffers;
		let status = false;
		if (
			specialOffers === 'email' ||
			(typeof specialOffers === 'object' && specialOffers.value.toLowerCase() === 'email')
		) {
			status = true;
		}

		return {
			defaultOptInToEmails: status
		};
	}, [user]);

	const [didChange, setDidChange] = useState(false);
	const translateBaseKey = 'ProfileSetup.modals.step3';

	const handleChange = useCallback(() => {
		updateForm({ field: 'optInToEmails', value: !value });
		setValue(!value);
		if (!didChange) {
			setDidChange(true);
		}
	}, [didChange, updateForm, value]);

	const _completeStep = useCallback(() => {
		setError(null);
		updateUser({ preferences: { notification: { specialOffers: value ? 'email' : 'none' } } });
	}, [updateUser, value]);

	return (
		<StepTemplate
			dataCy="complete-email"
			title={translate(`${translateBaseKey}.title`)}
			onSubmit={_completeStep}
			submitDisabled={!value}
		>
			<Col fillWidth>
				<FormServerError error={error} />
				<div className={styles.wrapper}>
					<Row fullWidth>
						<Text weight="300" className={styles.body}>
							{translate(`${translateBaseKey}.body`)}
						</Text>
						<span className={styles.switch}>
							<Switch
								data-cy="opt-in-to-emails-switch"
								checked={value}
								onChange={handleChange}
								name="optInToEmails"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						</span>
					</Row>
				</div>
			</Col>
		</StepTemplate>
	);
};

CompleteEmail.propTypes = {
	completeStep: PropTypes.func,
	updateForm: PropTypes.func
};

export default CompleteEmail;
