import React, { useMemo } from 'react';
import AutoEnrollAdminForm from './ui-component';
import { useCompanyByCodeQuery } from 'reactQuery/queries';
import useGeneralContext from 'context/GeneralContext';
import { useUserContext } from 'context';

/*
interface AutoEnrollAdminFormDataWrapperProps {

}
*/

const AutoEnrollAdminFormDataWrapper = (props /*: AutoEnrollAdminFormDataWrapperProps*/) => {
	const { user } = useUserContext();
	const { data: company } = useCompanyByCodeQuery({ companyCode: user?.company?.code });
	const {
		authState: { idToken }
	} = useGeneralContext();

	const moreProps = useMemo(
		() => ({
			idToken,
			supportedConnections: company?.supportedConnections || [],
			usersCompany: user?.company || {},
			username: user?.userName || '',
			initialValues: {
				includeCC: true,
				file: null,
				selectedCardType: company?.supportedConnections[0]
			}
		}),
		[company, idToken, user]
	);

	return <AutoEnrollAdminForm {...props} {...moreProps} />;
};

export default AutoEnrollAdminFormDataWrapper;
