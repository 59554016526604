import BusinessSearchFilter from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV, isLV } from 'utils/sizes';
import { toggleShowFilter } from 'actions/search_page';

const mapStateToFilters = state => {
	return {
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	toggleShowFilter: show => dispatch(toggleShowFilter(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToFilters, mapDispatchToProps)(BusinessSearchFilter));
