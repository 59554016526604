import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

const ProfilePreferenceItem = ({ className, item, section }) => {
	const translate = useTranslate();
	const classProps = classNames('profile-preference-item', !item.checked && 'unchecked', className);

	return (
		<div className={classProps} data-cy={`section-${section}-${item.key}`}>
			<i className={item.checked ? 'fa fa-check' : 'fa fa-ban fa-rotate-90'} aria-hidden="true" data-cy="icon" />
			<span className="item-label" data-cy="title">
				{translate(`Profile.${section}.labels.${item.key}`)}
			</span>
		</div>
	);
};

ProfilePreferenceItem.defaultProps = {
	className: ''
};

ProfilePreferenceItem.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security']).isRequired
};

export default ProfilePreferenceItem;
