import CompleteSetupBanner from './ui-component';
import { connect } from 'react-redux';
import { toggleModalOpen } from 'actions/profile_setup';

const mapStateToProps = state => {
	return {
		dismissedForever: state.profileSetup.dismissedForever
	};
};

const mapDispatchToProps = dispatch => ({
	toggleModalOpen: () => dispatch(toggleModalOpen())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSetupBanner);
