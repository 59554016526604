import React from 'react';
import { Button } from 'components/atoms';
// import PropTypes from 'prop-types'

/*
interface TermsAndConditionsNewProps {

}
*/

const TermsAndConditionsNew = (/*: TermsAndConditionsNewProps*/) => {
	return (
		<Button href="/terms/" target="_blank" variant="text">
			View
		</Button>
	);
};

// ConfirmDiscardModal.propTypes = {

// }

export default TermsAndConditionsNew;
