import { useCallback } from 'react';

// interface useEditMFAEnabledProps {}

export const MFA_FIELD_ID = 'mfaEnabled';

const useEditMFAEnabled = ({ onEnableMFA } /*:useEditMFAEnabledProps*/) => {
	const onMfaEnabledChange = useCallback(
		(change, target, checked) => {
			change(MFA_FIELD_ID, checked ? [MFA_FIELD_ID] : []);
			const onEnableCancel = () => {
				// if they cancel mfa connect, uncheck the mfa checkbox
				// if (checked) {
				change(MFA_FIELD_ID, []);
				// }
			};

			if (checked) {
				let mfa = {
					phone: {
						mobile: '0000000000'
					},
					enabled: true
				};
				onEnableMFA(mfa, onEnableCancel);
			} else {
				let mfa = {
					enabled: false
				};

				onEnableMFA(mfa, onEnableCancel);
			}
		},
		[onEnableMFA]
	);

	return {
		onMfaEnabledChange
	};
};

export default useEditMFAEnabled;
