import React, { createRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/atoms';
import { TextField } from 'mui-rff';
import { IconButton, InputAdornment } from '@mui/material';
import { FormFieldRequirements } from 'components/molecules';
import {
	containsLowerCaseValidator,
	containsNumberValidator,
	containsSpecialValidator,
	containsUpperCaseValidator,
	lengthValidator
} from 'utils/form/validators';

/*
interface PasswordInputProps {

}
*/

const PasswordInput = ({ value, ...props } /*: PasswordInputProps*/) => {
	const input = createRef();
	const [showCardNumber, setShowCardNumber] = useState(false);
	const toggleShowCardNumber = useCallback(() => {
		setShowCardNumber(prev => !prev);
		input.current?.focus();
	}, [input]);

	const icon = showCardNumber
		? 'https://dinova-images-dev.imgix.net/icons/icon-show.svg'
		: 'https://dinova-images-dev.imgix.net/icons/icon-hide.svg';

	const validState = useMemo(() => {
		const password = value;
		const requirements = [];

		requirements.push({
			status: lengthValidator(password, 10) ? 'valid' : 'invalid',
			textKey: 'Registration.requirement-boxes.password.1'
		});

		requirements.push({
			status: containsNumberValidator(password) ? 'valid' : 'invalid',
			textKey: 'Registration.requirement-boxes.password.2'
		});

		requirements.push({
			status: containsLowerCaseValidator(password) ? 'valid' : 'invalid',
			textKey: 'Registration.requirement-boxes.password.3'
		});

		requirements.push({
			status: containsUpperCaseValidator(password) ? 'valid' : 'invalid',
			textKey: 'Registration.requirement-boxes.password.4'
		});

		requirements.push({
			status: containsSpecialValidator(password) ? 'valid' : 'invalid',
			textKey: 'Registration.requirement-boxes.password.5'
		});

		return requirements;
	}, [value]);

	const validate = useCallback(() => {
		if (validState.find(requirement => requirement.status === 'invalid')) {
			return 'Required';
		}

		return;
	}, [validState]);

	return (
		<Col fullWidth>
			<Row fullWidth align="flex-start">
				<TextField
					{...props}
					// inputRef={input}
					validate={validate}
					type={showCardNumber ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={toggleShowCardNumber}>
									<img
										style={{ height: 30, width: 30 }}
										src={icon}
										alt={showCardNumber ? 'Hide' : 'Show'}
									/>
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Row>
			{!props.hideRequirements && (
				<FormFieldRequirements
					formFieldText={{
						requirements: validState,
						titleKey: 'Registration.requirement-boxes.password.title'
					}}
				/>
			)}
		</Col>
	);
};

PasswordInput.propTypes = {
	hideRequirements: PropTypes.bool,
	value: PropTypes.string // required if showing requirements. Otherwise, not required.
};

export default PasswordInput;
