import DeleteConnectionsWarningModal from './ui-component';
import { connect } from 'react-redux';
import { toggleShowDeleteConnectionsWarningModal } from 'actions/delete_connections_warning_modal';
import { setIsLoading } from 'actions/loader';
import { change } from 'redux-form';

const mapStateToProps = state => ({
	...state.deleteConnectionsWarningModal
});

const mapDispatchToProps = dispatch => ({
	close: () => dispatch(toggleShowDeleteConnectionsWarningModal(false, null)),
	setIsLoading: (show, persist) => dispatch(setIsLoading(show, 'removing payment information', persist)),
	setCompanySearch: text => dispatch(change('companySelect', 'name', text))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConnectionsWarningModal);
