// import { useCallback, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

/*
interface useTranslateProps {}
*/

const useTranslate = (/*:useTranslateProps*/) => {
	const translate = useSelector(state => getTranslate(state.locale));

	return translate;
};

export default useTranslate;
