import { hasProp } from 'utils/object';

export const getHasDineIn = restaurant => {
	return hasProp(restaurant, 'amenities.general')
		? restaurant.amenities.general.filter(g => g.id === 1).length > 0
		: false;
};

export const getHasCurbsidePickup = restaurant => {
	return hasProp(restaurant, 'amenities.general')
		? restaurant.amenities.general.filter(g => g.id === 2).length > 0
		: false;
};

export const getHasDelivery = restaurant => {
	return hasProp(restaurant, 'amenities.delivery.types') ? restaurant.amenities.delivery.types.length > 0 : false;
};

export const getHasTakeout = restaurant => {
	return hasProp(restaurant, 'amenities.takeout.url') ? restaurant.amenities.takeout.url.length > 0 : false;
};

export const getHasDriveThru = restaurant => {
	return hasProp(restaurant, 'amenities.general')
		? restaurant.amenities.general.filter(g => g.id === 5).length > 0
		: false;
};

export const getDeliveryTitle = restaurant => {
	if (hasProp(restaurant, 'amenities.delivery')) {
		let types = restaurant.amenities.delivery.types;
		if (types && types.length) {
			if (types[0].name === 'Self') {
				return `Delivery - by owner`;
			}
			return `Delivery - ${types[0].name}`;
		} else {
			return 'Delivery';
		}
	} else {
		return 'Delivery';
	}
};
