import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/atoms';
import { useAuthState, useTranslate } from 'hooks';
import { Body, NotYourCompanyButton } from 'components/atoms';
import { Form } from 'react-final-form';
import useGeneralContext from 'context/GeneralContext';
import DoDFormFields from './dodFormFields';
import FormFields from './notDoDFormFields';
import { PageError } from 'components/molecules';
import { useCreateConnectionForm, useConfirmDiscardModal } from './hooks';
import { useUserContext } from 'context';
import { useDispatch } from 'react-redux';
import { toggleShowCompanySelectModal } from 'actions/company_select_modal';

// interface ConnectToPointsFormNewProps {}
/*{}*/
/*: ConnectToPointsFormNewProps*/

const ConnectToPointsFormNew = ({
	// Component Props
	buttons,
	nextStep,
	onSubmitSuccess,
	registration,
	showRegistered
}) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const { isAdminUser } = useAuthState();
	const { user } = useUserContext();

	useConfirmDiscardModal();

	// const location = useLocation();
	// const isRegistration = useMemo(() => location.pathname.indexOf('register') > -1, [location]);

	// const proceed = useCallback(() => {
	// 	if (!isRegistration) {
	// 		history.push('/profile');
	// 	} else {
	// 		console.log('%c REGISTRATION FLOW COMPLETE ME', 'color: orange');
	// 	}
	// }, [isRegistration]);
	const { isDoDMode } = useGeneralContext({});

	const {
		_companyName,
		clearError,
		company,
		createError,
		initialValues,
		maxConnectionsReached,
		onNextClick,
		onSubmit,
		validate,
		isSuperUser
	} = useCreateConnectionForm({
		nextStep,
		onSubmitSuccess,
		registration
	});

	useEffect(() => {
		if (user && !user?.company?.name) {
			dispatch(toggleShowCompanySelectModal(true, 'select'));
		}
	}, [dispatch, user]);

	return (
		<Col fullWidth align="stretch">
			{createError && <PageError message={createError} />}
			<Row justify="space-between" fullWidth px={'16px'} mb={'32px'}>
				<Row gap={4}>
					<Body color="black">{translate('Profile.personal.labels.company')}:</Body>
					<Body color="black" weight="bold">
						{_companyName}
					</Body>
					{(!isAdminUser || isSuperUser) && <NotYourCompanyButton />}
				</Row>
				<Row>
					<Body color="black">
						<span className="text-danger">*</span>
						{translate('required.required')}
					</Body>
				</Row>
			</Row>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				validate={validate({ createError, isDoDMode, translate })}
				render={({ errors, handleSubmit, valid, dirty, values, dirtySinceLastSubmit, form: { restart } }) => {
					if (dirtySinceLastSubmit && createError) {
						clearError();
					}

					return (
						<>
							<form
								onSubmit={event => {
									try {
										handleSubmit(event).then(() => {
											restart();
										});
									} catch (e) {
										// console.log('%c e', 'color: orange', e);
									}
								}}
								noValidate
								style={{ paddingLeft: 16, paddingRight: 16 }}
							>
								{isDoDMode ? (
									<DoDFormFields
										cardTypes={company?.supportedConnections}
										hideButtons={Boolean(buttons)}
										onNextClick={onNextClick.bind(this, {
											errors,
											dirty,
											handleSubmit,
											valid,
											values
										})}
										selectedCard={values?.selectedCard}
										showRegistered={showRegistered}
										resetForm={() => restart(initialValues)}
										disabled={maxConnectionsReached}
									/>
								) : (
									<FormFields
										cardTypes={company?.supportedConnections}
										hideButtons={Boolean(buttons)}
										onNextClick={onNextClick.bind(this, {
											errors,
											dirty,
											handleSubmit,
											valid,
											values
										})}
										selectedCard={values?.selectedCard}
										showRegistered={showRegistered}
										resetForm={() => restart(initialValues)}
										disabled={maxConnectionsReached}
									/>
								)}
								{buttons && buttons({ submitDisabled: !valid || maxConnectionsReached })}
							</form>
						</>
					);
				}}
			/>
		</Col>
	);
};

ConnectToPointsFormNew.propTypes = {
	buttons: PropTypes.func,
	nextStep: PropTypes.func.isRequired,
	onSubmitSuccess: PropTypes.func,
	registration: PropTypes.object,
	showRegistered: PropTypes.bool
};

export default ConnectToPointsFormNew;
