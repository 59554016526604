import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Title } from 'components/atoms';
import { useTranslate } from 'hooks';

const PaymentNotConnected = ({ className = '', history, startChangeStep, showNotConnectedMessage = true }) => {
	const translate = useTranslate();
	const gotoConnect = useCallback(
		e => {
			e.preventDefault();
			startChangeStep(2);
			history.push('/users/connect?redirect=/profile');
		},
		[history, startChangeStep]
	);

	const classProps = classNames('payment-not-connected', className);

	return (
		<div className={classProps}>
			{showNotConnectedMessage && (
				<Title size="h4" transform="none">
					{translate('Profile.payment.notConnected.title')}
				</Title>
			)}
			<Button onClick={gotoConnect} className="btn-wrapper" id="profile-add-cc" color="primary">
				{translate('Profile.payment.notConnected.connectBtn')}
			</Button>
		</div>
	);
};

PaymentNotConnected.propTypes = {
	className: PropTypes.string,
	history: PropTypes.object,
	showNotConnectedMessage: PropTypes.bool,
	startChangeStep: PropTypes.func
};

export default PaymentNotConnected;
