import React from 'react';
import PropTypes from 'prop-types';
import { Caret } from 'components/atoms';
import classnames from 'classnames';

/**
 * DropdownLabel - A component to use for a dropdown's selected item.  Shows in the header of the dropdown next to the caret.
 * @constructor
 * @param {DropdownLabel_Props} props
 */
const DropdownLabel = ({ dataCy, isOpen, label, labelClass, onClick, refSetter, required, value }) => (
	<div
		className={classnames('label-wrapper', required && 'required')}
		onClick={onClick}
		ref={refSetter}
		data-cy={`${dataCy}-label`}
	>
		<p className={labelClass}>{label}</p>
		{value && <p className="value">{value}</p>}
		<Caret isOpen={isOpen} />
	</div>
);

DropdownLabel.defaultProps = {
	isOpen: false,
	label: null,
	labelClass: 'label',
	onClick: () => {},
	refSetter: () => {},
	value: null
};

/**
 * DropdownLabel Props
 * @interface DropdownLabel_Props
 * @param {string}    dataCy     A unique ID for cyrpess tests.
 * @param {boolean}   isOpen     If the dropdown is expanded.
 * @param {string}    label      The name of this dropdown.
 * @param {string}    labelClass A className to add to the label element.
 * @param {function}  onClick    DropdownLabel click handler.
 * @param {func}      refSetter  Used to assoctiate this label with WithDropdown.dropdown_trigger. @{@link WithDropdown}
 * @param {boolean}   required   If this field is required
 * @param {string}    value      The text to show for the current value of the dropdown.
 */
DropdownLabel.propTypes = {
	dataCy: PropTypes.string,
	isOpen: PropTypes.bool,
	label: PropTypes.string,
	labelClass: PropTypes.string,
	onClick: PropTypes.func,
	refSetter: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.string
};

export default DropdownLabel;
