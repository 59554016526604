import React from 'react';
import { PageHeaderTitle, Text } from 'components/atoms';
import { PageHeader } from 'components/molecules';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';

// import './style.scss';

const AutoEnrollUserUsedToken = () => {
	const translate = useTranslate();

	return (
		<div className="flex row justify-center" data-cy="auto-enroll-used-token">
			<PageHeader>
				<PageHeaderTitle align="left" dataCy="update-personal-title">
					{translate('AutoEnrollUserForm.usedToken.header')}
				</PageHeaderTitle>
			</PageHeader>
			<Text>{translate('AutoEnrollUserForm.usedToken.description')}</Text>
			<Link className="btn btn-primary mt-10" to="/login">
				{translate('AutoEnrollUserForm.usedToken.cta')}
			</Link>
		</div>
	);
};

AutoEnrollUserUsedToken.defaultProps = {};

AutoEnrollUserUsedToken.propTypes = {};

export default AutoEnrollUserUsedToken;
