import React, { useMemo } from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { InvisibleButton, Text } from 'components/atoms';
import { useTranslate } from 'hooks';
import { selectCompletedStepsFromUser } from 'actions/profile_setup/selectors';
import { useUserContext } from 'context';

const CompleteSetupBanner = ({ dismissedForever, mb, toggleModalOpen }) => {
	const translate = useTranslate();
	const user = useUserContext();
	const { showComplete, viewCompletion } = useMemo(() => {
		if (user) {
			const steps = selectCompletedStepsFromUser(user);
			const { viewCompletion } = steps;
			return {
				viewCompletion,
				showComplete: user ? !Object.values(selectCompletedStepsFromUser(user)).includes(false) : false
			};
		}

		return { showComplete: false, viewCompletion: false };
	}, [user]);
	const points = translate('ProfileSetup.points');

	let message = (
		<Text className={styles.link}>
			{translate(
				!viewCompletion && showComplete
					? 'ProfileSetup.completeBanner.finished'
					: 'ProfileSetup.completeBanner.noStepsCompleteMessage',
				{ points }
			)}
		</Text>
	);

	if (dismissedForever) {
		return null;
	}

	return (
		<div className={styles.wrapper} style={mb ? { marginBottom: mb } : {}} data-cy="profile-setup-banner">
			<InvisibleButton onClick={() => toggleModalOpen()} dataCy="open-btn">
				<div className={styles.btnContent}>{message}</div>
			</InvisibleButton>
		</div>
	);
};

CompleteSetupBanner.propTypes = {
	dismissedForever: PropTypes.bool,
	mb: PropTypes.number,
	toggleModalOpen: PropTypes.func
};

export default CompleteSetupBanner;
