import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button as MyButton, Col as MyCol, Row as MyRow } from 'components/atoms';
import { Checkboxes } from 'components/atoms';

const FirstPage = ({ body, header, onCancelClick, secondaryButtonText, isRequired }) => {
	return (
		<div>
			<ModalBody>
				<div className="mb-4">{header}</div>
				<div>{body}</div>
			</ModalBody>
			<ModalFooter>
				<MyCol fullWidth gap="15px" px="15px">
					<MyButton id="modal-primary-button" type="submit" fullWidth size="large">
						<MyRow gap="12px">
							<i className="fa fa-mobile-alt fa-2x" aria-hidden="true"></i>
							<div className="d-inline-block">SMS Authentication</div>
						</MyRow>
					</MyButton>
					{secondaryButtonText && (
						<MyButton
							variant="outlined"
							fullWidth
							onClick={onCancelClick}
							id="modal-secondary-button"
							size="large"
							dataCy="mfa-skip"
						>
							{secondaryButtonText}
						</MyButton>
					)}
					{!isRequired && (
						<Checkboxes
							name="dontAsk"
							id="dontAsk"
							data={[{ label: "Don't ask again", value: 'dontAsk' }]}
						/>
					)}
				</MyCol>
			</ModalFooter>
		</div>
	);
};

FirstPage.defaultProps = {};
FirstPage.propTypes = {
	body: PropTypes.string,
	header: PropTypes.string,
	onCancelClick: PropTypes.func,
	secondaryButtonText: PropTypes.string,
	isRequired: PropTypes.bool
};

export default FirstPage;
