'use client';

import React, { /* ReactNode, SyntheticEvent,*/ useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete as MuiAutocomplete } from 'mui-rff';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

/*
interface SelectProps<ValueType> {
  getOptionLabel?: (opt: ValueType) => string;
  initialSearch?: string;
  initialSelectedValue?: ValueType | null;
  isOptionEqualToValue: (option: ValueType, value?: ValueType) => boolean;
  label?: string;
  loadingText?: string;
  name: string;
  noOptionsItem?: ReactNode;
  noOptionsText?: (didSearch: boolean) => ReactNode;
  onSearchChange?: (value: string) => void;
  onItemSelect?: (item: ValueType | undefined) => void;
  query: (queryOpts: { searchValue: string | undefined }) => {
    data: ValueType[];
    loading: boolean;
    isFetched: boolean;
  };
}
*/

function Autocomplete /*<ValueType>*/(
	{
		disableClearable = true,
		dataCy,
		getOptionLabel,
		initialSearch,
		initialSelectedValue = null,
		isOptionEqualToValue,
		label = 'Label Placeholder',
		loadingText,
		name,
		noOptionsItem,
		noOptionsText,
		onBlur,
		onFocus,
		onItemSelect: onItemSelectProp,
		onSearchChange: onSearchChangeProp,
		open,
		query
	} /*: SelectProps<ValueType>*/
) {
	const [searchValue, setSearchValue] = useState(initialSearch);
	useEffect(() => {
		if (initialSearch && !searchValue) {
			setSearchValue(initialSearch);
		}
	}, [initialSearch, searchValue]);

	const [selectedValue, setSelectedValue] = useState(/*<ValueType|null > */ initialSelectedValue);
	useEffect(() => {
		if (initialSelectedValue && !selectedValue) {
			setSelectedValue(initialSelectedValue);
		}
	}, [initialSelectedValue, selectedValue]);

	const { data = [], loading, isFetched } = query({
		searchValue
	});

	const onSearchChange = useCallback(
		(e /*: SyntheticEvent<Element, Event>*/, value /*: string*/) => {
			setSearchValue(value);

			onSearchChangeProp && onSearchChangeProp(value);
		},
		[onSearchChangeProp]
	);

	const onItemSelect = useCallback(
		(
			e /*: SyntheticEvent<Element, Event>*/,
			value /*: ValueType | null*/
			// reason: AutocompleteChangeReason,
			// details?: AutocompleteChangeDetails<ValueType> | undefined
		) => {
			setSelectedValue(value);

			onItemSelectProp && onItemSelectProp(value || undefined);
		},
		[onItemSelectProp]
	);

	const _noOptionsText = useMemo(() => {
		return (noOptionsText && noOptionsText(isFetched)) || '';
	}, [noOptionsText, isFetched]);

	return (
		<div className={styles.wrapper}>
			<MuiAutocomplete
				//<ValueType, false, false, false>
				className={styles.select}
				data-cy={dataCy}
				disableClearable={disableClearable}
				disablePortal
				id="combo-box-demo"
				open={open}
				options={data}
				onChange={onItemSelect}
				onInputChange={onSearchChange}
				value={selectedValue}
				loading={loading}
				loadingText={loadingText}
				isOptionEqualToValue={isOptionEqualToValue}
				noOptionsText={noOptionsItem || _noOptionsText}
				name={name}
				label={label}
				getOptionLabel={getOptionLabel}
				onFocus={onFocus}
				onBlur={onBlur}
				componentsProps={{
					popper: {
						'data-cy': `${dataCy || ''}-popper`
					},
					paper: {
						'data-cy': `${dataCy || ''}-paper`
					}
				}}
			/>
		</div>
	);
}

Autocomplete.propTypes = {
	dataCy: PropTypes.string,
	disableClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	initialSearch: PropTypes.string,
	initialSelectedValue: PropTypes.object,
	isOptionEqualToValue: PropTypes.func.isRequired,
	label: PropTypes.string,
	loadingText: PropTypes.string,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	noOptionsItem: PropTypes.node,
	noOptionsText: PropTypes.func,
	onSearchChange: PropTypes.func,
	onItemSelect: PropTypes.func,
	open: PropTypes.bool,
	query: PropTypes.func
};

export default Autocomplete;
