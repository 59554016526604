import { useCallback, useMemo } from 'react';
import { getInternalConnectionType } from 'utils/card';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from 'actions/loader';
import { updatePaymentInfo as updatePaymentInfoAction } from 'actions/users';
import { useUserMutation } from 'reactQuery/mutations';
import { useQueryClient } from '@tanstack/react-query';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import useGeneralContext from 'context/GeneralContext';
import { history } from 'utils/router';
import { useUserContext } from 'context';
// interface useEditPaymentFormProps {}

const useEditPaymentForm = ({ onCancel: onCancelProp } /*:useEditPaymentFormProps*/) => {
	const { user } = useUserContext();
	const { isDoDMode } = useGeneralContext();
	const { card } = useSelector(state => ({ card: state.editPayment.card }));
	const dispatch = useDispatch();
	const { updateUser } = useUserMutation({
		onSuccess: () => history?.goBack()
	});

	const queryClient = useQueryClient();
	const refreshUser = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
	}, [queryClient]);

	const initialValues = useMemo(() => {
		let cardNumber;
		if (card.fullNumberRequired) {
			cardNumber = card.cardType === '1' ? `XXXX-XXXXXX-X${card.last4}` : `XXXX-XXXX-XXXX-${card.last4}`;
		} else {
			cardNumber = card.last4;
		}
		return {
			firstName: user?.name?.first || '',
			middleName: user?.name?.middle || '',
			lastName: user?.name?.last || '',
			...(card
				? {
						cardNumber,
						employeeId: card.employeeId || '',
						nameOnCard: card.nameOnCard,
						email: ''
				  }
				: {})
		};
	}, [card, user]);

	const updateConnection = useCallback(
		(vals, cardId, cardIdType) => {
			const cardInfo = {
				nameOnCard: vals.nameOnCard,
				firstName: vals.firstName,
				middleName: vals.middleName,
				lastName: vals.lastName
			};

			if (vals.cardNumber.indexOf('X') === -1) {
				// only add this if they changed the card number
				cardInfo.number = vals.cardNumber;
			}

			if (cardIdType === 'email') {
				cardInfo.email = vals.email;
			} else if (cardIdType === 'empID') {
				cardInfo.employeeId = vals.employeeId;
			}

			if (isDoDMode) {
				cardInfo.nameOnCard = `${cardInfo.firstName} ${cardInfo.middleName} ${cardInfo.lastName}`;
				updatePaymentInfo({ connectionID: cardId, payment: { ...cardInfo } }, response => {
					if (response.code === 200) {
						updateUser(
							{
								name: {
									first: cardInfo.firstName,
									middle: cardInfo.middleName,
									last: cardInfo.lastName
								}
							},
							() => {
								refreshUser();
								history.goBack();
							}
						);
					}
				});
			} else {
				updatePaymentInfo({ connectionID: cardId, payment: { ...cardInfo } }, response => {
					if (response.code === 200) {
						refreshUser();
						history.goBack();
					}
				});
			}
		},
		[isDoDMode, updatePaymentInfo, updateUser, refreshUser]
	);

	const onCancel = useCallback(
		(pristine, e) => {
			if (pristine) {
				history.replace('/profile');
			} else {
				onCancelProp(e);
			}
		},
		[onCancelProp]
	);

	const onPristineSubmit = useCallback(() => {
		history.push('/profile');
	}, []);

	const onSubmit = useCallback(
		values => {
			const { id, type } = card;
			const idType = getInternalConnectionType(type);
			updateConnection(values, id, idType);
		},
		[card, updateConnection]
	);

	const updatePaymentInfo = useCallback(
		(paymentInfo, callback) => dispatch(updatePaymentInfoAction(paymentInfo, callback)),
		[dispatch]
	);

	const clearErrors = useCallback(() => {
		dispatch(setError());
	}, [dispatch]);

	return {
		companyName: user?.company?.name || '',
		clearErrors,
		initialValues,
		onCancel,
		onPristineSubmit,
		onSubmit
	};
};

export default useEditPaymentForm;
