import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Button, Row } from 'reactstrap';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { Text, Title } from 'components/atoms';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReferSharing } from 'components/organisms';
import { history } from 'utils/router';
import { useTranslate } from 'hooks';
import { setReferralLink as setReferralLinkAction } from 'actions/registration';
import { useDispatch } from 'react-redux';
import { useUserContext } from 'context';

const ReferACoworker = ({ className = '', onClick, parent, showCompleteButton, skipStep }) => {
	const { error, user, isLoading: isLoadingUser, refetch: refetchUser, isRefetching } = useUserContext();
	const translate = useTranslate();
	const [didRefetch, setDidRefetch] = useState(false);
	const dispatch = useDispatch();
	const setReferralLink = useCallback(link => dispatch(setReferralLinkAction(link)), [dispatch]);

	const { hasConnections, link } = useMemo(
		() => ({
			hasConnections: Boolean((user?.connections || [])?.length > 0),
			link: user?.urls?.referral
		}),
		[user]
	);

	const [hasError, setHasError] = useState(false);
	useEffect(() => {
		if (!didRefetch) {
			// TODO: Remove didRefetch once ConnectACard step is moved to react-query?
			refetchUser().then(() => {
				setDidRefetch(true);
			});
		} else if (!isLoadingUser && !isRefetching) {
			if (link) {
				if (link) {
					setReferralLink(link);
				} else {
					setHasError(error);
				}
			} else {
				if (skipStep) {
					skipStep();
					return;
				}
			}
		}
	}, [didRefetch, error, isLoadingUser, isRefetching, refetchUser, skipStep, link, setReferralLink]);

	useEffect(() => {
		if (!isLoadingUser && !hasConnections && didRefetch) {
			/*
			If the user doesn't have a connected card, they should
			not be able to access this page.
		 */
			history.replace('/mydinova/#/');
		}
	}, [didRefetch, hasConnections, isLoadingUser]);

	const classProps = classNames('refer-a-coworker', className);
	const subTitle = translate('ReferACoWorker.auth-sub-title');

	return (
		<div className={classProps}>
			<MetaTags tags={metaTagConfig} />

			<Row>
				<Col>
					{subTitle.indexOf('Missing') !== -1 && (
						<Text size="h2" className="refer-body-content" dataCy="refer-sub-titles" align="center">
							{subTitle}
						</Text>
					)}
					<Text size="sm" weight="thin" className="refer-body-content" dataCy="refer-body-content">
						{translate('ReferACoWorker.body')}
					</Text>
				</Col>
			</Row>
			{!hasError ? (
				<ReferSharing link={link} parent={parent} isLoadingLink={isLoadingUser} />
			) : (
				<Row className="refer-body-error">
					<Col>
						<Title align="center" weight="thin" dataCy="refer-error" className="refer-body-content">
							{translate('ReferACoWorker.error-title')}
						</Title>
						<Text align="center" weight="thin" dataCy="refer-error" className="refer-body-content">
							{translate('ReferACoWorker.error-body')}
						</Text>
					</Col>
				</Row>
			)}

			{showCompleteButton && (
				<Row className="buttons">
					<Col md="12" xs="12" className="d-flex align-items-center justify-content-center">
						<Button color="primary" className="z-depth-0" onClick={onClick} data-cy="next-button">
							{translate('ReferACoWorker.completebutton')}
						</Button>
					</Col>
				</Row>
			)}
		</div>
	);
};

ReferACoworker.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	parent: PropTypes.string.isRequired,
	showCompleteButton: PropTypes.bool,
	skipStep: PropTypes.func.isRequired
};

export default ReferACoworker;
