import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CardPromoBanner } from 'components/molecules';
import { CardFace, CardFold } from 'components/organisms';

class Card extends React.Component {
	componentDidMount = () => {
		window.addEventListener('keyup', this.handleKeyUp);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keyup', this.handleKeyUp);
	};

	handleKeyUp = e => {
		if (e.key === 'Escape' && this.props.isActive) {
			this.props.close();
		} else {
			return;
		}
	};

	render = () => {
		const { ariaHidden, index, imageBaseUrl, isActive, isLV, showAbout, translate } = this.props;
		const translateKey = `landingPage.cards.cards.${index}`;
		const cardKey = translate(`${translateKey}.cardKey`);

		const cardClassProps = classnames(
			'card dinova-card',
			index,
			cardKey,
			isActive && 'active',
			isActive && isLV && 'slide right',
			!isLV && isActive && showAbout ? 'slide left' : 'slide right'
		);

		return (
			<div className={cardClassProps} aria-hidden={ariaHidden} tabIndex={ariaHidden ? -1 : undefined}>
				<div className="card-background">
					<div className="gradient" />

					<img
						src={
							isLV
								? `${imageBaseUrl}${translate(`${translateKey}.image.lv`)}`
								: `${imageBaseUrl}${translate(`${translateKey}.image.sv`)}`
						}
						alt={`${imageBaseUrl}${translate(`${translateKey}.image.alt`)}`}
						aria-hidden={true}
					/>
				</div>

				<div className="content-wrapper">
					<CardFace cardId={index} />
					{/* FOLD */}
					<CardFold cardId={index} />
				</div>
				<CardPromoBanner
					hideImages={true}
					className="bubble"
					translateKey={translateKey}
					ariaHidden={isActive}
				/>
			</div>
		);
	};
}

Card.defaultProps = {};

Card.propTypes = {
	// comonent props
	ariaHidden: PropTypes.bool,
	index: PropTypes.string.isRequired,
	// redux props
	close: PropTypes.func.isRequired,
	imageBaseUrl: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	showAbout: PropTypes.bool,
	translate: PropTypes.func.isRequired,

	// with sizes props
	isLV: PropTypes.bool.isRequired
};

export default Card;
