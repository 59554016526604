import * as t from './types';
import { getSuggestionsFromGoogle } from 'utils/google_autocomplete_service';

export const initLocationAutoCompleteInstance = (id, currentLocationItem, noSuggestionsFoundItem) => {
	return {
		type: t.INIT_LOCATION_AUTOCOMPLETE_INSTANCE,
		payload: { id, currentLocationItem, noSuggestionsFoundItem }
	};
};

const fetchLocationCompletionsStart = (id, search, showLoader) => ({
	type: t.FETCH_LOCATION_COMPLETIONS_START,
	payload: {
		id,
		search,
		showLoader
	}
});

const fetchLocationsSuccess = (id, suggestions) => ({
	type: t.FETCH_LOCATION_COMPLETIONS_SUCCESS,
	payload: { id, suggestions }
});

const fetchLocationsNoResults = id => ({
	type: t.FETCH_LOCATION_COMPLETIONS_NO_RESULTS,
	payload: id
});

export const fetchLocationCompletions = (id, search, showLoader = true) => async dispatch => {
	dispatch(fetchLocationCompletionsStart(id, search, showLoader));

	if (search) {
		const suggestions = await getSuggestionsFromGoogle(search);
		if (suggestions) {
			// matches found
			dispatch(fetchLocationsSuccess(id, suggestions));
		} else {
			// no matches found
			dispatch(fetchLocationsNoResults(id));
		}
	} else {
		// backspaced entire search
		dispatch(fetchLocationsSuccess(id, []));
	}
};

export const setActiveSuggestion = (id, activeItem) => ({
	type: t.SET_LOCATION_AUTOCOMPLETE_ACTIVE_ITEM,
	payload: { id, activeItem }
});

export const suggestionsItemClick = (id, suggestion) => ({
	type: t.LOCATION_AUTOCOMPLETE_ITEM_CLICK,
	payload: { id, suggestion }
});
