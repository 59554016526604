import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingBase from '../LoadingBase';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';

const Loader = ({ message }) => {
	const [container] = useState(() => {
		const el = document.createElement('div');
		el.classList.add(styles.portalRoot);
		return el;
	});
	useEffect(() => {
		const appRoot = document.getElementById('root');
		appRoot.classList.add('no-scroll');

		document.body.appendChild(container);

		return () => {
			try {
				document.body.removeChild(container);
				appRoot.classList.remove('no-scroll');
			} catch (e) {
				// console.log('%c loader e', 'color: orange', e);
			}
		};
	});

	return createPortal(
		<div className="overlay" id="loader">
			<div className="dnLoader">
				<LoadingBase message={message} />
			</div>
		</div>,
		container
	);
};

Loader.defaultProps = {
	message: 'Loading'
};

Loader.propTypes = {
	message: PropTypes.string
};

export default Loader;
