import COVIDRestaurantFeedback from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	fetchOnButtonClick: !hasProp(state, 'recOptions.positive') || !hasProp(state, 'recOptions.negative'),
	restaurantId: hasProp(state.details, 'restaurant.id') ? state.details.restaurant.id : ''
});

export default connect(mapStateToProps)(COVIDRestaurantFeedback);
