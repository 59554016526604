import React, { useMemo } from 'react';
import { SubHeader } from 'components/molecules';
import { useUserContext } from 'context';

const LINKS = [
	{
		to: '/mydinova',
		titleKey: 'common.home',
		dataCy: 'dashboard-subheader-home'
	},
	{
		to: '/history',
		titleKey: 'common.history',
		dataCy: 'dashboard-subheader-history'
	},
	{
		to: '/rewards',
		titleKey: 'common.redemption',
		dataCy: 'dashboard-subheader-redemption'
	},
	{
		to: '/recommendations',
		titleKey: 'Profile.recommendations.title',
		dataCy: 'dashboard-subheader-recommendations'
	},
	{
		to: '/refer/details',
		titleKey: 'common.rac',
		dataCy: 'dashboard-subheader-rac'
	}
];

const DashboardHeader = () => {
	const { user, isLoading } = useUserContext();
	const hasConnections = user?.hasConnections;

	const links = useMemo(() => {
		if (!isLoading && hasConnections) {
			return LINKS;
		} else {
			return LINKS.slice(0, LINKS.length - 1);
		}
	}, [isLoading, hasConnections]);

	return <SubHeader size="h1" titleKey={'Rewards.navHeaderTitle'} links={links} dataCy="dashboard-subheader" />;
};

DashboardHeader.defaultProps = {};

DashboardHeader.propTypes = {};

export default DashboardHeader;
