import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CTAItem = ({ className, config: { action, text }, dataCy }) => {
	const classProps = classnames('cta-item', className);
	return (
		<div className={classProps} onClick={action} data-cy={dataCy}>
			{text}
		</div>
	);
};

CTAItem.defaultProps = {
	config: {
		action: () => console.log('default CTAItem action'),
		text: 'CTAItem'
	}
};

CTAItem.propTypes = {
	className: PropTypes.string,
	config: PropTypes.shape({ action: PropTypes.func, text: PropTypes.string }),
	dataCy: PropTypes.string
};

export default CTAItem;
