import { useQuery } from './';
import { basePath } from 'actions/utils';

export const USER_RECOMMENDATION_OPTIONS_QUERY_KEY = 'user_recommendation_options';

const useRecommendationOptionsQuery = ({ setReduxIsLoading, setDataInRedux } = {}) => {
	const url = `${basePath()}/recommendations/categories`;

	return useQuery({
		// Req
		queryHandler: res => res.data.recommendationCategories,
		queryKey: USER_RECOMMENDATION_OPTIONS_QUERY_KEY,
		url,

		// Opt
		quietLoading: true,
		// reduxLoadingMsg: 'Loading Recommendation Options...',
		reduxQueryDataAccr: query => query.data.recommendationCategories,
		setReduxIsLoading,
		setDataInRedux
		// urlBuilder: (queryKey) => string
	});
};

export default useRecommendationOptionsQuery;
