import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

const ModalPage = ({
	closeOnOutsideClick,
	primaryClick: primaryClickProp,
	secondaryClick: secondaryClickProp,
	toggleModal
}) => {
	const { modal } = useSelector(state => ({ modal: state.modal }));
	const [didClick, setDidClick] = useState(false);

	useEffect(() => {
		if (closeOnOutsideClick) {
			document.addEventListener('click', handleOutsideClick);
		}

		return () => {
			if (closeOnOutsideClick) {
				document.removeEventListener('click', handleOutsideClick);
			}
		};
	}, [closeOnOutsideClick, handleOutsideClick]);

	const handleOutsideClick = useCallback(
		e => {
			let wrapper = document.getElementById(modal.id);
			if (wrapper) {
				let isInside = wrapper.contains(e.target);
				if (!isInside && modal.isOpen) {
					if (!didClick) {
						setDidClick(true);
					} else {
						toggleModal(false);
					}
				}
			}
		},
		[didClick, modal, toggleModal]
	);

	const onToggle = useCallback(() => {
		const newIsOpen = !modal.isOpen;
		if (!newIsOpen) {
			setDidClick(false);
		}
		toggleModal(newIsOpen);
	}, [modal, toggleModal]);

	const onPrimaryClick = useCallback(() => {
		primaryClickProp && primaryClickProp();
		toggleModal(false);
	}, [toggleModal, primaryClickProp]);

	const onSecondaryClick = useCallback(() => {
		secondaryClickProp && secondaryClickProp();
	}, [secondaryClickProp]);

	const { body, id, header, primaryButtonText, secondaryButtonText } = modal;
	let largeColWidth = secondaryButtonText ? '6' : '12';

	return (
		<Modal isOpen={modal.isOpen} toggle={onToggle} fade={true} centered id={id} data-cy={`modal-${id}`}>
			{header && <ModalHeader toggle={onToggle}>{header}</ModalHeader>}
			<ModalBody>{body}</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12" lg={largeColWidth}>
							<Button
								block
								className="z-depth-0"
								onClick={onPrimaryClick}
								color="primary"
								id="modal-primary-button"
								data-cy="modal-primary-button"
							>
								{primaryButtonText}
							</Button>
						</Col>
						<Col sm="12" lg={largeColWidth}>
							{secondaryButtonText && (
								<Button
									block
									className="z-depth-0"
									onClick={onSecondaryClick}
									id="modal-secondary-button"
									data-cy="modal-secondary-button"
								>
									{secondaryButtonText}
								</Button>
							)}
						</Col>
						{/* {tertiaryButtonText && (
							<Col sm="12" lg={largeColWidth}>
								<Button
									block
									className="z-depth-0"
									onClick={onTertiaryClick}
									id="modal-tertiary-button"
								>
									{tertiaryButtonText}
								</Button>
							</Col>
						)} */}
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

ModalPage.propTypes = {
	closeOnOutsideClick: PropTypes.bool,
	primaryClick: PropTypes.func,
	secondaryClick: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired
};

export default ModalPage;
