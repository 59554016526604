import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks';

const PointsGetConnectedRingSVG = ({ points }) => {
	const translate = useTranslate();

	const pointsGetConnectedText = [];
	const pointsGetConnectedTextCount = parseInt(translate('Rewards.pointsRing.points-getConnected.count'));
	for (let i = 0; i < pointsGetConnectedTextCount; i++) {
		pointsGetConnectedText.push(translate(`Rewards.pointsRing.points-getConnected.${i + ''}`));
	}

	return (
		<text
			x="50%"
			y="30%"
			textAnchor="middle"
			dy="0.3em"
			data-cy={points ? 'has-points-ring' : 'points-get-connected'}
		>
			<tspan x="50%" dy="1.5em" className="font-xsmall font-weight-bold">
				{pointsGetConnectedText[0]}
			</tspan>
			<tspan x="50%" dy=".9em" className="font-weight-bold font-large point">
				{pointsGetConnectedText[1].replace('{points}', Number.parseInt(points).toLocaleString())}
			</tspan>
			<tspan x="50%" dy="1.5em" className="font-small font-weight-bold">
				{pointsGetConnectedText[2].replace('{s}', points === 1 ? '' : 'S')}
			</tspan>
		</text>
	);
};

PointsGetConnectedRingSVG.propTypes = {
	points: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PointsGetConnectedRingSVG;
