import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
/*
interface useUserMutationProps {}
*/

const useUserConnectMFAMutation = (
	{ quietLoading, onError, onSuccess, setErrorInRedux, setDataInRedux } = {} /*:useUserMutationProps*/
) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: user => {
			return axios.post(`${basePath()}/users/authorize/mfa`, JSON.stringify(user), AUTH_REQUEST_HEADER());
		},
		onMutate: async () => {
			if (!quietLoading) {
				dispatch(setIsLoading(true, 'Please wait...'));
			}
		},
		onError: (error /*variables, context*/) => {
			// An error happened!
			if (!quietLoading) {
				dispatch(setIsLoading(false));
			}

			if (setErrorInRedux) {
				setErrorInRedux(error);
			}

			onError && onError(error);
		},
		onSuccess: (data /*variables, context*/) => {
			if (!quietLoading) {
				dispatch(setIsLoading(false));
			}
			if (data.data.errors) {
				onError && onError(data);
			} else {
				// Update cache with new user data
				queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
				queryClient.refetchQueries({ queryKey: [USER_QUERY_KEY] });
				// END - Update cache with new user data

				onSuccess && onSuccess(data);
				// Boom baby!
				if (setDataInRedux) {
					setDataInRedux(data);
				}
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		connectMFA: mutation.mutate
	};
};

export default useUserConnectMFAMutation;
