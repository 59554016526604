import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';
import * as services from './services';

/**
 * Used in {@link DetailsPage} to show avaialable amenities.
 * @param {AmenitiesProps} props
 *
 * @example <Amenities amenities={UIAmenity[]} entertainments=["Takeout"] />
 */
const Amenities = ({ restaurant }) => {
	const { briefAmenities, detailedAmenities } = useMemo(() => {
		if (restaurant) {
			let detailed = services.mapDetailedAmenitiesFromState({ details: { restaurant } });
			let brief = services.mapBriefAmenitiesFromState({ details: { restaurant } });

			return {
				briefAmenities: services.compileBriefAmenities(brief),
				detailedAmenities: services.compileDetailedAmenities(detailed)
			};
		}
		return {
			briefAmenities: [],
			detailedAmenities: []
		};
	}, [restaurant]);

	let detailed = [];
	detailedAmenities.forEach((entertainment, i) => {
		if (entertainment.text) {
			detailed.push(
				<p key={`${entertainment.title}-${i}`}>
					<span>{entertainment.title}: </span>
					{entertainment.text}
				</p>
			);
		}
	});

	return (
		<div className="amenities" data-cy="amenities-section">
			<OptionsList options={briefAmenities} dataCy="amenities-list" />
			{detailed.length > 0 && (
				<div className="detailed" data-cy="amenities-detailed">
					{detailed}
				</div>
			)}
		</div>
	);
};

Amenities.defaultProps = {};

/**
 * IUIAmenity
 * @interface IUIAmenity
 * @property {string} text  The description text for this amenity.
 * @property {string} title The title text for this amenity.
 */

/**
 * {@link Amenities} Props
 * @interface Props_Amenities
 * @property {object} [restaurant]
 */
Amenities.propTypes = {
	restaurant: PropTypes.object.isRequired
};

export default Amenities;
