import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { hasProp } from 'utils/object';
import queryString from 'query-string';
import { history } from 'utils/router';
import Cookies from 'universal-cookie';
import { useCompanyByCodeQuery } from 'reactQuery/queries';
import { getLowerCasedParams } from 'utils/url';
import { useCOVID19State, useLogin, useRecommendationModal } from './hooks';
import useAuthContext from './AuthContext';
import useUserContext from './UserContext';

const cookies = new Cookies();

// interface useGeneralContextProps {}
const useGeneralContextValue = (/*:useGeneralContextProps*/) => {
	const authState = useAuthContext();
	const { clearUser, user } = useUserContext();
	const location = useLocation();
	const { data: company } = useCompanyByCodeQuery({ companyCode: user?.company?.code });
	const covid19State = useCOVID19State();
	const recommendationModal = useRecommendationModal();
	const login = useLogin();
	const [isInitialRender, setIsInitialRender] = useState(true);

	const logout = useCallback(() => {
		clearUser();
	}, [clearUser]);

	const isDoorDashCompany = useMemo(() => {
		if (isInitialRender) {
			return false;
		}
		let _isDoorDash = false;
		const search = queryString.parse(location.search.slice(1));

		if (search.doordash) {
			_isDoorDash = search.doordash.startsWith('true');
			if (_isDoorDash) {
				cookies.set('doordash', true, { maxAge: 2147483647 });
			}
			delete search.doordash;
			const searchString = queryString.stringify(search);
			history.replace(`${location.pathname}?${searchString}`);
		} else {
			_isDoorDash = cookies.get('doordash') === 'true';
		}

		return _isDoorDash;
	}, [isInitialRender, location]);

	const isDodCompany = useMemo(() => {
		if (isInitialRender) {
			return false;
		}
		const company = user?.company;
		let _isDoD;

		const search = queryString.parse(location.search.slice(1));
		if (search.dod) {
			if (!user?.company) {
				_isDoD = search.dod.startsWith('true');
			} else {
				_isDoD = true;
			}
			delete search.dod;
			const searchString = queryString.stringify(search);
			history.replace(`${location.pathname}?${searchString}`);
		} else if (!company) {
			_isDoD = cookies.get('dod') === 'true';
		} else if (typeof company === 'string') {
			_isDoD = company.toLowerCase().indexOf('dod') > -1;
		} else if (hasProp(company, 'code')) {
			_isDoD = company.code.toLowerCase().indexOf('dod') > -1 || company.name.toLowerCase().indexOf('dod') > -1;
		} else {
			_isDoD = company.name && company.name.toLowerCase().indexOf('dod') > -1;
		}

		if (_isDoD) {
			cookies.set('dod', true, { maxAge: 2147483647 });
		} else {
			cookies.remove('dod');
		}

		return _isDoD;
	}, [isInitialRender, user?.company, location.search, location.pathname]);

	const preferredEmployeeIDType = useMemo(() => {
		const connection = company?.supportedConnections[0];
		if (!connection) {
			return null;
		}

		if (connection.type === 2) {
			return 'empID';
		}

		if (connection.type === 3) {
			return 'email';
		}

		return 'hash';
	}, [company]);

	const campaignID = useMemo(() => {
		const params = getLowerCasedParams();
		return params.campaignid;
		/* eslint-disable-next-line */
	}, [location]);

	useEffect(() => {
		setIsInitialRender(false);
	}, []);

	return {
		authState,
		campaignID,
		covid19State,
		isDoDMode: isDodCompany,
		isDoorDashMode: isDoorDashCompany,
		login,
		logout,
		preferredEmployeeIDType,
		recommendationModal
	};
};

const GeneralContext = createContext({
	authState: {
		idToken: null,
		isAdminUser: false,
		isAuthed: false,
		errorCode: null,
		lastLogin: null,
		permissions: [],
		refreshingUser: false
	},
	campaignID: null,
	covid19State: {
		state: '', // state abbr
		isOpen: true,
		show: true,
		hideSafetyMeasures: false,
		config: {
			active: false,
			message: '',
			color: '',
			activeFilter: false
		}
	},
	isDoDMode: false,
	isDoorDashMode: false,
	login: {
		login: () => {},
		mfaToken: null,
		sentTo: null
	},
	logout: () => {},
	preferredEmployeeIDType: null,
	recommendationModal: {
		prefill: false,
		recommendationToEdit: null,
		show: false,
		type: 'positive',
		error: false,
		errorCode: false,
		showThankYou: false,
		restaurantId: null
	},
	userQuery: {}
});

const GeneralContextProvider = ({ children }) => {
	const value = useGeneralContextValue();

	return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
};

GeneralContextProvider.propTypes = {
	children: PropTypes.node
};

const useGeneralContext = () => {
	const context = useContext(GeneralContext);

	return context;
};

const GeneralContextConsumer = GeneralContext.Consumer;

export { GeneralContextConsumer, GeneralContextProvider };
export default useGeneralContext;
