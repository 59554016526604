import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Image } from 'components/atoms';
import { withImageSizeBounds } from 'hoc';
import { configureLogoImageSize } from 'utils/image';
import { logoImageSizes } from './types';

/**
 * An image tag that uses HOC {@link withImageSizeBounds} and size configuration {@link configureLogoImageSize}.
 * @param {LogoImage} props
 */
const LogoImage = ({ alt, className, dataCy, src }) => {
	const Logo = withImageSizeBounds(Image, configureLogoImageSize, logoImageSizes, dataCy);
	const classProps = classnames('logo-img', className);

	return <Logo className={classProps} src={src} dataCy={`logo-img-${dataCy}`} alt={alt} />;
};

LogoImage.defaultProps = {
	src: ''
};

/**
 * Image component props shape.
 * @interface Props_Image
 * @property {string} [className=null] Additional className to add to the image element.
 * @property {string} [dataCy=null]    A unique di for cypress tests.
 * @property {string} [src=""]         The image src.
 */
LogoImage.propTypes = {
	alt: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	src: PropTypes.string
};

export default LogoImage;
